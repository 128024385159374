import { BudgetVariability } from 'common/generated/graphql';

// This is the default variability for a category/group if it's not set
export const DEFAULT_BUDGET_VARIABILITY_IF_UNSET = BudgetVariability.FIXED;

export const BUDGET_VARIABILITY_ORDER = {
  [BudgetVariability.FIXED]: 0,
  [BudgetVariability.FLEXIBLE]: 1,
  [BudgetVariability.NON_MONTHLY]: 2,
};

export const BUDGET_VARIABILITY_TO_TITLE_MAPPING = {
  [BudgetVariability.FIXED]: 'Fixed',
  [BudgetVariability.FLEXIBLE]: 'Flexible',
  [BudgetVariability.NON_MONTHLY]: 'Non-Monthly',
};
