import type { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useCallback, useMemo, useRef } from 'react';
import deepIsEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { FlatProgressBar } from 'components/budget/BudgetTable';
import FlexContainer from 'components/lib/ui/FlexContainer';
import { BackgroundBar } from 'components/lib/ui/ProgressBar';
import type { PlanCellMethods } from 'components/plan/PlanCell';
import { PlanCellError } from 'components/plan/PlanCell';
import PlanColumnAmounts from 'components/plan/PlanColumnAmounts';
import {
  PlanGridRow,
  GroupHeaderRow,
  PlanGroupDrawer,
  PROGRESS_BAR_Z_INDEX,
  ALL_PLANNED_SECTION_PADDING_PX,
} from 'components/plan/PlanGrid';
import { PlanUnallocatedBudgetRowAmount } from 'components/plan/PlanUnallocatedBudgetRow';

import { getMonthProgressForMonthStart, isCurrentMonth } from 'common/lib/budget/Progress';
import { isDateWithinRolloverPeriod } from 'common/lib/budget/Rollovers';
import { usePlanContext } from 'lib/contexts/PlanContext';
import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import {
  getAmountForType,
  getRemainingColorForAmounts,
  getSectionHasBottomPadding,
} from 'lib/plan';
import { RowType } from 'lib/plan/Adapters';
import type {
  GridAmountsForGroup,
  PlanGroupData,
  PlanRowData,
  PlanSectionType,
} from 'lib/plan/Adapters';
import fakeCornersMixin from 'lib/styles/fakeCornersMixin';
import {
  getExpandedGroupRow,
  getPlanCollapsedGroups,
  getUnplannedExpandedByGroup,
} from 'selectors';

import { BudgetVariability } from 'common/generated/graphql';

const Root = styled(FlexContainer)`
  flex: 1;
`;

const GroupHeaderRight = styled(GroupHeaderRow)<{ $isCollapsed: boolean }>`
  transition: ${({ theme }) => theme.transition.default};
  transition-delay: ${({ $isCollapsed }) =>
    // give some time for the transition to finish before animating the corner
    $isCollapsed ? '150ms' : 0};
  border-bottom-right-radius: ${({ theme, $isCollapsed }) =>
    $isCollapsed ? theme.radius.medium : 0};
  ${({ theme }) => fakeCornersMixin({ theme, topRight: true })};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const StyledPlanGridRow = styled(PlanGridRow)`
  position: relative;
`;

const GroupContainer = styled.div<{ $hasBottomPadding?: boolean }>`
  padding-bottom: ${({ $hasBottomPadding }) =>
    $hasBottomPadding ? ALL_PLANNED_SECTION_PADDING_PX : 0}px;
`;

const StyledFlatProgressBar = styled(FlatProgressBar).attrs({ roundAppearance: false })`
  bottom: 0;
  z-index: ${PROGRESS_BAR_Z_INDEX}; /* stylelint-disable-line plugin/no-z-index */

  ${BackgroundBar} {
    border-radius: 0;
  }
`;

type Props = {
  amounts: GridAmountsForGroup;
  group: PlanGroupData;
  numColumns: number;
  sectionType: PlanSectionType;
  unallocatedFlexBudgetAmount: number | null;
};

const PlanGroupRight = ({
  amounts,
  group,
  numColumns,
  sectionType,
  unallocatedFlexBudgetAmount,
}: Props) => {
  const { timeframe, openEditCategoryGroup, getDateForColumn, getAmountTypeForColumn } =
    usePlanContext();
  const {
    id: groupId,
    name: groupName,
    rows,
    unplannedCount,
    groupLevelBudgetingEnabled,
    rolloverPeriod: groupRolloverPeriod,
    canBeBudget,
    canMoveBudget,
  } = group;
  const { aggregate, aggregateUnplanned } = amounts;
  const showUnplannedAmounts = useMemo(
    () =>
      R.any(
        (column) => {
          const amounts = aggregateUnplanned[getDateForColumn(column).toISODate()];
          const amount = getAmountForType(amounts, getAmountTypeForColumn(column));
          return !!amount;
        },
        R.range(0, numColumns),
      ),
    [numColumns, getDateForColumn, getAmountTypeForColumn, aggregateUnplanned],
  );

  const isCollapsed = useSelector((state: any) => getPlanCollapsedGroups(state, groupId));
  const isUnplannedExpanded = useSelector((state: any) =>
    getUnplannedExpandedByGroup(state, groupId),
  );

  const firstColumnDate = useMemo(() => getDateForColumn(0), [getDateForColumn]);
  const progressTicks = useMemo(
    () =>
      isCurrentMonth(firstColumnDate)
        ? [{ percent: getMonthProgressForMonthStart(firstColumnDate) }]
        : undefined,
    [firstColumnDate],
  );
  const hasBottomPadding = useMemo(
    () =>
      getSectionHasBottomPadding(
        timeframe,
        unplannedCount,
        isCollapsed,
        groupLevelBudgetingEnabled,
      ),
    [isCollapsed, timeframe, unplannedCount],
  );

  // If the group id is a number then it is a category group
  // otherwise it could be goals or fix/flex
  const groupRowType = RA.isNotNaN(Number(groupId)) ? RowType.CategoryGroup : undefined;

  const groupError: PlanCellError | undefined = useMemo(() => {
    const isSumExceedingGroupBudget =
      RA.isNotNil(unallocatedFlexBudgetAmount) && unallocatedFlexBudgetAmount < 0;

    if (isSumExceedingGroupBudget) {
      return PlanCellError.FlexibleBudgetExceeded;
    }
  }, [unallocatedFlexBudgetAmount]);

  const categoryBudgetAmount = useMemo(
    () => amounts.rowsAggregate[firstColumnDate.toISODate()]?.budgeted || 0,
    [amounts, firstColumnDate],
  );

  const isFlexibleGroup = groupId === BudgetVariability.FLEXIBLE;
  const flexibleBudgetAmount = useMemo(
    () => amounts.aggregate[firstColumnDate.toISODate()]?.budgeted || 0,
    [amounts, firstColumnDate],
  );
  const flexibleBudgetCellRef = useRef<PlanCellMethods>(null);
  const updateFlexBudgetValue = useCallback(() => {
    if (unallocatedFlexBudgetAmount && flexibleBudgetCellRef.current) {
      flexibleBudgetCellRef.current.setValue(categoryBudgetAmount, false);
    }
  }, [unallocatedFlexBudgetAmount, flexibleBudgetCellRef, categoryBudgetAmount]);

  const maybeGroupLevelProgressBar = useMemo(() => {
    const isVisible =
      timeframe === PlanTimeframe.SingleMonth && (groupLevelBudgetingEnabled || isFlexibleGroup);

    if (!isVisible) {
      return null;
    }

    const dateAmounts = aggregate[firstColumnDate.toISODate()];
    return (
      <StyledFlatProgressBar
        color={getRemainingColorForAmounts(dateAmounts, sectionType)}
        value={dateAmounts?.actual ?? 0}
        max={dateAmounts?.budgeted || 1}
        ticks={progressTicks}
        animated
      />
    );
  }, [
    timeframe,
    groupLevelBudgetingEnabled,
    isFlexibleGroup,
    firstColumnDate,
    progressTicks,
    aggregate,
    sectionType,
  ]);

  return (
    <Root column>
      <GroupHeaderRight $isCollapsed={isCollapsed}>
        <PlanColumnAmounts
          numColumns={numColumns}
          amounts={aggregate}
          itemHasRolloverEnabled={
            RA.isNotNil(groupRolloverPeriod) &&
            isDateWithinRolloverPeriod(firstColumnDate, groupRolloverPeriod)
          }
          sectionType={sectionType}
          itemId={groupId}
          itemName={groupName}
          groupLevelBudgetingEnabled={groupLevelBudgetingEnabled ?? false}
          openEditGroupModal={() => openEditCategoryGroup(groupId)}
          canBeBudget={canBeBudget}
          canMoveBudget={canMoveBudget}
          rowType={groupRowType}
          error={groupError}
          flexibleBudgetCellRef={flexibleBudgetCellRef}
          updateFlexBudgetValue={
            // Only provide the update function if the group error is the Flexible Budget Exceeded error
            groupError === PlanCellError.FlexibleBudgetExceeded ? updateFlexBudgetValue : undefined
          }
          isAggregate
        />
        {maybeGroupLevelProgressBar}
      </GroupHeaderRight>
      <PlanGroupDrawer open={!isCollapsed} shouldAnimateOnMount={false}>
        <GroupContainer $hasBottomPadding={hasBottomPadding}>
          {RA.isNotNil(unallocatedFlexBudgetAmount) && (
            <PlanUnallocatedBudgetRowAmount
              amount={unallocatedFlexBudgetAmount}
              numColumns={numColumns}
              getDateForColumn={getDateForColumn}
              onClickUpdateFlexBudget={updateFlexBudgetValue}
              categoryBudgetAmount={categoryBudgetAmount}
              flexibleBudgetAmount={flexibleBudgetAmount}
              error={groupError}
            />
          )}

          {rows.map((rowData) => (
            <PlanGroupRightRow
              key={rowData.id}
              rowData={rowData}
              isUnplannedExpanded={isUnplannedExpanded}
              groupLevelBudgetingEnabled={groupLevelBudgetingEnabled}
              numColumns={numColumns}
              amounts={amounts}
              sectionType={sectionType}
              firstColumnDate={firstColumnDate}
              timeframe={timeframe}
              progressTicks={progressTicks}
              isFlexibleGroup={isFlexibleGroup}
            />
          ))}

          {unplannedCount > 0 && (
            <PlanGridRow>
              {showUnplannedAmounts && (
                <PlanColumnAmounts
                  numColumns={numColumns}
                  amounts={aggregateUnplanned}
                  isAggregate
                  isUnplannedAggregate
                  sectionType={sectionType}
                  canBeBudget={false}
                  canMoveBudget={false}
                />
              )}
            </PlanGridRow>
          )}
        </GroupContainer>
      </PlanGroupDrawer>
    </Root>
  );
};

const PlanGroupRightRow = (props: {
  rowData: PlanRowData;
  isUnplannedExpanded: boolean;
  groupLevelBudgetingEnabled?: boolean | null;
  numColumns: number;
  amounts: GridAmountsForGroup;
  sectionType: PlanSectionType;
  firstColumnDate: DateTime;
  timeframe: PlanTimeframe;
  progressTicks?: { percent: number }[];
  isFlexibleGroup: boolean;
}) => {
  const {
    isUnplannedExpanded,
    rowData,
    sectionType,
    amounts,
    numColumns,
    firstColumnDate,
    timeframe,
    progressTicks,
    isFlexibleGroup,
  } = props;

  const {
    id,
    isUnplanned,
    name,
    icon,
    height,
    rolloverPeriod,
    canBeBudgeted: rowCanBeBudget,
    canMoveBudget: rowCanMoveBudget,
    parentGroupId,
    rowType,
    groupLevelBudgetingEnabled,
    excludeFromBudget,
    budgetVariability,
  } = rowData;

  const isSingleMonth = timeframe === PlanTimeframe.SingleMonth;
  const isCategoryGroup = rowType === RowType.CategoryGroup;

  const maybeRowProgressBar = useMemo(() => {
    const dateAmounts = amounts[id]?.[firstColumnDate.toISODate()];
    const isFlexibleAndHasBudget =
      isFlexibleGroup && dateAmounts?.budgeted && dateAmounts?.budgeted > 0;
    const isPlanned = !isUnplanned; // just for readability

    if (
      isSingleMonth &&
      (!groupLevelBudgetingEnabled || isCategoryGroup) &&
      isPlanned &&
      (isFlexibleAndHasBudget || !isFlexibleGroup)
    ) {
      return (
        <StyledFlatProgressBar
          color={getRemainingColorForAmounts(dateAmounts, sectionType)}
          value={dateAmounts?.actual ?? 0}
          max={dateAmounts?.budgeted || 1}
          ticks={progressTicks}
          animated
        />
      );
    }
  }, [
    timeframe,
    groupLevelBudgetingEnabled,
    isFlexibleGroup,
    amounts,
    firstColumnDate,
    isUnplanned,
  ]);

  const isExpandedGroupRow = useSelector((state: any) =>
    getExpandedGroupRow(state, parentGroupId ?? ''),
  );

  if (parentGroupId) {
    if (!isExpandedGroupRow) {
      return null;
    }
  }

  if (isUnplanned && !isUnplannedExpanded) {
    return null;
  }

  return (
    <StyledPlanGridRow key={id} $grayBackground={isUnplanned || !!parentGroupId} $height={height}>
      <PlanColumnAmounts
        groupLevelBudgetingEnabled={groupLevelBudgetingEnabled ?? false}
        excludeFromBudget={excludeFromBudget}
        numColumns={numColumns}
        amounts={amounts[id]}
        itemId={id}
        itemName={name}
        itemIcon={icon}
        itemHasRolloverEnabled={
          RA.isNotNil(rolloverPeriod) && isDateWithinRolloverPeriod(firstColumnDate, rolloverPeriod)
        }
        sectionType={sectionType}
        canBeBudget={rowCanBeBudget}
        canMoveBudget={rowCanMoveBudget}
        rowType={rowType}
        hasParentRow={!!parentGroupId}
        budgetVariability={budgetVariability}
      />
      {maybeRowProgressBar}
    </StyledPlanGridRow>
  );
};

export default React.memo(PlanGroupRight, deepIsEqual);
