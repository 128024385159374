import type { DateTime } from 'luxon';
import { useMemo } from 'react';

import {
  getSafeToSpendAmount,
  rolloverDisplayBudgetAdapter,
  rolloversBudgetSummaryAdapter,
} from 'common/lib/budget/Adapters';
import { BUDGET_DATA_QUERY } from 'common/lib/graphQl/budgets';
import useQuery from 'common/lib/hooks/useQuery';

import { BudgetSystem } from 'common/generated/graphql';

type Options = {
  skip?: boolean;
  isFlexBudgetingEnabled?: boolean;
};

const useBudgetData = (
  date: DateTime,
  { skip = false, isFlexBudgetingEnabled = false }: Options | undefined = {},
) => {
  const dateString = date.toISODate();
  const cacheKey = dateString;

  const { data, ...queryInfo } = useQuery(BUDGET_DATA_QUERY, {
    variables: {
      startDate: dateString,
      endDate: date.endOf('month').toISODate(),
      cacheKey,
    },
    skip,
  });

  const hasData = data?.cacheKey === cacheKey;
  // Account for the case where the budget system is not yet loaded
  const isFlexBudgetSystem = data?.budgetSystem
    ? data.budgetSystem === BudgetSystem.FIXED_AND_FLEX
    : undefined;
  const useFlexBudgetingGroups = isFlexBudgetSystem && isFlexBudgetingEnabled;

  const budgetStatusData = data?.budgetStatus;

  const budgetSummaryData = useMemo(
    () => (data ? rolloversBudgetSummaryAdapter(data, date) : []),
    [data, date],
  );

  const safeToSpend = useMemo(() => getSafeToSpendAmount(budgetSummaryData), [budgetSummaryData]);

  const budgetListData = useMemo(
    () => (hasData ? rolloverDisplayBudgetAdapter(data, date, useFlexBudgetingGroups) : undefined),
    [date, data, hasData, useFlexBudgetingGroups],
  );

  return {
    ...queryInfo,
    budgetListData,
    budgetStatusData,
    budgetSummaryData,
    isFlexBudgetSystem,
    safeToSpend,
    shouldShowSafeToSpend: !!isFlexBudgetSystem,
  };
};

export default useBudgetData;
