import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import CurrencyInput from 'components/lib/form/CurrencyInput';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';
import { OverlayTrigger } from 'components/lib/ui/popover';
import BudgetHistoricalChartPopover from 'components/lib/ui/popover/BudgetHistoricalChartPopover';
import { INPUT_HEIGHT_PX } from 'components/plan/PlanGrid';

const INPUT_WIDTH_PX = 140;

const AmountInput = styled(CurrencyInput)`
  width: ${INPUT_WIDTH_PX}px;
  height: ${INPUT_HEIGHT_PX}px;
  text-align: right;
  background: none;
`;

const StyledCashFlowCurrency = styled(CashFlowCurrency)`
  padding-right: 13px; /* Compensate for the margin-right + border on the input */
`;

type Props = {
  itemId?: GraphQlUUID;
  value?: number;
  isGroup?: boolean;
  canEdit?: boolean;
};

const OnboardingAmountCell = ({ itemId, value, canEdit, isGroup }: Props) => {
  const inputRef = useRef<HTMLElement>(null);
  const [localValue, setLocalValue] = useState(value ?? 0);

  const onClickChartAmount = useCallback(
    (amount: number) => {
      setLocalValue(amount);
      setTimeout(() => inputRef.current?.blur(), 0); // Need setTimeout so localValue updates before blur
    },
    [setLocalValue, inputRef],
  );

  if (!canEdit) {
    return <StyledCashFlowCurrency bold={!!isGroup} round type="income" value={localValue} />;
  }

  return (
    <>
      <OverlayTrigger
        overlay={
          !!itemId && (
            <BudgetHistoricalChartPopover
              itemId={itemId}
              isCategoryGroup={isGroup}
              isIncome
              onClickBar={onClickChartAmount}
            />
          )
        }
        placement="bottom-end"
      >
        {({ open, close }) => (
          <AmountInput
            ref={inputRef}
            tabIndex={0}
            name="budgeted"
            selectOnFocus
            value={localValue}
            onChange={setLocalValue}
            onBlur={() => {
              close();
            }}
            onFocus={() => {
              open();
            }}
            autoComplete="off"
            maskOptions={{ allowNegative: true }}
            {...sensitiveClassProps}
            bold={!!isGroup}
          />
        )}
      </OverlayTrigger>
    </>
  );
};

export default OnboardingAmountCell;
