import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import InfiniteScroll from 'components/lib/ui/InfiniteScroll';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Scroll from 'components/lib/ui/Scroll';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { defaultButtonMixin } from 'components/lib/ui/button/DefaultButton';
import { useOverlay } from 'components/lib/ui/popover';
import Notification from 'components/notifications/Notification';
import NotificationCenterEmpty from 'components/notifications/NotificationCenterEmpty';
import PinnedNotification from 'components/notifications/PinnedNotification';

import { useNotifications } from 'common/lib/hooks/notifications/useNotifications';
import { findMarkdownLink } from 'common/utils/Markdown';
import typewriter from 'lib/analytics/typewriter';

import { ActivityEventType } from 'common/generated/graphQlTypes/globalTypes';

const Root = styled(Card)`
  width: min(100vw, 450px);
  overflow: hidden;
  max-height: min(calc(100vh - 100px), 800px);
`;

const Header = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacing.default};
  align-items: center;
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
  flex-shrink: 0;
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true, column: true })`
  padding: ${({ theme }) => theme.spacing.xlarge} 0;
`;

const StyledNotification = styled(Notification)`
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
  }
`;

const DefaultAsyncButton = styled(AsyncButton)`
  ${defaultButtonMixin};
`;

const GroupLabel = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  color: ${({ theme }) => theme.color.textLight};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.xlarge};
`;

type OnClickNotificationArgs = {
  id: string;
  action: string | null;
};

const NotificationCenter = () => {
  const { close } = useOverlay();
  const history = useHistory();

  const {
    pinnedNotifications,
    unreadNotifications,
    readNotifications,
    notificationsCount,
    loading,
    hasNextPage,
    markAllAsRead,
    clearAll,
    isLoadingClearAll,
    markAsRead,
    dismissActivity,
    requestNextPage,
    hasUnreadNotifications,
  } = useNotifications();

  useEffect(
    () => () => {
      if (hasUnreadNotifications) {
        markAllAsRead();
      }
    },
    [markAllAsRead, hasUnreadNotifications],
  );

  const onClickNotification = ({ id, action }: OnClickNotificationArgs) => {
    markAsRead({ variables: { id } });
    const url = action && findMarkdownLink(action);
    if (url) {
      history.push(url);
    }
  };

  const notificationSections = notificationsCount
    ? [
        { data: pinnedNotifications },
        { data: unreadNotifications, key: 'Unread' },
        { data: readNotifications, key: 'Read' },
      ].filter(({ data }) => data.length > 0)
    : [];

  const renderNotification = ({
    id,
    title,
    body,
    action,
    createdAt,
    readAt,
    isPinned,
    logo,
    actionLabel,
    eventType,
  }: any) => {
    // Pinned notifications does not exist without an event_type
    if (isPinned && eventType) {
      // @ts-ignore
      const pinnedNotificationType = ActivityEventType[eventType];

      return (
        <PinnedNotification
          key={id}
          title={title ?? undefined}
          body={body ?? undefined}
          logo={logo}
          actionLabel={actionLabel}
          date={createdAt}
          read={!!readAt}
          onClick={() => {
            close();
            dismissActivity({ variables: { id } });
            onClickNotification({ id, action });
            typewriter.pinnedNotificationOpened({
              type: pinnedNotificationType,
            });
          }}
          onDismiss={() => {
            dismissActivity({ variables: { id } });
            typewriter.pinnedNotificationDismissed({
              type: pinnedNotificationType,
            });
          }}
        />
      );
    }

    return (
      <StyledNotification
        key={id}
        title={title ?? undefined}
        body={body ?? undefined}
        date={createdAt}
        read={!!readAt}
        onClick={() => onClickNotification({ id, action })}
      />
    );
  };

  return (
    <Root>
      <Header>
        <FlexContainer alignCenter>Notifications</FlexContainer>
        <DefaultAsyncButton
          disabled={!notificationsCount}
          onClick={() => clearAll()}
          pending={isLoadingClearAll}
        >
          Clear all
        </DefaultAsyncButton>
      </Header>
      <Switch>
        <Case when={loading && !notificationsCount}>
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        </Case>
        <Case when={notificationsCount === 0}>
          <NotificationCenterEmpty />
        </Case>
        <Case default>
          <Scroll>
            <InfiniteScroll hasNextPage={hasNextPage} onRequestNextPage={requestNextPage}>
              {notificationSections.map(({ data, key }) => (
                <React.Fragment key={key}>
                  {key && <GroupLabel>{key}</GroupLabel>}
                  {data.map(renderNotification)}
                </React.Fragment>
              ))}
            </InfiniteScroll>
          </Scroll>
        </Case>
      </Switch>
    </Root>
  );
};

export default NotificationCenter;
