import { gql } from 'common/generated/gql';

export const USER_FIELDS_FRAGMENT = gql(/* GraphQL */ `
  fragment UserFields on User {
    birthday
    email
    id
    isSuperuser
    name
    timezone
    hasPassword
    hasMfaOn
    externalAuthProviderNames
    pendingEmailUpdateVerification {
      email
    }
    profilePicture {
      id
      cloudinaryPublicId
      thumbnailUrl
    }
    profilePictureUrl
    activeSupportAccountAccessGrant {
      id
      createdAt
      expiresAt
    }
  }
`);

export const GET_ME = gql(/* GraphQL */ `
  query Common_GetMe {
    me {
      ...UserFields
      id
      profile {
        id
        hasSeenCategoriesManagementTour
        dismissedTransactionsListUpdatesTourAt
        viewedMarkAsReviewedUpdatesCalloutAt
        dismissedRecurringWalkthroughAt
      }
    }
  }
`);

export const UPDATE_ME = gql(/* GraphQL */ `
  mutation Common_UpdateMe($input: UpdateMeInput!) {
    updateMe(input: $input) {
      user {
        ...UserFields
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const HAS_UNREAD_QUERY = gql(/* GraphQL */ `
  query Common_HasActivity {
    me {
      id
      hasNewActivity
    }
  }
`);

export const CHECK_ACTIVITY_MUTATION = gql(/* GraphQL */ `
  mutation Common_CheckActivityMutation {
    checkActivity {
      user {
        id
        hasNewActivity
      }
    }
  }
`);

export const UPDATE_USER_PROFILE_MUTATION = gql(/* GraphQL */ `
  mutation Common_UpdateUserProfile(
    $updateProfileInput: UpdateUserProfileInput!
    $updateMeInput: UpdateMeInput!
    $updateMyHouseholdInput: UpdateMyHouseholdInput = {}
  ) {
    updateUserProfile(input: $updateProfileInput) {
      userProfile {
        id
        birthday
        collaboratesOnFinancesDetailed
        hasSeenCategoriesManagementTour
        aiAssistantOptedInAt
        viewedMarkAsReviewedUpdatesCalloutAt
      }
      errors {
        ...PayloadErrorFields
      }
    }
    updateMe(input: $updateMeInput) {
      user {
        id
        name
      }
      errors {
        ...PayloadErrorFields
      }
    }
    updateMyHousehold(input: $updateMyHouseholdInput) {
      household {
        country
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);
