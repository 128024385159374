import React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import OnboardingCard from 'components/plan/onboarding/OnboardingCard';
import OnboardingCategoryVariabilityCardRow from 'components/plan/onboarding/OnboardingCategoryVariabilityCardRow';

import { spacing } from 'common/lib/theme/dynamic';

import type { BudgetVariability } from 'common/generated/graphql';

const InfoIcon = styled(Icon).attrs({ name: 'info', size: 14 })`
  display: inline-block;
  margin-left: ${spacing.xxsmall};
  margin-right: 6px;
  cursor: default;

  /* Align with text */
  position: relative;
  top: 1px;
`;

type Props = {
  group: {
    name: string;
    categories: {
      id: string;
      name: string;
      icon: string;
      budgetVariability?: BudgetVariability | null;
    }[];
  };
};

const OnboardingCategoryVariabilityCard = ({ group: { name, categories } }: Props) => (
  <OnboardingCard title={name} titleAccessory={TitleAccessory}>
    {categories.map((category) => (
      <OnboardingCategoryVariabilityCardRow
        key={category.id}
        icon={category.icon}
        name={category.name}
        variability={category.budgetVariability}
      />
    ))}
  </OnboardingCard>
);

const TitleAccessory = (
  <Text size="small" color="textLight" weight="medium">
    Category type <InfoIcon />
  </Text>
);

export default OnboardingCategoryVariabilityCard;
