import pluralize from 'pluralize';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import FeatureOnboardingPage from 'components/lib/layouts/FeatureOnboardingPage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';
import OnboardingAccountGroup from 'components/plan/onboarding/OnboardingAccountGroup';

import useQuery from 'common/lib/hooks/useQuery';
import { spacing } from 'common/lib/theme/dynamic';

import { AccountTypeName } from 'common/constants/accounts';
import * as COPY from 'common/constants/copy';

import { gql } from 'common/generated/gql';
import type { Web_BudgetOnboardingGetAccountsQuery } from 'common/generated/graphql';

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: ${spacing.xlarge};
`;

const OtherAccounts = styled.div`
  margin-top: ${spacing.xxlarge};
`;

const StyledFlexContainer = styled(FlexContainer).attrs({
  full: true,
  column: true,
  gap: 'default',
})`
  width: 600px;
`;

type AccountsType =
  Web_BudgetOnboardingGetAccountsQuery['accountTypeSummaries'][number]['accounts'];

type Props = {
  onNext: () => void;
  onBack: () => void;
  onCancel: () => void;
  progress: number;
};

const OnboardingAccounts = ({ onNext, onBack, onCancel, progress }: Props) => {
  const { data, isLoadingInitialData } = useQuery(QUERY);

  const creditCardSummary = useMemo(
    () => data?.accountTypeSummaries.find((a) => a.type.name === AccountTypeName.CREDIT),
    [data],
  );

  const cashSummary = useMemo(
    () => data?.accountTypeSummaries.find((a) => a.type.name === AccountTypeName.DEPOSITORY),
    [data],
  );

  const sortByBalance = R.sort<AccountsType[number]>(R.descend(R.prop('displayBalance')));

  // Sort messes up the type here so I'm casting it back to the original
  const cashAccounts = useMemo(() => sortByBalance(cashSummary?.accounts || []), [cashSummary]);
  const creditCardAccounts = useMemo(
    () => sortByBalance(creditCardSummary?.accounts || []),
    [creditCardSummary],
  );

  // Number of accounts that are not cash or credit
  const otherAccountsNumber = useMemo(
    () =>
      (data?.accountTypeSummaries.reduce((acc, { accounts }) => acc + accounts.length, 0) || 0) -
      cashAccounts.length -
      creditCardAccounts.length,
    [data],
  );

  const hasNoAccounts = cashAccounts.length === 0 && creditCardAccounts.length === 0;

  return (
    <FeatureOnboardingPage
      pageName="Accounts"
      progress={progress}
      title={COPY.BUDGET.ONBOARDING.ACCOUNTS.TITLE}
      description={COPY.BUDGET.ONBOARDING.ACCOUNTS.DESCRIPTION}
      onClickBack={onBack}
      onClickNext={onNext}
      onClickCancel={onCancel}
      descriptionMaxWidth={600}
      hideNextButton={hasNoAccounts}
    >
      <FlexContainer center column>
        {isLoadingInitialData ? (
          <StyledLoadingSpinner />
        ) : (
          <StyledFlexContainer>
            <OnboardingAccountGroup
              title="Credit cards"
              totalBalance={creditCardSummary?.totalDisplayBalance || 0}
              accounts={creditCardAccounts}
              emptyText="You haven't added any credit card accounts yet"
            />

            <OnboardingAccountGroup
              title="Cash"
              totalBalance={cashSummary?.totalDisplayBalance || 0}
              accounts={cashAccounts}
              emptyText="You haven't added any cash accounts yet"
            />
          </StyledFlexContainer>
        )}
        {otherAccountsNumber > 0 && (
          <OtherAccounts>
            <Text color="textLight">
              {otherAccountsNumber} other {pluralize('account', otherAccountsNumber)}{' '}
              {otherAccountsNumber === 1 ? 'has' : 'have'} also been added
            </Text>
          </OtherAccounts>
        )}
      </FlexContainer>
    </FeatureOnboardingPage>
  );
};

export const QUERY = gql(/* GraphQL */ `
  query Web_BudgetOnboardingGetAccounts {
    accountTypeSummaries {
      isAsset
      totalDisplayBalance

      type {
        name
      }
      accounts {
        id
        displayName
        displayBalance
        icon
        logoUrl
      }
    }
  }
`);

export default OnboardingAccounts;
