import { useMutation } from '@apollo/client';
import { emojiIndex } from 'emoji-mart';
import { DateTime } from 'luxon';
import React from 'react';

import CreateEditCategoryFormFields from 'components/categories/CreateEditCategoryFormFields';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';

import { BudgetRolloverPeriodType } from 'common/constants/budget';
import { CACHE_KEYS } from 'common/constants/cache';

import { gql } from 'common/generated/gql';
import type { CreateCategoryInput, Web_CreateCategoryMutation } from 'common/generated/graphql';

const getInitialValues = (
  initialValues: Partial<CreateCategoryInput> = {
    icon: '❓',
    name: '',
  },
): CreateCategoryInput =>
  ({
    ...initialValues,
    name: initialValues.name ?? '',
    // @ts-ignore - type lib is wrong
    icon: initialValues.icon ?? emojiIndex.search(initialValues.name ?? '')?.[0]?.native ?? '❓',
    rolloverEnabled: false,
    rolloverType: BudgetRolloverPeriodType.Monthly,
    rolloverStartMonth: DateTime.local().startOf('month').toISODate(),
    excludeFromBudget: false,
  }) as CreateCategoryInput;

type Props = {
  onCreate?: (
    category: NonNullable<NonNullable<Web_CreateCategoryMutation['createCategory']>['category']>,
  ) => void;
  initialValues?: Partial<CreateCategoryInput>;
};

const CreateCategoryModal = ({ onCreate, initialValues }: Props) => {
  const [createCategory] = useMutation(CREATE_CATEGORY, {
    update: (cache) => {
      cache.evict({ fieldName: CACHE_KEYS.CATEGORIES });
      cache.evict({ fieldName: CACHE_KEYS.CATEGORY_GROUPS });
    },
    onQueryUpdated(observableQuery) {
      return observableQuery.refetch();
    },
  });

  return (
    <ModalCard title="Create Category">
      <Form
        initialValues={getInitialValues(initialValues)}
        mutation={createCategory}
        onSubmitSuccess={(response) => {
          if (response?.createCategory?.category) {
            onCreate?.(response.createCategory.category);
          }
        }}
      >
        <CardBody>
          <CreateEditCategoryFormFields />
        </CardBody>
        <CardFooter>
          <FormSubmitButton disableWhenValuesUnchanged={false} size="small">
            Save
          </FormSubmitButton>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};

const CREATE_CATEGORY = gql(`
  mutation Web_CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      errors {
        ...PayloadErrorFields
      }
      category {
        id
        ...CategoryFormFields
      }
    }
  }
`);

export default CreateCategoryModal;
