import React from 'react';
import { FiCheck } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import routes from 'constants/routes';

export const IGNORE_ACCOUNT_VALUE = 'ignore';

const Root = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  max-width: 765px;
  width: 100%;
`;

const Title = styled(Text).attrs({ size: 'xlarge', weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const RowContainer = styled.div`
  width: 100%;
`;

const OptionRow = styled(FlexContainer)<{ isSelected?: boolean }>`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.default};
  transition: ${({ theme }) => theme.transition.default};
  border: ${({ theme }) => `1px solid ${theme.color.grayFocus}`};
  border-top-width: 0px;
  display: flex;
  align-items: center;

  :first-child {
    border-radius: 6px 6px 0 0;
    border-top-width: 1px;
    margin-top: 30px;
  }

  :last-child {
    border-radius: 0 0 6px 6px;
  }
`;

const RowTitle = styled(Text).attrs({ size: 'default', weight: 'medium' })``;
const RowDescription = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  display: block;
  padding-right: ${({ theme }) => theme.spacing.default};
`;

const RowButton = styled(DefaultButton)`
  width: 100%;
  max-width: 150px;
  max-height: 50px;
`;

const CheckContainer = styled(FlexContainer).attrs({ center: true })`
  background-color: ${({ theme }) => theme.color.green};
  border-radius: ${({ theme }) => theme.radius.round};
  width: 72px;
  height: 72px;
`;

const CheckIcon = styled(FiCheck)`
  width: 48px;
  height: 48px;
  color: ${({ theme }) => theme.color.textWhite};
`;

const MapSpinwheelLiabilitiesSuccess = () => {
  const history = useHistory();

  return (
    <Root>
      <CheckContainer>
        <CheckIcon />
      </CheckContainer>
      <Title>Liabilities imported! Here&apos;s what to do next.</Title>

      <RowContainer>
        <OptionRow>
          <div>
            <RowTitle>View your recurring items</RowTitle>
            <RowDescription>
              You can now look visualize your bills and subscriptions in a single place, and manage
              your cash flow.
            </RowDescription>
          </div>

          <RowButton onClick={() => history.push(routes.recurring())}>View recurring</RowButton>
        </OptionRow>
      </RowContainer>
    </Root>
  );
};

export default MapSpinwheelLiabilitiesSuccess;
