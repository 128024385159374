import queryString from 'query-string';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import OnboardingAccounts from 'components/routes/budget/OnboardingAccounts';
import OnboardingExpenses from 'components/routes/budget/OnboardingExpenses';
import OnboardingFlexIntroduction from 'components/routes/budget/OnboardingFlexIntroduction';
import OnboardingIncome from 'components/routes/budget/OnboardingIncome';
import OnboardingMethodChoice from 'components/routes/budget/OnboardingMethodChoice';
import OnboardingSelectVariabilities from 'components/routes/budget/OnboardingSelectVariabilities';
import OnboardingSuccess from 'components/routes/budget/OnboardingSuccess';

import routes from 'constants/routes';

import { BudgetSystem } from 'common/generated/graphql';

enum OnboardingFlowSteps {
  Accounts = 'Accounts',
  Income = 'Income',
  MethodChoice = 'MethodChoice',
  FlexIntroduction = 'FlexIntroduction',
  Variabilities = 'Variabilities',
  Expenses = 'Expenses',
  Success = 'Success',
}

const OnboardingFlow = () => {
  const history = useHistory();

  const [selectedBudgetSystem, setSelectedBudgetSystem] = useState<BudgetSystem>();

  const stepParam = queryString.parse(window.location.search).step;

  const currentStep = useMemo(
    () =>
      stepParam && Object.values(OnboardingFlowSteps).includes(stepParam as OnboardingFlowSteps)
        ? (stepParam as OnboardingFlowSteps)
        : OnboardingFlowSteps.Accounts,
    [stepParam],
  );

  const updateStep = (newStep: OnboardingFlowSteps) => {
    const params = { step: newStep };

    const url = queryString.stringifyUrl({
      url: window.location.pathname,
      query: params,
    });

    history.push(url);
  };

  const onCancel = () => {
    history.push(routes.budget.path);
  };

  const OnboardingFlowStepsValues = Object.values(OnboardingFlowSteps);
  const progress =
    (OnboardingFlowStepsValues.indexOf(currentStep) + 1) / OnboardingFlowStepsValues.length;

  switch (currentStep) {
    case OnboardingFlowSteps.Accounts: {
      return (
        <OnboardingAccounts
          onCancel={onCancel}
          onBack={onCancel}
          onNext={() => updateStep(OnboardingFlowSteps.Income)}
          progress={progress}
        />
      );
    }
    case OnboardingFlowSteps.Income: {
      return (
        <OnboardingIncome
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowSteps.Accounts)}
          onNext={() => updateStep(OnboardingFlowSteps.MethodChoice)}
          progress={progress}
        />
      );
    }
    case OnboardingFlowSteps.MethodChoice: {
      return (
        <OnboardingMethodChoice
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowSteps.Income)}
          onSelectFixFlexBudget={() => {
            setSelectedBudgetSystem(BudgetSystem.FIXED_AND_FLEX);
            updateStep(OnboardingFlowSteps.FlexIntroduction);
          }}
          onSelectCategoryBudget={() => {
            setSelectedBudgetSystem(BudgetSystem.GROUPS_AND_CATEGORIES);
            updateStep(OnboardingFlowSteps.Expenses);
          }}
          progress={progress}
        />
      );
    }
    case OnboardingFlowSteps.FlexIntroduction: {
      return (
        <OnboardingFlexIntroduction
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowSteps.MethodChoice)}
          onNext={() => updateStep(OnboardingFlowSteps.Variabilities)}
          progress={progress}
        />
      );
    }
    case OnboardingFlowSteps.Variabilities: {
      return (
        <OnboardingSelectVariabilities
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowSteps.FlexIntroduction)}
          onNext={() => updateStep(OnboardingFlowSteps.Expenses)}
          progress={progress}
        />
      );
    }
    case OnboardingFlowSteps.Expenses: {
      return (
        <OnboardingExpenses
          onCancel={onCancel}
          onBack={() =>
            updateStep(
              selectedBudgetSystem === BudgetSystem.FIXED_AND_FLEX
                ? OnboardingFlowSteps.FlexIntroduction
                : OnboardingFlowSteps.MethodChoice,
            )
          }
          onNext={() => updateStep(OnboardingFlowSteps.Success)}
          progress={progress}
        />
      );
    }
    case OnboardingFlowSteps.Success: {
      return (
        <OnboardingSuccess
          onBack={() => updateStep(OnboardingFlowSteps.Expenses)}
          onCancel={onCancel}
          onNext={onCancel}
          progress={progress}
        />
      );
    }
  }
};

export default OnboardingFlow;
