import * as R from 'ramda';
import React, { useMemo } from 'react';

import { useFormContext } from 'common/components/form/FormContext';
import CombinedEmojiAndTextFields from 'components/lib/form/CombinedEmojiAndTextFields';
import CurrencyField from 'components/lib/form/CurrencyField';
import FieldCell from 'components/lib/form/FieldCell';
import RadioGroupField from 'components/lib/form/RadioGroupField';
import SelectField from 'components/lib/form/SelectField';
import ToggleField from 'components/lib/form/ToggleField';
import FeatureFlagGate from 'components/lib/higherOrder/FeatureFlagGate';
import Link from 'components/lib/ui/Link';
import PremiumBadge from 'components/premium/PremiumBadge';
import PremiumFeatureOverlayTrigger from 'components/premium/PremiumFeatureOverlayTrigger';

import { getRolloverStartMonthOptions } from 'common/lib/budget/Rollovers';
import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';
import useCategoryGroupSelectOptions from 'lib/hooks/useCategoryGroupSelectOptions';

import { ROLLOVER_ENABLED_FIELD_NAME } from 'common/constants/budget';
import { BUDGET, BUDGET_VARIABILITY } from 'common/constants/copy';
import {
  HELP_CENTER_BUDGETS_ARTICLE_URL,
  HELP_CENTER_ROLLOVER_ARTICLE_URL,
} from 'common/constants/externalUrls';
import { ProductFeature } from 'common/constants/premium';

import { gql } from 'common/generated/gql';
import { BudgetSystem, CategoryGroupType, BudgetVariability } from 'common/generated/graphql';

export const CATEGORY_NAME_FIELD_NAME = 'name';
export const GROUP_FIELD_NAME = 'group';

type Props = {
  categoryType?: string;
  isSystemCategory?: boolean;
  systemCategoryDisplayName?: string | null;
};

const CreateEditCategoryFormFields = ({
  categoryType = undefined,
  isSystemCategory = false,
  systemCategoryDisplayName,
}: Props) => {
  const { budgetSystem } = useHouseholdPreferences();
  const [isLoadingGroups, groupOptions, categoryGroups] =
    useCategoryGroupSelectOptions(categoryType);

  const { getFieldMeta } = useFormContext();
  const { value: isRolloverEnabled } = getFieldMeta(ROLLOVER_ENABLED_FIELD_NAME);
  const { value: categoryName } = getFieldMeta(CATEGORY_NAME_FIELD_NAME);
  const { value: group } = getFieldMeta(GROUP_FIELD_NAME);

  const rolloverStartMonthOptions = useMemo(() => getRolloverStartMonthOptions(), []);
  const categoryGroup = R.find(R.propEq('id', group), categoryGroups ?? []);
  const isGroupBudgetingEnabledForGroup = categoryGroup?.groupLevelBudgetingEnabled ?? true;
  const groupType = categoryGroup?.type ?? categoryType;

  const textFieldDescription = `This system category automatically categorizes transactions related to ${
    systemCategoryDisplayName ?? categoryName
  }.`;

  const options = useMemo(() => {
    if (categoryType) {
      return groupOptions;
    }

    // Have to maintain both old and new values for now or type checking complains
    const groupedByType: Record<
      CategoryGroupType,
      { options: typeof groupOptions; label: string }
    > = {
      [CategoryGroupType.E]: {
        label: 'Expense',
        options: [],
      },
      [CategoryGroupType.EXPENSE]: {
        label: 'Expense',
        options: [],
      },
      [CategoryGroupType.I]: {
        label: 'Income',
        options: [],
      },
      [CategoryGroupType.INCOME]: {
        label: 'Income',
        options: [],
      },
      [CategoryGroupType.T]: {
        label: 'Transfer',
        options: [],
      },
      [CategoryGroupType.TRANSFER]: {
        label: 'Transfer',
        options: [],
      },
    };

    groupOptions.forEach((option) => {
      const groupType = option.type;

      if (!groupedByType[groupType]) {
        return;
      }

      groupedByType[groupType].options = [...groupedByType[groupType].options, option];
    });

    return Object.values(groupedByType);
  }, [categoryType, groupOptions]);

  const shouldShowFixFlex =
    groupType === CategoryGroupType.EXPENSE &&
    !isGroupBudgetingEnabledForGroup &&
    budgetSystem === BudgetSystem.FIXED_AND_FLEX;

  return (
    <>
      <div>
        <CombinedEmojiAndTextFields
          label="Icon & Name"
          emojiField={{
            name: 'icon',
          }}
          textField={{
            name: CATEGORY_NAME_FIELD_NAME,
            placeholder: 'New Category',
            required: true,
            autoComplete: 'off',
          }}
          description={isSystemCategory && categoryName ? textFieldDescription : undefined}
        />
      </div>
      <SelectField name="group" options={options} isLoading={isLoadingGroups} required />

      {shouldShowFixFlex && (
        <FeatureFlagGate name="fixed-flex-budgeting">
          <RadioGroupField
            required
            name="budgetVariability"
            label="Type"
            tooltip={BUDGET.BUDGET_VARIABILITY_TOOLTIP_CONTENT}
            options={[
              {
                value: BudgetVariability.FIXED,
                label: BUDGET_VARIABILITY.fixed.label,
                description: BUDGET_VARIABILITY.fixed.description,
              },
              {
                value: BudgetVariability.FLEXIBLE,
                label: BUDGET_VARIABILITY.flex.label,
                description: BUDGET_VARIABILITY.flex.description,
              },
              {
                value: BudgetVariability.NON_MONTHLY,
                label: BUDGET_VARIABILITY.nonMonthly.label,
                description: BUDGET_VARIABILITY.nonMonthly.description,
              },
            ]}
          />
        </FeatureFlagGate>
      )}

      {groupType === CategoryGroupType.EXPENSE && !isGroupBudgetingEnabledForGroup && (
        <PremiumFeatureOverlayTrigger feature={ProductFeature.rollovers} placement="bottom-end">
          {({ hasAccess }) => (
            <FieldCell
              rightAccessory={
                hasAccess ? (
                  <ToggleField name={ROLLOVER_ENABLED_FIELD_NAME} hideLabel />
                ) : (
                  <PremiumBadge />
                )
              }
              title="Make this category a monthly rollover"
              subtitle={
                <>
                  Every month the remaining balance will roll over to the next month.{' '}
                  <Link href={HELP_CENTER_ROLLOVER_ARTICLE_URL} target="_blank">
                    Learn more
                  </Link>
                </>
              }
            >
              {isRolloverEnabled ? (
                <>
                  <SelectField
                    name="rolloverStartMonth"
                    label="Starting Month"
                    options={rolloverStartMonthOptions}
                  />

                  <CurrencyField
                    name="rolloverStartingBalance"
                    label="Starting Balance"
                    placeholder="$0.00"
                    maskOptions={{
                      prefix: '$',
                      allowDecimal: true,
                      decimalLimit: 2,
                      allowNegative: false,
                    }}
                  />
                </>
              ) : null}
            </FieldCell>
          )}
        </PremiumFeatureOverlayTrigger>
      )}

      <FieldCell
        rightAccessory={<ToggleField name="excludeFromBudget" hideLabel />}
        title="Exclude this category from the budget"
        subtitle={
          <>
            This category and any transactions linked to it will be hidden from your budget.{' '}
            <Link href={HELP_CENTER_BUDGETS_ARTICLE_URL} target="_blank">
              Learn more
            </Link>
          </>
        }
      />
    </>
  );
};

CreateEditCategoryFormFields.fragments = {
  CategoryFormFields: gql(`
    fragment CategoryFormFields on Category {
      id
      order
      name
      icon
      systemCategory
      systemCategoryDisplayName
      budgetVariability
      excludeFromBudget
      isSystemCategory
      isDisabled
      group {
        id
        type
        groupLevelBudgetingEnabled
      }
      rolloverPeriod {
        id
        startMonth
        startingBalance
      }
    }
  `),
};

export default CreateEditCategoryFormFields;
