import * as R from 'ramda';

import { TITLE_FOR_STATUS, COLOR_FOR_STATUS } from 'common/constants/institutions';

import { DataProviderLegacy } from 'common/generated/graphQlTypes/globalTypes';
import { InstitutionStatus } from 'common/types/institutions';

export const getInstitutionPlaidStatus = (
  institution: any,
  status: 'transactionsStatus' | 'balanceStatus',
): InstitutionStatus =>
  R.pathOr(institution?.status, [status], institution) || InstitutionStatus.Healthy;

/**
 * We always show "Healthy" for accounts linked with Finicity because we don't have granular status
 * information like we do for Plaid accounts. And for now we're just assuming that if Finicity is
 * enabled for an institution then we know the connection works. This can be improved in the future.
 */
export const getStatusForDataProvider = (
  status: InstitutionStatus,
  dataProvider?: string | null,
  syncDisabled?: boolean,
) => {
  if (syncDisabled) {
    return InstitutionStatus.SyncDisabled;
  }
  return dataProvider === DataProviderLegacy.FINICITY ? InstitutionStatus.Healthy : status;
};

export const validateInstitutionReportForm = (values: Record<string, unknown>) => {
  const { additionalNotes, ...issues } = values;
  const errors: Record<string, string> = {};
  const checkedIssues = Object.entries(issues).filter(([, value]) => value === true);

  if (R.isEmpty(checkedIssues)) {
    errors.issues = 'Select at least one option';
  }

  return errors;
};

const DISCONNECTED_TITLE = 'Disconnected';

type InstitutionConnectionStatus = {
  transactionsStatus: InstitutionStatus;
  balanceStatus: InstitutionStatus;
};

const isInstitutionDown = ({ transactionsStatus, balanceStatus }: InstitutionConnectionStatus) =>
  balanceStatus === InstitutionStatus.Down || transactionsStatus === InstitutionStatus.Down;

const isInstitutionDegraded = ({
  transactionsStatus,
  balanceStatus,
}: InstitutionConnectionStatus) =>
  balanceStatus === InstitutionStatus.Degraded || transactionsStatus === InstitutionStatus.Degraded;

type EvaluateStatusArgs = InstitutionConnectionStatus & {
  showTransactionSyncingStatus: boolean;
};

export const evaluateInstitutionStatus = ({
  transactionsStatus,
  balanceStatus,
  showTransactionSyncingStatus,
}: EvaluateStatusArgs) => {
  if (isInstitutionDown({ transactionsStatus, balanceStatus })) {
    return InstitutionStatus.Down;
  }

  if (isInstitutionDegraded({ transactionsStatus, balanceStatus })) {
    return InstitutionStatus.Degraded;
  }

  return showTransactionSyncingStatus ? transactionsStatus : balanceStatus;
};

export const evaluateInstitutionStatusTitle = ({
  transactionsStatus,
  balanceStatus,
  showTransactionSyncingStatus,
  updateRequired,
}: EvaluateStatusArgs & { updateRequired: boolean }) => {
  if (updateRequired) {
    return DISCONNECTED_TITLE;
  }

  const status = evaluateInstitutionStatus({
    transactionsStatus,
    balanceStatus,
    showTransactionSyncingStatus,
  });

  return TITLE_FOR_STATUS[status];
};

export const evaluateInstitutionStatusColor = ({
  transactionsStatus,
  balanceStatus,
  showTransactionSyncingStatus,
  updateRequired,
  disconnected,
}: EvaluateStatusArgs & { updateRequired: boolean; disconnected: boolean }) => {
  if (updateRequired) {
    return COLOR_FOR_STATUS[InstitutionStatus.Down];
  } else if (disconnected) {
    return COLOR_FOR_STATUS[InstitutionStatus.SyncDisabled];
  }

  const status = evaluateInstitutionStatus({
    transactionsStatus,
    balanceStatus,
    showTransactionSyncingStatus,
  });

  return COLOR_FOR_STATUS[status];
};
