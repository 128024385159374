import React, { useState } from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import GoalAccountsMapRow from 'components/goalsV2/GoalAccountsMapRow';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import IconButton from 'components/lib/ui/button/IconButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import { Currency } from 'components/lib/ui/currency/Currency';

import { getAmountColor } from 'common/lib/budget/Amounts';
import useUpdateGoalAccountAllocation from 'common/lib/hooks/goalsV2/useUpdateGoalAccountAllocation';
import useQuery from 'common/lib/hooks/useQuery';
import { formatCurrency } from 'common/utils/Currency';
import { useModalContext } from 'lib/contexts/ModalContext';
import useTheme from 'lib/hooks/useTheme';

import * as COPY from 'common/constants/copy';

import { gql } from 'common/generated/gql';
import type { Web_GoalsAllocateBalanceModal } from 'common/generated/graphQlTypes/Web_GoalsAllocateBalanceModal';

import GoalAllocationAmountInput from './GoalAllocationAmountInput';

const StyledModalCard = styled(ModalCard)`
  padding: 0;
`;

const Header = styled(FlexContainer).attrs({ column: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const Title = styled(Text).attrs({ size: 'large', weight: 'medium' })`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const SubHeader = styled(Text).attrs({ size: 'xsmall', weight: 'bold', color: 'textLight' })`
  text-transform: uppercase;
`;

const GoalRow = styled.div`
  :not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const AccountTitleContainer = styled(FlexContainer).attrs({ column: true })`
  flex: 1;
  margin: 0 ${({ theme }) => theme.spacing.default};
`;

const AccountSubtitleText = styled(Text).attrs({ size: 'small', weight: 'medium' })<{
  $color: string;
}>`
  color: ${({ $color }) => $color};
`;

const AccountSubtitle = styled(AccountSubtitleText)`
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const StyledGoalAccountsMapRow = styled(GoalAccountsMapRow)`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const AllocationInputContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: 0;
`;

const StyledCardFooter = styled(CardFooter)`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

const LoadingWrapper = styled(FlexContainer).attrs({ alignCenter: true, justifyCenter: true })`
  min-height: 600px;
`;

const StyledIconButton = styled(IconButton).attrs({ size: 'medium' })`
  position: absolute;
  right: ${({ theme }) => theme.spacing.default};
  top: ${({ theme }) => theme.spacing.default};
`;

const ACCOUNT_LOGO_SIZE_PX = 40;

type Props = {
  onFinishAllocation: () => void;
};

const GoalsAllocateBalanceModal = ({ onFinishAllocation }: Props) => {
  const theme = useTheme();
  const [accountIndex, setAccountIndex] = useState(0);

  const { close } = useModalContext();

  const { data, isLoadingInitialData } = useQuery<Web_GoalsAllocateBalanceModal>(QUERY);
  const { accountsWithUnallocatedBalancesForGoals: accounts = [] } = data ?? {};

  const account = accounts[accountIndex];

  const {
    goalAllocations = [],
    displayName,
    displayBalance,
    availableBalanceForGoals,
  } = account ?? {};

  const isOnLastAccount = accountIndex === accounts.length - 1;
  const hasMultipleGoals = goalAllocations.length > 1;

  const onClickNext = () => {
    if (isOnLastAccount) {
      onFinishAllocation();
      close();
    } else {
      setAccountIndex((prev) => prev + 1);
    }
  };

  const onClickDistribute = () => {
    if (!goalAllocations.length) {
      return;
    }

    const amountPerGoal = (availableBalanceForGoals ?? 0) / goalAllocations.length;

    goalAllocations.forEach(
      ({ id, currentAmount, account: { id: accountId }, goal: { id: goalId } }) => {
        updateAllocation({
          id,
          goalId,
          accountId,
          availableBalanceForGoals,
          amount: (currentAmount ?? 0) + amountPerGoal,
        });
      },
    );
  };

  const [updateAllocation] = useUpdateGoalAccountAllocation();

  return (
    <StyledModalCard hideBottomBorder hideHeader>
      {isLoadingInitialData ? (
        <LoadingWrapper>
          <LoadingSpinner />
        </LoadingWrapper>
      ) : (
        <>
          <Header>
            <SubHeader>
              account {accountIndex + 1} of {accounts.length}
            </SubHeader>
            <Title>{COPY.GOALS.ALLOCATE.TITLE}</Title>
            <StyledIconButton onClick={close} icon="x" />

            {account && (
              <FlexContainer>
                <AccountLogo
                  logoUrl={account.logoUrl}
                  icon={account.icon}
                  size={ACCOUNT_LOGO_SIZE_PX}
                />
                <AccountTitleContainer alignStart>
                  <Text>{displayName}</Text>
                  <AccountSubtitle $color={getAmountColor(availableBalanceForGoals ?? 0)}>
                    {formatCurrency(availableBalanceForGoals)}{' '}
                    <AccountSubtitleText $color={theme.color.textLight}>
                      available
                    </AccountSubtitleText>
                  </AccountSubtitle>
                  {!!availableBalanceForGoals && (
                    <DefaultButton onClick={onClickDistribute}>
                      {hasMultipleGoals ? 'Add to goals evenly' : 'Add to goal'}
                    </DefaultButton>
                  )}
                </AccountTitleContainer>
                <Currency value={displayBalance || 0} />
              </FlexContainer>
            )}
          </Header>

          <FlexContainer column>
            {goalAllocations.map((allocation) => (
              <GoalRow key={allocation.id}>
                <StyledGoalAccountsMapRow data={allocation.goal} showAccountLogos={false} />

                <AllocationInputContainer>
                  <GoalAllocationAmountInput allocation={allocation} />
                </AllocationInputContainer>
              </GoalRow>
            ))}
          </FlexContainer>

          <StyledCardFooter>
            <DefaultButton size="small" onClick={close}>
              Cancel
            </DefaultButton>
            <PrimaryButton size="small" onClick={onClickNext}>
              {isOnLastAccount ? 'Done' : 'Next'}
            </PrimaryButton>
          </StyledCardFooter>
        </>
      )}
    </StyledModalCard>
  );
};

const QUERY = gql(/* GraphQL */ `
  query Web_GoalsAllocateBalanceModal {
    accountsWithUnallocatedBalancesForGoals {
      id
      displayName
      displayBalance
      logoUrl
      icon
      availableBalanceForGoals
      goalAllocations {
        id
        amount
        currentAmount
        account {
          id
          availableBalanceForGoals
        }
        goal {
          id
          targetAmount
          currentAmount
          completionPercent
          completedAt
          archivedAt
          ...GoalAccountsMapRowFields
        }
      }
    }
  }
`);

export default GoalsAllocateBalanceModal;
