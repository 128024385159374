import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

const Root = styled(FlexContainer)`
  text-align: center;
  max-width: 672px;
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
  overflow: hidden;
`;

const Title = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

export const Description = styled(Text)<{ $maxWidth?: number }>`
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth}px;`}
`;

type Props = {
  title: string;
  description: React.ReactNode;
  className?: string;
  descriptionMaxWidth?: number;
};

const SetupContentHeader = ({ title, description, className, descriptionMaxWidth }: Props) => (
  <Root column alignCenter className={className}>
    <Title size="xlarge" weight="medium">
      {title}
    </Title>
    <Description $maxWidth={descriptionMaxWidth}>{description}</Description>
  </Root>
);

export default SetupContentHeader;
