import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import * as RA from 'ramda-adjunct';
import React, { useState } from 'react';
import styled from 'styled-components';

import DisableRolloverConfirmation from 'components/categories/DisableRolloverConfirmation';
import FieldCell from 'components/lib/form/FieldCell';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import RadioGroupField from 'components/lib/form/RadioGroupField';
import FeatureFlagGate from 'components/lib/higherOrder/FeatureFlagGate';
import Badge from 'components/lib/ui/Badge';
import CardFooter from 'components/lib/ui/CardFooter';
import Confirmation from 'components/lib/ui/Confirmation';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Modal from 'components/lib/ui/Modal';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import useBudgetSystem from 'common/lib/hooks/budget/useBudgetSystem';
import { fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';
import noop from 'common/utils/noop';
import { errorToast } from 'lib/ui/toast';

import { BudgetRolloverPeriodType } from 'common/constants/budget';

import { gql } from 'common/generated/gql';
import type { UpdateBudgetSettingsMutationInput } from 'common/generated/graphql';
import { BudgetSystem } from 'common/generated/graphql';

type Props = {
  onDone?: () => void;
};

const Root = styled.div`
  padding: ${spacing.xlarge};
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: 0px auto;
`;

const StyledButton = styled(DefaultButton)`
  text-wrap: nowrap;
`;

const RecommendedBadge = styled(Badge).attrs({
  color: 'orange',
})`
  text-transform: unset;
  letter-spacing: unset;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.xsmall};
`;

const LOADING_STATE = (
  <Root>
    <StyledLoadingSpinner />
  </Root>
);

const ERROR_STATE = <Root>Sorry, there was an error loading the settings.</Root>;

type FormValues = Partial<UpdateBudgetSettingsMutationInput>;

const BudgetSettingsForm = ({ onDone = noop }: Props) => {
  const [confirmingRecalculate, setConfirmingRecalculate] = useState(false);
  const [confirmingClear, setConfirmingClear] = useState(false);
  const { data, loading, error, updateBudgetSystem, updateLoading } = useBudgetSystem();

  const [recalculateBudget, { loading: recalculateLoading }] = useMutation(
    RECALCULATE_BUDGET_MUTATION,
  );
  const [clearAll, { loading: clearAllLoading }] = useMutation(CLEAR_BUDGET_MUTATION);

  if (error) {
    return ERROR_STATE;
  }

  if (!data || loading) {
    return LOADING_STATE;
  }

  const initialValues = {
    budgetSystem: data.budgetSystem,
    rolloverEnabled: RA.isNotNil(data.flexExpenseRolloverPeriod),
    rolloverStartMonth:
      data.flexExpenseRolloverPeriod?.startMonth ?? DateTime.local().startOf('month').toISODate(),
    rolloverType: BudgetRolloverPeriodType.Monthly, // This is the only one we support for now
    rolloverStartingBalance: data.flexExpenseRolloverPeriod?.startingBalance ?? 0,
  };

  const handleSubmit = async (values: FormValues) => {
    await performMutation(values);
  };

  const performMutation = async (values: FormValues) => {
    await updateBudgetSystem(
      values.budgetSystem as BudgetSystem, // FormValue is string, but mutation expects BudgetSystem
      {
        onError: errorToast,
        onDone,
      },
    );
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      <Root>
        <FeatureFlagGate name="fixed-flex-budgeting">
          <RadioGroupField
            name="budgetSystem"
            label="System"
            options={[
              {
                value: BudgetSystem.FIXED_AND_FLEX,
                label: 'Flex Budget',
                description:
                  'Budget all of your flexible spending into one amount. More copy here explaining how this works. Learn more',
                rightAccessory: <RecommendedBadge>Recommended</RecommendedBadge>,
              },
              {
                value: BudgetSystem.GROUPS_AND_CATEGORIES,
                label: 'Category Budget',
                description:
                  'Budget every category or group individually for maximum control over every aspect of your finances.',
              },
            ]}
          />
        </FeatureFlagGate>

        <FieldCell
          rightAccessory={
            <StyledButton onClick={() => setConfirmingRecalculate(true)}>Recalculate</StyledButton>
          }
          title="Recalculate default budgets"
          subtitle={
            <>
              Use your historical averages to automatically generate a new default budget. This will
              override all existing budget amounts.
            </>
          }
        />

        <FieldCell
          rightAccessory={
            <StyledButton onClick={() => setConfirmingClear(true)}>Clear all</StyledButton>
          }
          title="Clear all budget values"
          subtitle={
            <>
              Clear all budget values going back to the starting month and start over from scratch.
            </>
          }
        />

        {/* Modals */}
        {confirmingRecalculate && (
          <Modal onClose={() => setConfirmingRecalculate(false)}>
            {({ close }) => (
              <Confirmation
                title="Are you sure you want to recalculate?"
                confirm="Recalculate"
                onCancel={close}
                onConfirm={async () => {
                  await recalculateBudget({
                    variables: {
                      input: {
                        startDate: DateTime.local().toISODate(),
                      },
                    },
                  });
                  close();
                  onDone();
                }}
                useDangerButton
                isLoading={recalculateLoading}
              >
                This will override all existing budget amounts and cannot be undone.
              </Confirmation>
            )}
          </Modal>
        )}

        {confirmingClear && (
          <Modal onClose={() => setConfirmingClear(false)}>
            {({ close }) => (
              <Confirmation
                title="Are you sure you want to clear all?"
                confirm="Clear all"
                onCancel={close}
                onConfirm={async () => {
                  await clearAll({
                    variables: {
                      input: {
                        startDate: DateTime.local().toISODate(),
                      },
                    },
                  });
                  close();
                  onDone();
                }}
                useDangerButton
                isLoading={clearAllLoading}
              >
                This will clear all existing budget amounts and cannot be undone.
              </Confirmation>
            )}
          </Modal>
        )}
      </Root>

      <FeatureFlagGate name="fixed-flex-budgeting">
        <CardFooter>
          <DefaultButton onClick={onDone}>Cancel</DefaultButton>

          <DisableRolloverConfirmation
            rolloverEnabledInitially={initialValues?.rolloverEnabled ?? false}
            label="Turning off rollovers for Fixed & Flex will remove all historical and future rollovers from your flex plan."
          >
            {({ onSave }) => (
              <FormSubmitButton type="button" size="small" onClick={onSave} pending={updateLoading}>
                Save
              </FormSubmitButton>
            )}
          </DisableRolloverConfirmation>
        </CardFooter>
      </FeatureFlagGate>
    </Form>
  );
};

export const RECALCULATE_BUDGET_MUTATION = gql(/* GraphQL */ `
  mutation Web_RecalculateBudgetMutation($input: ResetBudgetMutationInput!) {
    resetBudget(input: $input) {
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const CLEAR_BUDGET_MUTATION = gql(/* GraphQL */ `
  mutation Web_ClearAllMutation($input: ClearBudgetMutationInput!) {
    clearBudget(input: $input) {
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export default BudgetSettingsForm;
