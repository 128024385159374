import type { DateTime } from 'luxon';
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import FeatureFlagGate from 'components/lib/higherOrder/FeatureFlagGate';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import TextButton from 'components/lib/ui/TextButton';
import RecurringUpcomingTable from 'components/recurring/RecurringUpcomingTable';
import RecurringCalendar from 'components/recurring/calendar/RecurringCalendar';
import RecurringActions from 'components/recurring/new/RecurringActions';
import NewRecurringUpcomingTable from 'components/recurring/new/RecurringUpcomingTable';

import { splitUpcomingCompleteItems } from 'common/lib/recurring';
import type { RecurringStreamToReview, RecurringTransactionItem } from 'common/lib/recurring/types';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';

const Root = styled(FlexContainer).attrs({ column: true })`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.gutter};
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
`;

const StreamsToReviewBanner = styled(Flex).attrs({ row: true, justifyBetween: true })`
  padding: ${({ theme }) => theme.spacing.default};
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.color.redDark} 0%,
    ${({ theme }) => theme.color.orangeDark} 100%
  );
  color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const ReviewButton = styled(TextButton)`
  color: ${({ theme }) => theme.color.white};
  text-decoration: underline;

  :hover {
    color: ${({ theme }) => theme.color.white};
    background-color: transparent;
  }
`;

export enum RecurringView {
  Calendar = 'calendar',
  List = 'list',
}

type Props = {
  items: RecurringTransactionItem[];
  streamsToReview: RecurringStreamToReview[];
  currentStartDate: DateTime;
  isLoading: boolean;
  setStartDate: (date: string) => void;
  refetch: () => void;
  onClickToReviewStreams?: () => void;
  emptyStateComponent?: React.ReactNode;
};

const RecurringUpcoming = ({
  items,
  currentStartDate,
  isLoading,
  setStartDate,
  streamsToReview,
  refetch,
  onClickToReviewStreams,
  emptyStateComponent,
}: Props) => {
  const isBillTrackingEnabled = useIsFeatureFlagOn('load-liabilities');

  const { upcomingItems, completeItems } = splitUpcomingCompleteItems(items);

  const [recurringView, setRecurringView] = React.useState<RecurringView>(RecurringView.List);

  const shouldShowCalendarView =
    (isBillTrackingEnabled && recurringView === RecurringView.Calendar) || !isBillTrackingEnabled;

  const shouldShowListView =
    (isBillTrackingEnabled && recurringView === RecurringView.List) || !isBillTrackingEnabled;

  const shouldShowUpcomingTable = shouldShowListView && !!upcomingItems.length;

  const shouldShowCompleteTable = shouldShowListView && !!completeItems.length;

  const shouldShowEmptyState =
    !shouldShowCalendarView && !shouldShowUpcomingTable && !shouldShowCompleteTable;

  return (
    <>
      <Helmet>
        <title>Recurring &middot; Upcoming</title>
      </Helmet>
      <Root>
        <FeatureFlagGate name="load-liabilities">
          <>
            {streamsToReview.length > 0 ? (
              <StreamsToReviewBanner>
                There are {streamsToReview.length} new recurring merchants and accounts for you to
                review
                <ReviewButton onClick={onClickToReviewStreams}>Review now</ReviewButton>
              </StreamsToReviewBanner>
            ) : null}
            <RecurringActions
              startDate={currentStartDate}
              setStartDate={setStartDate}
              recurringView={recurringView}
              setRecurringView={setRecurringView}
              refetch={refetch}
            />
          </>
        </FeatureFlagGate>

        {shouldShowCalendarView && (
          <RecurringCalendar
            items={items}
            startDate={currentStartDate}
            setStartDate={setStartDate}
            refetch={refetch}
          />
        )}

        {shouldShowUpcomingTable &&
          (isBillTrackingEnabled ? (
            <NewRecurringUpcomingTable
              title="Upcoming"
              items={upcomingItems}
              isLoading={isLoading}
              startDate={currentStartDate}
              refetch={refetch}
            />
          ) : (
            <RecurringUpcomingTable
              title="Upcoming"
              items={upcomingItems}
              isLoading={isLoading}
              startDate={currentStartDate}
              refetch={refetch}
            />
          ))}

        {shouldShowCompleteTable &&
          (isBillTrackingEnabled ? (
            <NewRecurringUpcomingTable
              title="Complete"
              items={completeItems}
              isLoading={isLoading}
              startDate={currentStartDate}
              refetch={refetch}
            />
          ) : (
            <RecurringUpcomingTable
              title="Complete"
              items={completeItems}
              isLoading={isLoading}
              startDate={currentStartDate}
              refetch={refetch}
            />
          ))}

        {shouldShowEmptyState && emptyStateComponent}
      </Root>
    </>
  );
};

export default RecurringUpcoming;
