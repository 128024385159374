import React, { useMemo, useRef } from 'react';
import { ReferenceLine } from 'recharts';
import styled from 'styled-components';

import ExcludedFromPortfolioIndicator from 'components/investments/ExcludedFromPortfolioIndicator';
import InvestmentsPerformanceChartTooltip from 'components/investments/InvestmentsPerformanceChartTooltip';
import MultipleLineChart from 'components/lib/charts/MultipleLineChart';
import { cardDropShadowStyleMixin } from 'components/lib/ui/Card';
import ExportElementButton from 'components/lib/ui/ExportElementButton';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { formatISODate, isoDateToMonthAbbreviationAndDay } from 'common/utils/date';
import type { HoldingInfo } from 'lib/hooks/investments/usePerformanceChartColors';
import useMeasure from 'lib/hooks/useMeasure';
import useTheme from 'lib/hooks/useTheme';
import {
  getHistoricalChartTooltipData,
  getHoldingOrBenchmarkLabel,
} from 'lib/investments/adapters';

import type { HistoricalGraphDataPoint } from 'types/investments';

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
  ${cardDropShadowStyleMixin}
`;

const ChartHeaderRoot = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.xlarge};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const ChartHeaderLeft = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })``;

const ChartHeaderRight = styled(FlexContainer)``;

const ChartExportButton = styled(ExportElementButton)`
  padding: 6px 0;
`;

const ChartRoot = styled.div`
  margin-top: ${({ theme }) => theme.spacing.default};
  width: 100%;
`;

const LegendText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const LegendColorDot = styled.div<{ $color: string }>`
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: ${({ theme }) => theme.radius.round};
  background-color: ${({ $color }) => $color};
`;

type Props = {
  data: HistoricalGraphDataPoint[];
  selectedAggregateHoldingInfos: HoldingInfo[];
  selectedBenchmarks: HoldingInfo[];
  isLoading: boolean;
};

const InvestmentsHoldingsPerformanceGraph = ({
  data: _data,
  selectedAggregateHoldingInfos,
  selectedBenchmarks,
  isLoading,
}: Props) => {
  /**
   * If data or width change too quickly upon initial render, the rechart animation fails.
   * To fix this, we need to memoize the data.
   */
  const dataString = JSON.stringify(_data);
  const data = useMemo(() => _data, [dataString]);
  const [ref, { width = 0 }] = useMeasure<HTMLDivElement>();
  const theme = useTheme();
  const padding = 2 * parseInt(theme.spacing.default, 10);
  const portfolioLineColor = theme.color.orange;
  const combinedSelectedHoldingsAndBenchmarks = selectedBenchmarks.concat(
    selectedAggregateHoldingInfos,
  );

  const lineOptions = combinedSelectedHoldingsAndBenchmarks.map(({ color }) => ({
    lineColor: color,
  }));
  const yDataKeys = combinedSelectedHoldingsAndBenchmarks.map(({ dataKey }) => dataKey);

  const graphRef = useRef();

  return (
    <Root ref={graphRef}>
      <ChartHeaderRoot>
        <ChartHeaderLeft>
          <LegendColorDot $color={portfolioLineColor} />
          <LegendText>Your Portfolio</LegendText>
          {combinedSelectedHoldingsAndBenchmarks.map((holdingInfo) => (
            <React.Fragment key={holdingInfo.dataKey + holdingInfo.isBenchmark}>
              <LegendColorDot $color={holdingInfo.color} />
              <LegendText>{getHoldingOrBenchmarkLabel(holdingInfo)}</LegendText>
            </React.Fragment>
          ))}
          <ExcludedFromPortfolioIndicator
            tooltipText="Holdings with this icon are not included in your portfolio"
            extraNode={<LegendText>Holding not included</LegendText>}
          />
        </ChartHeaderLeft>
        <ChartHeaderRight>
          <ChartExportButton
            element={graphRef.current}
            fileName={`performance-${formatISODate(new Date())}.png`}
            type="Investment Performance"
          />
        </ChartHeaderRight>
      </ChartHeaderRoot>
      <ChartRoot ref={ref}>
        <MultipleLineChart
          width={width - padding / 2}
          height={373}
          xDataKey="date"
          yDataKeys={[...yDataKeys, 'portfolioReturn']}
          lineOptions={[...lineOptions, { lineColor: portfolioLineColor }]}
          formatXAxis={isoDateToMonthAbbreviationAndDay}
          formatYAxis={(val) => `${val}%`}
          isAnimationActive
          isLoadingNewData={isLoading}
          // @ts-ignore
          data={data}
          referenceLine={<ReferenceLine y={0} position="start" stroke={theme.color.gray} />}
          tooltipComponent={({ active, payload }) => {
            const tooltipData = getHistoricalChartTooltipData(
              combinedSelectedHoldingsAndBenchmarks,
              data,
              active,
              payload,
            );
            return tooltipData ? (
              <InvestmentsPerformanceChartTooltip
                active={active}
                header={tooltipData.header}
                data={tooltipData.data}
              />
            ) : null;
          }}
        />
      </ChartRoot>
    </Root>
  );
};

export default InvestmentsHoldingsPerformanceGraph;
