import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import withDefaultErrorBoundary from 'components/lib/higherOrder/withDefaultErrorBoundary';
import Card from 'components/lib/ui/Card';
import Column from 'components/lib/ui/Column';
import Flex from 'components/lib/ui/Flex';
import Menu, { MenuItemLink } from 'components/lib/ui/Menu';
import Page from 'components/lib/ui/Page';
import Row from 'components/lib/ui/Row';
import BillingSettings from 'components/routes/settings/BillingSettings';
import CategorySettings from 'components/routes/settings/CategorySettings';
import DisplaySettings from 'components/routes/settings/DisplaySettings';
import HouseholdSettings from 'components/routes/settings/HouseholdSettings';
import InstitutionSettings from 'components/routes/settings/InstitutionSettings';
import MemberSettings from 'components/routes/settings/MemberSettings';
import MerchantSettings from 'components/routes/settings/MerchantSettings';
import NotificationSettings from 'components/routes/settings/NotificationSettings';
import PreferencesSettings from 'components/routes/settings/PreferencesSettings';
import ProfileSettings from 'components/routes/settings/ProfileSettings';
import ReferralSettings from 'components/routes/settings/ReferralSettings';
import RulesSettings from 'components/routes/settings/RulesSettings';
import SecuritySettings from 'components/routes/settings/SecuritySettings';
import TransactionTagSettings from 'components/routes/settings/TransactionTagSettings';
import DataSettings from 'components/routes/settings/dataSettings/DataSettings';

import useSubscriptionDetails from 'common/lib/hooks/billing/useSubscriptionDetails';

import routes from 'constants/routes';

const Root = styled(Flex)``;

const SubNavCard = styled(Card)`
  :not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing.gutter};
  }
  overflow: hidden;
`;

const Settings = () => {
  const { isSubscriptionActive } = useSubscriptionDetails();

  return (
    <Page name="Settings">
      <Root alignStart>
        <Row>
          <Column md={3}>
            <SubNavCard title="Account">
              <Menu>
                <MenuItemLink to={routes.settings.profile()}>Profile</MenuItemLink>
                <MenuItemLink to={routes.settings.display()}>Display</MenuItemLink>
                <MenuItemLink to={routes.settings.notifications()}>Notifications</MenuItemLink>
                <MenuItemLink to={routes.settings.security()}>Security</MenuItemLink>
              </Menu>
            </SubNavCard>
            <SubNavCard title="Household">
              <Menu>
                <MenuItemLink to={routes.settings.household()}>General</MenuItemLink>
                <MenuItemLink to={routes.settings.members()}>Members</MenuItemLink>
                <MenuItemLink to={routes.settings.preferences()}>Preferences</MenuItemLink>
                <MenuItemLink to={routes.settings.institutions()}>Institutions</MenuItemLink>
                <MenuItemLink to={routes.settings.categories()}>Categories</MenuItemLink>
                <MenuItemLink to={routes.settings.merchants()}>Merchants</MenuItemLink>
                <MenuItemLink to={routes.settings.rules()}>Rules</MenuItemLink>
                <MenuItemLink to={routes.settings.tags()}>Tags</MenuItemLink>
                <MenuItemLink to={routes.settings.data()}>Data</MenuItemLink>
                <MenuItemLink to={routes.settings.billing()}>Billing</MenuItemLink>
                {isSubscriptionActive && (
                  <MenuItemLink to={routes.settings.referrals()}>Referrals</MenuItemLink>
                )}
              </Menu>
            </SubNavCard>
          </Column>
          <Switch>
            <Route path={routes.settings.profile.path} component={ProfileSettings} />
            <Route path={routes.settings.display.path} component={DisplaySettings} />
            <Route path={routes.settings.notifications.path} component={NotificationSettings} />
            <Route path={routes.settings.security.path} component={SecuritySettings} />
            <Route path={routes.settings.household.path} component={HouseholdSettings} />
            <Route path={routes.settings.members.path} component={MemberSettings} />
            <Route path={routes.settings.institutions.path} component={InstitutionSettings} />
            <Route path={routes.settings.preferences.path} component={PreferencesSettings} />
            <Route path={routes.settings.rules.path} component={RulesSettings} />
            <Route path={routes.settings.data.path} component={DataSettings} />
            <Route path={routes.settings.categories.path} component={CategorySettings} />
            <Route path={routes.settings.billing.path} component={BillingSettings} />
            <Route path={routes.settings.referrals.path} component={ReferralSettings} />
            <Route path={routes.settings.tags.path} component={TransactionTagSettings} />
            <Route path={routes.settings.merchants.path} component={MerchantSettings} />
          </Switch>
        </Row>
      </Root>
    </Page>
  );
};

export default withDefaultErrorBoundary(Settings);
