import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import BudgetCreationSuccessCard from 'components/budget/BudgetCreationSuccessCard';
import BudgetListLoading from 'components/budget/BudgetListLoading';
import BudgetSummary from 'components/budget/BudgetSummary';
import BudgetSummaryWidget from 'components/budget/BudgetSummaryWidget';
import CreateBudgetCard from 'components/budget/CreateBudgetCard';
import EditCategoryGroupModal from 'components/categories/EditCategoryGroupModal';
import withDefaultErrorBoundary from 'components/lib/higherOrder/withDefaultErrorBoundary';
import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import PageWithNoAccountsEmptyState from 'components/lib/ui/PageWithNoAccountsEmptyState';
import Scroll from 'components/lib/ui/Scroll';
import Text from 'components/lib/ui/Text';
import Toggle from 'components/lib/ui/Toggle';
import Tooltip from 'components/lib/ui/Tooltip';
import { SECTION_HEADER_HEIGHT_PX } from 'components/plan/PlanGrid';
import PlanHeader from 'components/plan/PlanHeader';
import PlanSection from 'components/plan/PlanSection';
import PlanSummaryAmounts from 'components/plan/PlanSummaryAmounts';

import { getMonthProgressForMonthStart, isCurrentMonth } from 'common/lib/budget/Progress';
import { getIsEmployee } from 'common/state/user/selectors';
import { formatMonthWithYear } from 'common/utils/date';
import PlanContext from 'lib/contexts/PlanContext';
import { useQueryParam } from 'lib/hooks';
import usePlanAdapter from 'lib/hooks/plan/usePlanAdapter';
import usePlanQuery from 'lib/hooks/plan/usePlanQuery';
import usePlanState, { PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useModal from 'lib/hooks/useModal';
import useRestoreScrollPosition from 'lib/hooks/useRestoreScrollPosition';

import { ONBOARDING_PLAN_OVERLAY_TITLE } from 'common/constants/copy';

const INTRO_MODAL_PARAM = 'introModal';

const ScrollRoot = styled(Scroll)`
  position: relative;
  margin-left: ${({ theme }) => theme.spacing.xlarge};
  transform: scale(1); /* create stacking context */
`;

const Container = styled(FlexContainer)`
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  padding-right: ${({ theme }) => theme.spacing.xlarge};
`;

const SectionsContainer = styled.div`
  flex: 1;
`;

const Sticky = styled.div`
  min-width: 25vw;
  position: sticky;
  top: 0;
  height: 100%; /* needed for sticky */
`;

const StickyCover = styled.div`
  background: ${({ theme }) => theme.color.grayBackground};
  height: ${SECTION_HEADER_HEIGHT_PX}px;
  width: 100%;
`;

const StyledBudgetListLoading = styled(BudgetListLoading)`
  margin-top: ${SECTION_HEADER_HEIGHT_PX}px;
`;

const SummaryCard = styled(Card)`
  margin-left: ${({ theme }) => theme.spacing.xlarge};
`;

const Plan = () => {
  const isBudgetChangesEnabled = useIsFeatureFlagOn('budget-changes-aug-2024');
  const isFixedFlexBudgetingEnabled = useIsFeatureFlagOn('fixed-flex-budgeting');

  const [oldSummaryWidgetEnabled, setOldSummaryWidgetEnabled] = useState(false);
  const isEmployee = useSelector(getIsEmployee);

  const [state, actions] = usePlanState();
  const {
    data,
    refetch,
    fetchedDateRange,
    doesNotHaveBudget,
    budgetStatus,
    refetchBudgetStatus,
    updateCellValue,
  } = usePlanQuery(state);
  const {
    isLoadingInitialData,
    gridDisplayData,
    numColumns,
    getDateForColumn,
    getAmountTypeForColumn,
    getCanEditColumn,
    getIsColumnLoading,
    gridAmounts,
    budgetSummaryData,
    initialScrollOffset,
    shouldShowSafeToSpend,
  } = usePlanAdapter(data, state, fetchedDateRange);

  const { timeframe, highlightedDate, thisMonth, columnDisplayInterval } = state;
  const { setTimeframe, setHighlightedDate, moveHighlightedDate } = actions;

  const [editingGroupId, setEditingGroupId] = useState<string | null>(null);
  const [EditGroupModal, { open: openEditGroupModal, close: closeEditGroupModal }] = useModal();
  const openEditCategoryGroup = useCallback(
    (id: string) => {
      setEditingGroupId(id);
      openEditGroupModal();
    },
    [openEditGroupModal],
  );

  const context = useMemo(
    () => ({
      refetch,
      getIsColumnLoading,
      timeframe,
      openEditCategoryGroup,
      actions,
      getDateForColumn,
      getAmountTypeForColumn,
      getCanEditColumn,
      updateCellValue,
      isFixedFlexBudgetingEnabled,
    }),
    [
      refetch,
      getIsColumnLoading,
      timeframe,
      openEditCategoryGroup,
      actions,
      getDateForColumn,
      getAmountTypeForColumn,
      getCanEditColumn,
      updateCellValue,
      isFixedFlexBudgetingEnabled,
    ],
  );

  const onClickColumn = useCallback(
    (column: number) => {
      setHighlightedDate(getDateForColumn(column));
      setTimeframe(
        timeframe === PlanTimeframe.Yearly ? PlanTimeframe.Monthly : PlanTimeframe.SingleMonth,
        { pushHistory: true },
      );
    },
    [setHighlightedDate, getDateForColumn, setTimeframe, timeframe],
  );

  const pageTitle = useMemo(() => {
    if (timeframe === PlanTimeframe.SingleMonth) {
      return formatMonthWithYear(highlightedDate);
    } else {
      const startYear = columnDisplayInterval.start.toFormat('y');
      const endYear = columnDisplayInterval.end.toFormat('y');
      if (startYear === endYear) {
        return startYear;
      } else {
        return `${startYear} - ${endYear}`;
      }
    }
  }, [timeframe, columnDisplayInterval, highlightedDate]);

  const summaryTicks = useMemo(
    () =>
      isCurrentMonth(highlightedDate)
        ? [{ percent: getMonthProgressForMonthStart(highlightedDate) }]
        : undefined,
    [highlightedDate],
  );

  const introModal = useQueryParam(INTRO_MODAL_PARAM);

  const [
    CreationSuccessModal,
    { open: openCreationSuccessModal, close: closeCreationSuccessModal },
  ] = useModal(!!introModal);

  const [preservedScrollOffset, onScroll] = useRestoreScrollPosition();

  return (
    <PlanContext.Provider value={context}>
      <PageWithNoAccountsEmptyState
        name="Plan"
        emptyIcon="map"
        emptyTitle={ONBOARDING_PLAN_OVERLAY_TITLE}
        shouldScroll={false}
        header={
          <PlanHeader
            title={pageTitle}
            timeframe={timeframe}
            onClickToday={() => setHighlightedDate(thisMonth)}
            onPanLeft={() => moveHighlightedDate(false)}
            onPanRight={() => moveHighlightedDate(true)}
            onChangeTimeframe={setTimeframe}
          />
        }
        overlayEmptyComponent={
          budgetStatus && doesNotHaveBudget ? (
            <CreateBudgetCard
              date={columnDisplayInterval.start}
              onCompleted={() => {
                refetch();
                refetchBudgetStatus();
                openCreationSuccessModal();
              }}
              budgetStatus={budgetStatus}
            />
          ) : undefined
        }
        overrideTitle={pageTitle}
      >
        <ScrollRoot
          initialOffset={preservedScrollOffset ?? initialScrollOffset}
          onScroll={onScroll}
        >
          <Container>
            <SectionsContainer>
              {isLoadingInitialData ? (
                <StyledBudgetListLoading />
              ) : (
                gridDisplayData.map((section) => (
                  <PlanSection
                    key={section.id}
                    section={section}
                    amounts={gridAmounts[section.id]}
                    timeframe={timeframe}
                    numColumns={numColumns}
                    onClickColumn={onClickColumn}
                  />
                ))
              )}
            </SectionsContainer>
            {timeframe === PlanTimeframe.SingleMonth && (
              <Sticky>
                <StickyCover />

                {/* TODO: Remove this once the feature is fully rolled out. This is only for employees. */}
                {isBudgetChangesEnabled && isEmployee && (
                  <BudgetWidgetSwitch
                    enabled={oldSummaryWidgetEnabled}
                    setEnabled={setOldSummaryWidgetEnabled}
                  />
                )}

                {isBudgetChangesEnabled && !oldSummaryWidgetEnabled ? (
                  <BudgetSummaryWidget
                    data={budgetSummaryData}
                    ticks={summaryTicks}
                    isLoading={isLoadingInitialData}
                  />
                ) : (
                  <>
                    <PlanSummaryAmounts
                      data={budgetSummaryData}
                      shouldShowSafeToSpend={shouldShowSafeToSpend}
                    />
                    <SummaryCard title="Summary">
                      <BudgetSummary
                        isLoading={isLoadingInitialData}
                        data={budgetSummaryData}
                        ticks={summaryTicks}
                        animated
                        shouldShowSafeToSpend={shouldShowSafeToSpend}
                      />
                    </SummaryCard>
                  </>
                )}
              </Sticky>
            )}
            <EditGroupModal>
              <EditCategoryGroupModal
                onDone={() => {
                  refetch();
                  closeEditGroupModal();
                }}
                onDeleted={refetch}
                categoryGroupId={editingGroupId ?? ''}
              />
            </EditGroupModal>
          </Container>
        </ScrollRoot>
        <CreationSuccessModal>
          <BudgetCreationSuccessCard onCompleted={closeCreationSuccessModal} />
        </CreationSuccessModal>
      </PageWithNoAccountsEmptyState>
    </PlanContext.Provider>
  );
};

/**
 * TODO: Remove this once the feature is fully rolled out. This is only for employees.
 */
const BudgetWidgetSwitch = ({
  enabled,
  setEnabled,
}: {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
}) => (
  <Tooltip
    maxWidth={310}
    place="left"
    content={
      <FlexContainer padding="small" gap="xxsmall" column>
        <Text size="xsmall" weight="medium">
          Employees only: Toggle the old summary widget
        </Text>
        <Text size="xsmall" weight="book">
          This is useful in case you want to compare the data from the old widget to the new one
          while testing. We will remove this toggle before launching Fixed/Flex budgeting.
        </Text>
      </FlexContainer>
    }
    portal
  >
    <FlexContainer
      style={{ marginLeft: 20, marginBottom: 12, cursor: 'pointer' }}
      onClick={() => setEnabled(!enabled)}
      justifyStart
    >
      <Toggle checked={enabled} onChange={(e) => setEnabled(e.target.checked)} />
    </FlexContainer>
  </Tooltip>
);

export default withDefaultErrorBoundary(Plan);
