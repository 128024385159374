import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useState, useMemo } from 'react';
import { FiInfo } from 'react-icons/fi';
import styled from 'styled-components';

import CreateEditCategoryFormFields from 'components/categories/CreateEditCategoryFormFields';
import DeleteOrDisableCategoryConfirmation from 'components/categories/DeleteOrDisableCategoryConfirmation';
import DisableRolloverConfirmation from 'components/categories/DisableRolloverConfirmation';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import Flex from 'components/lib/ui/Flex';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Modal from 'components/lib/ui/Modal';
import ModalCard from 'components/lib/ui/ModalCard';
import Tooltip from 'components/lib/ui/Tooltip';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { defaultButtonMixin } from 'components/lib/ui/button/DefaultButton';

import { DEFAULT_BUDGET_VARIABILITY_IF_UNSET } from 'common/lib/budget/constants';
import useQuery from 'common/lib/hooks/useQuery';
import { errorToast } from 'lib/ui/toast';

import { BudgetRolloverPeriodType } from 'common/constants/budget';
import { CACHE_KEYS } from 'common/constants/cache';

import { gql } from 'common/generated/gql';
import type { PayloadErrorFields } from 'common/generated/graphQlTypes/PayloadErrorFields';
import type { CategoryFormFieldsFragment, UpdateCategoryInput } from 'common/generated/graphql';

// Exclude __typename, order, etc. from category
const getInitialValues = <TCategory extends CategoryFormFieldsFragment>(
  category: TCategory,
): UpdateCategoryInput => ({
  id: category.id,
  name: category.name,
  icon: category.icon,
  budgetVariability: R.propOr(DEFAULT_BUDGET_VARIABILITY_IF_UNSET, 'budgetVariability', category),
  excludeFromBudget: category.excludeFromBudget,
  group: category.group.id,
  type: category.group.type,
  rolloverEnabled: RA.isNotNil(category.rolloverPeriod),
  rolloverStartMonth:
    category.rolloverPeriod?.startMonth ?? DateTime.local().startOf('month').toISODate(),
  rolloverType: BudgetRolloverPeriodType.Monthly, // This is the only one we support for now
  rolloverStartingBalance: category.rolloverPeriod?.startingBalance ?? 0,
});

const ENABLE_SYSTEM_CATEGORY =
  'Activate a system category to restore it and automatically have Monarch categorize transactions related to it.';
const DISABLE_SYSTEM_CATEGORY =
  'Deactivate will remove this category everywhere in Monarch. Any transactions related to this category will be marked as uncategorized unless custom rules are setup that categorize them.';
const MODAL_TITLE = 'Edit Category';

const EnableDisableButton = styled(AsyncButton)`
  ${defaultButtonMixin}
`;

const InfoTooltipWrapper = styled(Flex)`
  padding: 0 ${({ theme }) => theme.spacing.xsmall};
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: 0px auto;
`;

const FormDefaultButtonContainer = styled.div`
  display: flex;
`;

type Props = {
  categoryId: string;
  onDone: () => void;
};

const EditCategoryModal = ({ categoryId, onDone }: Props) => {
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  const { data, isLoadingInitialData } = useQuery(GET_CATEGORY, {
    variables: { id: categoryId },
  });
  const { category } = data ?? {};

  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    update: (cache) => {
      cache.evict({ fieldName: CACHE_KEYS.CATEGORIES });
      cache.evict({ fieldName: CACHE_KEYS.CATEGORY_GROUPS });
    },
  });
  const [restoreCategory, { loading: loadingRestoreCategory }] = useMutation(RESTORE_CATEGORY);

  // TODO may want to get the "protected" attribute from the backend instead
  const systemCategory = category?.systemCategory;
  const showDisableOrDeleteButton =
    systemCategory !== 'uncategorized' && systemCategory !== 'balance_adjustments';

  const [disableOrDeleteCategory, { loading: loadingDisableOrDeleteCategory }] = useMutation(
    DELETE_CATEGORY,
    {
      update: (cache) => {
        cache.evict({ fieldName: CACHE_KEYS.CATEGORIES });
        cache.evict({ fieldName: CACHE_KEYS.CATEGORY_GROUPS });
      },
    },
  );

  const onConfirmDelete = async (moveToCategoryId?: string) => {
    setConfirmingDelete(false);
    if (!category) {
      return;
    }
    const { data } = await disableOrDeleteCategory({
      variables: { id: category?.id, moveToCategoryId },
      update: updateCachedCategory(category?.id, { isDisabled: () => true }),
    });
    const errors: PayloadErrorFields | undefined = R.path(['deleteCategory', 'errors'], data);
    if (errors) {
      errorToast(errors.message);
    } else {
      onDone?.();
    }
  };

  const onActivateCategory = async () => {
    if (!category) {
      return;
    }
    const { data } = await restoreCategory({
      variables: { id: category?.id ?? '' },
      update: updateCachedCategory(category?.id, { isDisabled: () => false }),
    });
    const errors: PayloadErrorFields | undefined = R.path(['restoreCategory', 'errors'], data);
    if (errors) {
      errorToast(errors.message);
    } else {
      onDone?.();
    }
  };

  const initialValues = useMemo(
    () => (category ? getInitialValues(category) : undefined),
    [category],
  );

  const formDefaultButton = category?.isDisabled ? (
    <FormDefaultButtonContainer>
      <EnableDisableButton pending={loadingRestoreCategory} onClick={onActivateCategory}>
        Activate
      </EnableDisableButton>
      <Tooltip content={ENABLE_SYSTEM_CATEGORY}>
        <InfoTooltipWrapper center>
          <FiInfo />
        </InfoTooltipWrapper>
      </Tooltip>
    </FormDefaultButtonContainer>
  ) : (
    showDisableOrDeleteButton && (
      <FormDefaultButtonContainer>
        <EnableDisableButton
          pending={loadingDisableOrDeleteCategory}
          onClick={() => {
            setConfirmingDelete(true);
          }}
        >
          {category?.isSystemCategory ? 'Deactivate' : 'Delete'}
        </EnableDisableButton>
        {category?.isSystemCategory && (
          <Tooltip content={DISABLE_SYSTEM_CATEGORY}>
            <InfoTooltipWrapper center>
              <FiInfo />
            </InfoTooltipWrapper>
          </Tooltip>
        )}
      </FormDefaultButtonContainer>
    )
  );

  if (isLoadingInitialData) {
    return (
      <ModalCard title={MODAL_TITLE}>
        <CardBody>
          <StyledLoadingSpinner />
        </CardBody>
      </ModalCard>
    );
  } else if (!category) {
    return (
      <ModalCard title={MODAL_TITLE}>
        <CardBody>Category not found</CardBody>
      </ModalCard>
    );
  }

  return (
    <ModalCard title={MODAL_TITLE}>
      <Form initialValues={initialValues} mutation={updateCategory} onSubmitSuccess={onDone}>
        <CardBody>
          <CreateEditCategoryFormFields
            categoryType={category?.group.type}
            systemCategoryDisplayName={category?.systemCategoryDisplayName}
            isSystemCategory={category?.isSystemCategory}
          />
        </CardBody>
        <CardFooter justifyBetween>
          {formDefaultButton}
          <DisableRolloverConfirmation
            rolloverEnabledInitially={initialValues?.rolloverEnabled ?? false}
          >
            {({ onSave }) => (
              <FormSubmitButton type="button" size="small" onClick={onSave}>
                Save
              </FormSubmitButton>
            )}
          </DisableRolloverConfirmation>
        </CardFooter>
      </Form>
      {confirmingDelete && (
        <Modal onClose={() => setConfirmingDelete(false)}>
          {({ close }) => (
            <DeleteOrDisableCategoryConfirmation
              category={category}
              onCancel={close}
              disableOrDeleteCategory={onConfirmDelete}
            />
          )}
        </Modal>
      )}
    </ModalCard>
  );
};

const updateCachedCategory = (categoryId: string, fields: any) => (cache: any) => {
  cache.modify({
    fields: {
      categories: (existingCategories = []) => {
        const affectedCategory = existingCategories.find(
          (categoryRef: any) => categoryRef.__ref !== `Category:${categoryId}`,
        );
        cache.modify({
          id: cache.identify(affectedCategory),
          fields,
        });
      },
    },
  });
};

const GET_CATEGORY = gql(`
  query Web_GetEditCategory($id: UUID!) {
    category(id: $id) {
      id
      ...CategoryFormFields
    }
  }
`);

const UPDATE_CATEGORY = gql(`
  mutation Web_UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      errors {
        ...PayloadErrorFields
      }
      category {
        id
        ...CategoryFormFields
      }
    }
  }
`);

const RESTORE_CATEGORY = gql(`
  mutation Web_RestoreCategory($id: UUID!) {
    restoreCategory(id: $id) {
      errors {
        ...PayloadErrorFields
      }
      category {
        id
        ...CategoryFormFields
      }
    }
  }
`);

const DELETE_CATEGORY = gql(`
  mutation Web_DeleteCategory($id: UUID!, $moveToCategoryId: UUID) {
    deleteCategory(id: $id, moveToCategoryId: $moveToCategoryId) {
      errors {
        ...PayloadErrorFields
      }
      deleted
    }
  }
`);

export default EditCategoryModal;
