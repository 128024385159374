import React, { useState } from 'react';
import styled from 'styled-components';

import Select from 'components/lib/form/Select';
import Confirmation from 'components/lib/ui/Confirmation';

import useCategoryGroupSelectOptions from 'lib/hooks/useCategoryGroupSelectOptions';

import { isSingleValue } from 'types/select';

type CategoryT = {
  name: string;
  icon: string;
};

type Props = {
  onDone: () => void;
  onDelete: (moveToGroupId?: string) => void;
  group: {
    id: GraphQlUUID;
    categories: CategoryT[];
  };
};

const Root = styled.div``;
const Subtitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;
const Categories = styled.div``;
const Category = styled.div`
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.default};
  background-color: ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.small};
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
`;
const Icon = styled.span`
  padding-right: ${({ theme }) => theme.spacing.xsmall};
`;
const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.color.red};
`;

const DeleteCategoryGroupConfirmation = ({ group, onDelete, onDone }: Props) => {
  const [isLoading, categoryGroupOptions] = useCategoryGroupSelectOptions();
  const [moveToGroupId, setMoveToGroupId] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const deleteGroupWithMoveToGroupId = () => {
    if (moveToGroupId) {
      onDelete(moveToGroupId);
    } else {
      setErrorMessage('Please set a group to move the categories to.');
    }
  };

  const validCategoryGroupOptions = categoryGroupOptions.filter((cg) => cg.value !== group.id);

  return (
    <Root>
      <Confirmation
        title="Delete Group"
        confirm="Delete"
        onConfirm={deleteGroupWithMoveToGroupId}
        onCancel={onDone}
        useDangerButton
      >
        There are {group.categories.length} categories nested in this group (listed below). Before
        you delete it, where should we move them to?
        <Categories>
          {group.categories.map((category, i) => (
            <Category key={i}>
              <Icon>{category.icon}</Icon>
              {category.name}
            </Category>
          ))}
        </Categories>
        <Subtitle>Move Categories to Group</Subtitle>
        {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Select
          openMenuOnFocus
          isLoading={isLoading}
          options={validCategoryGroupOptions}
          onChange={(option) => {
            if (isSingleValue(option)) {
              setMoveToGroupId(option.value);
            }
          }}
        />
      </Confirmation>
    </Root>
  );
};

export default DeleteCategoryGroupConfirmation;
