import _ from 'lodash';
import * as R from 'ramda';
import * as Yup from 'yup';

export type ValidationOptions = {
  name: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  email?: boolean;
  url?: boolean;
  password?: boolean;
  sameAs?: string;
  label?: string;
  errorLabel?: string;
};

export const validationOptionKeys: (keyof ValidationOptions)[] = [
  'name',
  'required',
  'minLength',
  'maxLength',
  'email',
  'url',
  'password',
  'sameAs',
  'label',
  'errorLabel',
];

const schemaForOptions = ({
  name,
  required,
  minLength,
  maxLength,
  email,
  url,
  password,
  sameAs,
  label: labelProp,
  errorLabel,
}: ValidationOptions) => {
  let schema: Yup.StringSchema<string | null> = Yup.string();

  const label = errorLabel || labelProp || _.startCase(name);
  schema = schema.label(label);

  if (required) {
    schema = schema.trim().required('This field is required');
  } else {
    schema = schema.nullable();
  }

  if (minLength) {
    schema = schema.min(minLength);
  }

  if (maxLength) {
    schema = schema.max(maxLength);
  }

  if (email) {
    schema = schema.email();
  }

  if (url) {
    schema = schema.url();
  }

  if (password) {
    LOGIN_PASSWORD_SCHEMAS.forEach((passwordSchema) => {
      schema = schema.concat(passwordSchema.schema(label));
    });
  }

  if (sameAs) {
    schema = schema.oneOf([Yup.ref(sameAs)], `Does not match ${_.startCase(sameAs)}`);
  }

  return schema;
};

type CriteriaMessageFunction = (label: string, error?: Yup.ValidationError) => string;

type PasswordSchema = {
  schema: (label: string) => Yup.StringSchema<string | null>;
  criteriaMessage: string | CriteriaMessageFunction;
};

// We use this validator on login screens because some old users signed up without
// the new symbol criteria below.
const LOGIN_PASSWORD_SCHEMAS: PasswordSchema[] = [
  {
    schema: (label) => Yup.string().min(8).max(128),
    criteriaMessage: (label: string, error?: Yup.ValidationError) =>
      error && error.message.includes('at most')
        ? 'Maximum 128 characters'
        : 'Minimum 8 characters',
  },
  {
    schema: (label) =>
      Yup.string().matches(/\d/, {
        message: `${label} must contain at least 1 number`,
      }),
    criteriaMessage: 'At least one number',
  },
];

// We use this strictor validator on the signup screen.
export const SIGNUP_PASSWORD_SCHEMAS: PasswordSchema[] = [
  ...LOGIN_PASSWORD_SCHEMAS,
  {
    schema: (label) =>
      Yup.string().matches(
        // https://owasp.org/www-community/password-special-characters
        /[!"#$%&'()*+'-./:;<=>?@[\\\]^_`{|}~]/,
        { message: `${label} must contain at least 1 symbol` },
      ),
    criteriaMessage: 'At least one symbol',
  },
];

// Return a schema that merges all the schemas in the input.
const mergeSchemas = (schemas: Yup.StringSchema<string | null>[]) => {
  let mergedSchema: Yup.StringSchema<string | null> = Yup.string();
  schemas.forEach((schema) => {
    mergedSchema = mergedSchema.concat(schema);
  });
  return mergedSchema;
};

export const getMergedSignupPasswordSchema = (label: string) => {
  const mergedSchemas = SIGNUP_PASSWORD_SCHEMAS.map((schemaGenerator) =>
    schemaGenerator.schema(label),
  );
  return mergeSchemas(mergedSchemas);
};

const objectToSchema = (object: any): Yup.ObjectSchema<Record<string, unknown>> => {
  if (object.yupSchema) {
    return object.yupSchema;
  }
  const mapped = _.mapValues(object, objectToSchema);
  return Yup.object().shape(mapped);
};

export const createValidationSchema = (fields: ValidationOptions[]) => {
  const nested = fields.reduce(
    (combined, field) =>
      R.assocPath([...field.name.split('.'), 'yupSchema'], schemaForOptions(field), combined),
    {},
  );

  return objectToSchema(nested);
};

type PasswordValidationResult = {
  meetsCriteria: boolean;
  criteriaMessage: string;
};

export const validateWithPasswordSchema = (
  label: string,
  entry: PasswordSchema,
  password: string,
): PasswordValidationResult => {
  const result: PasswordValidationResult = {
    meetsCriteria: true,
    criteriaMessage: getPasswordCriteriaMessageForEntry(entry, label),
  };
  try {
    entry.schema(label).validateSync(password);
  } catch (err: any) {
    result.meetsCriteria = false;
    result.criteriaMessage = getPasswordCriteriaMessageForEntry(entry, label, err);
  }
  return result;
};

const getPasswordCriteriaMessageForEntry = (
  entry: PasswordSchema,
  label: string,
  err?: Yup.ValidationError,
) =>
  _.isFunction(entry.criteriaMessage) ? entry.criteriaMessage(label, err) : entry.criteriaMessage;
