import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import EmptyStateCard from 'components/lib/ui/EmptyStateCard';
import ManualLink from 'components/lib/ui/link/ManualLink';
import RouteLink from 'components/lib/ui/link/RouteLink';

import { setReportsFilters } from 'actions';
import { useDispatch } from 'lib/hooks';
import { selectReportsHasFilters } from 'state/reports/selectors';

import routes from 'constants/routes';

type Props = {
  title?: string;
  iconName?: string;
  width?: number;
};

const ReportsChartCardEmpty: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const dispatch = useDispatch();
  const hasFilters = useSelector(selectReportsHasFilters);
  const clearFilters = useCallback(() => dispatch(setReportsFilters(null)), []);

  return (
    <EmptyStateCard {...props}>
      Try updating your date range, or{' '}
      {hasFilters ? (
        <ManualLink onClick={clearFilters}>clearing your filters</ManualLink>
      ) : (
        <RouteLink to={routes.accounts.addAccount()}>add more accounts</RouteLink>
      )}
      .
    </EmptyStateCard>
  );
};

export default ReportsChartCardEmpty;
