import { gql } from '@apollo/client';
import pluralize from 'pluralize';
import { rgba } from 'polished';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import { getDaysLeftOfTrial, getTrialProgressPercent } from 'common/lib/billing/Billing';

import { TRIAL_TYPE_TO_LABEL_MOBILE } from 'common/constants/billing';
import { DECIMAL_TO_PERCENT } from 'common/constants/math';
import routes from 'constants/routes';

import type { Web_GetSidebarDataQuery } from 'common/generated/graphql';
import { TrialType } from 'common/generated/graphql';

type Props = {
  data: Web_GetSidebarDataQuery['subscription'];
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.xsmall} 0;
  padding: ${({ theme }) => theme.spacing.default};
  background-color: ${({ theme }) => theme.color.navyLight};
  border-radius: ${({ theme }) => theme.radius.small};
  cursor: pointer;
`;

const SecondaryText = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  opacity: 0.7;
`;

const ProgressBar = styled.span<{ progressPercent: number; daysLeft: number }>`
  width: 100%;
  height: 8px;
  border-radius: ${({ theme }) => theme.radius.small};
  background-color: ${({ theme }) => rgba(theme.color.black, 0.4)};
  margin-top: 9px;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    border-radius: ${({ theme }) => theme.radius.small};
    top: 0;
    left: 0;
    width: ${({ progressPercent }) => progressPercent}%;
    height: 100%;
    background-color: ${({ theme, daysLeft }) => theme.color[daysLeft <= 3 ? 'yellow' : 'green']};
  }
`;

const GiftIcon = styled(Icon).attrs({ name: 'gift' })`
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const FreeTrialDurationPanel = ({ data }: Props) => {
  const history = useHistory();
  const { trialEndsAt, trialDurationDays, trialType } = data;

  const daysLeft = Math.max(getDaysLeftOfTrial(trialEndsAt) ?? trialDurationDays, 0);
  const progressPercent = getTrialProgressPercent(daysLeft, trialDurationDays) * DECIMAL_TO_PERCENT;

  const isGuestPassTrial = data.trialType === TrialType.GUEST_PASS;

  return (
    <Root onClick={() => history.push(routes.settings.billing())}>
      <FlexContainer alignCenter justifyBetween full>
        {isGuestPassTrial ? (
          <FlexContainer alignCenter>
            <GiftIcon />
            <Text weight="medium" size="small">
              {TRIAL_TYPE_TO_LABEL_MOBILE.guest_pass}
            </Text>
          </FlexContainer>
        ) : (
          <Text weight="medium" size="small">
            {TRIAL_TYPE_TO_LABEL_MOBILE[trialType ?? TrialType.PREMIUM]}
          </Text>
        )}
        <SecondaryText>
          {`${daysLeft ?? '-'} ${pluralize('days', daysLeft ?? 0)} left`}
        </SecondaryText>
      </FlexContainer>
      <ProgressBar daysLeft={daysLeft} progressPercent={progressPercent} />
    </Root>
  );
};

FreeTrialDurationPanel.fragments = {
  daysLeftOfTrial: gql`
    fragment FreeTrialDurationPanelData on HouseholdSubscription {
      id
      trialEndsAt
      trialDurationDays
      trialType
    }
  `,
};

export default FreeTrialDurationPanel;
