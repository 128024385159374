import html2canvas from 'html2canvas';
import type { Options } from 'html-to-image/lib/options';

import { MONARCH_WATERMARK_NODE_ID } from 'components/lib/ui/MonarchWatermark';

import typewriter from 'lib/analytics/typewriter';
import isEnvDevelopment from 'lib/isEnvDevelopment';
import type { ReportsChart } from 'state/reports/types';

export enum ChartType {
  CashFlowSankey = 'sankey',
  CashFlowBreakdown = 'breakdown',
}

const BREAKDOWN_CANVAS_WIDTH_PX = 512;

export type DownloadChartOptions<ElementT> = {
  element: Maybe<ElementT>;
  fileName: string;
  chartType: ChartType | ReportsChart | string | undefined;
  view?: string;
  backgroundColor?: string;
  includeWatermark?: boolean;
  scale?: number;
} & Options;

export const onGenerateChartForDownload = async <ElementT extends HTMLElement>({
  element,
  fileName,
  chartType,
  view,
  backgroundColor = 'transparent',
  includeWatermark = true,
  ...options
}: DownloadChartOptions<ElementT>) => {
  if (!element) {
    return;
  }

  const canvas = await html2canvas(element, {
    backgroundColor,
    logging: isEnvDevelopment(),
    /* The code in here is executed before the snapshot is taken. `document` and `el` are
    the cloned nodes already. If you want to access something outside of it, use `window`. */
    onclone: (document, el) => {
      if (includeWatermark) {
        // We'll probably want to get a remote SVG for this at some point
        const watermark = document.getElementById(MONARCH_WATERMARK_NODE_ID);

        if (watermark) {
          watermark.style.display = 'flex';
          watermark.style.left = '0px';
          el.prepend(watermark);
        }

        if (watermark && chartType === ChartType.CashFlowSankey) {
          // Sankey has a weird margin issue with the watermark
          watermark.style.marginBottom = '-24px';
        }
      }

      // Modify specific styles for the bar chart before taking the snapshot
      if (chartType === ChartType.CashFlowBreakdown) {
        el.style.width = `${BREAKDOWN_CANVAS_WIDTH_PX}px`;
      }
    },
    ...options,
  });

  return canvas;
};

export const onDownloadChartAsPng = (
  canvas: HTMLCanvasElement,
  fileName: string,
  eventProps?: Parameters<typeof typewriter.chartDownloaded>[0],
) =>
  new Promise<void>((resolve) =>
    canvas.toBlob((blob) => {
      if (!blob) {
        return;
      }
      const hiddenLink = window.document.createElement('a');
      hiddenLink.href = URL.createObjectURL(blob);
      hiddenLink.download = fileName;
      hiddenLink.click();

      if (eventProps) {
        typewriter.chartDownloaded(eventProps);
      }

      resolve();
    }),
  );
