import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import PagedCarousel from 'components/lib/ui/PagedCarousel';
import Text from 'components/lib/ui/Text';

import { TESTIMONIALS } from 'common/constants/onboarding/testimonials';

const PLATFORM_ICON_NAME = {
  apple: 'apple',
  google: 'monarch-google-play-logo',
};

const Root = styled.div`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Card = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadow.medium};
  padding: ${({ theme }) => theme.spacing.xlarge} ${({ theme }) => theme.spacing.default};
  border: 1px solid ${({ theme }) => theme.color.grayLight};

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    margin: 0 ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.large};
  }
`;

const StarsContainer = styled.div`
  color: ${({ theme }) => theme.color.yellow};
  display: grid;
  grid-template-columns: repeat(5, min-content);
  column-gap: 4px;
  user-select: none;
`;

const Name = styled(Text).attrs({ size: 'small', color: 'textLight' })`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const Carousel = styled(PagedCarousel)`
  max-width: 100%;
  width: auto;
  display: block;

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    display: none;
  }
`;

const MobileTestimonials = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    display: block;
  }
`;

const DesktopTestimonials = styled.div`
  display: none;

  column-gap: ${({ theme }) => theme.spacing.xlarge};
  row-gap: ${({ theme }) => theme.spacing.xlarge};
  grid-template-columns: repeat(${TESTIMONIALS.length}, 60vw);
  overflow: auto;

  @media (min-width: ${({ theme }) => theme.breakPoints.xs}px) {
    overflow: visible;
    max-width: 1008px;
  }
  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    display: grid;
  }
`;

type Props = {
  className?: string;
};

const Testimonials = ({ className }: Props) => (
  <Root className={className}>
    <DesktopTestimonials>
      {TESTIMONIALS.map(({ quote, name, platform }, i) => (
        <Card key={i}>
          <FlexContainer justifyBetween>
            <FlexContainer column>
              <StarsContainer>
                {R.range(0, 5).map((i) => (
                  <Icon key={i} name="star-filled" size={20} />
                ))}
              </StarsContainer>
              <Name>{name}</Name>
            </FlexContainer>
            {!!platform && <Icon name={PLATFORM_ICON_NAME[platform]} size={24} />}
          </FlexContainer>
          {quote}
        </Card>
      ))}
    </DesktopTestimonials>
    <MobileTestimonials>
      <Carousel name="testimonials" automated>
        {TESTIMONIALS.map(({ quote, name, platform }, i) => (
          <Card key={i}>
            <FlexContainer justifyBetween>
              <FlexContainer column>
                <StarsContainer>
                  {R.range(0, 5).map((i) => (
                    <Icon key={i} name="star-filled" size={20} />
                  ))}
                </StarsContainer>
                <Name>{name}</Name>
              </FlexContainer>
              {!!platform && <Icon name={PLATFORM_ICON_NAME[platform]} size={24} />}
            </FlexContainer>
            {quote}
          </Card>
        ))}
      </Carousel>
    </MobileTestimonials>
  </Root>
);

export default Testimonials;
