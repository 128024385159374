import React from 'react';

import ExpandMoreIconButton from 'components/lib/ui/ExpandMoreIconButton';
import PlanRowTitle from 'components/plan/PlanRowTitle';
import OnboardingAmountCell from 'components/plan/onboarding/OnboardingAmountCell';
import { CardRow, Left, Right } from 'components/plan/onboarding/OnboardingCard';

import type { PlanRowData } from 'lib/plan/Adapters';

export type BudgetedRowData = PlanRowData & { budgeted?: number | null };

const OnboardingBudgetCardRow = ({
  rowData,
  onClickIcon,
}: {
  onClickIcon?: () => void;
  rowData: BudgetedRowData;
}) => {
  const { id, name, icon: rowIcon, budgeted, canBeBudgeted, groupLevelBudgetingEnabled } = rowData;

  const icon = groupLevelBudgetingEnabled ? <ExpandMoreIconButton expanded={false} /> : rowIcon;

  return (
    <CardRow $withTopBorder>
      <Left $rightShadow>
        <PlanRowTitle icon={icon} onClickIcon={onClickIcon}>
          <span>{name}</span>
        </PlanRowTitle>
      </Left>
      {!groupLevelBudgetingEnabled && (
        <Right>
          <OnboardingAmountCell canEdit={canBeBudgeted} itemId={id} value={budgeted ?? 0} />
        </Right>
      )}
    </CardRow>
  );
};

export default OnboardingBudgetCardRow;
