import React from 'react';
import styled from 'styled-components';

import Mark from 'components/brand/Mark';
import FlexContainer from 'components/lib/ui/FlexContainer';

import theme from 'common/lib/theme';

const Root = styled(FlexContainer).attrs({ center: true })<{
  $size: number;
  $backgroundColor: string;
}>`
  border-radius: ${({ theme }) => theme.radius.round};
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

type Props = {
  color?: string;
  backgroundColor?: string;
  size?: number;
  padding?: number;
  className?: string;
};

const MarkCircle = ({
  color = theme.color.white,
  backgroundColor = theme.color.orange,
  size = 56,
  padding = 14,
  className,
}: Props) => (
  <Root className={className} $size={size} $backgroundColor={backgroundColor}>
    <Mark color={color} size={size - padding * 2} />
  </Root>
);

export default MarkCircle;
