import { useApolloClient, useMutation } from '@apollo/client';
import type { DateTime } from 'luxon';
import { useCallback } from 'react';

import { getOptimisticMonthlyAmounts } from 'common/lib/budget/OptimisticAdapter';

import { gql } from 'common/generated/gql';
import { BudgetTimeframeInput } from 'common/generated/graphql';
import type { MutationHookOptionsFromDocument } from 'common/types/graphql';

type Options = MutationHookOptionsFromDocument<typeof UPDATE_BUDGET_ITEM>;

const useUpdateBudgetItemMutation = (options: Options) => {
  const client = useApolloClient();
  const [performMutation, { loading }] = useMutation(UPDATE_BUDGET_ITEM, options);

  const optimisticUpdateCacheRollovers = useCallback(
    (startDate: DateTime, id: string, amount: number, isGroup = false) => {
      const cacheSubfieldId = isGroup
        ? `{"categoryGroup":{"id":"${id}"}}`
        : `{"category":{"id":"${id}"}}`;

      const cacheId = isGroup
        ? `BudgetCategoryGroupMonthlyAmounts:${cacheSubfieldId}`
        : `BudgetCategoryMonthlyAmounts:${cacheSubfieldId}`;
      const fragment = isGroup
        ? BUDGET_CATEGORY_GROUP_MONTHLY_AMOUNTS_FRAGMENT
        : BUDGET_CATEGORY_MONTHLY_AMOUNTS_FRAGMENT;

      const data = client.readFragment({
        id: cacheId,
        fragment,
      });

      if (data) {
        const writeData = getOptimisticMonthlyAmounts<typeof data>(data, amount, startDate);
        client.writeFragment({
          id: cacheId,
          fragment,
          data: writeData,
        });
      }
    },
    [client],
  );

  const updateBudgetItemAmount =
    (startDate: DateTime, categoryId?: string, categoryGroupId?: string) =>
    async (amount: number, applyToFuture?: boolean) => {
      if (categoryGroupId) {
        optimisticUpdateCacheRollovers(startDate, categoryGroupId, amount, true);

        await performMutation({
          variables: {
            // @ts-expect-error: TODO: change graphql codegen to use optionals for nullable fields
            input: {
              startDate: startDate.toISODate(),
              timeframe: BudgetTimeframeInput.MONTH,
              categoryGroupId,
              amount,
              applyToFuture,
            },
          },
        });
      }

      if (categoryId) {
        optimisticUpdateCacheRollovers(startDate, categoryId, amount);

        await performMutation({
          variables: {
            // @ts-expect-error: TODO: change graphql codegen to use optionals for nullable fields
            input: {
              startDate: startDate.toISODate(),
              timeframe: BudgetTimeframeInput.MONTH,
              categoryId,
              amount,
              applyToFuture,
            },
          },
        });
      }
    };

  return { updateBudgetItemAmount, optimisticUpdateCacheRollovers, loading };
};

const BUDGET_CATEGORY_MONTHLY_AMOUNTS_FRAGMENT = gql(/* GraphQL */ `
  fragment OptimisticUpdateCategoryMonthlyAmounts on BudgetCategoryMonthlyAmounts {
    category {
      id
    }
    monthlyAmounts {
      month
      plannedCashFlowAmount
      plannedSetAsideAmount
      actualAmount
      remainingAmount
      previousMonthRolloverAmount
      rolloverType
    }
  }
`);

const BUDGET_CATEGORY_GROUP_MONTHLY_AMOUNTS_FRAGMENT = gql(/* GraphQL */ `
  fragment OptimisticUpdateCategoryGroupMonthlyAmounts on BudgetCategoryGroupMonthlyAmounts {
    categoryGroup {
      id
    }
    monthlyAmounts {
      month
      plannedCashFlowAmount
      plannedSetAsideAmount
      actualAmount
      remainingAmount
      previousMonthRolloverAmount
      rolloverType
    }
  }
`);

const UPDATE_BUDGET_ITEM = gql(/* GraphQL */ `
  mutation Common_UpdateBudgetItem($input: UpdateOrCreateBudgetItemMutationInput!) {
    updateOrCreateBudgetItem(input: $input) {
      budgetItem {
        id
        budgetAmount
      }
    }
  }
`);

export default useUpdateBudgetItemMutation;
