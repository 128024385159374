import * as RA from 'ramda-adjunct';
import * as React from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import AnimatedNumber from 'components/lib/ui/AnimatedNumber';
import Card from 'components/lib/ui/Card';
import Tooltip from 'components/lib/ui/Tooltip';
import AmountsTooltip, { WIDTH_PX as TOOLTIP_WIDTH_PX } from 'components/plan/AmountsTooltip';

import {
  getExcessAmount,
  ExcessBudgetType,
  getSafeToSpendAmount,
} from 'common/lib/budget/Adapters';
import type { SummaryDisplayBudgetType } from 'common/lib/budget/Adapters';
import { getAmountColor } from 'common/lib/budget/Amounts';
import { formatCurrencyNoCents } from 'common/utils/Currency';

const Root = styled(Card)<{ $background: string }>`
  background-color: ${({ $background }) => $background};
  transition: background-color 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
  margin-left: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
  padding: ${({ theme }) => theme.spacing.xlarge};
  color: ${({ theme }) => theme.color.textWhite};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-align: center;
  line-height: initial;
`;

const Amount = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxlarge};
`;

const Subtitle = styled.div`
  margin-top: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  data?: SummaryDisplayBudgetType[];
  shouldShowSafeToSpend: boolean;
};

const PlanSummaryAmounts = ({ data, shouldShowSafeToSpend }: Props) => {
  // If amount is null means no data is available yet
  // so probably reloading due to a change in category group settings
  const amount = shouldShowSafeToSpend ? getSafeToSpendAmount(data) : getExcessAmount(data);
  const background = getAmountColor(amount);

  return (
    <Tooltip
      portal
      maxWidth={TOOLTIP_WIDTH_PX}
      place="left"
      content={
        data && (
          <AmountsTooltip
            title="Budgeted"
            rows={data.map(({ title, type, budgeted }) => {
              const isExcess = type === ExcessBudgetType;

              if (shouldShowSafeToSpend && isExcess) {
                return {
                  title: 'Safe to spend',
                  value: amount ?? 0,
                  type: 'income',
                  emphasis: true,
                };
              }

              if (isExcess) {
                return {
                  title: 'Left to budget',
                  value: budgeted ?? 0,
                  type: 'income',
                  emphasis: true,
                };
              }

              return {
                title: title ?? '',
                value: budgeted ?? undefined,
                type: undefined,
              };
            })}
          />
        )
      }
    >
      <div>
        <Root $background={background}>
          <Amount {...maskClassProps}>
            {RA.isNotNumber(amount) || amount === null ? (
              '-'
            ) : (
              <AnimatedNumber value={amount} formattingFn={formatCurrencyNoCents} />
            )}
          </Amount>

          <Switch>
            <Case when={shouldShowSafeToSpend}>
              <Subtitle>Safe to spend</Subtitle>
            </Case>

            <Case default>
              <Subtitle>Left to budget</Subtitle>
            </Case>
          </Switch>
        </Root>
      </div>
    </Tooltip>
  );
};

export default PlanSummaryAmounts;
