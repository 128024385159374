import type StyleMixin from 'types/StyleMixin';

const buttonShadowMixin: StyleMixin = ({ theme }) => `
  box-shadow: ${theme.shadow.small};
  :active:not(:disabled), &.btn-active:not(:disabled) {
    box-shadow: ${theme.shadow.inset};
  }
`;

export default buttonShadowMixin;
