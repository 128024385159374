import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

import {
  CREATE_SPINWHEEL_ACCOUNT_MAPPING_MUTATION,
  CREATE_SPINWHEEL_TOKEN_MUTATION,
  GET_SPINWHEEL_CREDIT_REPORT,
} from 'common/lib/graphQl/spinwheel';
import usePollQueryUntil from 'common/lib/hooks/usePollQueryUntil';

import { MS_PER_SECOND } from 'common/constants/time';

import type { AccountMapping } from 'common/generated/graphql';
import { OnboardingStatus } from 'common/generated/graphql';

const POLL_INTERVAL_MS = MS_PER_SECOND;
const POLL_TIMEOUT_MS = 10 * MS_PER_SECOND; // 10 seconds

/**
 * Hook used to connect with Spinwheel and fetch Liabilities.
 */
const useSpinwheel = () => {
  const [isSyncingCreditReport, setIsSyncingCreditReport] = useState(false);
  const [isCreditReportSyncTimedOut, setIsCreditReportSyncTimedOut] = useState(false);

  const [performCreateSpinwheelTokenMutation] = useMutation(CREATE_SPINWHEEL_TOKEN_MUTATION);
  const [performCreateSpinwheelAccountMappingMutation] = useMutation(
    CREATE_SPINWHEEL_ACCOUNT_MAPPING_MUTATION,
  );

  const authSpinwheel = useCallback(async () => {
    const response = await performCreateSpinwheelTokenMutation();

    const { token, errors } = response?.data?.createSpinwheelToken ?? {};

    if (errors || !token) {
      throw new Error(errors?.message ?? undefined);
    } else {
      return token;
    }
  }, [performCreateSpinwheelTokenMutation]);

  // temp/fake
  const createSpinwheelAccountMapping = useCallback(
    async (accountMapping: AccountMapping[]) => {
      const response = await performCreateSpinwheelAccountMappingMutation({
        variables: {
          input: {
            // the fake endpoint is expecting a JSON string for now
            accountMapping,
          },
        },
      });

      return response?.data?.createSpinwheelAccountMapping;
    },
    [performCreateSpinwheelAccountMappingMutation],
  );

  /*
   * Polls liabilities from our server since we need to wait until for Spinwheel's webhook
   * Spinwheel's webhook orders the first credit report so we have liabilities to show.
   *
   * If it times out, we show an error message and ask the user to try again later.
   */

  const [startPolling, { data: creditReportData }] = usePollQueryUntil(
    GET_SPINWHEEL_CREDIT_REPORT,
    {
      pollUntil: (data) => data?.spinwheelUser?.onboardingStatus === OnboardingStatus.ACTIVE,
      onComplete: (data) => {
        setIsSyncingCreditReport(false);
      },
      pollIntervalMs: POLL_INTERVAL_MS,
      skipWhenNotPolling: true,
      pollTimeoutMs: POLL_TIMEOUT_MS,
      onTimeoutReached: () => {
        setIsSyncingCreditReport(false);
        setIsCreditReportSyncTimedOut(true);
      },
    },
  );

  const syncCreditReport = async () => {
    setIsSyncingCreditReport(true);
    startPolling();
  };

  return {
    authSpinwheel,
    syncCreditReport,
    isSyncingCreditReport,
    createSpinwheelAccountMapping,
    creditReportLiabilityAccounts: creditReportData?.creditReportLiabilityAccounts,
    spinwheelUser: creditReportData?.spinwheelUser,
    isCreditReportSyncTimedOut,
  };
};

export default useSpinwheel;
