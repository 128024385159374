import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';

import useCachedAccountTypeOptions from 'common/lib/hooks/accounts/useCachedAccountTypeOptions';
import { formatCurrency } from 'common/utils/Currency';
import { formatPercentNoDecimals } from 'common/utils/Number';

import { ACCOUNT_TYPE_TO_COLOR_MAP } from 'common/constants/accounts';
import type { AccountType } from 'common/constants/accounts';

type Props = Partial<{
  type: AccountType;
  name: string;
  balance: number;
  inverted: boolean;
  className: string;
  onClick: () => void;
  hideDot: boolean;
  isAsset: boolean;
  percentage: number;
}>;

const DOT_SIZE_PX = 8;

const Root = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })<{
  $inverted: boolean;
  $clickable: boolean;
}>`
  color: ${({ $inverted, theme }) => ($inverted ? theme.color.textWhite : theme.color.text)};
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'initial')};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const Dot = styled.div<{ $color?: string }>`
  display: inline-block;
  width: ${DOT_SIZE_PX}px;
  height: ${DOT_SIZE_PX}px;
  border-radius: ${({ theme }) => theme.radius.round};
  margin-right: ${DOT_SIZE_PX}px;
  background-color: ${({ $color, theme }) => $color ?? theme.color.gray};
`;

const AccountLabel = styled(Text)`
  text-transform: capitalize;
`;

const OTHER_TYPE_LABEL = 'Other';

const AccountBalanceIndicator = ({
  type,
  name,
  balance,
  className,
  onClick,
  inverted = false,
  hideDot = false,
  isAsset,
  percentage,
}: Props) => {
  if (R.isNil(type) && R.isNil(name)) {
    throw new Error('AccountBalanceIndicator must have either a type or a name');
  }

  const { typeOptions } = useCachedAccountTypeOptions();
  const matchingAccountType = useMemo(
    () =>
      typeOptions.find(({ value, label }) =>
        type ? value.toLowerCase() === type : label === name,
      ),
    [typeOptions, name, type],
  );
  const accountTypeValue = matchingAccountType?.value.toLowerCase() as AccountType;
  const accountLabel = matchingAccountType?.label ?? '';

  /** Both other_asset and other_liability have the same label ("Other"), we don't want to show
   * duplicates on the tooltip at least because the accounts aren't grouped like on summary card */
  const adaptedAccountLabel =
    accountTypeValue && ['other_asset', 'other_liability'].includes(accountTypeValue)
      ? R.replace(/_/g, ' ', accountTypeValue)
      : accountLabel;

  const dotColor = useMemo(() => {
    if (RA.isNilOrEmpty(isAsset)) {
      return ACCOUNT_TYPE_TO_COLOR_MAP[accountTypeValue];
    }

    if (adaptedAccountLabel === OTHER_TYPE_LABEL) {
      return ACCOUNT_TYPE_TO_COLOR_MAP[isAsset ? 'other_asset' : 'other_liability'];
    }

    return ACCOUNT_TYPE_TO_COLOR_MAP[accountTypeValue];
  }, [adaptedAccountLabel, accountTypeValue, isAsset]);

  return (
    <Root
      $inverted={inverted}
      $clickable={RA.isNotNil(onClick)}
      className={className}
      onClick={onClick}
    >
      <FlexContainer alignCenter>
        {!hideDot && accountTypeValue && <Dot $color={dotColor} />}
        <AccountLabel>{adaptedAccountLabel}</AccountLabel>
      </FlexContainer>
      <Tooltip content={percentage ? formatCurrency(balance) : undefined}>
        <span {...sensitiveClassProps}>
          {percentage ? formatPercentNoDecimals(percentage) : formatCurrency(balance)}
        </span>
      </Tooltip>
    </Root>
  );
};

export default AccountBalanceIndicator;
