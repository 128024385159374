import { rgba } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';

import CurrencyInput from 'components/lib/form/CurrencyInput';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Currency from 'components/lib/ui/currency/Currency';

import type StyleMixin from 'types/StyleMixin';

const PlanningTableCell = styled.span<{
  isHighlighted?: boolean;
  lastRow?: boolean;
  isFocused?: boolean;
  isHeader?: boolean;
}>`
  position: relative;
  display: flex;
  background-color: ${({ theme, isHeader, isHighlighted }) =>
    isHeader || isHighlighted ? theme.color.white : rgba(theme.color.white, 0.5)};
  border: 1px solid
    ${({ theme, isHeader, isHighlighted }) =>
      isHeader || isHighlighted ? theme.color.grayBackground : theme.color.grayFocus};
  justify-content: flex-end;
  font-weight: ${({ theme, isHeader }) =>
    isHeader ? theme.fontWeight.medium : theme.fontWeight.book};
  user-select: none;
  min-width: 112px;
  height: 60px;

  ${({ isHighlighted, isFocused, theme, lastRow }) =>
    isHighlighted &&
    !isFocused &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: calc(-1 * ${theme.spacing[lastRow ? 'large' : 'default']} - 1px);
        left: 0;
        width: 100%;
        height: ${theme.spacing[lastRow ? 'large' : 'default']};
        background-color: ${({ theme }) => theme.color.white};
        border-radius: 0 0 ${lastRow ? theme.radius.medium : 0} ${lastRow ? theme.radius.medium : 0};
      }
    `};
`;

const cellStyleMixin: StyleMixin<{ isEmpty: boolean }> = ({ theme, isEmpty }) => `
  width: 100%;
  text-align: right;
  color: ${isEmpty ? theme.color.textLight : theme.color.text};
  background: none;
`;

export const Input = styled(CurrencyInput)<{ isEmpty: boolean }>`
  ${cellStyleMixin};

  :not(:focus):not(:hover) {
    border-color: transparent;
  }
`;

export const ActualCell = styled(Currency)<{ isEmpty: boolean }>`
  ${cellStyleMixin};
  padding: 0px ${({ theme }) => theme.spacing.default};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const NonInputItemContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.default};
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  top: -1px;
  left: -7px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid ${({ theme }) => theme.color.blue};
  transform: rotate(-45deg);
  transform-origin: top center;
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  width: 24px;
  height: 24px;
`;
export const CellLoadingSpinner = () => (
  <FlexContainer alignCenter marginRight="default">
    <StyledLoadingSpinner />
  </FlexContainer>
);

export default PlanningTableCell;
