import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import type { GroupDataWithBudgetedValue } from 'components/plan/onboarding/OnboardingBudgetCard';
import OnboardingBudgetCard from 'components/plan/onboarding/OnboardingBudgetCard';
import OnboardingGroupFooter from 'components/plan/onboarding/OnboardingGroupFooter';

const DividerWrapper = styled(FlexContainer).attrs({ alignCenter: true, full: true })``;

const TopDividerWrapper = styled(DividerWrapper)`
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const DividerText = styled(Text).attrs({ size: 'xsmall', color: 'textLight', weight: 'bold' })`
  text-wrap: nowrap;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  margin-left: ${({ theme }) => theme.spacing.large};
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.grayFocus};
  position: relative;
`;

const BottomDividerWrapper = styled(DividerWrapper)`
  margin: ${({ theme }) => theme.spacing.default} 0;
`;

const StyledButton = styled(DefaultButton).attrs({ size: 'small' })`
  text-wrap: nowrap;
  margin-right: ${({ theme }) => theme.spacing.large};
`;

type Props = {
  data: GroupDataWithBudgetedValue[];
  footerTitle: string;
  footerValue: number;
};

const OnboardingBudgetList = ({ data, footerTitle, footerValue }: Props) => (
  <div>
    <TopDividerWrapper>
      <Line />
      <DividerText size="small">monthly budget</DividerText>
    </TopDividerWrapper>

    <FlexContainer column gap="default">
      {data?.map((group) => <OnboardingBudgetCard key={group.id} group={group} />)}
    </FlexContainer>
    <BottomDividerWrapper>
      <StyledButton>
        <ButtonIcon name="plus-circle" size={14} />
        <span>Add group</span>
      </StyledButton>
      <Line />
    </BottomDividerWrapper>
    <OnboardingGroupFooter title={footerTitle} value={footerValue} />
  </div>
);

export default OnboardingBudgetList;
