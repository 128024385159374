import React from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import Confirmation from 'components/lib/ui/Confirmation';

import useModal from 'lib/hooks/useModal';

import { ROLLOVER_ENABLED_FIELD_NAME } from 'common/constants/budget';

const ConfirmationBody = styled.div`
  margin-top: -${({ theme }) => theme.spacing.default};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  rolloverEnabledInitially: boolean;
  label?: string;
  children: (props: { onSave: () => void }) => React.ReactNode;
};

const DisableRolloverConfirmation = ({ rolloverEnabledInitially, children, label }: Props) => {
  const [ConfirmModal, { open, close }] = useModal();

  const { submitForm, getFieldMeta, setFieldValue } = useFormContext();

  const onSave = () => {
    const isRolloverEnabled = getFieldMeta(ROLLOVER_ENABLED_FIELD_NAME)?.value;
    if (!isRolloverEnabled && rolloverEnabledInitially) {
      // Rollover was disabled, so show confirmation to user before submitting
      open();
    } else {
      // Just submit the form normally
      submitForm();
    }
  };

  const onConfirm = () => {
    close();
    submitForm();
  };

  const onCancel = () => {
    close();
    setFieldValue(ROLLOVER_ENABLED_FIELD_NAME, true);
  };

  return (
    <>
      {children({ onSave })}
      <ConfirmModal>
        <Confirmation
          title="Are you sure?"
          confirm="Yes, I'm sure"
          onConfirm={onConfirm}
          onCancel={onCancel}
        >
          <ConfirmationBody>
            {label ??
              'Turning off rollovers for this category will remove all historical and future rollovers from your plan.'}
          </ConfirmationBody>
        </Confirmation>
      </ConfirmModal>
    </>
  );
};

export default DisableRolloverConfirmation;
