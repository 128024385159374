import { gql } from 'common/generated/gql';

export const GET_JOINT_PLANNING_DATA = gql(`
  query Common_GetJointPlanningData($startDate: Date!, $endDate: Date!) {
    budgetSystem
    budgetData(startMonth: $startDate, endMonth: $endDate) {
      ...BudgetDataFields
    }
    categoryGroups {
      ...BudgetCategoryGroupFields
    }
    goalsV2 {
      ...BudgetDataGoalsV2Fields
    }
  }
`);
