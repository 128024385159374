import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Banner from 'components/lib/ui/Banner';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';

import useSpinwheel from 'common/lib/hooks/recurring/useSpinwheel';

import type { Common_GetSpinwheelCreditReportQuery } from 'common/generated/graphql';
import { OnboardingStatus } from 'common/generated/graphql';

const Root = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  text-align: center;
  max-width: 600px;
  width: 100%;
`;

const Title = styled(Text).attrs({ size: 'xlarge', weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.default};
  max-width: 475px;
`;

const StyledButton = styled(AsyncButton)`
  ${primaryButtonMixin};
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
`;

type Props = {
  onClickNext: (
    creditReport:
      | Common_GetSpinwheelCreditReportQuery['creditReportLiabilityAccounts']
      | null
      | undefined,
  ) => void;
};

const TIMEOUT_ERROR_MESSAGE =
  "We're having trouble syncing your credit report. Please try again later.";

// for now this is using the mock data, but this will be replaced with the actual data in the future
const MapSpinwheelLiabilitiesStart = ({ onClickNext }: Props) => {
  const {
    syncCreditReport,
    isSyncingCreditReport,
    spinwheelUser,
    creditReportLiabilityAccounts,
    isCreditReportSyncTimedOut,
  } = useSpinwheel();
  const [hasTimedOut, setHasTimedOut] = useState(false);

  useEffect(() => {
    // User is active and credit report has liabilities
    const hasSyncedCreditReport =
      creditReportLiabilityAccounts &&
      creditReportLiabilityAccounts.length > 0 &&
      spinwheelUser?.onboardingStatus === OnboardingStatus.ACTIVE;

    if (hasSyncedCreditReport) {
      onClickNext(creditReportLiabilityAccounts);
    } else if (
      isCreditReportSyncTimedOut &&
      spinwheelUser?.onboardingStatus !== OnboardingStatus.ACTIVE
    ) {
      // We didn't receive Spinwheel webhook or the user hasn't finished onboarding
      setHasTimedOut(true);
    }
  }, [creditReportLiabilityAccounts, spinwheelUser, isCreditReportSyncTimedOut]);

  return (
    <>
      <Root>
        {hasTimedOut && <Banner type="error">{TIMEOUT_ERROR_MESSAGE}</Banner>}
        <Title>Connect to Spinwheel to track statement balances and due dates</Title>
        <Text>
          You will be able to view debt accounts in your name, and choose which ones you&apos;d like
          to view and track within Monarch.
        </Text>

        <StyledButton size="large" pending={isSyncingCreditReport} onClick={syncCreditReport}>
          Sync with Spinwheel
        </StyledButton>
      </Root>
    </>
  );
};

export default MapSpinwheelLiabilitiesStart;
