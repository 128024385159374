import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

const OPTION_RADIO_CHECKBOX_SIZE_PX = 20;

const Root = styled.div`
  width: 100%;
`;

export const OptionRow = styled(FlexContainer)<{ isSelected?: boolean }>`
  --default-radius: ${({ theme }) => theme.radius.medium};

  width: 100%;
  padding: ${({ theme }) => theme.spacing.default};
  transition: ${({ theme }) => theme.transition.default};
  border: ${({ theme }) => `1px solid ${theme.color.grayFocus}`};
  border-top-width: 0px;
  cursor: pointer;
  user-select: none;
  gap: ${({ theme }) => theme.spacing.small};

  :first-child {
    border-radius: var(--default-radius) var(--default-radius) 0px 0px;
    border-top-width: 1px;
  }

  :last-child {
    border-radius: 0px 0px var(--default-radius) var(--default-radius);
  }
`;

const OptionRowLabel = styled(Text)`
  display: block;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const SelectIndicator = styled(FlexContainer).attrs({ center: true })<{ isSelected: boolean }>`
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray};
  width: ${OPTION_RADIO_CHECKBOX_SIZE_PX}px;
  height: ${OPTION_RADIO_CHECKBOX_SIZE_PX}px;
  transition: ${({ theme }) => theme.transition.default};
  flex-shrink: 0;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: ${({ theme }) => theme.color.orange};
      border-color: ${({ theme }) => theme.color.orange};
    `}
`;

const Checkbox = styled(SelectIndicator)`
  border-radius: ${({ theme }) => theme.radius.small};
`;

const CircleIndicator = styled(SelectIndicator)`
  border-radius: ${({ theme }) => theme.radius.round};
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: ${({ theme }) => theme.radius.round};
  background: ${({ theme }) => theme.color.white};
`;

const CheckIcon = styled(Icon).attrs({ name: 'check' })`
  width: 14px;
  height: 14px;
  color: ${({ theme }) => theme.color.textWhite};
`;

const OptionRowContent = styled(FlexContainer).attrs({
  justifyStart: true,
  alignStart: true,
  column: true,
})`
  transform: translateY(-2px);
`;

const RightAccessory = styled(FlexContainer).attrs({
  justifyEnd: true,
  alignStart: true,
})`
  flex-shrink: 0;
`;

const OptionRowContainer = ({
  isSelected,
  allowMultiple,
  label,
  description,
  onClick,
  rightAccessory,
}: {
  isSelected: boolean;
  allowMultiple: boolean;
  label: string;
  description?: string;
  onClick: () => void;
  rightAccessory?: React.ReactNode;
}) => (
  <OptionRow isSelected={isSelected} onClick={onClick}>
    {allowMultiple ? (
      <Checkbox isSelected={isSelected}>{isSelected && <CheckIcon />}</Checkbox>
    ) : (
      <CircleIndicator isSelected={isSelected}>{isSelected && <Dot />}</CircleIndicator>
    )}

    <OptionRowContent>
      <OptionRowLabel>{label}</OptionRowLabel>
      {description && <Text size="small">{description}</Text>}
    </OptionRowContent>
    {rightAccessory && <RightAccessory>{rightAccessory}</RightAccessory>}
  </OptionRow>
);

export type OptionType<ValueT> = {
  value: ValueT;
  label: string;
  description?: string;
  rightAccessory?: React.ReactNode;
};

export type Props<ValueT> = {
  options: OptionType<ValueT>[];
  value?: ValueT | ValueT[];
  onChange?: (value: ValueT | ValueT[]) => void;
  allowMultiple?: boolean;
  isNoneSelectedInitial?: boolean;
};

const RadioGroup = <ValueT = unknown,>({
  options,
  value,
  onChange,
  allowMultiple = false,
}: Props<ValueT>) => {
  const isOptionSelected = (optionValue: ValueT) =>
    value && RA.isArray(value)
      ? value?.some((value) => value === optionValue)
      : value === optionValue;

  const toggleOption = (optionValue: ValueT) =>
    onChange?.(
      value && RA.isArray(value)
        ? R.symmetricDifferenceWith((a, b) => a === b, value ?? [], [optionValue])
        : optionValue,
    );

  return (
    <Root>
      {options.map(({ value: optionValue, label, description, rightAccessory }, index) => (
        <OptionRowContainer
          key={index}
          isSelected={isOptionSelected(optionValue)}
          allowMultiple={allowMultiple}
          onClick={() => toggleOption(optionValue)}
          label={label}
          description={description}
          rightAccessory={rightAccessory}
        />
      ))}
    </Root>
  );
};

export default RadioGroup;
