import numbro from 'numbro';
import { path } from 'ramda';
import { isArray, isValidNumber } from 'ramda-adjunct';

import { CENTS_PER_DOLLAR } from 'common/constants/currency';

const replaceNanWithZero = (value: number) => (isNaN(value) ? 0 : value);

export const replaceNanWithUndefined = (value: number | undefined) =>
  isNaN(value ?? 0) ? undefined : value;

export const formatPercent = (num: number) =>
  numbro(replaceNanWithZero(num)).format({
    output: 'percent',
    mantissa: 1,
    optionalMantissa: true,
  });

export const formatPercentNoDecimals = (num: number) =>
  numbro(replaceNanWithZero(num)).format({ output: 'percent', mantissa: 0 });

export const formatPercentTwoDecimals = (num: number, options?: { showPlus?: boolean }) =>
  numbro(replaceNanWithZero(num)).format({
    output: 'percent',
    mantissa: 2,
    optionalMantissa: true,
    trimMantissa: true,
    forceSign: options?.showPlus ?? false,
  });

export const formatThousands = (num: number) =>
  numbro(replaceNanWithZero(num)).format({
    thousandSeparated: true,
    mantissa: 2,
    optionalMantissa: true,
    trimMantissa: true,
  });

export const formatTwelveDecimalPlaces = (num: number) =>
  numbro(num).format({ mantissa: 12, trimMantissa: true, thousandSeparated: true });

export const formatPercentThousands = (percent: number) =>
  `${numbro(replaceNanWithZero(percent)).format({ mantissa: 2, thousandSeparated: true })}%`;

export const roundUpToNearestCent = (amount: number) =>
  Math.ceil(amount * CENTS_PER_DOLLAR) / CENTS_PER_DOLLAR;

export const roundToNearestCent = (amount: number) =>
  Math.round(amount * CENTS_PER_DOLLAR) / CENTS_PER_DOLLAR;

export const isIntLessThan = (a: number, b: number) => Math.round(a) < Math.round(b);

export const isIntGreaterThan = (a: number, b: number) => Math.round(a) > Math.round(b);

export const parseThousands = (amountString: string) => numbro.unformat(amountString);

/**
 * Parse number from string. Handles decimal, currency, percent, etc.
 * Returns undefined for invalid numbers.
 * https://numbrojs.com/format.html#unformat
 */
export const parseNumber = (amountString: string): number | undefined =>
  numbro.unformat(amountString);

/**
 * Enhances an object with a `percent` property.
 *
 * The value of the `percent` property is calculated as the ratio of the specified
 * key's value in the object to the total provided. If the key's value is undefined
 * or falsy, the `percent` property will be undefined.
 */
export const withPercent = <T extends Record<string, any>>({
  item,
  key,
  total,
}: {
  item: T;
  key: keyof T | string[];
  total: number;
}): T & { percent: number | undefined } => {
  const value = isArray(key) ? path(key, item) : item[key];

  return {
    ...item,
    percent: isValidNumber(value) ? Number(value) / total : undefined,
  };
};
