import { useMutation, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import DeleteCategoryGroupConfirmation from 'components/budget/DeleteCategoryGroupConfirmation';
import DisableRolloverConfirmation from 'components/categories/DisableRolloverConfirmation';
import GroupRolloverConfirmation from 'components/categories/GroupRolloverConfirmation';
import CurrencyField from 'components/lib/form/CurrencyField';
import FieldCell from 'components/lib/form/FieldCell';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import RadioGroupField from 'components/lib/form/RadioGroupField';
import SelectField from 'components/lib/form/SelectField';
import TextField from 'components/lib/form/TextField';
import ToggleField from 'components/lib/form/ToggleField';
import FeatureFlagGate from 'components/lib/higherOrder/FeatureFlagGate';
import CardFooter from 'components/lib/ui/CardFooter';
import Link from 'components/lib/ui/Link';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Modal from 'components/lib/ui/Modal';
import ModalCard from 'components/lib/ui/ModalCard';
import DangerButton from 'components/lib/ui/button/DangerButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { getRolloverStartMonthOptions } from 'common/lib/budget/Rollovers';
import { DEFAULT_BUDGET_VARIABILITY_IF_UNSET } from 'common/lib/budget/constants';
import { CATEGORY_GROUP_BUDGET_TYPE_OPTIONS } from 'common/lib/categories/constants';
import { UPDATE_CATEGORY_GROUP, DELETE_CATEGORY_GROUP } from 'common/lib/graphQl/categoryGroups';
import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';
import noop from 'common/utils/noop';
import logger from 'lib/logger';
import { errorToast } from 'lib/ui/toast';

import { BudgetRolloverPeriodType, ROLLOVER_ENABLED_FIELD_NAME } from 'common/constants/budget';
import { CACHE_KEYS } from 'common/constants/cache';
import { GROUP_BUDGET, BUDGET_VARIABILITY, BUDGET } from 'common/constants/copy';
import { HELP_CENTER_ROLLOVER_ARTICLE_URL } from 'common/constants/externalUrls';

import { gql } from 'common/generated/gql';
import type { PayloadErrorFields } from 'common/generated/graphQlTypes/PayloadErrorFields';
import { BudgetSystem, CategoryGroupType, BudgetVariability } from 'common/generated/graphql';
import type { UpdateCategoryGroupInput } from 'common/generated/graphql';

type Props = {
  onDone: () => void;
  onDeleted?: () => void;
  categoryGroupId: GraphQlUUID;
};

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: 0px auto;
`;

const StyledFieldCell = styled(FieldCell)`
  margin-top: ${({ theme }) => theme.spacing.large};
`;

const LOADING_STATE = (
  <ModalCard title="Edit Group">
    <Root>
      <StyledLoadingSpinner />
    </Root>
  </ModalCard>
);

const MISSING_STATE = (
  <ModalCard title="Edit Group">
    <Root>Sorry, we can&apos;t find that group</Root>
  </ModalCard>
);

const ERROR_STATE = (
  <ModalCard title="Edit Group">
    <Root>Sorry, there was an error loading the group.</Root>
  </ModalCard>
);

type FormValues = Partial<UpdateCategoryGroupInput>;

const EditCategoryGroupModal = ({ onDone, categoryGroupId, onDeleted = noop }: Props) => {
  // isDeleting is set to prohibit the refetching of the category group when the ApolloClient store is reset
  // If we don't skip the GET_CATEGORY_GROUP query, between the store resetting and the modal being dismissed
  //  it will try to refetch the delete group and fail
  const [isDeleting, setIsDeleting] = useState(false);
  const { data, loading, error } = useQuery(QUERY, {
    variables: { id: categoryGroupId, includeDisabledSystemCategories: true },
    skip: isDeleting,
  });
  const { budgetSystem } = useHouseholdPreferences();

  const [updateCategoryGroup] = useMutation(UPDATE_CATEGORY_GROUP, {
    update: (cache) => {
      cache.evict({ fieldName: CACHE_KEYS.CATEGORIES });
      cache.evict({ fieldName: CACHE_KEYS.CATEGORY_GROUPS });
    },
  });
  const [deleteCategoryGroup] = useMutation(DELETE_CATEGORY_GROUP, {
    update: (cache) => {
      cache.evict({ fieldName: CACHE_KEYS.CATEGORIES });
      cache.evict({ fieldName: CACHE_KEYS.CATEGORY_GROUPS });
    },
  });

  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [showingRolloverConfirmation, setShowingRolloverConfirmation] = useState(false);

  const rolloverStartMonthOptions = useMemo(() => getRolloverStartMonthOptions(), []);

  if (error) {
    return ERROR_STATE;
  }

  if (!data || loading || isDeleting) {
    return LOADING_STATE;
  }

  const { categoryGroup } = data;
  if (!categoryGroup) {
    return MISSING_STATE;
  }

  const deleteGroup = async (moveToGroupId?: string) => {
    setIsDeleting(true);
    const { data } = await deleteCategoryGroup({
      variables: { id: categoryGroup.id, moveToGroupId },
    });
    if (data?.deleteCategoryGroup?.deleted) {
      onDeleted();
      onDone();
    } else {
      logger.error('Group did not delete.', data?.deleteCategoryGroup?.errors);
      setIsDeleting(false);
    }
  };

  const deleteOrShowConfirmation = () => {
    if (categoryGroup.categories.length === 0) {
      deleteGroup();
      return;
    }

    setConfirmingDelete(true);
  };

  const initialValues: FormValues = {
    id: categoryGroup.id,
    name: categoryGroup.name,
    groupLevelBudgetingEnabled: categoryGroup.groupLevelBudgetingEnabled,
    rolloverEnabled: RA.isNotNil(categoryGroup.rolloverPeriod),
    rolloverStartMonth:
      categoryGroup.rolloverPeriod?.startMonth ?? DateTime.local().startOf('month').toISODate(),
    rolloverType: BudgetRolloverPeriodType.Monthly, // This is the only one we support for now
    rolloverStartingBalance: categoryGroup.rolloverPeriod?.startingBalance ?? 0,
    budgetVariability: R.propOr(
      DEFAULT_BUDGET_VARIABILITY_IF_UNSET,
      'budgetVariability',
      categoryGroup,
    ),
  };

  const categoriesWithRolloverEnabled = data?.categoryGroup?.categories.filter(
    ({ rolloverPeriod }) => RA.isNotNil(rolloverPeriod),
  );

  const handleSubmit = async (values: FormValues) => {
    if (
      !categoryGroup.groupLevelBudgetingEnabled && // check if group level budgeting wasn't already enabled
      values.groupLevelBudgetingEnabled &&
      !!categoriesWithRolloverEnabled?.length
    ) {
      setShowingRolloverConfirmation(true);
      return;
    }
    performMutation(values);
  };

  const performMutation = async (values: FormValues) => {
    const { data } = await updateCategoryGroup({
      variables: {
        input: {
          id: values.id!,
          name: values.name,
          groupLevelBudgetingEnabled: values.groupLevelBudgetingEnabled,
          rolloverEnabled: values.rolloverEnabled,
          rolloverStartMonth: values.rolloverStartMonth,
          rolloverType: values.rolloverType,
          rolloverStartingBalance: values.rolloverStartingBalance,
          budgetVariability: values.budgetVariability,
          color: undefined,
          icon: undefined,
        },
      },
    });

    const errors: PayloadErrorFields | undefined = R.path(['updateCategoryGroup', 'errors'], data);
    if (errors) {
      errorToast(errors.message);
    } else {
      onDone?.();
    }
  };

  const shouldShowFixFlex =
    budgetSystem === BudgetSystem.FIXED_AND_FLEX &&
    categoryGroup.type === CategoryGroupType.EXPENSE;

  return (
    <ModalCard title="Edit Group">
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        <Root>
          <TextField name="name" required autoComplete="off" />

          <FormContext.Consumer>
            {({ values }) => (
              <>
                <SelectField
                  name="groupLevelBudgetingEnabled"
                  label="Budget"
                  options={CATEGORY_GROUP_BUDGET_TYPE_OPTIONS}
                  description={
                    values.groupLevelBudgetingEnabled
                      ? GROUP_BUDGET.BUDGET_BY_GROUP
                      : GROUP_BUDGET.BUDGET_BY_CATEGORY
                  }
                />

                <FeatureFlagGate name="fixed-flex-budgeting">
                  {values.groupLevelBudgetingEnabled && shouldShowFixFlex && (
                    <RadioGroupField
                      required
                      name="budgetVariability"
                      label="Type"
                      tooltip={BUDGET.BUDGET_VARIABILITY_TOOLTIP_CONTENT}
                      options={[
                        {
                          value: BudgetVariability.FIXED,
                          label: BUDGET_VARIABILITY.fixed.label,
                          description: BUDGET_VARIABILITY.fixed.description,
                        },
                        {
                          value: BudgetVariability.FLEXIBLE,
                          label: BUDGET_VARIABILITY.flex.label,
                          description: BUDGET_VARIABILITY.flex.description,
                        },
                        {
                          value: BudgetVariability.NON_MONTHLY,
                          label: BUDGET_VARIABILITY.nonMonthly.label,
                          description: BUDGET_VARIABILITY.nonMonthly.description,
                        },
                      ]}
                    />
                  )}
                </FeatureFlagGate>

                {categoryGroup.type === CategoryGroupType.EXPENSE &&
                  values.groupLevelBudgetingEnabled && (
                    <StyledFieldCell
                      rightAccessory={<ToggleField name={ROLLOVER_ENABLED_FIELD_NAME} hideLabel />}
                      title="Make this category group a monthly rollover"
                      subtitle={
                        <>
                          Every month the remaining balance will roll over to the next month.{' '}
                          <Link href={HELP_CENTER_ROLLOVER_ARTICLE_URL} target="_blank">
                            Learn more
                          </Link>
                        </>
                      }
                    >
                      <FormContext.Consumer>
                        {({ values }) =>
                          values.rolloverEnabled ? (
                            <>
                              <SelectField
                                name="rolloverStartMonth"
                                label="Starting Month"
                                options={rolloverStartMonthOptions}
                              />
                              <CurrencyField
                                name="rolloverStartingBalance"
                                label="Starting Balance"
                                placeholder="$0.00"
                                maskOptions={{
                                  prefix: '$',
                                  allowDecimal: true,
                                  decimalLimit: 2,
                                  decimalSymbol: '.',
                                }}
                              />
                            </>
                          ) : null
                        }
                      </FormContext.Consumer>
                    </StyledFieldCell>
                  )}
              </>
            )}
          </FormContext.Consumer>

          {confirmingDelete && (
            <Modal onClose={() => setConfirmingDelete(false)}>
              {({ close }) => (
                <DeleteCategoryGroupConfirmation
                  group={categoryGroup}
                  onDelete={deleteGroup}
                  onDone={close}
                />
              )}
            </Modal>
          )}

          {!!categoriesWithRolloverEnabled?.length && showingRolloverConfirmation && (
            <FormContext.Consumer>
              {({ values }) => (
                <Modal onClose={() => setShowingRolloverConfirmation(false)}>
                  {({ close }) => (
                    <GroupRolloverConfirmation
                      onConfirm={() => performMutation(values)}
                      onCancel={close}
                      rolloverCategories={categoriesWithRolloverEnabled}
                    />
                  )}
                </Modal>
              )}
            </FormContext.Consumer>
          )}
        </Root>
        <CardFooter>
          <DangerButton onClick={deleteOrShowConfirmation}>Delete</DangerButton>
          <DefaultButton onClick={onDone}>Cancel</DefaultButton>

          <DisableRolloverConfirmation
            rolloverEnabledInitially={initialValues?.rolloverEnabled ?? false}
          >
            {({ onSave }) => (
              <FormSubmitButton type="button" size="small" onClick={onSave}>
                Save
              </FormSubmitButton>
            )}
          </DisableRolloverConfirmation>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};

export const QUERY = gql(`
  query Web_GetCategoryGroupModal($id: UUID!, $includeDisabledSystemCategories: Boolean) {
    categoryGroup(id: $id) {
      ...CategoryGroupFields
      categories(includeDisabledSystemCategories: $includeDisabledSystemCategories) {
        id
        name
        icon
        rolloverPeriod {
          id
          startMonth
          startingBalance
        }
      }
    }
  }
`);

export default EditCategoryGroupModal;
