import React from 'react';
import styled from 'styled-components';

import ButterflyLogo from 'components/lib/ui/ButterflyLogo';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import IconButton from 'components/lib/ui/button/IconButton';
import NavLink from 'components/lib/ui/link/NavLink';
import NotificationCenterButton from 'components/notifications/NotificationCenterButton';

import routes from 'constants/routes';

const HeaderLink = styled(NavLink)`
  flex: 1;
`;

const SearchTooltipText = styled(Text).attrs({ weight: 'medium' })`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const SearchTooltipContent = styled.div`
  display: flex;
  align-items: center;
  padding: 6px ${({ theme }) => theme.spacing.xsmall};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.xxsmall};
  border-radius: ${({ theme }) => theme.radius.small};
  background: ${({ theme }) => theme.color.navy};
  margin-left: ${({ theme }) => theme.spacing.xxxsmall};
  min-width: 20px;
  height: 20px;
`;

type Props = {
  onOpenSearch?: () => Promise<void> | void;
  isMacOs?: boolean;
};

const SideBarDefaultHeader = ({ isMacOs = false, onOpenSearch }: Props) => (
  <>
    <HeaderLink to={routes.dashboard()}>
      <ButterflyLogo />
    </HeaderLink>
    <Tooltip
      portal
      content={
        <SearchTooltipContent>
          <SearchTooltipText>Search</SearchTooltipText>
          <IconWrapper>{isMacOs ? <Icon size={12} name="command" /> : <kbd>ctrl</kbd>}</IconWrapper>
          <IconWrapper>K</IconWrapper>
        </SearchTooltipContent>
      }
      place="bottom"
    >
      <IconButton icon="search" size="small" darkBg onClick={onOpenSearch} />
    </Tooltip>
    <NotificationCenterButton />
    <IconButton
      icon="settings"
      size="small"
      linkTo={routes.settings.profile()}
      // @ts-ignore - activeClassName is passed through to NavLink
      activeClassName="jon-was-here"
      darkBg
    />
  </>
);

export default SideBarDefaultHeader;
