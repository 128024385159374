import { rgba } from 'polished';
import styled from 'styled-components';

export default styled.div`
  color: ${({ theme }) => rgba(theme.color.textWhite, 0.7)};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.small};

  padding: ${({ theme }) => theme.spacing.small};
  transition: ${({ theme }) => theme.transition.default};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  border-radius: ${({ theme }) => theme.radius.small};

  :hover {
    color: ${({ theme }) => theme.color.textWhite};
    background-color: ${({ theme }) => theme.color.navyLight};
  }
  :active {
    background-color: ${({ theme }) => theme.color.navyDark};
  }
`;
