import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { cardDropShadowStyleMixin } from 'components/lib/ui/Card';
import Flex from 'components/lib/ui/Flex';
import IconButton from 'components/lib/ui/button/IconButton';

import { toggleSidebarExpanded } from 'actions';

export const HEADER_HEIGHT = '64px';

const Root = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing.default};
  padding: 0 ${({ theme }) => theme.spacing.gutter};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: 20px;
  min-height: ${HEADER_HEIGHT};
  background-color: ${({ theme }) => theme.color.white};
  ${cardDropShadowStyleMixin}

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    padding: 0 ${({ theme }) => theme.spacing.xsmall};
  }
`;

const StyledFlex = styled(Flex)`
  overflow: hidden;
`;

const ToggleSidebarButton = styled(IconButton)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  children?: React.ReactNode;
  controls?: React.ReactNode;
  showSidebarToggle?: boolean;
};

const Header = ({ controls, children, showSidebarToggle = true }: Props) => {
  const dispatch = useDispatch();

  return (
    <Root>
      <StyledFlex alignCenter>
        {showSidebarToggle && (
          <ToggleSidebarButton icon="menu" onClick={() => dispatch(toggleSidebarExpanded())} />
        )}
        {children ?? null}
      </StyledFlex>
      {controls ?? null}
    </Root>
  );
};

export default Header;
