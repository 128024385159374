import _ from 'lodash';
import type { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import {
  BUDGET_VARIABILITY_ORDER,
  BUDGET_VARIABILITY_TO_TITLE_MAPPING,
  DEFAULT_BUDGET_VARIABILITY_IF_UNSET,
} from 'common/lib/budget/constants';
import {
  categoryGroupTypeComparator,
  sortByOrder,
  sortCategoryGroups,
} from 'common/lib/categories/Adapters';
import { CATEGORY_GROUP_TYPE_TO_TITLE } from 'common/lib/categories/constants';
import { filterOutArchivedGoals, sortGoalsByPriority } from 'common/lib/goalsV2/adapters';

import type { Common_BudgetDataQuery, Maybe } from 'common/generated/graphql';
import { BudgetSystem, BudgetVariability, CategoryGroupType } from 'common/generated/graphql';
import type { ElementOf, MarkOptional } from 'common/types/utility';

type CategoryGroup = ElementOf<Common_BudgetDataQuery, 'categoryGroups'>;
type Category = ElementOf<CategoryGroup, 'categories'>;
type Amounts = ElementOf<Common_BudgetDataQuery['budgetData'], 'totalsByMonth'>['totalExpenses'];

export type DisplayBudget = {
  startDate: string | null;
  displayTypes: DisplayBudgetType[];
  hiddenGroupsByType: { [key: string]: DisplayGroup[] };
  goalsSections: GoalsGroup[];
};

export type DisplayBudgetType = {
  title: string | undefined;
  displayGroups: DisplayGroup[];
  type: CategoryGroupType;
};

export const ExcessBudgetType = 'Excess';

export type ActiveColumnType = 'actual' | 'remaining';

export type SummaryDisplayRow = {
  budgeted: Maybe<number>;
  actual: Maybe<number>;
  remaining: Maybe<number>;
  rollover?: Maybe<number>;
};

export type SummaryDisplayBudgetType = {
  title: string | undefined;
  budgeted: Maybe<number>;
  actual: Maybe<number>;
  rollover?: Maybe<number>;
  remaining?: Maybe<number>;
  type: string | undefined;
  budgetVariabilities?: ({
    title: string;
    type: BudgetVariability;
  } & SummaryDisplayRow)[];
  // Only needed for the Income and Expenses tabs
  categoryGroups?: Record<string, { name: string } & MarkOptional<SummaryDisplayRow, 'remaining'>>;
};

export type GoalsGroup = DisplayGroup & {
  data: DisplayCategory[];
};

export type DisplayGroup = {
  id: string;
  type: CategoryGroupType;
  budgetVariability: Maybe<BudgetVariability>;
  name: string;
  sectionTitle?: string;
  isSectionEditable?: boolean;
  budgetedAmounts: BudgetAmounts;
  budgetedCategories: DisplayCategory[];
  unbudgetedCategories: DisplayCategory[];
  unbudgetedAmounts?: BudgetAmounts;
  groupLevelBudgetingEnabled: Maybe<boolean>;
  rolloverPeriod?: CategoryGroup['rolloverPeriod'];
  isGoalsSection?: boolean;
};

export type DisplayCategory = {
  id: string;
  name: string;
  icon: string;
  order: number;
  groupId: string;
  groupType: CategoryGroupType;
  budgetVariability: Maybe<BudgetVariability>;
  excludeFromBudget: Maybe<boolean>;
  amounts: BudgetAmounts | undefined;
  groupLevelBudgetingEnabled: Maybe<boolean>;
  rolloverPeriod?: Category['rolloverPeriod'];
};

export type BudgetAmounts = {
  budgeted: Maybe<number>;
  actual: Maybe<number>;
  available: Maybe<number>;
  rollover?: Maybe<number>;
  rolloverType?: string;
};

type BudgetSummaryDetailsByType = {
  type: string;
  amounts: BudgetAmounts;
};

type BudgetSummaryDetails = {
  savings: BudgetAmounts;
  byType: BudgetSummaryDetailsByType[];
};

const EMPTY_BUDGET_AMOUNTS = { budgeted: null, actual: null, available: null };

const partitionUnbudgetedCategories = R.partition<DisplayCategory>(({ amounts }) => {
  const hasNoBudget = R.isNil(amounts?.budgeted) || amounts?.budgeted === 0;
  const hasRollover = RA.isNotNil(amounts?.rolloverType) || !!amounts?.rollover;
  return hasNoBudget && !hasRollover;
});

export const sumBudgetAmountFields = (amounts: BudgetAmounts[]): BudgetAmounts | undefined => {
  if (!amounts.length) {
    return undefined;
  }
  return amounts.reduce((acc, amounts) =>
    R.mergeWith(
      (a, b) => (_.isNumber(a) || a === null ? (a ?? 0) + (b ?? 0) : a ?? 0),
      acc,
      amounts,
    ),
  );
};

export const budgetSummaryAdapter = (details: BudgetSummaryDetails): SummaryDisplayBudgetType[] => {
  const { byType, savings } = details;
  const sortedTypes = R.sort(categoryGroupTypeComparator, byType)
    // exclude transfers
    .filter(({ type }) => type !== CategoryGroupType.TRANSFER)
    .map(({ type, amounts }) => ({
      title: type ? CATEGORY_GROUP_TYPE_TO_TITLE[type] : undefined,
      type,
      ...amounts,
    }));

  return [...sortedTypes, { ...savings, title: '', type: ExcessBudgetType }];
};

export const mapBudgetTotals = (amounts?: Amounts) => ({
  budgeted: amounts?.plannedAmount ?? null,
  actual: amounts?.actualAmount ?? null,
  remaining: amounts?.remainingAmount,
  rollover: amounts?.previousMonthRolloverAmount,
});

export const rolloversBudgetSummaryAdapter = (
  data: Common_BudgetDataQuery,
  date: DateTime,
): SummaryDisplayBudgetType[] => {
  const { totalsByMonth } = data.budgetData;
  const totals = totalsByMonth.find(R.propEq('month', date.toISODate()));

  const goalAggregateAmounts = getGoalV2AggregateAmounts(data, date);
  const includeGoals = !!goalAggregateAmounts?.budgeted;

  return [
    {
      title: 'Income',
      type: CategoryGroupType.INCOME,
      ...mapBudgetTotals(totals?.totalIncome),
    },
    {
      title: 'Expenses',
      type: CategoryGroupType.EXPENSE,
      ...mapBudgetTotals(totals?.totalExpenses),
      budgetVariabilities:
        data?.budgetSystem === BudgetSystem.FIXED_AND_FLEX
          ? [
              {
                title: BUDGET_VARIABILITY_TO_TITLE_MAPPING[BudgetVariability.FIXED],
                type: BudgetVariability.FIXED,
                ...mapBudgetTotals(totals?.totalFixedExpenses),
              },
              {
                title: BUDGET_VARIABILITY_TO_TITLE_MAPPING[BudgetVariability.FLEXIBLE],
                type: BudgetVariability.FLEXIBLE,
                ...mapBudgetTotals(totals?.totalFlexibleExpenses),
              },
              {
                title: BUDGET_VARIABILITY_TO_TITLE_MAPPING[BudgetVariability.NON_MONTHLY],
                type: BudgetVariability.NON_MONTHLY,
                ...mapBudgetTotals(totals?.totalNonMonthlyExpenses),
              },
            ]
          : undefined,
    },
    ...(includeGoals
      ? [
          {
            title: 'Goals',
            ...(goalAggregateAmounts ?? EMPTY_BUDGET_AMOUNTS),
            type: undefined,
          },
        ]
      : []),
    {
      title: '',
      type: ExcessBudgetType,
      // Excess amount is: planned income - planned expenses - planned goals
      budgeted:
        (totals?.totalIncome?.plannedAmount ?? 0) -
        (totals?.totalExpenses?.plannedAmount ?? 0) -
        (goalAggregateAmounts?.budgeted ?? 0),
      actual: null, // this is only needed for TS, it's not actually used
    },
  ];
};

const getGoalV2AggregateAmounts = (data: Common_BudgetDataQuery, date: DateTime) => {
  const { goalsV2 = [] } = data;

  return R.reduce(
    (acc, goal) => {
      const plannedContribution = goal.plannedContributions.find(
        ({ month }) => month === date.toISODate(),
      );

      const contributionSummary = goal.monthlyContributionSummaries.find(
        ({ month }) => month === date.toISODate(),
      );

      return {
        budgeted: acc.budgeted + (plannedContribution?.amount ?? 0),
        actual: acc.actual + (contributionSummary?.sum ?? 0),
      };
    },
    { budgeted: 0, actual: 0 },
    filterOutArchivedGoals(goalsV2),
  );
};

const filterHiddenGroups =
  (hidden: boolean) =>
  ({ budgetedCategories, budgetedAmounts: { actual, budgeted } }: DisplayGroup) => {
    const shouldHide =
      budgetedCategories.length === 0 && (actual ?? 0) === 0 && (budgeted ?? 0) === 0;
    return shouldHide ? hidden : !hidden;
  };

const partitionDisplayGroupsByType = R.groupBy<DisplayGroup>(R.prop('type'));

/**
 * Get the budget variability for a category, taking into account group level budgeting
 */
const getBudgetVariabilityForCategory = ({ budgetVariability, group }: Category) => {
  const variability = group.groupLevelBudgetingEnabled
    ? group.budgetVariability
    : budgetVariability;
  return variability ?? DEFAULT_BUDGET_VARIABILITY_IF_UNSET;
};

export const rolloverDisplayBudgetAdapter = (
  data: Common_BudgetDataQuery,
  date: DateTime,
  isFlexBudgetingEnabled = false,
): DisplayBudget => {
  const { budgetData, categoryGroups } = data;
  const { monthlyAmountsByCategory, monthlyAmountsByCategoryGroup, monthlyAmountsForFlexExpense } =
    budgetData;

  const sortedCategoryGroups = sortCategoryGroups(categoryGroups).filter(
    ({ type }) => type !== CategoryGroupType.TRANSFER,
  );

  const groupedByType = R.groupBy(R.prop('type'), sortedCategoryGroups);

  const getAmountsForCategory = (categoryId: string): BudgetAmounts => {
    const { monthlyAmounts } =
      monthlyAmountsByCategory.find(({ category: { id } }) => id === categoryId) ?? {};
    const amounts = monthlyAmounts?.find(({ month }) => month === date.toISODate());

    return {
      budgeted: amounts?.plannedCashFlowAmount ?? null,
      available: amounts?.remainingAmount ?? null,
      actual: amounts?.actualAmount ?? null,
      rolloverType: amounts?.rolloverType ?? undefined,
    };
  };

  const toDisplayCategory = (category: Category): DisplayCategory => ({
    id: category.id,
    name: category.name,
    icon: category.icon,
    order: category.order,
    budgetVariability: getBudgetVariabilityForCategory(category),
    excludeFromBudget: category.excludeFromBudget,
    amounts: getAmountsForCategory(category.id),
    rolloverPeriod: category.rolloverPeriod,
    groupId: category.group.id,
    groupType: category.group.type,
    groupLevelBudgetingEnabled: category.group.groupLevelBudgetingEnabled,
  });

  const getDisplayCategories = (group: CategoryGroup, isFlexBudgeting?: boolean) =>
    sortByOrder(group.categories).map((category) => ({
      ...toDisplayCategory(category),
      // This is needed for the sections to collapse properly when using flex budgeting
      ...(isFlexBudgeting ? { groupId: getBudgetVariabilityForCategory(category) } : {}),
    }));

  const getDisplayGroup = (
    group: CategoryGroup,
    displayCategories: DisplayCategory[],
  ): DisplayGroup => {
    const [unbudgetedCategories, budgetedCategories] =
      partitionUnbudgetedCategories(displayCategories);
    const unbudgetedAmounts = sumBudgetAmountFields(
      unbudgetedCategories.flatMap(({ amounts }) => (amounts ? [amounts] : [])),
    );

    const isFlexibleGroup = group.budgetVariability === BudgetVariability.FLEXIBLE;
    const isMatchingCategoryGroup = R.pathEq(['categoryGroup', 'id'], group.id);

    const groupAmounts = isFlexibleGroup
      ? monthlyAmountsForFlexExpense.monthlyAmounts
      : monthlyAmountsByCategoryGroup.find(isMatchingCategoryGroup)?.monthlyAmounts;

    const groupAmount = groupAmounts?.find(R.propEq('month', date.toISODate()));
    const useMonthlyAmount = groupAmount && (group.groupLevelBudgetingEnabled || isFlexibleGroup);

    const budgetedAmounts = useMonthlyAmount
      ? {
          // If group level budgeting is enabled or is the flex group, use the group level amounts
          budgeted: groupAmount?.plannedCashFlowAmount ?? null,
          available: groupAmount?.remainingAmount ?? null,
          actual: groupAmount?.actualAmount ?? null,
        }
      : sumBudgetAmountFields(
          // Otherwise, sum the category amounts
          displayCategories.flatMap(({ amounts }) => (amounts ? [amounts] : [])),
        );

    return {
      id: group.id,
      type: group.type,
      budgetVariability: group.budgetVariability,
      name: group.name,
      groupLevelBudgetingEnabled: group.groupLevelBudgetingEnabled,
      rolloverPeriod: group.rolloverPeriod,
      budgetedCategories,
      budgetedAmounts: budgetedAmounts ?? EMPTY_BUDGET_AMOUNTS,
      unbudgetedCategories,
      unbudgetedAmounts,
    };
  };

  const toDisplayGroup = (group: CategoryGroup, isFlexBudgeting?: boolean): DisplayGroup => ({
    ...getDisplayGroup(group, getDisplayCategories(group, isFlexBudgeting)),
    isSectionEditable: !isFlexBudgeting,
  });

  /**
   * Only used for Flex Budgeting
   * It creates a category group for all the categories with the same variability
   */
  const getFlexDisplayGroup = (
    categoriesByVariability: Record<string, Category[]>,
    budgetVariability: BudgetVariability,
  ) => {
    const categories = categoriesByVariability[budgetVariability] ?? [];

    if (RA.isNilOrEmpty(categories)) {
      return {
        id: budgetVariability,
        type: CategoryGroupType.EXPENSE,
        budgetVariability,
        name: BUDGET_VARIABILITY_TO_TITLE_MAPPING[budgetVariability],
        groupLevelBudgetingEnabled: undefined,
        rolloverPeriod: undefined,
        budgetedCategories: [],
        budgetedAmounts: EMPTY_BUDGET_AMOUNTS,
        unbudgetedCategories: [],
        unbudgetedAmounts: undefined,
      } as DisplayGroup;
    }

    const categoryGroup: CategoryGroup = {
      __typename: 'CategoryGroup',
      id: budgetVariability,
      name: BUDGET_VARIABILITY_TO_TITLE_MAPPING[budgetVariability],
      order: BUDGET_VARIABILITY_ORDER[budgetVariability],
      type: CategoryGroupType.EXPENSE,
      budgetVariability,
      groupLevelBudgetingEnabled: undefined,
      rolloverPeriod: undefined,
      categories,
    };

    return toDisplayGroup(categoryGroup, true);
  };

  const getDisplayGroups = (groups: CategoryGroup[], type: string): DisplayGroup[] => {
    let displayGroups: DisplayGroup[];

    if (isFlexBudgetingEnabled && type === CategoryGroupType.EXPENSE) {
      // Group categories by variability taking into account group level budgeting
      const categoriesByVariability = R.groupBy(
        getBudgetVariabilityForCategory,
        groups.flatMap(R.prop('categories')),
      );

      displayGroups = [
        getFlexDisplayGroup(categoriesByVariability, BudgetVariability.FIXED),
        getFlexDisplayGroup(categoriesByVariability, BudgetVariability.FLEXIBLE),
        getFlexDisplayGroup(categoriesByVariability, BudgetVariability.NON_MONTHLY),
      ];
    } else {
      displayGroups = groups.map((group) => toDisplayGroup(group, false));
    }

    return displayGroups.filter(filterHiddenGroups(false));
  };

  const getHiddenGroups = (groups: CategoryGroup[]) => {
    if (isFlexBudgetingEnabled) {
      return [];
    }

    return groups.map((group) => toDisplayGroup(group, false)).filter(filterHiddenGroups(true));
  };

  const displayTypes: DisplayBudgetType[] = R.toPairs(groupedByType).map(([type, groups]) => ({
    title: CATEGORY_GROUP_TYPE_TO_TITLE[type],
    type: type as CategoryGroupType,
    displayGroups: getDisplayGroups(groups, type),
  }));

  const hiddenGroupsByType = partitionDisplayGroupsByType(getHiddenGroups(sortedCategoryGroups));

  return {
    startDate: date.toISODate(),
    displayTypes,
    hiddenGroupsByType,
    goalsSections: generateGoalsV2Section(data, date),
  };
};

export const getCategoriesBudgetedAmount = (categories: DisplayCategory[]) =>
  categories.reduce((total, category) => total + (category.amounts?.budgeted ?? 0), 0);

const generateGoalsV2Section = (data: Common_BudgetDataQuery, date: DateTime): GoalsGroup[] => {
  const { goalsV2 = [] } = data;

  const groupId = 'goals-group';

  let goals = filterOutArchivedGoals(goalsV2);
  goals = sortGoalsByPriority(goals);

  const displayGoals: DisplayCategory[] = goals.map((goal) => {
    const { id, name } = goal ?? {};

    const plannedContribution = goal.plannedContributions.find(
      ({ month }) => month === date.toISODate(),
    );

    const contributionSummary = goal.monthlyContributionSummaries.find(
      ({ month }) => month === date.toISODate(),
    );

    const budgeted = plannedContribution?.amount ?? 0;
    const actual = contributionSummary?.sum ?? 0;

    return {
      isGoal: true,
      goal,
      id,
      icon: '',
      name,
      groupId,
      order: 0,
      groupType: CategoryGroupType.EXPENSE,
      groupLevelBudgetingEnabled: undefined,
      rolloverPeriod: undefined,
      budgetVariability: undefined,
      excludeFromBudget: undefined,
      amounts: {
        budgeted,
        actual,
        available: budgeted - actual,
      },
    };
  });

  const aggregateAmounts = sumBudgetAmountFields(
    displayGoals.map(R.prop('amounts')).filter(RA.isNotNil),
  );

  return [
    {
      id: groupId,
      type: CategoryGroupType.EXPENSE,
      budgetVariability: undefined,
      name: 'Goals',
      groupLevelBudgetingEnabled: undefined,
      rolloverPeriod: undefined,
      budgetedCategories: [],
      budgetedAmounts: aggregateAmounts ?? EMPTY_BUDGET_AMOUNTS,
      unbudgetedCategories: [],
      unbudgetedAmounts: undefined,
      data: displayGoals,
      isGoalsSection: true,
      sectionTitle: 'Contributions',
    },
  ];
};

export const getExcessAmount = (data: SummaryDisplayBudgetType[] | undefined): number | null => {
  const excess = data?.find(({ type }) => type === ExcessBudgetType);
  const amount = excess && RA.isNotNil(excess?.budgeted) ? excess?.budgeted : null;
  return amount ? Math.round(amount) : null;
};

export const getSafeToSpendAmount = (
  data: SummaryDisplayBudgetType[] | undefined,
): number | null => {
  const expenses = data?.find(({ type }) => type === CategoryGroupType.EXPENSE);
  const flex = expenses?.budgetVariabilities?.find(({ title }) => title === 'Flex');
  const amount = flex && RA.isNotNil(flex?.remaining) ? flex?.remaining : null;
  return amount ? Math.round(amount) : null;
};

export const getFlexActual = (data: SummaryDisplayBudgetType[] | undefined): number | null => {
  const expenses = data?.find(({ type }) => type === CategoryGroupType.EXPENSE);
  const amounts = expenses?.budgetVariabilities?.find(
    ({ type }) => type === BudgetVariability.FLEXIBLE,
  );
  const amount = amounts && RA.isNotNil(amounts?.actual) ? amounts?.actual : null;
  return amount ? Math.round(amount) : null;
};

export const getFixedExpected = (data: SummaryDisplayBudgetType[] | undefined): number | null => {
  const expenses = data?.find(({ type }) => type === CategoryGroupType.EXPENSE);
  const amounts = expenses?.budgetVariabilities?.find(
    ({ type }) => type === BudgetVariability.FIXED,
  );

  if (!amounts) {
    return null;
  }
  if (!RA.isNotNil(amounts.remaining)) {
    return null;
  }

  return Math.round(amounts.remaining);
};

export const getNonMonthlyExpected = (
  data: SummaryDisplayBudgetType[] | undefined,
): number | null => {
  const expenses = data?.find(({ type }) => type === CategoryGroupType.EXPENSE);
  const amounts = expenses?.budgetVariabilities?.find(
    ({ type }) => type === BudgetVariability.NON_MONTHLY,
  );

  if (!amounts) {
    return null;
  }
  if (!RA.isNotNil(amounts.remaining)) {
    return null;
  }

  return Math.round(amounts.remaining);
};

export const getExpectedExpense = (data: SummaryDisplayBudgetType[] | undefined): number => {
  const fixedExpected = getFixedExpected(data) ?? 0;
  const nonMonthlyExpected = getNonMonthlyExpected(data) ?? 0;

  return fixedExpected + nonMonthlyExpected;
};
