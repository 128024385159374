import React from 'react';
import styled from 'styled-components';

import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import AnimatedNumber from 'components/lib/ui/AnimatedNumber';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import type { AmountsTooltipRowProps } from 'components/plan/AmountsTooltip';
import AmountsTooltip, { WIDTH_PX as TOOLTIP_WIDTH_PX } from 'components/plan/AmountsTooltip';

import type { SummaryDisplayBudgetType } from 'common/lib/budget/Adapters';
import { ExcessBudgetType } from 'common/lib/budget/Adapters';
import { fontWeight, radius, spacing } from 'common/lib/theme/dynamic';
import { formatCurrencyNoCents } from 'common/utils/Currency';

import type { WithTheme } from 'types/utility';

const TOOLTIP_SHOW_DELAY_MS = 500;

type RootProps = {
  $isNegative: boolean;
  $isZero: boolean;
};

const getTextColor = ({ $isNegative, $isZero, theme }: WithTheme<RootProps>) => {
  if ($isNegative) {
    return theme.color.redText;
  }
  if ($isZero) {
    return theme.color.textLight;
  }
  return theme.color.greenText;
};

const getBackgroundColor = ({ $isNegative, $isZero, theme }: WithTheme<RootProps>) => {
  if ($isNegative) {
    return theme.color.redBackground;
  }
  if ($isZero) {
    return theme.color.grayBackground;
  }
  return theme.color.greenBackground;
};

const Root = styled(FlexContainer).attrs({ column: true })<RootProps>`
  background-color: ${getBackgroundColor};
  color: ${getTextColor};
  border-radius: ${radius.medium};
  padding: ${spacing.gutter};
  text-align: center;
  font-weight: ${fontWeight.medium};
  gap: ${spacing.xxxsmall};
`;

const Amount = styled(Text).attrs({ size: 'xxlarge' })``;

const InfoIcon = styled(Icon).attrs({ name: 'info', size: 14 })`
  display: inline-block;
  margin-left: ${spacing.xxsmall};
  cursor: default;
`;

type Props = {
  data: SummaryDisplayBudgetType[];
};

const BudgetSummaryWidgetHeader = ({ data }: Props) => {
  const excessGroup = data.find((group) => group.type === ExcessBudgetType);
  const amount = excessGroup?.budgeted;

  return (
    <Tooltip
      content={<AmountsTooltip title="Budgeted" rows={getRowsForAmountsTooltip(data)} />}
      maxWidth={TOOLTIP_WIDTH_PX}
      delayShow={TOOLTIP_SHOW_DELAY_MS}
      place="left"
      portal
    >
      <Root $isNegative={(amount || 0) < 0} $isZero={amount === 0}>
        <Amount>
          <span {...maskClassProps}>
            <AnimatedNumber value={amount || 0} formattingFn={formatCurrencyNoCents} />
          </span>
        </Amount>
        <Text>
          Left to budget <InfoIcon />
        </Text>
      </Root>
    </Tooltip>
  );
};

const getRowsForAmountsTooltip = (data: SummaryDisplayBudgetType[]): AmountsTooltipRowProps[] =>
  data.map(({ title, budgeted, type }) => {
    if (type === ExcessBudgetType) {
      return {
        title: 'Left to budget',
        value: budgeted || 0,
        type: 'income',
        emphasis: true,
      };
    }

    return { title: title || '', value: budgeted || 0 };
  });

export default BudgetSummaryWidgetHeader;
