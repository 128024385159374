import React from 'react';
import styled from 'styled-components';

import { transition } from 'common/lib/theme/dynamic';

const Root = styled.div<{ $collapsed: boolean }>`
  display: flex;
  transition: ${transition.fast};
  gap: ${({ theme, $collapsed }) => ($collapsed ? 0 : theme.spacing.gutter)};
  padding: ${({ theme }) => theme.spacing.gutter};
`;

const PrimaryColumn = styled.div<{ $collapsed: boolean }>`
  flex-grow: 1;
  min-width: ${({ theme }) => theme.breakPoints.sm}px;
`;

const CollapsibleColumn = styled.div<{ $collapsed: boolean; $width: number }>`
  height: 100%;
  overflow: ${({ $collapsed }) => ($collapsed ? 'hidden' : 'visible')};
  opacity: ${({ $collapsed }) => ($collapsed ? 0 : 1)};
  position: sticky;
  top: 0;
  transition: ${transition.fast};
  width: ${({ $collapsed, $width }) => ($collapsed ? 0 : $width)}px;
`;

const CollapsibleColumnContent = styled.div<{ $width: number }>`
  width: ${({ $width }) => $width}px;
`;

type Props = React.PropsWithChildren<{
  collapsibleColumnWidth: number;
  isCollapsed?: boolean;
  className?: string;
}>;

/**
 * `CollapsibleLayout` creates a two-column layout. The first child is rendered as the primary column with
 * a flexible width, whereas the second child is rendered in a collapsible column with a fixed width.
 *
 * If `isCollapsed` is true, the collapsible column is hidden by setting its width to zero while preserving
 * the space for the primary column. The width of the collapsible column and the collapsed state can be controlled
 * via `collapsibleColumnWidth` and `isCollapsed` respectively.
 *
 * This component throws an error if not exactly two children are provided. It adopts a fail-fast approach to
 * ensure the correct number of child components.
 *
 * The CSS properties that control the look and feel are dynamically adjusted based on the `isCollapsed` prop to provide
 * smooth transition effects and appropriate spacing.
 */
const CollapsibleLayout: React.FC<Props> = ({
  children,
  className,
  collapsibleColumnWidth,
  isCollapsed = false,
}) => {
  if (React.Children.count(children) !== 2) {
    throw new Error('CollapsibleLayout must have exactly 2 children');
  }

  return (
    <Root className={className} $collapsed={isCollapsed}>
      {React.Children.map(children, (child, index) =>
        index === 0 ? (
          <PrimaryColumn $collapsed={isCollapsed}>{child}</PrimaryColumn>
        ) : (
          <CollapsibleColumn $collapsed={isCollapsed} $width={collapsibleColumnWidth}>
            <CollapsibleColumnContent $width={collapsibleColumnWidth}>
              {child}
            </CollapsibleColumnContent>
          </CollapsibleColumn>
        ),
      )}
    </Root>
  );
};

export default CollapsibleLayout;
