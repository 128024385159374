import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import PremiumUpgradeFormTimelineFlow from 'components/premium/PremiumUpgradeFormTimelineFlow';

import { hasSponsoredPlanAvailable } from 'common/lib/billing/Billing';
import usePremiumStripeOfferings from 'common/lib/hooks/premium/usePremiumStripeOfferings';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useTrack from 'lib/hooks/useTrack';
import { getTemporaryPromoCode } from 'state/onboarding/selectors';

import { PremiumEventNames } from 'common/constants/analytics';
import * as COPY from 'common/constants/copy';

const StyledOnboardingTitle = styled(OnboardingTitle)`
  margin-top: 34px;
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
  white-space: pre;
`;

const StyledOnboardingFormContainer = styled(OnboardingFormContainer)`
  padding-left: 0;
  padding-right: 0;
`;

const OnboardingSubtitleContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.large};
  text-align: center;
`;

type Props = {
  next: () => void;
};

const WELCOME30_PROMO_CODE = 'WELCOME30';

const OnboardingPlanSelect = ({ next }: Props) => {
  const isModifiedSubtitlePositionEnabled = useIsFeatureFlagOn(
    'premium-upgrade-form-copy-position',
    { trackImpression: true },
  );

  const isGoalOrientedSignUpTestOn = useIsFeatureFlagOn('ab-test-goal-oriented-signup', {
    trackImpression: true,
  });

  useTrack(PremiumEventNames.PaymentScreenViewed);

  const temporaryPromoCode = useSelector(getTemporaryPromoCode);
  const { plans } = usePremiumStripeOfferings({ promoCode: temporaryPromoCode });

  const shouldShowGoalOrientedSignUpMessaging =
    isGoalOrientedSignUpTestOn && temporaryPromoCode?.toUpperCase() === WELCOME30_PROMO_CODE;

  let title = shouldShowGoalOrientedSignUpMessaging
    ? 'Transform the way you manage money'
    : COPY.PREMIUM.ONBOARDING_PLAN_SELECT.TITLE.TEST_DEFAULT_TO_ANNUAL;
  let subtitle = shouldShowGoalOrientedSignUpMessaging ? (
    <>
      Take control, feel empowered, and start making real financial progress today. We’ve applied{' '}
      <strong>WELCOME30</strong> so you can get going for less.
    </>
  ) : (
    COPY.PREMIUM.ONBOARDING_PLAN_SELECT.SUBTITLE.TEST_DEFAULT_TO_ANNUAL
  );

  if (hasSponsoredPlanAvailable(plans ?? [])) {
    title = COPY.PREMIUM.ONBOARDING_PLAN_SELECT.TITLE.HAS_SPONSOR_CODE;
    subtitle = COPY.PREMIUM.ONBOARDING_PLAN_SELECT.SUBTITLE.HAS_SPONSOR_CODE;
  }

  return (
    <>
      <StyledOnboardingTitle>{title}</StyledOnboardingTitle>
      {!isModifiedSubtitlePositionEnabled && <OnboardingSubtitle>{subtitle}</OnboardingSubtitle>}
      <StyledOnboardingFormContainer>
        <PremiumUpgradeFormTimelineFlow onSuccess={next} initialPromoCode={temporaryPromoCode} />
        {isModifiedSubtitlePositionEnabled && (
          <OnboardingSubtitleContainer>
            <OnboardingSubtitle>{subtitle}</OnboardingSubtitle>
          </OnboardingSubtitleContainer>
        )}
      </StyledOnboardingFormContainer>
    </>
  );
};

export default OnboardingPlanSelect;
