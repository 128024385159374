import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

import {
  getTrendIndicatorIconGivenChange,
  getTrendIndicatorLineColor,
} from 'common/lib/accounts/accountCharts';
import { formatCurrency } from 'common/utils/Currency';
import { formatPercent } from 'common/utils/Number';

// Prevent 14023.4% from displaying because it looks a little ridiculous
const MAX_DISPLAY_PERCENT = 10;

const ARROW_ICON_SIZE_PX = 18;

const Root = styled(FlexContainer).attrs({ alignCenter: true })<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  line-height: 150%;
`;

const ArrowIcon = styled(Icon).attrs({ size: ARROW_ICON_SIZE_PX })`
  margin-right: ${({ theme }) => theme.spacing.xxsmall};
  transform: translateY(1px);
`;

export const TimeframeText = styled.span`
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  color: ${({ theme }) => theme.color.textLight};
  text-transform: uppercase;
`;

export type Props = {
  startAmount: number;
  endAmount: number;
  timeframeDisplay?: string;
  percentChange?: number;
  className?: string;
  isAsset?: boolean;
};

const TimeframeTrendIndicator = ({
  startAmount,
  endAmount,
  timeframeDisplay,
  percentChange: overridePercentChange,
  className,
  isAsset,
}: Props) => {
  const change = R.ifElse(RA.isNaN, R.always(0), R.identity)(endAmount - startAmount);
  const percentChange = overridePercentChange ?? change / Math.abs(startAmount);
  const color = getTrendIndicatorLineColor({ isAsset, balance: change });
  const arrowIcon = getTrendIndicatorIconGivenChange(change);

  return (
    <Root color={color} className={className}>
      <ArrowIcon name={arrowIcon} />
      <span {...sensitiveClassProps}>
        {formatCurrency(Math.abs(change))}
        {Math.abs(percentChange) < MAX_DISPLAY_PERCENT &&
          !Number.isNaN(percentChange) &&
          ` (${formatPercent(percentChange)})`}
      </span>
      {timeframeDisplay && <TimeframeText>{timeframeDisplay}</TimeframeText>}
    </Root>
  );
};

export default TimeframeTrendIndicator;
