import { mergeRight, omit, pathOr } from 'ramda';
import { noop } from 'ramda-adjunct';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import TabsContext from 'common/components/tabs/Tabs';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Grid, { GridItem } from 'components/lib/ui/Grid';
import PageWithNoAccountsEmptyState from 'components/lib/ui/PageWithNoAccountsEmptyState';
import StatisticCard from 'components/lib/ui/StatisticCard';
import Text from 'components/lib/ui/Text';
import ReportsCashFlowValue from 'components/reports/ReportsCashFlowValue';
import ReportsChartCard from 'components/reports/ReportsChartCard';
import ReportsChartCardEmpty from 'components/reports/ReportsChartCardEmpty';
import ReportsHeaderControls from 'components/reports/ReportsHeaderControls';
import ReportsHeaderTabs from 'components/reports/ReportsHeaderTabs';
import TransactionsListContainer from 'components/transactions/TransactionsListContainer';
import TransactionsSummaryCard from 'components/transactions/TransactionsSummaryCard';

import { setReportsTransactionsSortBy } from 'actions';
import { ensureGroupBy } from 'common/lib/reports';
import { shadow, color, radius, breakPoints, spacing } from 'common/lib/theme/dynamic';
import { formatCurrency } from 'common/utils/Currency';
import { formatPercent } from 'common/utils/Number';
import typewriter from 'lib/analytics/typewriter';
import { useDispatch } from 'lib/hooks';
import useAdaptedReportsData from 'lib/hooks/reports/useAdaptedReportsData';
import useReportsCurrentTab from 'lib/hooks/reports/useReportsCurrentTab';
import useSelectors from 'lib/hooks/useSelectors';
import useToast from 'lib/hooks/useToast';
import { formatReportsValue, makeChartTitle } from 'lib/reports';
import {
  selectReportsFilters,
  selectReportsGroupBy,
  selectReportsSortBy,
} from 'state/reports/selectors';
import { downloadTransactions } from 'state/transactions/thunks';

import { REPORTS } from 'common/constants/copy';
import routes from 'constants/routes';

import type { TransactionOrdering } from 'common/generated/graphql';

const DEFAULT_ROUTE = routes.reports.cashFlow.path;

const WithShadow = styled.div`
  box-shadow: ${shadow.medium};
  background-color: ${color.white};
  border-radius: ${radius.medium};
`;

const StatisticCardGrow = styled(StatisticCard)`
  flex: 1;
`;

const SummaryCardsContainer = styled(FlexContainer).attrs({
  justifyBetween: true,
  alignCenter: true,
  gap: 'gutter',
})`
  margin-top: ${spacing.gutter};

  @media (max-width: ${({ theme }) => theme.breakPoints.md}px) {
    flex-flow: row wrap;
    gap: ${({ theme }) => theme.spacing.small};
    margin: ${spacing.gutter} 0 ${spacing.small};

    > ${StatisticCardGrow} {
      flex-basis: 33%;
    }
  }

  @media (max-width: ${breakPoints.xs}px) {
    > ${StatisticCardGrow} {
      flex-basis: 50%;
    }
  }
`;

const Reports = () => {
  const dispatch = useDispatch();
  const currentTab = useReportsCurrentTab();

  const [showAllEntities, setShowAllEntities] = useState(false);

  const [filters, groupBy, sortTransactionsBy] = useSelectors([
    selectReportsFilters,
    selectReportsGroupBy,
    selectReportsSortBy,
  ]);

  useEffect(() => {
    // Reset every time the tab or filters change
    setShowAllEntities(false);
  }, [currentTab, filters]);

  const supportsSingleGroupBy = ['spending', 'income'].includes(currentTab);

  const { result, summary, isLoading, timeframe, queryVariables } = useAdaptedReportsData({
    groupBy: supportsSingleGroupBy ? ensureGroupBy(groupBy) : undefined,
    currentTab,
    filters,
    showAllEntities,
  });

  const onChangeSortBy = useCallback(
    (sortBy: TransactionOrdering) => dispatch(setReportsTransactionsSortBy(sortBy)),
    [],
  );

  const chartTitle = useMemo(() => makeChartTitle(groupBy, currentTab), [currentTab, groupBy]);

  const memoizedTransactionsList = useMemo(
    () => (
      <TransactionsListContainer
        transactionFilters={queryVariables.filters ?? {}}
        emptyComponent={<ReportsChartCardEmpty />}
        sortBy={sortTransactionsBy}
        onChangeSortBy={onChangeSortBy}
        overrideTitle={
          <Text size="large" weight="medium">
            Transactions
          </Text>
        }
      />
    ),
    [queryVariables.filters, sortTransactionsBy, onChangeSortBy],
  );

  const renderChartCard = useCallback(
    (props: Partial<React.ComponentProps<typeof ReportsChartCard>>) => (
      <ReportsChartCard
        data={result}
        isLoading={isLoading}
        title={chartTitle}
        currentTab={currentTab}
        timeframe={timeframe}
        summary={summary}
        {...omit(['total'], props)}
        total={formatReportsValue(props.total)}
        showAllEntities={showAllEntities}
        onChangeShowAllEntities={setShowAllEntities}
      />
    ),
    [result, isLoading, chartTitle, currentTab, timeframe, summary, showAllEntities],
  );

  const { openErrorToast, openToast } = useToast();
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
  const onClickDownloadCsv = useCallback(async () => {
    setIsDownloadingCsv(true);

    const { startDate, endDate, ...rest } = filters;
    const filtersWithCounts = Object.keys(rest).reduce((acc, key) => {
      const count = pathOr(0, [key, 'length'], filters);
      return count > 0 ? { ...acc, [key]: count } : acc;
    }, {});

    typewriter.transactionsDownloadedToCsv({
      filtersWithCounts: mergeRight(filtersWithCounts, { startDate, endDate }),
      origin: 'reports',
    });

    try {
      await dispatch(downloadTransactions(filters));
      openToast({
        title: 'Transactions downloaded',
        description: 'Your transactions have been downloaded to your computer.',
      });
    } catch (error) {
      openErrorToast({
        title: 'Error downloading your transactions',
      });
    } finally {
      setIsDownloadingCsv(false);
    }
  }, [filters, dispatch]);

  return (
    <TabsContext>
      <PageWithNoAccountsEmptyState
        name="Reports"
        tabs={<ReportsHeaderTabs />}
        controls={<ReportsHeaderControls isGroupByEnabled={supportsSingleGroupBy} />}
        emptyIcon="pie-chart"
        emptyTitle={REPORTS.EMPTY_STATE_TITLE}
        emptyButtonText={REPORTS.EMPTY_STATE_BUTTON}
      >
        <Grid
          template={`"chart chart" "transactions summary" / 1fr 30%`}
          md={`"chart" "summary" "transactions"`}
        >
          <GridItem area="chart">
            <Switch>
              {/* This redirects the user to the default route if they try to access the page by
               the direct URL (/reports). Without this we'd render a blank page. */}
              <Route
                exact
                path={routes.reports.path}
                render={() => <Redirect to={DEFAULT_ROUTE} />}
              />
              <Route path={routes.reports.cashFlow.path}>
                {renderChartCard({ title: 'Cash Flow' })}
                <SummaryCardsContainer>
                  <StatisticCardGrow
                    label="Total Income"
                    value={
                      <ReportsCashFlowValue
                        formatter={formatCurrency}
                        value={summary?.sumIncome ?? 0}
                        color="greenText"
                      />
                    }
                    isLoading={isLoading}
                  />
                  <StatisticCardGrow
                    label="Total Expenses"
                    value={
                      <ReportsCashFlowValue
                        formatter={formatCurrency}
                        value={(summary?.sumExpense ?? 0) * -1}
                        color="redText"
                      />
                    }
                    isLoading={isLoading}
                  />
                  <StatisticCardGrow
                    label="Total Net Income"
                    value={
                      <ReportsCashFlowValue
                        formatter={formatCurrency}
                        value={summary?.savings ?? 0}
                      />
                    }
                    isLoading={isLoading}
                  />
                  <StatisticCardGrow
                    label="Savings Rate"
                    value={
                      <ReportsCashFlowValue
                        formatter={formatPercent}
                        value={Math.max(0, summary?.savingsRate ?? 0)}
                      />
                    }
                    isLoading={isLoading}
                  />
                </SummaryCardsContainer>
              </Route>
              <Route path={routes.reports.spending.path}>
                {renderChartCard({ total: summary?.sumExpense })}
              </Route>
              <Route path={routes.reports.income.path}>
                {renderChartCard({ total: summary?.sumIncome })}
              </Route>
              <Route path={routes.reports.sankey.path}>
                {renderChartCard({ title: 'Sankey Diagram' })}
              </Route>
            </Switch>
          </GridItem>
          <GridItem area="transactions">
            <WithShadow>{memoizedTransactionsList}</WithShadow>
          </GridItem>
          <GridItem area="summary">
            <TransactionsSummaryCard
              transactionsSummary={summary}
              empty={<ReportsChartCardEmpty />}
              filters={filters}
              onChangeFilters={noop}
              onClickDownloadCsv={onClickDownloadCsv}
              isDownloadingCsv={isDownloadingCsv}
            />
          </GridItem>
        </Grid>
      </PageWithNoAccountsEmptyState>
    </TabsContext>
  );
};

export default Reports;
