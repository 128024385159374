import * as RA from 'ramda-adjunct';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import OnboardingAccoladesBanner from 'components/onboarding/OnboardingAccoladesBanner';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import PremiumPlanComparisonTable from 'components/premium/PremiumPlanComparisonTable';
import PremiumPlanTimeline from 'components/premium/PremiumPlanTimeline';

import { getDaysLeftOfTrial } from 'common/lib/billing/Billing';
import usePremiumStripeOfferings from 'common/lib/hooks/premium/usePremiumStripeOfferings';
import useTrialStatusQuery from 'common/lib/hooks/premium/useTrialStatusQuery';
import { isoDateToMonthAndDay } from 'common/utils/date';
import { trackPremiumScreenViewed } from 'lib/analytics/premium';
import typewriter from 'lib/analytics/typewriter';
import { getTemporaryPromoCode } from 'state/onboarding/selectors';

import * as COPY from 'common/constants/copy';
import { ReferredFeatureAnalyticsName } from 'common/constants/premium';

const StyledOnboardingTitle = styled(OnboardingTitle)`
  margin-top: 34px;
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
  white-space: pre;
`;

const StyledOnboardingAccoladesBanner = styled(OnboardingAccoladesBanner)`
  margin: -${({ theme }) => theme.spacing.xlarge};
  margin-bottom: 0;
  width: calc(100% + ${({ theme }) => parseInt(theme.spacing.xlarge) * 2}px);
`;

const Footer = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  bottom: 0;
  width: 100%;
  background: ${({ theme }) => theme.color.white};
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
`;

const StyledPremiumPlanComparisonTable = styled(PremiumPlanComparisonTable)`
  width: 100%;
  max-width: 437px;
`;

const TimelineOnboardingFormContainer = styled(OnboardingFormContainer)`
  max-width: 555px;
  padding: 0;
`;

type Props = {
  next: () => void;
  skipToComplete?: () => void;
};

const OnboardingPremiumUpsell = ({ next, skipToComplete }: Props) => {
  const temporaryPromoCode = useSelector(getTemporaryPromoCode);
  const { plans } = usePremiumStripeOfferings({
    prefetch: true,
    promoCode: temporaryPromoCode,
  }); // Prefetch offerings since they're shown on the next page
  const { trialDurationDays, hasPremiumTrialAvailable } = useTrialStatusQuery();

  useEffect(() => {
    if (temporaryPromoCode) {
      typewriter.webAppPromoCodeApplied({
        promoCode: temporaryPromoCode,
        auto: true,
      });
    }
  }, [temporaryPromoCode]);

  const trialDays = useMemo(() => {
    // Check if any of the plans have a new trial date (changed by the promo code) and use the first one
    const planWithNewTrial = plans.find(({ newTrialEndsAt }) => newTrialEndsAt);

    return RA.isNotNil(planWithNewTrial)
      ? getDaysLeftOfTrial(planWithNewTrial.newTrialEndsAt) ?? trialDurationDays
      : trialDurationDays;
  }, [plans, trialDurationDays]);

  useEffect(() => trackPremiumScreenViewed(ReferredFeatureAnalyticsName.Signup), []);

  const trialEndDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + trialDays);
    return isoDateToMonthAndDay(date.toISOString());
  }, [trialDays]);

  const regularSignupPremiumUpsellScreen = (
    <>
      <StyledOnboardingAccoladesBanner />
      <StyledOnboardingTitle>
        {COPY.PREMIUM.PLAN_COMPARISION.TITLE.TRIAL_UNAVAILABLE}
      </StyledOnboardingTitle>
      <StyledPremiumPlanComparisonTable promoCode={temporaryPromoCode} />
      <Footer>
        <OnboardingFormContainer>
          <StyledPrimaryButton size="medium" onClick={next}>
            <ButtonIcon name="diamond" />
            <span>{COPY.PREMIUM.UPGRADE_CTA.TRIAL_AVAILABLE}</span>
          </StyledPrimaryButton>
        </OnboardingFormContainer>
      </Footer>
    </>
  );

  const timelineSignupPremiumUpsellScreen = (
    <>
      <StyledOnboardingAccoladesBanner />
      <StyledOnboardingTitle>{COPY.PREMIUM.TIMELINE.TITLE}</StyledOnboardingTitle>
      <PremiumPlanTimeline trialDays={trialDays} trialEndDate={trialEndDate} />
      <Footer>
        <TimelineOnboardingFormContainer>
          <StyledPrimaryButton size="medium" onClick={next}>
            {COPY.PREMIUM.TIMELINE.CONTINUE_BUTTON}
          </StyledPrimaryButton>
        </TimelineOnboardingFormContainer>
      </Footer>
    </>
  );

  return hasPremiumTrialAvailable
    ? timelineSignupPremiumUpsellScreen
    : regularSignupPremiumUpsellScreen;
};

export default OnboardingPremiumUpsell;
