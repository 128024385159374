import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import ChartTooltip from 'components/lib/charts/ChartTooltip';
import ChartTooltipDataRow from 'components/lib/charts/ChartTooltipDataRow';
import StackedBarChart from 'components/lib/charts/StackedBarChart';
import Card from 'components/lib/ui/Card';
import Empty, { Theme as EmptyTheme } from 'components/lib/ui/Empty';
import Flex from 'components/lib/ui/Flex';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';

import { getChartDateFromTimeframe } from 'common/lib/charts';
import THEME from 'common/lib/theme';
import { formatCurrencyMaybeThousands } from 'common/utils/Currency';
import type { FilteredChartDataPoint } from 'lib/cashFlow/Adapters';
import {
  cashFlowChartReferenceLineAdapter,
  findActiveBarIndex,
  getBarColor,
} from 'lib/cashFlow/Adapters';
import useMeasure from 'lib/hooks/useMeasure';
import useTheme from 'lib/hooks/useTheme';
import { getDateShorthand } from 'utils/dateRange';

import type { Timeframe } from 'common/constants/timeframes';

import type DateRange from 'common/types/DateRange';

const ROOT_PADDING = parseInt(THEME.spacing.default, 10);

const Root = styled(Card)`
  padding: 0px ${ROOT_PADDING}px ${ROOT_PADDING}px;
`;

const CHART_HEIGHT_PX = 275;

const StyledEmpty = styled(Flex)`
  margin: ${({ theme }) => theme.spacing.default};
  height: ${CHART_HEIGHT_PX}px;
`;

type Props = {
  activeDateRange: DateRange;
  data: FilteredChartDataPoint[];
  timeframe: Timeframe;
  activeDate: string;
  dataLabel: string;
  showLoader: boolean;
  displayAsExpense: boolean;
  onBarClick: (date: ISODate) => void;
};

const FilteredCashFlowBarChart = ({
  data: chartData,
  onBarClick,
  dataLabel,
  displayAsExpense,
  showLoader,
  timeframe,
  activeDate,
}: Props) => {
  const [ref, { width = 0 }] = useMeasure<HTMLDivElement>();
  const theme = useTheme();

  const onBarAreaClick = useCallback(
    ({ startDate }: { startDate: ISODate }) => onBarClick(startDate),
    [onBarClick],
  );
  const yearDividers = useMemo(
    // Hide year dividers if grouping by year since it's redundant
    () => (timeframe !== 'year' ? cashFlowChartReferenceLineAdapter(chartData) : []),
    [chartData, timeframe],
  );
  const activeBarIndex = findActiveBarIndex(chartData, activeDate);

  const formatTooltipDate = useCallback(
    (startDate: string) =>
      getDateShorthand({
        startDate,
        endDate: DateTime.fromISO(startDate).endOf(timeframe).toISODate(),
      }) ?? startDate,
    [timeframe],
  );

  if (showLoader) {
    return (
      <Root ref={ref}>
        <StyledEmpty center>
          <LoadingSpinner />
        </StyledEmpty>
      </Root>
    );
  }

  if (chartData.every(({ sum }) => sum === 0)) {
    return (
      <Root ref={ref}>
        <StyledEmpty center>
          <Empty emptyTheme={EmptyTheme.OLD} title={`No data for ${dataLabel}.`} />
        </StyledEmpty>
      </Root>
    );
  }

  const barShouldBeRed = (amount: number) => (displayAsExpense ? amount > 0 : amount < 0);

  const getColor = (amount: number) => {
    if (amount === 0) {
      return theme.color.gray;
    }
    return barShouldBeRed(amount) ? theme.color.red : theme.color.green;
  };

  return (
    <Root ref={ref}>
      <StackedBarChart
        activeBarIndex={activeBarIndex}
        data={chartData}
        barDataKeys={[
          {
            name: 'sum',
            color: ({ amount, hoveredBarIndex, index, activeBarIndex }) => {
              const isExpense = barShouldBeRed(amount);
              const colorFn = isExpense
                ? getBarColor(theme.color.red, theme.color.redFocus)
                : getBarColor(theme.color.green, theme.color.greenFocus);
              return colorFn({ amount, hoveredBarIndex, index, activeBarIndex });
            },
          },
        ]}
        xAxisDataKey="startDate"
        heightPx={CHART_HEIGHT_PX}
        widthPx={width - ROOT_PADDING}
        formatXAxis={getChartDateFromTimeframe(timeframe)}
        formatYAxis={formatCurrencyMaybeThousands}
        onBarAreaClick={onBarAreaClick}
        xAxisReferenceLines={yearDividers}
        tooltipComponent={({ payload, active }) => {
          if (!payload?.[0]) {
            return null;
          }

          const { startDate, sum } = payload[0].payload ?? {};

          return (
            <ChartTooltip active={active} header={formatTooltipDate(startDate)}>
              <ChartTooltipDataRow
                label={dataLabel}
                value={
                  <CashFlowCurrency
                    value={displayAsExpense ? -1 * sum : sum}
                    type={displayAsExpense ? 'expense' : 'income'}
                    round
                  />
                }
                dotFillColor={getColor(sum)}
              />
            </ChartTooltip>
          );
        }}
      />
    </Root>
  );
};

export default FilteredCashFlowBarChart;
