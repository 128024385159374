import React from 'react';
import styled from 'styled-components';

import FeatureOnboardingPage from 'components/lib/layouts/FeatureOnboardingPage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

const CIRCLE_SIZE_PX = 80;
const CHECK_ICON_SIZE_PX = 48;

const Circle = styled(FlexContainer).attrs({ center: true })`
  width: ${CIRCLE_SIZE_PX}px;
  height: ${CIRCLE_SIZE_PX}px;
  background: ${({ theme }) => theme.color.green};
  border-radius: ${({ theme }) => theme.radius.round};

  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const CheckIcon = styled(Icon).attrs({ name: 'check' })`
  width: ${CHECK_ICON_SIZE_PX}px;
  height: ${CHECK_ICON_SIZE_PX}px;
  color: ${({ theme }) => theme.color.textWhite};
`;

type Props = {
  onBack: () => void;
  onCancel: () => void;
  onNext: () => void;
  progress: number;
};

const OnboardingSuccess = ({ onBack, onCancel, onNext, progress }: Props) => (
  <FeatureOnboardingPage
    pageName="Onboarding Success"
    title="You're all set!"
    description="You're all set!"
    descriptionMaxWidth={496}
    progress={progress}
    onClickBack={onBack}
    onClickCancel={onCancel}
    onClickNext={onNext}
    nextButtonText="View my budget"
  >
    <FlexContainer column center>
      <Circle>
        <CheckIcon />
      </Circle>
    </FlexContainer>
  </FeatureOnboardingPage>
);

export default OnboardingSuccess;
