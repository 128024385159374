import React from 'react';
import styled from 'styled-components';

import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

const TRANSPARENCY_HEIGHT_PX = 50;

const Root = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.shadow.medium};
  padding: ${({ theme }) => theme.spacing.medium};
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: -${TRANSPARENCY_HEIGHT_PX}px;
    left: 0;
    right: 0;
    height: ${TRANSPARENCY_HEIGHT_PX}px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      ${({ theme }) => theme.color.white}
    );
    pointer-events: none;
  }
`;

const Button = styled(PrimaryButton).attrs({ size: 'large' })`
  width: 100%;
  max-width: 438px;
`;

const FooterButton = ({ onClickNext }: { onClickNext: () => void }) => (
  <Root>
    <Button onClick={onClickNext}>Continue</Button>
  </Root>
);

export default FooterButton;
