import { rgba } from 'polished';
import { css } from 'styled-components';

import fieldStyleMixin, { fieldStyleFocus, fieldStyleHover } from 'lib/styles/fieldStyleMixin';

import type StyleMixin from 'types/StyleMixin';

export const dateRangeStyleMixin: StyleMixin<{ $small: boolean; $focused: boolean }> = ({
  theme,
  $small,
  $focused,
}) => `
  .DateRangePicker {
    width: 100%;
    &_picker {
      background: ${theme.color.white};
    }
  }
  .DateRangePickerInput {
    flex: 1;
    display: flex;
    align-items: center;
    border: 1px solid ${theme.color.grayFocus};
    border-radius: ${theme.radius.small};
    transition: ${theme.transition.default};
    background: transparent;
    :hover {
      ${!$focused && fieldStyleHover({ theme })};
    }
    &__disabled {
      background: ${theme.color.white};
    }
    ${$focused && fieldStyleFocus({ theme })};
    .DateInput {
      flex: 1;
      border-radius: ${theme.radius.small};
      background: transparent;
      &_fangStroke {
        stroke: ${theme.color.grayBackground};
        fill: ${theme.color.white};
      }
      &_input {
        ${fieldStyleMixin({ theme, small: $small, dynamic: false })};

        ${
          $small &&
          css`
            padding: 4px 12px;
          `
        }
        line-height: unset;
        border: 0px;
        :focus {
          color: ${theme.color.blueDark};
          ::placeholder {
            color: ${$focused ? theme.color.gray : theme.color.textLight};
          }
        }
        :disabled {
          color: ${theme.color.textLight};
          background-color: ${theme.color.white};
        }
      }
    }
  }
  .DateRangePickerInput_arrow {
    position: relative;
    top: -2px;
  }
  .CalendarDay {
    &__default {
      color: ${theme.color.text};
      background: ${theme.color.white};
      border-color: ${theme.color.grayFocus};
      &:not(.CalendarDay__selected_start)
        :not(.CalendarDay__selected_end)
        :not(.CalendarDay__selected_span)
        :not(.CalendarDay__hovered_span)
        :not(.CalendarDay__blocked_out_of_range)
        :hover {
        background: ${theme.color.grayFocus};
      }
    }
    &__selected_start,
    &__selected_end {
      background: ${theme.color.blue};
      border-color: ${theme.color.blue};
      color: ${theme.color.textWhite};
    }
    &__hovered_span, &:not(&__hovered_span):not(&__selected):hover {
      background: ${rgba(theme.color.blue, 0.3)};
      border-color: ${rgba(theme.color.blue, 0.4)};
      color: ${theme.color.blue};
    }
    &__selected_span {
      background: ${rgba(theme.color.blue, 0.4)};
      border-color: ${rgba(theme.color.blue, 0.5)};
      color: ${theme.color.textWhite};
    }
    &__blocked_out_of_range {
      color: ${theme.color.textLight};
    }
  }
  .CalendarMonth_caption {
    color: ${theme.color.text};
    padding-bottom: 50px;

    > strong {
      font-weight: ${theme.fontWeight.medium};
    }
  }
  .DayPicker {
    background: ${theme.color.white};
  }
  .DayPicker_weekHeader {
    color: ${theme.color.textLight};
  }
  .DayPicker > div {
    display: flex;
  }
  .DayPicker_calendarInfo_horizontal {
    display: flex;
    background: ${theme.color.white};
  }
  .CalendarMonthGrid {
    background: ${theme.color.white};
  }
  .CalendarMonth {
    background: ${theme.color.white};
  }
  .DayPickerNavigation_button {
    border: none !important;
  }
  .DayPickerNavigation_button__disabled {
    opacity: 0.5;
    cursor: disabled;
    pointer-events: none;
  }

  .DateRangePickerInput_clearDates {
    margin: 0;
  }
`;
