import * as R from 'ramda';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AppleSignInButton from 'components/lib/external/AppleSignInButton';
import GoogleSignInButton from 'components/lib/external/GoogleSignInButton';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import Divider from 'components/lib/ui/Divider';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinnerWithText from 'components/lib/ui/LoadingSpinnerWithText';
import TermsAndConditions from 'components/lib/ui/TermsAndConditions';
import Text from 'components/lib/ui/Text';
import ManualLink from 'components/lib/ui/link/ManualLink';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';

import getUserApi from 'common/lib/api/user';
import api from 'lib/api';
import useFingerprint from 'lib/hooks/auth/useFingerprint';
import useIsReferralCodeValid from 'lib/hooks/useIsReferralCodeValid';
import { useQueryParam } from 'lib/hooks/useQueryParams';
import useUserAttributionData from 'lib/hooks/useUserAttributionData';

import { AUTH_PROVIDER_TO_LABEL } from 'common/constants/auth';
import routes from 'constants/routes';

import type { AuthProvider } from 'common/types/auth';

const MAX_WIDTH_CONTAINER = '350px';

const FormContainer = styled(OnboardingFormContainer)<{ $resetPadding: boolean }>`
  padding: ${({ $resetPadding }) => ($resetPadding ? 0 : 'initial')} 0
    ${({ theme }) => theme.spacing.large};
  max-width: ${MAX_WIDTH_CONTAINER};
`;

const IntroContainer = styled(FlexContainer).attrs({ column: true })`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  width: 100%;

  ${OnboardingTitle} {
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: ${({ theme }) => theme.spacing.small};
  }
`;

const WelcomePageSubtitle = styled.p`
  margin: ${({ theme }) => theme.spacing.xsmall} 0 ${({ theme }) => theme.spacing.xlarge};
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing.large} 0;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH_CONTAINER};
  margin: 0 auto;
  padding: 0;

  > button:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.default};
  }
`;

const TermsAndConditionsWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.textLight};
  margin-bottom: ${({ theme }) => theme.spacing.xxxlarge};
  max-width: 300px;
`;

type Props = {
  next: (params: { queryParams: { email: string } }) => void;
};

const OnboardingWelcome = ({ next }: Props) => {
  const referralCode = useQueryParam('r');
  const isReferralCodeValid = useIsReferralCodeValid(referralCode);

  const email = useQueryParam('email') || '';
  const history = useHistory();
  const userAttributionData = useUserAttributionData();
  const fingerprint = useFingerprint();
  const [pendingAuthProvider, setPendingAuthProvider] = useState<AuthProvider | undefined>();

  const onSubmit = async ({ email }: { email: string }) => {
    const trimmedEmail = R.trim(email);

    await getUserApi(api).createUnverifiedUser({
      email: trimmedEmail,
      referralCode: isReferralCodeValid ? referralCode : null,
      userAttributionData,
      fingerprint,
    });
    await getUserApi(api).createVerification(trimmedEmail);
    next({ queryParams: { email: trimmedEmail } });
  };

  if (pendingAuthProvider) {
    return (
      <LoadingSpinnerWithText
        text={`Signing up with ${AUTH_PROVIDER_TO_LABEL[pendingAuthProvider]}`}
      />
    );
  }

  return (
    <>
      <IntroContainer>
        <OnboardingTitle>Sign up to start your free trial</OnboardingTitle>
        <WelcomePageSubtitle>Try Monarch free, cancel anytime.</WelcomePageSubtitle>
        <ButtonsContainer>
          <AppleSignInButton
            setIsPending={(isPending) => setPendingAuthProvider(isPending ? 'apple' : undefined)}
            skipConfirmation
          />
          <GoogleSignInButton
            onError={() => history.push(`${routes.login()}?message=authError`)}
            setIsPending={(isPending) => setPendingAuthProvider(isPending ? 'google' : undefined)}
            skipConfirmation
          />
          <StyledDivider />
        </ButtonsContainer>
      </IntroContainer>
      <FormContainer $resetPadding>
        <Form enableReinitialize onSubmit={onSubmit} initialValues={{ email }}>
          <TextField
            name="email"
            label="Your Email Address"
            placeholder="email@address.com"
            email
            autoFocus
            autoFocusOnMobile={false}
          />
          <FormSubmitButton variant="gradient" size="medium">
            Sign up with email
          </FormSubmitButton>
        </Form>
      </FormContainer>
      <TermsAndConditionsWrapper>
        <TermsAndConditions />
      </TermsAndConditionsWrapper>
      <>
        <Text size="small">
          Already have an account?{' '}
          <ManualLink onClick={() => history.push(routes.login())}>Sign in</ManualLink>
        </Text>
      </>
    </>
  );
};

export default OnboardingWelcome;
