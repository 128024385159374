import { gql } from 'common/generated/gql';

export const CREATE_SPINWHEEL_TOKEN_MUTATION = gql(/* GraphQL */ `
  mutation Common_CreateSpinwheelTokenMutation {
    createSpinwheelToken {
      token
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

/*
 * fetch liability accounts with its recurringTransactionStream so we can get
 * the frequency previously saved by users. The field `termsFrequency` is the default
 * frequency from spinwheel API.
 */
export const GET_SPINWHEEL_CREDIT_REPORT = gql(/* GraphQL */ `
  query Common_GetSpinwheelCreditReport {
    spinwheelUser {
      user {
        id
        name
      }
      onboardingStatus
    }
    creditReportLiabilityAccounts {
      spinwheelLiabilityId
      liabilityType
      isOpen
      currentTotalBalance
      account {
        id
      }
      description
      termsFrequency
      spinwheelUser {
        user {
          id
          name
          profilePictureUrl
        }
      }
      accountType
      recurringTransactionStream {
        frequency
        reviewStatus
        baseDate
        dayOfTheMonth
      }
      lastStatement {
        dueDate
      }
    }
  }
`);

export const CREATE_SPINWHEEL_ACCOUNT_MAPPING_MUTATION = gql(`
  mutation Web_CreateSpinwheelAccountMapping($input: SpinwheelAccountMappingInput!) {
    createSpinwheelAccountMapping(input: $input) {
      success
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);
