import * as R from 'ramda';
import React from 'react';
import deepIsEqual from 'react-fast-compare';
import styled, { css } from 'styled-components';

import PlanColumnAmounts from 'components/plan/PlanColumnAmounts';
import {
  PlanGridGroup,
  PlanGridRow,
  LeftColumn,
  HeaderText,
  PlanGridColumn,
} from 'components/plan/PlanGrid';
import PlanRowTitle from 'components/plan/PlanRowTitle';

import { getAmountColor } from 'common/lib/budget/Amounts';
import { usePlanContext } from 'lib/contexts/PlanContext';
import type { PlanSectionType } from 'lib/plan/Adapters';

const Root = styled(PlanGridGroup)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const Row = styled(PlanGridRow)<{ backgroundColor?: string }>`
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
      transition: background-color 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
      color: ${({ theme }) => theme.color.textWhite};
      border-top-right-radius: ${({ theme }) => theme.radius.medium};
      border-bottom-right-radius: ${({ theme }) => theme.radius.medium};
    `}
`;

const Title = styled(PlanRowTitle)<{ backgroundColor?: string }>`
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      height: 100%;
      background-color: ${backgroundColor};
      transition: background-color 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
      border-top-left-radius: ${({ theme }) => theme.radius.medium};
      border-bottom-left-radius: ${({ theme }) => theme.radius.medium};
    `}
`;

const Separator = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.color.grayLight};
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

type Props = {
  name: string;
  amounts: any;
  numColumns: number;
  sectionType: PlanSectionType;
  showSeparator?: boolean;
  /** Only true for last row on "Budget" page, will set background color to green or red and only show first column */
  isPlanSummary?: boolean;
};

const PlanSectionFooter = ({
  name,
  amounts,
  numColumns,
  sectionType,
  showSeparator,
  isPlanSummary,
}: Props) => {
  const columnCount = isPlanSummary ? 1 : numColumns;
  const { getDateForColumn, getAmountTypeForColumn } = usePlanContext();

  const backgroundColor = isPlanSummary
    ? getAmountColor(amounts[getDateForColumn(0).toISODate()]?.[getAmountTypeForColumn(0)])
    : undefined;

  return (
    <>
      {showSeparator && <Separator />}
      <Root>
        <Row backgroundColor={backgroundColor}>
          <LeftColumn>
            <Title backgroundColor={backgroundColor}>
              <HeaderText>{name}</HeaderText>
            </Title>
          </LeftColumn>
          <PlanColumnAmounts
            numColumns={columnCount}
            amounts={amounts}
            isAggregate
            sectionType={sectionType}
            canBeBudget={false}
            canMoveBudget={false}
          />
          {/* We only show the first column for the "Left to Budget" row, but we need empty columns afterward to ensure everything lines up. */}
          {columnCount !== numColumns &&
            R.range(columnCount, numColumns).map((i) => <PlanGridColumn key={i} />)}
        </Row>
      </Root>
    </>
  );
};

export default React.memo(PlanSectionFooter, deepIsEqual);
