import { useQuery } from '@apollo/client';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import CategorySelect from 'components/categories/FullHeightCategorySelect';
import Confirmation from 'components/lib/ui/Confirmation';

import routes from 'constants/routes';

import { gql } from 'common/generated/gql';
import type {
  CategoryDeletionInfo,
  CategoryDeletionInfoVariables,
} from 'common/generated/graphQlTypes/CategoryDeletionInfo';
import type { CategoryFieldsFragment } from 'common/generated/graphql';

type Props = {
  category: Pick<CategoryFieldsFragment, 'id' | 'isSystemCategory' | 'icon' | 'name'>;
  onCancel: () => void;
  disableOrDeleteCategory: (moveToCategoryId?: string) => void;
};

const Root = styled.div``;

const Paragraph = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const Bold = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.color.red};
  padding-bottom: ${({ theme }) => theme.spacing.default};
`;

const StyledLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.color.blue};
  }
`;

const DeleteOrDisableCategoryConfirmation = ({
  category,
  onCancel,
  disableOrDeleteCategory,
}: Props) => {
  const { id, isSystemCategory } = category;
  const { data } = useQuery<CategoryDeletionInfo, CategoryDeletionInfoVariables>(QUERY, {
    variables: { id },
  });
  const [moveToCategoryId, setMoveToCategoryId] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  if (!data || !data.categoryDeletionInfo) {
    return null;
  }
  const { transactionsCount, rulesCount } = data?.categoryDeletionInfo;
  const shouldSelectMoveToCategory = transactionsCount > 0 || rulesCount > 0;
  const clickButton = () => {
    if (shouldSelectMoveToCategory) {
      if (!moveToCategoryId) {
        setErrorMessage('Please select a category to move transactions and rules to.');
      } else {
        disableOrDeleteCategory(moveToCategoryId);
      }
    } else {
      disableOrDeleteCategory();
    }
  };

  const deleteOrDisableText = isSystemCategory ? 'Disable Category' : 'Delete Category';
  return (
    <Confirmation
      title={deleteOrDisableText}
      confirm={deleteOrDisableText}
      onCancel={onCancel}
      onConfirm={clickButton}
      useDangerButton
    >
      <Root>
        <Paragraph>
          You will no longer see{' '}
          <Bold>
            {category.icon} {category.name}
          </Bold>{' '}
          as a category anywhere in our app, including budgets, cash flow, or transactions.
        </Paragraph>
        {shouldSelectMoveToCategory && (
          <>
            <Paragraph>
              You have{' '}
              <Bold>
                <StyledLink to={routes.transactions({ queryParams: { categories: [id] } })}>
                  {pluralize('transaction', transactionsCount, true)}
                </StyledLink>
              </Bold>{' '}
              and <Bold>{pluralize('rule', rulesCount, true)}</Bold> for {category.icon}{' '}
              {category.name}. Existing transactions and rules will be assigned to the category you
              select below.{' '}
            </Paragraph>
            <Paragraph>
              <Bold>Where should we reassign these transactions and rules?</Bold>
            </Paragraph>
            {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <CategorySelect
              value={moveToCategoryId}
              alwaysShowBorder
              filters={{ excludeCategories: [id] }}
              onChange={(_, action, [category]) => setMoveToCategoryId(category.id)}
              fullWidthTrigger
              isCreatable
            />
          </>
        )}
      </Root>
    </Confirmation>
  );
};

const QUERY = gql(`
  query CategoryDeletionInfo($id: UUID!) {
    categoryDeletionInfo(id: $id) {
      transactionsCount
      rulesCount
    }
  }
`);

export default DeleteOrDisableCategoryConfirmation;
