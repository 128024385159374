import { rgba } from 'polished';
import React from 'react';
import type { NavLinkProps } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import type { IconProps } from 'components/lib/ui/Icon';
import Icon, { monarchIconExists } from 'components/lib/ui/Icon';
import NavLink, { ACTIVE_CLASS_NAME } from 'components/lib/ui/link/NavLink';
import LinkIcon from 'components/sidebar/LinkIcon';

import useTheme from 'lib/hooks/useTheme';

const UnsavedChangesBadge = styled.span`
  width: 6px;
  height: 6px;
  position: absolute;
  background-color: ${({ theme }) => theme.color.red};
  top: 0;
  right: -2px;
  border-radius: 100%;
`;

const NavIcon = (props: IconProps) => <Icon {...props} size={16} />;

const NavBadge = styled.span<{ $color: string }>`
  display: inline-block;
  margin-left: auto;
  padding: 0 ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.radius.small};
  background-color: ${({ $color }) => $color};
`;

type NavBarLinkProps = Omit<NavLinkProps<unknown>, 'activeClassName'> & {
  iconName: string;
  activeIcon?: React.ReactNode;
  hasUnsavedChanges?: boolean;
  badge?: string;
  badgeColor?: string;
};

const NavBarLink = styled(
  ({
    iconName,
    children,
    hasUnsavedChanges,
    isActive,
    badge,
    badgeColor,
    to,
    ...rest
  }: NavBarLinkProps) => {
    const theme = useTheme();
    const routeMatch = useRouteMatch(to.toString());

    const filledIconName = `${iconName}-filled`;
    const activeIconName = monarchIconExists(filledIconName) ? filledIconName : iconName;
    const icon = routeMatch ? activeIconName : iconName;

    return (
      <NavLink to={to} {...rest}>
        <LinkIcon>
          <NavIcon name={icon} />
          {hasUnsavedChanges && <UnsavedChangesBadge />}
        </LinkIcon>
        {children}
        {!!badge && <NavBadge $color={badgeColor ?? theme.color.blue}>{badge}</NavBadge>}
      </NavLink>
    );
  },
)`
  color: ${({ theme }) => rgba(theme.color.textWhite, 0.7)};
  display: flex;
  align-items: center;

  padding: ${({ theme }) => theme.spacing.small};
  transition: ${({ theme }) => theme.transition.default};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  border-radius: ${({ theme }) => theme.radius.small};

  &:not(.${ACTIVE_CLASS_NAME}) :hover {
    background-color: ${({ theme }) => theme.color.navyLight};
  }

  &.${ACTIVE_CLASS_NAME}, && :active {
    opacity: 1;
    background-color: ${({ theme }) => theme.color.navyDark};
  }
`;

export default NavBarLink;
