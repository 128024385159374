import { motion } from 'framer-motion';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Tooltip from 'components/lib/ui/Tooltip';
import IconButton from 'components/lib/ui/button/IconButton';

import useToggle from 'common/lib/hooks/useToggle';
import { color, fontSize, fontWeight, radius, shadow, spacing } from 'common/lib/theme/dynamic';
import { useContactSupportContext } from 'lib/contexts/ContactSupportContext';
import useTrack from 'lib/hooks/useTrack';

import { AnalyticsEventNames } from 'common/constants/analytics';
import { HELP_CENTER_URL } from 'common/constants/externalUrls';

const SUPPORT_CHAT_IFRAME_SRC = 'https://app.usefini.com/widgets/eRv8rClP1f';
const CHAT_CONTAINER_HEADER_HEIGHT_PX = 48;
const CHAT_CONTAINER_WIDTH_PX = 404;
const CHAT_CONTAINER_HEIGHT_PX = 482;
const HEADER_ICON_SIZE_PX = 21;

const Root = styled.div`
  width: ${CHAT_CONTAINER_WIDTH_PX}px;
  box-shadow: ${({ theme }) =>
    theme.uiTheme === 'dark' ? '0 0 20px 5px rgba(0, 0, 0, 0.3)' : shadow.medium};
  border-radius: ${radius.medium} ${radius.medium} 0 0;
  pointer-events: auto;
`;

const Header = styled(FlexContainer).attrs({ justifyBetween: true, alignCenter: true })`
  color: ${color.textWhite};
  background-color: ${color.navy};
  font-weight: ${fontWeight.medium};
  padding: ${spacing.small} ${spacing.default};
  border-radius: ${radius.medium} ${radius.medium} 0 0;
  height: ${CHAT_CONTAINER_HEADER_HEIGHT_PX}px;
  cursor: pointer;
`;

const HeaderButton = styled(IconButton).attrs({ size: 'xsmall', iconSize: HEADER_ICON_SIZE_PX })`
  color: ${color.textWhite};

  :focus {
    color: ${color.textWhite};

    ::before {
      opacity: 0;
    }
  }
`;

const Body = styled(FlexContainer).attrs({ column: true })`
  position: relative;
  height: ${CHAT_CONTAINER_HEIGHT_PX}px;
  background-color: ${color.white};
  overflow: hidden;
`;

const Disclaimer = styled.div`
  font-size: ${fontSize.xsmall};
  line-height: 1.8;
  padding: ${spacing.xsmall} ${spacing.default};
  border-bottom: 1px solid ${color.grayBackground};
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: ${color.white};
`;

const HeaderTooltip = styled(Tooltip).attrs(
  // Needed to align the tooltip with the button
  { offset: { bottom: -6 } },
)``;

const bodyAnimation = {
  enter: { height: 'auto' },
  exit: { height: 0 },
};

const AnimatedContainer = styled(motion.div).attrs({
  key: 'support-chat',
  initial: 'exit',
  transition: { duration: 0.2, ease: 'easeOut' },
  variants: bodyAnimation,
})``;

const ChatWidgetIframe = styled.iframe.attrs({
  title: 'Support Chat',
  src: SUPPORT_CHAT_IFRAME_SRC,
  frameBorder: 0,
  width: '100%',
  height: '100%',
})`
  /* Needed to align the iframe with our chat container. */
  margin-bottom: -0.8rem;
`;

const ContactSupportChat = () => {
  const [isExpanded, { toggle: toggleChatExpanded }] = useToggle(true);
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);

  const { endSupportChat, openContactSupportModal, openEndChatConfirmation } =
    useContactSupportContext();

  const onClickEndChat = useCallback(
    (e: React.SyntheticEvent<Element, Event>) => {
      e.stopPropagation();
      openEndChatConfirmation();
    },
    [openEndChatConfirmation],
  );

  const onClickGetSupportFromHuman = useCallback(() => {
    openContactSupportModal();
    endSupportChat();
  }, [openContactSupportModal, endSupportChat]);

  useTrack(AnalyticsEventNames.SupportChatModalViewed);

  return (
    <Root>
      <Header onClick={toggleChatExpanded}>
        <span>Support Chat</span>
        <FlexContainer gap="xxsmall">
          {/* The onClick will be propagated to the parent element, which will close the chat */}
          {isExpanded && <HeaderButton icon="minus" />}
          <HeaderTooltip content="End chat">
            <HeaderButton icon="x" onClick={onClickEndChat} />
          </HeaderTooltip>
        </FlexContainer>
      </Header>
      <AnimatedContainer animate={isExpanded ? 'enter' : 'exit'}>
        <Body>
          <Disclaimer>
            Our AI support agent is trained on our entire{' '}
            <Link href={HELP_CENTER_URL} target="_blank">
              Help Center
            </Link>
            . It can answer many of your questions instantly, but if you&apos;d like, you can always{' '}
            <Link onClick={onClickGetSupportFromHuman}>get support from a human.</Link>
          </Disclaimer>
          <ChatWidgetIframe onLoad={() => setIsWidgetLoaded(true)} />
          {!isWidgetLoaded && (
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          )}
        </Body>
      </AnimatedContainer>
    </Root>
  );
};

export default ContactSupportChat;
