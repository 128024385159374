import * as RA from 'ramda-adjunct';
import React from 'react';
import Helmet from 'react-helmet';
import styled, { css } from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import Flex from 'components/lib/ui/Flex';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import PageHeader from 'components/lib/ui/PageHeader';
import Scroll from 'components/lib/ui/Scroll';
import type { ScrollHandles } from 'components/lib/ui/Scroll';
import { HEADER_HEIGHT } from 'components/routes/Header';

import useNavigationTracking from 'lib/hooks/useNavigationTracking';
import useRestoreScrollPosition from 'lib/hooks/useRestoreScrollPosition';
import type { ScrollOffset } from 'state/scroll/reducer';

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.color.grayBackground};
  overflow-x: auto;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    min-width: 100vw;
  }
`;

const LoadingContainer = styled(Flex).attrs({ center: true })`
  flex: 1;
  height: 100%;
`;

const ScrollHeaderContainer = styled.div<{ $disabled: boolean }>`
  position: absolute;
  width: 100%;
  top: 0;

  ${({ $disabled }) =>
    $disabled &&
    css`
      @media (min-width: ${({ theme }) => theme.breakPoints.md}px) {
        opacity: 0.4;
        pointer-events: none;
      }
    `}
`;

const DisabledContainer = styled(ScrollHeaderContainer)`
  position: inherit;
`;

const ScrollHeaderSpacer = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT};
`;

const NonScrollContainer = styled.div<{ disabled: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

export type Props = {
  icon?: React.ReactNode;
  /** The name of the page, used for event tracking. Should not change. */
  name: string;
  /** Dynamic title to override the page. Used just for display. */
  overrideTitle?: string;
  controls?: React.ReactNode;
  /** Tabs component to show when page has sub routes. */
  tabs?: React.ReactNode;
  overlayEmptyComponent?: React.ReactNode;
  shouldScroll?: boolean;
  initialScrollOffset?: ScrollOffset;
  /** Use this to reset scroll to zero */
  scrollKey?: string;
  scrollRef?: React.RefObject<ScrollHandles>;
  showHeader?: boolean;
  /** Custom header component. If specified, will override default <Header> and related props. */
  header?: React.ReactNode;
  isLoading?: boolean;
  /** Custom component to show when loading. Defaults to loading spinner in center of page. */
  loadingComponent?: React.ReactNode;
  children: React.ReactNode;

  titleIsSensitive?: boolean;
  className?: string;
};

const Page: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    icon,
    name,
    overrideTitle,
    controls,
    children,
    titleIsSensitive,
    overlayEmptyComponent,
    tabs,
    shouldScroll = true,
    initialScrollOffset: initialScrollProp,
    scrollKey,
    scrollRef,
    showHeader = true,
    header: customHeader,
    isLoading = false,
    loadingComponent,
    className,
  }: Props,
  ref,
) => {
  const pageTitle = `Monarch | ${overrideTitle ?? name}`;
  useNavigationTracking(name, pageTitle);

  const [initialScrollOffset, onScroll] = useRestoreScrollPosition();

  const header = customHeader ?? (
    <PageHeader
      icon={icon}
      name={name}
      overrideTitle={overrideTitle}
      controls={controls}
      tabs={tabs}
      titleIsSensitive={titleIsSensitive}
    />
  );

  const isNotScrollPage = !shouldScroll;
  const isPageDisabled = RA.isNotNil(overlayEmptyComponent);

  return (
    <Root ref={ref} className={className}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {isNotScrollPage && showHeader && (
        <DisabledContainer $disabled={isPageDisabled}>{header}</DisabledContainer>
      )}
      <Switch>
        <Case when={isLoading}>
          {loadingComponent ?? (
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          )}
        </Case>
        <Case when={shouldScroll}>
          {showHeader && <ScrollHeaderSpacer />}

          <Scroll
            ref={scrollRef}
            disabled={isPageDisabled}
            onScroll={onScroll}
            initialOffset={initialScrollProp || initialScrollOffset}
            key={scrollKey}
          >
            {children}
          </Scroll>

          {showHeader && (
            <ScrollHeaderContainer $disabled={isPageDisabled}>{header}</ScrollHeaderContainer>
          )}
        </Case>
        <Case default>
          <NonScrollContainer disabled={isPageDisabled}>{children}</NonScrollContainer>
        </Case>
      </Switch>
      {overlayEmptyComponent}
    </Root>
  );
};

export default React.forwardRef(Page);
