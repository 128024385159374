export const getProgressBarMax = (actual: number, budgeted: number) => {
  if (actual < 0) {
    return 0; // If the actual is negative, we want to show the progress bar as 0%
  }

  if (actual === 0) {
    return 1; // This will show an empty progress bar
  }

  return budgeted;
};
