import styled from 'styled-components';

const Shield = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) =>
    theme.uiTheme === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 40, 100, 0.4)'};
`;

export default Shield;
