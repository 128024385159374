import React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';

const Root = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.color.yellowFocus};
  color: ${({ theme }) => theme.color.yellowText};
  border-radius: ${({ theme }) => theme.radius.small};
  padding: ${({ theme }) => theme.spacing.xxsmall} ${({ theme }) => theme.spacing.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  box-shadow: ${({ theme }) => theme.shadow.small};
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
  height: 24px;
`;

const StyledIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing.xxsmall};
`;

const Title = styled.span`
  white-space: nowrap;
`;

type Props = {
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
};

const PremiumBadge = ({ children, ...props }: Props) => (
  <Root {...props}>
    <StyledIcon name="diamond" size={12} />
    <Title>{children ?? 'Premium'}</Title>
  </Root>
);

export default PremiumBadge;
