import * as RA from 'ramda-adjunct';

import { getColorForAvailableAmount } from 'common/lib/budget/Amounts';
import type { PlanAmounts as PlanAmountsImport } from 'common/lib/planning';
import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';

import { CategoryGroupType } from 'common/generated/graphql';

export type PlanAmountType = 'budgeted' | 'actual' | 'remaining';

// Convenience export
export type PlanAmounts = PlanAmountsImport;

export const SAVINGS_PLAN_SECTION_TYPE = 'savings';

export type PlanSectionType = CategoryGroupType | typeof SAVINGS_PLAN_SECTION_TYPE;

/**
 * Convert between amount type and user visible string name.
 */
export const getDisplayNameForAmountType = (amountType: PlanAmountType) =>
  ({
    budgeted: 'Budget',
    actual: 'Actual',
    remaining: 'Remaining',
  })[amountType];

/**
 * Calculate remaining amount if needed, otherwise return amount matching key.
 */
export const getAmountForType = (amounts: PlanAmounts | undefined, amountType: PlanAmountType) => {
  if (amountType === 'remaining') {
    if (RA.isNotNil(amounts?.remaining)) {
      // Rollovers are enabled, so remaining amount comes from backend
      return amounts?.remaining;
    } else {
      // Rollovers are disabled, so remaining amount is calculated from budgeted and actual (old way)
      const budgeted = amounts?.budgeted ?? 0;
      const actual = amounts?.actual ?? 0;
      return budgeted - actual;
    }
  } else {
    return amounts?.[amountType] ?? undefined;
  }
};

export const getRemainingColorForAmounts = (
  amounts: PlanAmounts | undefined,
  sectionType: PlanSectionType,
) => {
  const available = Math.round(getAmountForType(amounts, 'remaining') ?? 0);
  const categoryType = sectionType === 'savings' ? CategoryGroupType.INCOME : sectionType;
  return getColorForAvailableAmount(available, categoryType);
};

export const getSectionHasBottomPadding = (
  timeframe: PlanTimeframe,
  unplannedCount: number,
  isCollapsed: boolean,
  groupLevelBudgetingEnabled: boolean | null | undefined,
) =>
  timeframe === PlanTimeframe.SingleMonth &&
  unplannedCount <= 0 &&
  !isCollapsed &&
  !groupLevelBudgetingEnabled;
