import { darken } from 'polished';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import BarChart from 'components/lib/charts/BarChart';

import { spacing } from 'common/lib/theme/dynamic';
import { formatCurrencyThousandsDecimal } from 'common/utils/Currency';
import type { KeyOfThemeProp } from 'lib/hooks/useTheme';
import useTheme from 'lib/hooks/useTheme';

const CHART_HEIGHT_PX = 124;
const BAR_WIDTH_PX = 24;

export type BudgetHistoricalAmountBar = {
  sum: number;
  month: string;
};

const Root = styled.div<{ $barColor: string }>`
  padding-top: ${spacing.small};

  .recharts-bar-rectangles .recharts-rectangle {
    &:hover {
      fill: ${({ $barColor }) => darken(0.04, $barColor)};
      cursor: pointer;
    }
  }
`;

type Props = {
  width: number;
  data: BudgetHistoricalAmountBar[];
  color: KeyOfThemeProp<'color'>;
  onClickBar: (amount: number) => void;
};

const BudgetHistoricalAmountsBarChart = ({ width, data, color = 'gray', onClickBar }: Props) => {
  const theme = useTheme();
  const tickStyle = useMemo(
    () => ({
      fontSize: 11,
      fontWeight: theme.fontWeight.bold,
      letterSpacing: '0.08em',
    }),
    [theme],
  );

  const barDataKeys = useMemo(
    () =>
      data.map(() => ({
        name: 'sum',
        color: theme.color[color],
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.length],
  );

  return (
    <Root $barColor={theme.color[color]}>
      <BarChart
        data={data}
        xDataKey="month"
        yDataKey="sum"
        width={width}
        height={CHART_HEIGHT_PX}
        barWidth={BAR_WIDTH_PX}
        barDataKeys={barDataKeys}
        yAxisFormatter={formatCurrencyThousandsDecimal}
        onClickBar={(value) => onClickBar(value.sum)}
        // All styling-related props are defined below
        dashed={false}
        margin={{ right: 12, bottom: 0, left: -12 }}
        yAxisProps={{
          tickLine: false,
          axisLine: false,
        }}
        xAxisProps={{
          tickLine: false,
          axisLine: true,
          stroke: theme.color.grayFocus,
        }}
        cartesianGridProps={{
          stroke: theme.color.grayFocus,
        }}
        customTickStyle={tickStyle}
        tickMargin={4}
        disableAnimation
        roundBarCorners
      />
    </Root>
  );
};

export default BudgetHistoricalAmountsBarChart;
