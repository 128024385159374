import type { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import MoveMoney from 'components/budget/MoveMoney';
import AmountPill from 'components/lib/ui/AmountPill';
import Tooltip from 'components/lib/ui/Tooltip';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';
import PlanAmountsTooltip, {
  WIDTH_PX as TOOLTIP_WIDTH_PX,
} from 'components/plan/PlanAmountsTooltip';

import { formatCurrencyNoCents } from 'common/utils/Currency';
import type { PlanAmounts } from 'lib/plan';
import { SAVINGS_PLAN_SECTION_TYPE } from 'lib/plan';
import type { PlanSectionType } from 'lib/plan/Adapters';

import { CategoryGroupType } from 'common/generated/graphql';

const StyledAmountPill = styled(AmountPill)<{ _header: boolean }>`
  font-size: ${({ _header, theme }) => (_header ? theme.fontSize.large : theme.fontSize.base)};
  margin-right: -${({ theme }) => theme.spacing.small};
`;

type Props = {
  date: DateTime;
  amounts: PlanAmounts | undefined;
  sectionType: PlanSectionType;
  itemId?: string;
  itemName?: string;
  itemIcon?: React.ReactNode | string;
  isAggregate?: boolean;
  isCategoryGroup?: boolean;
  showTooltip: boolean;
  refetch: () => void;
  availableDisplayAmount: number;
  isMovable: boolean;
  showRolloverIcon: boolean;
};

const PlanCellMoveMoneyPill = ({
  date,
  amounts,
  sectionType,
  itemId,
  itemName,
  itemIcon,
  showTooltip = false,
  isAggregate = false,
  isCategoryGroup = false,
  refetch,
  availableDisplayAmount,
  isMovable,
  showRolloverIcon,
}: Props) => (
  <OverlayTrigger
    placement="bottom-end"
    overlay={({ close }) => (
      <Popover>
        <MoveMoney
          budgetStartDate={date}
          onDone={async () => {
            await refetch();
            close();
          }}
          initialCategoryId={itemId}
          isGroup={isCategoryGroup}
          categoryType={
            sectionType === SAVINGS_PLAN_SECTION_TYPE ? CategoryGroupType.INCOME : sectionType
          }
        />
      </Popover>
    )}
  >
    {({ toggleOpen, isOpen }) => (
      <Tooltip
        portal
        maxWidth={TOOLTIP_WIDTH_PX}
        content={
          showTooltip && amounts ? (
            <PlanAmountsTooltip title={`${itemIcon ?? ''} ${itemName}`} amounts={amounts} />
          ) : null
        }
        opacity={1}
      >
        <div>
          <StyledAmountPill
            value={(() => {
              const displayAmount = Math.round(availableDisplayAmount);

              // We round here to match the tooltip (i.e. the remaining amount is exactly 0.50).
              return displayAmount;
            })()}
            formatter={formatCurrencyNoCents}
            onClick={isMovable ? toggleOpen : undefined}
            icon={showRolloverIcon ? 'rotate-cw' : undefined}
            active={isOpen}
            {...(sectionType === SAVINGS_PLAN_SECTION_TYPE
              ? {
                  positiveBackgroundColor: 'blueBackground',
                  positiveTextColor: 'blue',
                  negativeBackgroundColor: 'grayFocus',
                  negativeTextColor: 'textLight',
                }
              : {})}
            _header={isAggregate}
          />
        </div>
      </Tooltip>
    )}
  </OverlayTrigger>
);

export default React.memo(PlanCellMoveMoneyPill);
