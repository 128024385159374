import * as R from 'ramda';
import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import PageIndicatorDots from 'components/lib/ui/PageIndicatorDots';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import useProfileFlag from 'common/lib/hooks/users/useProfileFlag';
import { CATEGORIES_TOUR_STEPS } from 'lib/categories/Tour';
import useModal from 'lib/hooks/useModal';

const MAX_IMAGE_HEIGHT = Math.max(...CATEGORIES_TOUR_STEPS.map(({ image: { height } }) => height));
const IMAGE_WIDTH_PX = 516;
const MOBILE_IMAGE_SCALE = 0.65;

const ModalCardWithPadding = styled(ModalCard)`
  padding-top: ${({ theme }) => theme.spacing.xsmall};
`;

const ModalBody = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: 0;
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.color.text};
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-top: -${({ theme }) => theme.spacing.xxsmall};
`;

const ImageContainer = styled(FlexContainer).attrs({ center: true })`
  height: ${MAX_IMAGE_HEIGHT}px;
  margin: ${({ theme }) => theme.spacing.xlarge} 0;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    height: calc(${MAX_IMAGE_HEIGHT}px * ${MOBILE_IMAGE_SCALE});
    margin: ${({ theme }) => theme.spacing.xsmall} 0;
  }
`;

const Image = styled.img<{ $height: number }>`
  flex-shrink: 0;
  width: ${IMAGE_WIDTH_PX}px;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    height: calc(${({ $height }) => $height}px * ${MOBILE_IMAGE_SCALE});
    width: auto;
  }
`;

const CategoriesTourOverlay = () => {
  const [hasSeenCategoriesTour, didSeeCategoriesTour] = useProfileFlag(
    'hasSeenCategoriesManagementTour',
  );
  const [Modal, { open, isOpen, close }] = useModal();
  const [step, setStep] = useState(0);
  const { title, subtitle, image } = CATEGORIES_TOUR_STEPS[step];

  useEffect(() => {
    if (hasSeenCategoriesTour === false) {
      open();
    }
  }, [hasSeenCategoriesTour, open]);

  const incrementStep = useCallback(
    () => (step === CATEGORIES_TOUR_STEPS.length - 1 ? close() : setStep(R.add(1))),
    [step, setStep, close],
  );

  return isOpen ? (
    <Modal onClose={() => didSeeCategoriesTour()}>
      <ModalCardWithPadding title={title} description={<Subtitle>{subtitle}</Subtitle>}>
        <ModalBody>
          <ImageContainer>
            <Image alt={title} src={image.url} $height={image.height} />
          </ImageContainer>
        </ModalBody>
        <CardFooter justifyBetween>
          <DefaultButton onClick={close}>Skip</DefaultButton>
          <PageIndicatorDots
            count={CATEGORIES_TOUR_STEPS.length}
            currentIndex={step}
            onChangeIndex={setStep}
          />
          <PrimaryButton onClick={incrementStep}>Next</PrimaryButton>
        </CardFooter>
      </ModalCardWithPadding>
    </Modal>
  ) : null;
};

export default CategoriesTourOverlay;
