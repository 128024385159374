import React, { useMemo } from 'react';
import styled from 'styled-components';

import placeholderSankeyData from 'components/cashFlows/__mocks__/sankey.json';
import SankeyDiagram from 'components/lib/charts/SankeyDiagram';
import FlexContainer from 'components/lib/ui/FlexContainer';

import useTheme from 'lib/hooks/useTheme';

import * as COPY from 'common/constants/copy';

const Root = styled.div`
  position: relative;
`;

const CenterContainer = styled(FlexContainer).attrs({ center: true })`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const PlaceholderBanner = styled(FlexContainer).attrs({ center: true })`
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.xlarge};
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  border-radius: ${({ theme }) => theme.radius.pill};
  background: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.shadow.medium};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

type Props = {
  width?: number;
  text?: string;
};

const CashFlowSankeyPlaceholder = ({ width = 0, text = COPY.CASH_FLOW.SANKEY_EMPTY }: Props) => {
  const theme = useTheme();

  const placeholderData = useMemo(
    () => ({
      nodes: placeholderSankeyData.nodes.flatMap((node) => ({
        ...node,
        color: theme.color.grayLight,
      })),
      links: placeholderSankeyData.links,
    }),
    [theme.color.grayLight],
  );

  return (
    <Root>
      <SankeyDiagram data={placeholderData} width={width} formatValue={() => ''} />
      <CenterContainer>
        <PlaceholderBanner>{text}</PlaceholderBanner>
      </CenterContainer>
    </Root>
  );
};

export default React.memo(CashFlowSankeyPlaceholder);
