import type { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import type { Column } from 'react-table';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import Table from 'components/lib/ui/table/Table';
import RecurringAccount from 'components/recurring/RecurringAccount';
import RecurringCategory from 'components/recurring/RecurringCategory';
import RecurringStream from 'components/recurring/RecurringStream';
import RecurringTableLoading from 'components/recurring/RecurringTableLoading';
import RecurringAmountAndMenu from 'components/recurring/new/RecurringAmountAndMenu';

import { formatNextPaymentDate, getFrequencyLabel, sortItems } from 'common/lib/recurring';
import type { RecurringTransactionItem } from 'common/lib/recurring/types';
import { spacing } from 'common/lib/theme/dynamic';

import * as COPY from 'common/constants/copy';

type ColumnConfig = Column<RecurringTransactionItem>[];
type ItemCell = { row: { original: RecurringTransactionItem } };

const StyledTable: typeof Table = styled(Table)`
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
`;

const AmountTitle = styled(Text)`
  padding-right: 52px;
`;

const PaymentDateLabel = styled(Text)<{ $late: boolean }>`
  color: ${({ $late, theme }) => ($late ? theme.color.orange : theme.color.text)};
  font-weight: ${({ $late, theme }) => ($late ? theme.fontWeight.medium : theme.fontWeight.book)};
`;

const InfoIcon = styled(Icon).attrs({ name: 'info', size: 14 })`
  display: inline-block;
  margin-left: ${spacing.xxsmall};
  cursor: default;
`;

const GroupHeader = ({ header, tooltip }: { header: string; tooltip: string }) => (
  <Flex row alignCenter>
    {header}
    <Tooltip portal content={tooltip}>
      <div>
        <InfoIcon />
      </div>
    </Tooltip>
  </Flex>
);

type Props = {
  title: string;
  items?: RecurringTransactionItem[];
  isLoading: boolean;
  startDate: DateTime;
  refetch: () => void;
};

// dup of RecurringUpcomingTable to be used when the FeatureFlag is enabled
const RecurringUpcomingTable = ({ title, items, isLoading, startDate, refetch }: Props) => {
  // @ts-ignore issue with the accessors and the type of the data
  const columns: ColumnConfig = useMemo(
    () => [
      {
        id: 'Merchant',
        Header: () => <Text size="large">{title}</Text>,
        disableSortBy: true,
        accessor: ({ stream }) => stream?.name, // for sorting
        Cell: ({
          row: {
            original: { stream },
          },
        }: ItemCell) => (
          <RecurringStream
            id={stream.merchant?.id ?? stream.creditReportLiabilityAccount?.account?.id ?? ''}
            name={stream?.name ?? ''}
            frequency={getFrequencyLabel(stream.frequency)}
            logoUrl={stream.logoUrl}
            startDate={startDate}
            isMerchant={!!stream.merchant}
          />
        ),
      },
      {
        Header: 'Payment Date',
        accessor: ({ date }) => date,
        Cell: ({
          row: {
            original: { isPast, markedPaidAt, date, stream },
          },
        }: ItemCell) => (
          <PaymentDateLabel
            $late={isPast && !!stream.creditReportLiabilityAccount && !markedPaidAt}
          >
            {formatNextPaymentDate(date, true)}
          </PaymentDateLabel>
        ),
      },
      {
        Header: 'Payment Account',
        accessor: ({ account }) => account?.displayName, // for sorting
        Cell: ({
          row: {
            original: { account },
          },
        }: ItemCell) => (account ? <RecurringAccount account={account} /> : '-'),
      },
      {
        Header: 'Category',
        accessor: ({ category }) => category?.name, // for sorting
        Cell: ({
          row: {
            original: { category },
          },
        }: ItemCell) =>
          category ? <RecurringCategory category={category} startDate={startDate} /> : '-',
      },
      {
        id: 'Amount',
        Header: () => <AmountTitle>Amount</AmountTitle>,
        // This treats a null / missing value as an undefined everywhere downstream in the UI.
        // this works well today, but if we ever want to differentiate / do something with the
        // "missing value" state, we'll want to propagate the null union instead
        accessor: ({ amount }) => amount ?? undefined,
        Cell: ({
          value,
          row: {
            original: {
              isPast,
              stream,
              markedPaidAt,
              creditReportLiabilityStatementId,
              isAmountDifferentThanOriginal,
            },
          },
        }: { value: number | undefined } & ItemCell) => (
          <RecurringAmountAndMenu
            streamId={stream.id}
            amount={value}
            isPast={isPast}
            isApproximate={stream.isApproximate}
            hasMerchant={!!stream?.merchant}
            hasCreditReportLiabilityAccount={!!stream?.creditReportLiabilityAccount}
            liabilityType={stream?.creditReportLiabilityAccount?.liabilityType}
            markedPaidAt={markedPaidAt}
            creditReportLiabilityStatementId={creditReportLiabilityStatementId}
            accountId={stream?.creditReportLiabilityAccount?.account?.id}
            isPaid={!!markedPaidAt}
            merchantId={stream?.merchant?.id}
            name={stream.name}
            startDate={startDate}
            refetch={refetch}
            isAmountDifferentThanOriginal={!!isAmountDifferentThanOriginal}
          />
        ),
      },
    ],
    [startDate, title, refetch],
  );

  const columnNames = ['Merchant', 'Payment Date', 'Payment Account', 'Category', 'Amount'];
  const sortedItems = useMemo(() => (items ? sortItems(items) : []), [items]);

  return !isLoading ? (
    <StyledTable
      collapsible
      defaultAlignment="left"
      showFooter={false}
      columns={columns}
      data={sortedItems}
      groupByFn={(items) => {
        const merchantItems = items.filter((item) => item.original.stream?.merchant);
        const accountItems = items.filter(
          (item) => item.original.stream?.creditReportLiabilityAccount,
        );

        return [
          {
            header: (
              <GroupHeader header="Accounts" tooltip={COPY.RECURRING.RECURRING_ACCOUNT_TOOLTIP} />
            ),
            key: 'Accounts',
            rows: accountItems,
          },
          {
            header: (
              <GroupHeader header="Merchants" tooltip={COPY.RECURRING.RECURRING_MERCHANT_TOOLTIP} />
            ),
            key: 'Merchants',
            rows: merchantItems,
          },
        ];
      }}
    />
  ) : (
    <RecurringTableLoading columnNames={columnNames} />
  );
};

export default RecurringUpcomingTable;
