import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Card from 'components/lib/ui/Card';
import Drawer from 'components/lib/ui/Drawer';
import ExpandMoreIconButton from 'components/lib/ui/ExpandMoreIconButton';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { spacing } from 'common/lib/theme/dynamic';

export const ROW_HEIGHT_PX = 56;
const SHADOW_WIDTH_PX = 8;
const ICON_CONTAINER_SIZE_PX = 48;

// Card with heavier shadow to be used on white background
export const CardWithHeavyShadow = styled(Card)`
  box-shadow: ${({ theme }) => theme.shadow.medium};
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  background: ${({ theme }) => theme.color.white};
  width: 100%;
`;

export const Left = styled.div<{ $rightShadow?: boolean; $full?: boolean }>`
  width: ${({ $full }) => ($full ? '100%' : '300px')};
  display: flex;
  height: 100%;

  ${({ $rightShadow }) =>
    $rightShadow &&
    css`
      border-right: 1px solid ${({ theme }) => theme.color.grayFocus};
      box-shadow: 0 0 ${SHADOW_WIDTH_PX}px ${({ theme }) => theme.color.grayFocus};
      clip-path: inset(0px -${SHADOW_WIDTH_PX}px 0px 0px);
    `}
`;

export const Right = styled.div`
  width: 300px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing.small};
`;

export const CardRow = styled(FlexContainer).attrs({ justifyBetween: true })<{
  $withTopBorder?: boolean;
}>`
  height: ${ROW_HEIGHT_PX}px;

  ${({ $withTopBorder, theme }) =>
    $withTopBorder &&
    css`
      border-top: 1px solid ${theme.color.grayBackground};
    `}
`;

const CardTitle = styled.div`
  display: flex;
  width: 100%;

  padding: ${spacing.small};
  padding-left: 0;
`;

const IconContainer = styled(FlexContainer).attrs({ center: true })`
  width: ${ICON_CONTAINER_SIZE_PX}px;
  text-align: center;
  flex-shrink: 0;
  padding-left: ${({ theme }) => theme.spacing.xxsmall};
`;

type CardProps = {
  title: React.ReactNode;
  titleAccessory?: React.ReactNode;
  rightNode?: React.ReactNode;
  children?: React.ReactNode;
};

const OnboardingCard = ({ title, titleAccessory, rightNode, children }: CardProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <CardWithHeavyShadow>
      <CardRow>
        <Left $rightShadow={!!rightNode} $full={!rightNode}>
          <CardTitle>
            <IconContainer>
              <ExpandMoreIconButton
                onClick={() => setIsCollapsed(!isCollapsed)}
                expanded={!isCollapsed}
              />
            </IconContainer>
            <FlexContainer justifyBetween full alignCenter>
              <Text weight="medium">{title}</Text>

              {titleAccessory}
            </FlexContainer>
          </CardTitle>
        </Left>

        {rightNode && <Right>{rightNode}</Right>}
      </CardRow>
      <Drawer open={!isCollapsed} shouldAnimateOnMount={false}>
        {children}
      </Drawer>
    </CardWithHeavyShadow>
  );
};

export default OnboardingCard;
