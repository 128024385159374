import * as RA from 'ramda-adjunct';
import React from 'react';

import AmountsTooltip from 'components/plan/AmountsTooltip';

import type { PlanAmounts } from 'lib/plan';
import { getAmountForType } from 'lib/plan';

export const WIDTH_PX = 300;

type Props = {
  amounts: PlanAmounts;
  title?: string;
};

const PlanAmountsTooltip = ({ amounts, title }: Props) => (
  <AmountsTooltip
    title={title}
    rows={[
      ...(RA.isNotNil(amounts.rolloverType) || !!amounts.rollover
        ? [
            ...(RA.isNotNil(amounts.rolloverStartingBalance) &&
            RA.isPositive(amounts.rolloverStartingBalance)
              ? [
                  {
                    title: 'Starting balance',
                    value: amounts.rolloverStartingBalance ?? 0,
                    type: 'income' as const,
                    emphasis: true,
                  },
                ]
              : []),
            {
              title: 'Rollover from last month',
              value: amounts.rollover ?? 0,
              type: 'income' as const,
              emphasis: true,
            },
          ]
        : []),
      { title: 'Budget', value: getAmountForType(amounts, 'budgeted') },
      { title: 'Actual', value: getAmountForType(amounts, 'actual') },
      {
        title: 'Remaining',
        value: getAmountForType(amounts, 'remaining') ?? 0,
        type: 'income',
        emphasis: true,
      },
    ]}
  />
);

export default PlanAmountsTooltip;
