import type { MutationHookOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type { DateTime } from 'luxon';
import { useCallback } from 'react';

import { UPDATE_OR_CREATE_GOAL_PLANNED_CONTRIBUTION_MUTATION } from 'common/lib/graphQl/goals';

import type {
  UpdateOrCreateGoalPlannedContributionMutation,
  UpdateOrCreateGoalPlannedContributionMutationVariables,
} from 'common/generated/graphQlTypes/UpdateOrCreateGoalPlannedContributionMutation';

const useUpdateGoalPlannedContribution = (
  options?: MutationHookOptions<
    UpdateOrCreateGoalPlannedContributionMutation,
    UpdateOrCreateGoalPlannedContributionMutationVariables
  >,
) => {
  const [updateOrCreateGoalPlannedContribution] = useMutation<
    UpdateOrCreateGoalPlannedContributionMutation,
    UpdateOrCreateGoalPlannedContributionMutationVariables
  >(UPDATE_OR_CREATE_GOAL_PLANNED_CONTRIBUTION_MUTATION, options);

  const updateGoalPlannedContribution = useCallback(
    async (date: DateTime, goalId: string, amount: number | null) => {
      await updateOrCreateGoalPlannedContribution({
        variables: {
          input: {
            startDate: date.toISODate(),
            goalId,
            amount: amount ?? 0,
          },
        },
      });
    },
    [updateOrCreateGoalPlannedContribution],
  );

  return { updateGoalPlannedContribution };
};

export default useUpdateGoalPlannedContribution;
