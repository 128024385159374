import { rgba, transparentize } from 'polished';
import * as R from 'ramda';
import * as React from 'react';
import type {
  CartesianGridProps,
  LegendProps,
  TickFormatterFunction,
  TooltipProps,
  XAxisProps,
  YAxisProps,
  YPadding,
} from 'recharts';
import { Area, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';

import CartesianGrid from 'components/lib/charts/CartesianGrid';
import type { TooltipComponentProps } from 'components/lib/charts/ChartTooltip';
import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import THEME from 'common/lib/theme';
import useTheme from 'lib/hooks/useTheme';

export const DEFAULT_STROKE_WIDTH_PX = 4;
export const DEFAULT_TICK_FONT_SIZE = 12;
const DEFAULT_AXIS_FONT_WEIGHT = parseInt(THEME.fontWeight.medium, 10);
const TICK_PADDING_PX = parseInt(THEME.spacing.xsmall, 10);

const Root = styled.div`
  position: relative;
`;

const StyledComposedChart = styled(ComposedChart)`
  /* Hide last horizontal line of cartesian grid (so the XAxis border isn't overlapped by it) */
  g.recharts-cartesian-grid-horizontal > line:first-child {
    stroke-opacity: 0;
  }

  .recharts-tooltip-wrapper {
    /** We can't adjust the index by just editing our tooltip */
    z-index: 1; /* stylelint-disable-line plugin/no-z-index */
  }

  transition: width 1s;
`;

const SpinnerContainer = styled(FlexContainer).attrs({ center: true })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => transparentize(0.5, theme.color.white)};
  /* the value below roughly matches the height of XAxis */
  padding-bottom: 40px;
`;

export type LineOptions = {
  lineColor: string;
  isDashed?: boolean;
  hasArea?: boolean;
  legendName?: string;
  areaColor?: string;
  showDots?: boolean;
  strokeWidth?: number;
};

export type MultipleLineChartProps<XAxisT, YAxisT, DataT extends Record<string, unknown>> = {
  yDataKeys: readonly YAxisT[];
  lineOptions: LineOptions[];

  xDataKey: XAxisT;
  data: readonly DataT[];
  domain?: YAxisProps['domain'];

  height: number;
  width: number;
  hideActiveDot?: boolean;
  tooltipComponent?: TooltipComponentProps<XAxisT, YAxisT, DataT>;
  margin?: { left?: number; right?: number; top?: number; bottom?: number };
  formatYAxis: TickFormatterFunction;
  formatXAxis: TickFormatterFunction;
  onClickDot?: (data: any) => void;
  onAnimationEnd?: () => void;
  isAnimationActive?: boolean;
  isLoadingNewData?: boolean;

  // This is an escape hatch, if you want to add other custom elements to the chart, like
  // references, etc.
  children?: React.ReactNode;

  yPadding?: YPadding;

  // Pass through props for overriding
  gridProps?: CartesianGridProps;
  tooltipProps?: TooltipProps;
  xAxisProps?: XAxisProps;
  yAxisProps?: YAxisProps;
  legendProps?: LegendProps;

  // Use this prop if you want a reference line behind the lines.
  referenceLine?: React.ReactNode;

  smoothGradient?: boolean;
};

const MultipleLineChart = <
  XAxisT extends string,
  YAxisT extends string,
  DataT extends { [x in XAxisT]: string | number } & { [y in YAxisT]?: number | null },
>({
  height,
  width,
  data,
  lineOptions,
  tooltipComponent,
  yDataKeys,
  xDataKey,
  margin = {},
  formatYAxis,
  formatXAxis,
  children,
  hideActiveDot,
  onClickDot,
  onAnimationEnd,
  isAnimationActive,
  isLoadingNewData,
  yPadding,
  domain,
  gridProps,
  tooltipProps,
  xAxisProps,
  yAxisProps,
  legendProps,
  referenceLine,
  smoothGradient,
}: MultipleLineChartProps<XAxisT, YAxisT, DataT>) => {
  const theme = useTheme();
  const activeDot = onClickDot ? { onClick: onClickDot, cursor: 'pointer' } : undefined;

  const areaChartColorByLineOptions: Record<number, string> = lineOptions.reduce(
    (acc, { lineColor, areaColor }, index) => ({
      ...acc,
      [index]: areaColor ?? lineColor,
    }),
    {},
  );

  const gradientOpacityMap = smoothGradient
    ? {
        0: 0.5,
        0.6: 0.25,
        1: 0.1,
      }
    : {
        0: 1,
        0.6: 0.7,
        1: 0.8,
      };

  return (
    <Root>
      <StyledComposedChart
        width={width}
        height={height}
        data={data}
        margin={R.mergeLeft(margin, { top: DEFAULT_TICK_FONT_SIZE })}
        {...maskClassProps}
      >
        <CartesianGrid dashed {...gridProps} />
        {tooltipComponent && (
          <Tooltip cursor={false} content={tooltipComponent} {...tooltipProps} />
        )}
        <XAxis
          tick={{
            fontSize: DEFAULT_TICK_FONT_SIZE,
            fontWeight: DEFAULT_AXIS_FONT_WEIGHT,
            fill: theme.color.textLight,
            transform: `translate(0, ${TICK_PADDING_PX})`,
          }}
          stroke={theme.color.gray}
          tickFormatter={formatXAxis}
          dataKey={xDataKey}
          {...xAxisProps}
        />
        <YAxis
          tick={{
            fontSize: DEFAULT_TICK_FONT_SIZE,
            fontWeight: DEFAULT_AXIS_FONT_WEIGHT,
            fill: theme.color.textLight,
            transform: `translate(${TICK_PADDING_PX * -1}, 0)`,
          }}
          stroke={theme.color.gray}
          tickFormatter={formatYAxis}
          padding={yPadding}
          domain={domain}
          {...yAxisProps}
        />
        <Legend
          verticalAlign="bottom"
          wrapperStyle={{
            paddingTop: '15px',
            fontSize: theme.fontSize.xxsmall,
            fontWeight: theme.fontWeight.bold,
            color: theme.color.textLight,
          }}
          {...legendProps}
        />

        {referenceLine}

        {Object.entries(areaChartColorByLineOptions).map(([index, color]) => (
          <defs key={index}>
            <linearGradient id={`area${index}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={color} stopOpacity={gradientOpacityMap[0]} />
              <stop offset="60%" stopColor={color} stopOpacity={gradientOpacityMap[0.6]} />
              <stop
                offset="100%"
                stopColor={theme.uiTheme === 'light' ? theme.color.white : theme.color.black}
                stopOpacity={gradientOpacityMap[1]}
              />
            </linearGradient>
          </defs>
        ))}

        {/* For keys with an Area, we render both an Area and a Line, this allows us to stack things in the right order */}
        {yDataKeys.map(
          (yDataKey, index) =>
            lineOptions[index].hasArea && (
              <Area
                isAnimationActive={isAnimationActive}
                key={yDataKey}
                stroke={lineOptions[index].lineColor}
                strokeWidth={0}
                fill={`url(#area${index})`}
                legendType="none"
                dataKey={yDataKey}
                activeDot={onClickDot ? { onClick: onClickDot, cursor: 'pointer' } : undefined}
              />
            ),
        )}

        {yDataKeys.map((yDataKey, index) => (
          <Line
            isAnimationActive={isAnimationActive}
            onAnimationEnd={onAnimationEnd}
            key={yDataKey}
            stroke={lineOptions[index].lineColor}
            strokeDasharray={lineOptions[index].isDashed ? '0 5 4 0' : undefined}
            strokeLinecap="round"
            strokeWidth={lineOptions[index].strokeWidth ?? DEFAULT_STROKE_WIDTH_PX}
            name={lineOptions[index].legendName}
            legendType={lineOptions[index].legendName ? 'plainline' : 'none'}
            dataKey={yDataKey}
            dot={
              lineOptions[index].showDots
                ? {
                    strokeWidth: 2,
                    stroke: 'white',
                    strokeDasharray: undefined,
                    r: 4,
                    fill: lineOptions[index].lineColor,
                  }
                : false
            }
            activeDot={hideActiveDot ? false : activeDot}
            fill={lineOptions[index].areaColor ?? rgba(lineOptions[index].lineColor, 0.2)}
          />
        ))}

        {children}
      </StyledComposedChart>
      {isLoadingNewData && (
        <SpinnerContainer>
          <LoadingSpinner />
        </SpinnerContainer>
      )}
    </Root>
  );
};

export default MultipleLineChart;
