import * as R from 'ramda';
import React, { useCallback, useRef, useMemo, useState, useEffect } from 'react';
import mergeRefs from 'react-merge-refs';
import MaskedInput from 'react-text-mask';
import styled, { css } from 'styled-components';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import type { ValidationOptions } from 'common/lib/form/validation';
import { parseCurrency, formatCurrency, formatCurrencyNoCents } from 'common/utils/Currency';
import { replaceNanWithUndefined } from 'common/utils/Number';
import useEventListener from 'lib/hooks/useEventListener';
import fieldStyleMixin from 'lib/styles/fieldStyleMixin';

const ARROW_KEY_INCREMENT = 1;
const ARROW_KEY_SHIFT_INCREMENT = 10;

/** text-mask options: https://github.com/text-mask/text-mask/blob/master/addons/src/createNumberMask.js */
type MaskOptions = {
  prefix?: string;
  suffix?: string;
  includeThousandsSeparator?: boolean;
  thousandsSeparatorSymbol?: boolean;
  allowDecimal?: boolean;
  decimalSymbol?: string;
  decimalLimit?: number;
  requireDecimal?: boolean;
  allowNegative?: boolean;
  allowLeadingZeroes?: boolean;
};

const DEFAULT_MASK_OPTIONS: MaskOptions = {
  prefix: '$',
  allowDecimal: false,
  decimalLimit: 2,
  decimalSymbol: '.',
};

const getRawStringValue = (value: number | undefined, maskOptions: MaskOptions) => {
  if (R.isNil(value) || isNaN(value)) {
    return '';
  }
  return maskOptions.allowDecimal ? formatCurrency(value) : formatCurrencyNoCents(value);
};

export type Props = Pick<
  React.HTMLProps<HTMLInputElement>,
  | 'onBlur'
  | 'onClick'
  | 'onFocus'
  | 'id'
  | 'type'
  | 'placeholder'
  | 'defaultValue'
  | 'autoFocus'
  | 'autoComplete'
  | 'tabIndex'
  | 'disabled'
> &
  Pick<ValidationOptions, 'name'> & { onChange?: (value: number) => void } & {
    name: string;
    className?: string;
    small?: boolean;
    bold?: boolean;
    value?: number;
    maskOptions?: MaskOptions;
    /** Highlight all text when input is focused */
    selectOnFocus?: boolean;
  };

const Input = styled.input<{ $isBold?: boolean }>`
  ${fieldStyleMixin}

  ${({ theme, $isBold }) =>
    $isBold &&
    css`
      font-weight: ${theme.fontWeight.medium};
    `}
`;

const CurrencyInput: React.ForwardRefRenderFunction<HTMLElement, Props> = (
  {
    value,
    onChange: handleChange,
    small = false,
    bold = false,
    maskOptions: passedMaskOptions = DEFAULT_MASK_OPTIONS,
    selectOnFocus,
    onFocus,
    ...inputProps
  },
  passedRef,
) => {
  const maskOptions = useMemo(
    () => ({ ...DEFAULT_MASK_OPTIONS, ...passedMaskOptions }),
    [passedMaskOptions],
  );

  // This is a controlled input, but we keep the raw string value and abstract it away so
  // the parent only has to deal with number input/output
  const [rawStringValue, setRawStringValue] = useState(getRawStringValue(value, maskOptions));

  useEffect(() => {
    if (replaceNanWithUndefined(value) !== parseCurrency(rawStringValue)) {
      // Allow the parent component to change the state of this component.
      setRawStringValue(getRawStringValue(value, maskOptions));
    }
  }, [value, rawStringValue, maskOptions]);

  const textMask = useMemo(() => createNumberMask(maskOptions), [maskOptions]);
  const ref = useRef<MaskedInput>(null);
  const onChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setRawStringValue(value);
      handleChange?.(parseCurrency(value));
    },
    [handleChange, setRawStringValue],
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const increment = event.shiftKey ? ARROW_KEY_SHIFT_INCREMENT : ARROW_KEY_INCREMENT;

      const changeAmount = (() => {
        switch (event.key) {
          case 'ArrowUp':
            return increment;
          case 'ArrowDown':
            return -increment;
        }
      })();

      if (!R.isNil(changeAmount)) {
        handleChange?.((value ?? 0) + changeAmount);
      }
    },
    [value, handleChange],
  );

  useEventListener(ref.current?.inputElement, 'keydown', onKeyDown);
  return (
    // @ts-ignore
    <MaskedInput
      {...inputProps}
      ref={ref}
      value={rawStringValue}
      mask={textMask}
      onChange={onChange}
      render={(ref, props) => (
        <Input {...props} ref={mergeRefs([ref, passedRef])} small={small} $isBold={bold} />
      )}
      onFocus={(event) => {
        if (selectOnFocus) {
          event.target.select();
        }
        onFocus?.(event);
      }}
    />
  );
};

export default React.forwardRef(CurrencyInput);
