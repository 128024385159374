import { DateTime } from 'luxon';
import * as R from 'ramda';

const NUM_MONTHS_OPTIONS = 60;

export const getRolloverStartMonthOptions = () => {
  const startDate = DateTime.local().startOf('month');

  return R.range(0, NUM_MONTHS_OPTIONS)
    .map((i) => startDate.minus({ months: i }))
    .map((date) => ({
      value: date.toISODate(),
      label: date.toFormat('MMMM yyyy'),
    }));
};

export const isDateWithinRolloverPeriod = (
  date: DateTime,
  rolloverPeriod: { startMonth: string; endMonth: string | null | undefined },
) => {
  const startDate = DateTime.fromISO(rolloverPeriod.startMonth);
  if (rolloverPeriod.endMonth) {
    const endDate = DateTime.fromISO(rolloverPeriod.endMonth);
    return date >= startDate && date < endDate;
  } else {
    return date >= startDate;
  }
};
