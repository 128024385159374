import { useMediaQuery } from '@react-hook/media-query';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import defaultTheme from 'common/lib/theme';
import darkPalette from 'common/lib/theme/darkPalette';
import lightPalette from 'common/lib/theme/lightPalette';
import { getUiThemePreference } from 'selectors';

import type { UiTheme } from 'common/types';

const useTheme = (forceTheme?: UiTheme) => {
  const uiThemePreference = useSelector(getUiThemePreference);
  const systemUiTheme = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';

  const uiTheme =
    forceTheme ?? (uiThemePreference === 'system' ? systemUiTheme : uiThemePreference);

  const theme = useMemo(
    () => ({
      ...defaultTheme,
      uiTheme,
      color: uiTheme === 'dark' ? darkPalette : lightPalette,
    }),
    [uiTheme],
  );

  return theme;
};

export type UseThemeResult = ReturnType<typeof useTheme>;

/**
 * This is useful so we can easily get the keys of a specific theme property (e.g. color).
 *
 * Usage:
 * type Props = {
 *   color: KeyOfThemeProp<'color'>;
 * };
 * // color will be 'black' | 'textLight' | 'grayBackground' | ...
 */
export type KeyOfThemeProp<T extends keyof UseThemeResult> = keyof UseThemeResult[T];

export default useTheme;
