import { Duration } from 'luxon';

import useQueryWithCacheExpiration from 'common/lib/hooks/useQueryWithCacheExpiration';
import {
  getImpactOptions,
  getReasonOptions,
  getTopicOptions,
  parseRequiredFieldsForReason,
} from 'common/lib/support/form';

import { gql } from 'common/generated/gql';
import type { ContactSupportTopic } from 'common/generated/graphql';

const QUERY_CACHE_EXPIRATION = Duration.fromObject({ hours: 1 });

type Options = {
  topic?: ContactSupportTopic;
  reason?: string;
};

/**
 * Hook used to dynamically fetch the select options for the support form.
 * The options are fetched from the server and cached for a short period of time, and
 * the reason options are filtered based on the selected topic.
 */
const useSupportFormOptions = ({ topic, reason }: Options) => {
  const { data, isLoadingInitialData } = useQueryWithCacheExpiration(GET_SUPPORT_FORM_DATA, {
    variables: { topic: topic || null, reason: reason || null }, // Don't allow empty strings
    cacheExpiration: QUERY_CACHE_EXPIRATION,
  });

  const topicOptions = getTopicOptions(data?.contactFormData?.topics);
  const reasonOptions = getReasonOptions(data?.contactFormData?.reasonsForTopic);
  const impactOptions = getImpactOptions(data?.contactFormData?.impacts);
  const requiredFields = parseRequiredFieldsForReason(
    data?.contactFormData?.requiredFieldsForReason,
  );

  return { topicOptions, reasonOptions, impactOptions, requiredFields, isLoadingInitialData };
};

const GET_SUPPORT_FORM_DATA = gql(`
  query Web_GetContactSupportFormData($topic: ContactSupportTopic, $reason: String) {
    contactFormData {
      id

      topics {
        id
        value
        label
      }

      reasonsForTopic(topic: $topic) {
        id
        value
        label
      }

      impacts {
        id
        value
        label
      }

      requiredFieldsForReason(reason: $reason)
    }
  }
`);

export default useSupportFormOptions;
