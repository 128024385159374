import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import React from 'react';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { formatRelativeTime } from 'common/utils/date';

import * as COPY from 'common/constants/copy';

import type { MessageThreadListItemFieldsFragment } from 'common/generated/graphql';

const Root = styled(FlexContainer).attrs({ column: true })<{ $isSelected?: boolean }>`
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.xlarge};
  cursor: pointer;
  transition: ${({ theme }) => theme.transition.default};

  :hover {
    background: ${({ theme }) => theme.color.grayBackground};
  }
  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      background: ${({ theme }) => theme.color.grayBackground};
      box-shadow: ${({ theme }) => theme.shadow.inset};
    `}
`;

const Title = styled(Text).attrs({ weight: 'medium', clampLines: 1 })`
  margin-bottom: ${({ theme }) => theme.spacing.xxxsmall};
`;

type Props = {
  messageThread: MessageThreadListItemFieldsFragment;
  isSelected?: boolean;
  className?: string;
};

const MessageThreadListItem = ({
  messageThread: { lastMessageSentAt, createdAt, subject },
  isSelected,
  className,
}: Props) => (
  <Root className={className} $isSelected={isSelected}>
    <Title>{subject ?? COPY.ASSISTANT.MESSAGE_THREAD_SUBJECT_PLACEHOLDER}</Title>
    <Text color="textLight" size="small">
      {formatRelativeTime(DateTime.fromISO(lastMessageSentAt ?? createdAt))} ago
    </Text>
  </Root>
);

MessageThreadListItem.fragments = {
  MessageThreadListItemFields: gql`
    fragment MessageThreadListItemFields on MessageThread {
      id
      subject
      createdAt
      lastMessageSentAt
      hasOutstandingAssistantRequests
    }
  `,
};

export default MessageThreadListItem;
