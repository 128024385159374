import pluralize from 'pluralize';
import * as React from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import ContentLoader from 'components/lib/ui/ContentLoader';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { getAccountsFromTransactionsOverflow } from 'common/lib/transactions/bulkUpdate';

import type { Common_GetAccountsForTransactionsQuery } from 'common/generated/graphql';
import type StyleMixin from 'types/StyleMixin';

const ACCOUNT_LOGO_SIZE_PX = 40;

const accountCircleMixin: StyleMixin = ({ theme }) => `
  width: ${ACCOUNT_LOGO_SIZE_PX}px;
  height: ${ACCOUNT_LOGO_SIZE_PX}px;
  border: 1px solid ${theme.color.white};
`;

const Root = styled(FlexContainer).attrs({ alignCenter: true })`
  background-color: ${({ theme }) => theme.color.grayBackground};
  margin: ${({ theme }) => theme.spacing.large} 0;
`;

const LoadingPlaceholderRoot = styled.div`
  margin-left: ${({ theme }) => theme.spacing.xlarge};
  transform: translateY(5px);
`;

const StyledLogo = styled(AccountLogo)`
  ${accountCircleMixin}
  box-shadow: unset;
`;

const OverflowCircle = styled(FlexContainer).attrs({ center: true })`
  ${accountCircleMixin}

  border-radius: ${({ theme }) => theme.radius.round};
  background-color: ${({ theme }) => theme.color.grayDark};
  color: ${({ theme }) => theme.color.textWhite};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const LogoContainer = styled(FlexContainer)`
  --logo-offset: ${({ theme }) => theme.spacing.default};

  margin-left: ${({ theme }) => `calc(var(--logo-offset) + ${theme.spacing.xlarge})`};

  ${StyledLogo}, ${OverflowCircle} {
    margin-left: calc(var(--logo-offset) * -1);
  }
`;

const Content = styled(FlexContainer).attrs({ column: true })`
  margin: ${({ theme }) =>
    `${theme.spacing.default} ${theme.spacing.xlarge} ${theme.spacing.default} ${theme.spacing.default}`};
  overflow: hidden;
`;

const AccountNames = styled(Text).attrs({ size: 'xsmall', color: 'textLight' })`
  margin-top: 4px;
`;

type Props = {
  accounts: Common_GetAccountsForTransactionsQuery['getAccountsForTransactions'];
  isLoading: boolean;
};

const TransactionBulkUpdateDrawerAccounts = ({ accounts, isLoading }: Props) => {
  const [limit, overflowCount] = getAccountsFromTransactionsOverflow(accounts.length);

  return (
    <Root>
      {isLoading ? (
        <AccountsLoadingPlaceholder />
      ) : (
        <>
          <LogoContainer>
            {accounts.slice(0, limit).map((account) => (
              <StyledLogo key={account.id} logoUrl={account.logoUrl} icon={account.icon} />
            ))}
            {overflowCount > 0 && <OverflowCircle>+{overflowCount}</OverflowCircle>}
          </LogoContainer>
          <Content>
            <Text weight="medium">
              {accounts.length} {pluralize('account', accounts.length)} selected
            </Text>
            <AccountNames>{accounts.map(({ displayName }) => displayName).join(', ')}</AccountNames>
          </Content>
        </>
      )}
    </Root>
  );
};

const AccountsLoadingPlaceholder = () => (
  <LoadingPlaceholderRoot>
    <ContentLoader width={337} height={48} viewBox="0 0 337 48">
      <circle cx="20" cy="24" r="20" />
      <circle cx="41" cy="24" r="20" />
      <circle cx="63" cy="24" r="20" />
      <rect x="97" y="0" rx="5" ry="5" width="173" height="22" />
      <rect x="97" y="29" rx="5" ry="5" width="240" height="18" />
    </ContentLoader>
  </LoadingPlaceholderRoot>
);

export default TransactionBulkUpdateDrawerAccounts;
