import * as R from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';

import DragDots from 'components/lib/ui/DragDots';
import NavLink from 'components/lib/ui/link/NavLink';
import HorizontalTabContainer from 'components/lib/ui/tabs/HorizontalTabContainer';

import type StyleMixin from 'types/StyleMixin';

export type Props = {
  title?: React.ReactNode;
  headerLink?: string;
  description?: React.ReactNode;
  tabs?: React.ReactNode;
  controls?: React.ReactNode;
  small?: boolean;
  className?: string;
  hideBottomBorder?: boolean;
  draggable?: boolean;
};

type rootStyleMixinProps = {
  hasTabs: boolean;
  small: boolean;
  hideBottomBorder: boolean;
};

const rootStyleMixin: StyleMixin<rootStyleMixinProps> = ({
  theme,
  small,
  hasTabs,
  hideBottomBorder,
}) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${small ? '12px' : theme.spacing.default} ${
    small ? theme.spacing.default : theme.spacing.xlarge
  };
  border-bottom: ${hideBottomBorder ? 'none' : `1px solid ${theme.color.grayBackground}`};

  ${
    hasTabs
      ? css`
          padding-bottom: 0;
        `
      : ''
  }
`;

export const Root = styled.div<rootStyleMixinProps>`
  ${rootStyleMixin}
`;

const Title = styled.div<{ small: boolean }>`
  font-size: ${({ small, theme }) => (small ? theme.fontSize.small : theme.fontSize.large)};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  display: inline-block;
`;

export const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSize.small};
  padding-top: ${({ theme }) => theme.spacing.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeight.book};
`;

const Tabs = styled(HorizontalTabContainer)`
  /* Overlap bottom border */
  margin-bottom: -1px;

  :not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing.default};
  }
`;

// We need to peel off has hasTabs and small, otherwise NavLink complains about them
const LinkRoot = styled(({ hasTabs, small, hideBottomBorder, ...props }) => (
  <NavLink {...props} />
))<rootStyleMixinProps>`
  &:hover {
    color: ${({ theme }) => theme.color.blue};
  }
  ${rootStyleMixin}
`;

const StyledDragDots = styled(DragDots)`
  margin: 0 12px 0 -15px;
  display: inline-block;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition.default};
  opacity: 0;

  ${Root}:hover & {
    opacity: 1;
  }
`;

const CardHeader = ({
  title,
  description,
  tabs,
  controls,
  small = false,
  className,
  headerLink,
  hideBottomBorder = false,
  draggable = false,
}: Props) => {
  const wrappedComponent = (
    <>
      <div>
        {!!title && (
          <>
            {draggable && <StyledDragDots />}
            <Title small={small}>{title}</Title>
          </>
        )}
        {!!description && <Description>{description}</Description>}
        {!!tabs && <Tabs>{tabs}</Tabs>}
      </div>
      {!!controls && <div>{controls}</div>}
    </>
  );

  const rootElementProps = { className, small, hasTabs: !R.isNil(tabs), hideBottomBorder };

  if (headerLink) {
    return (
      <LinkRoot {...rootElementProps} to={headerLink}>
        {wrappedComponent}
      </LinkRoot>
    );
  } else {
    return <Root {...rootElementProps}>{wrappedComponent}</Root>;
  }
};

export default CardHeader;
