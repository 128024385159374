import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import MonarchLogo from 'components/lib/ui/MonarchLogo';
import ProgressBar from 'components/lib/ui/ProgressBar';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import Header from 'components/routes/Header';

import routes from 'constants/routes';

const StyledProgressBar = styled(ProgressBar)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
`;

const Container = styled(FlexContainer)`
  flex: 1;
`;

type Props = {
  progress: number;
  showBackButton?: boolean;
  showCancelButton?: boolean;
  showSaveButton?: boolean;
  showSkipButton?: boolean;
  cancelButtonOnRight?: boolean;
  onClickBackButton?: () => void;
  onClickSkipButton?: () => void;
  onClickCancelButton?: () => void;
  overrideLogo?: React.ReactNode;
};

const QuestionnaireHeader = ({
  progress,
  showBackButton,
  showCancelButton,
  showSaveButton,
  showSkipButton,
  onClickBackButton,
  onClickSkipButton,
  onClickCancelButton,
  cancelButtonOnRight,
  overrideLogo,
}: Props) => {
  const history = useHistory();

  const cancelBtn = showCancelButton ? (
    <DefaultButton
      linkTo={onClickCancelButton ? undefined : routes.advice({})}
      onClick={onClickCancelButton}
    >
      Cancel
    </DefaultButton>
  ) : null;

  return (
    <Header showSidebarToggle={false}>
      <Container alignCenter justifyBetween>
        <Container alignCenter>
          {showBackButton && (
            <DefaultButton onClick={onClickBackButton ?? history.goBack}>
              <ButtonIcon name="arrow-left" />
              <span>Back</span>
            </DefaultButton>
          )}
          {showCancelButton && !cancelButtonOnRight && cancelBtn}
        </Container>
        {overrideLogo ?? <MonarchLogo dark />}
        <Container alignCenter justifyEnd>
          {showSaveButton && <DefaultButton linkTo={routes.advice({})}>Save & Exit</DefaultButton>}
          {showSkipButton && onClickSkipButton && (
            <DefaultButton onClick={onClickSkipButton}>Skip</DefaultButton>
          )}
          {showCancelButton && cancelButtonOnRight && cancelBtn}
        </Container>
      </Container>
      <StyledProgressBar value={progress} color="orange" backgroundColor="white" animated />
    </Header>
  );
};

export default QuestionnaireHeader;
