import { gql } from '@apollo/client';
import { rgba } from 'polished';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import ReconnectCredentialButton from 'components/accounts/ReconnectCredentialButton';
import InstitutionLogo from 'components/institutions/InstitutionLogo';
import EventPropagationBoundary from 'components/lib/higherOrder/EventPropagationBoundary';
import FeatureFlagGate from 'components/lib/higherOrder/FeatureFlagGate';
import FlexContainer from 'components/lib/ui/FlexContainer';
import StatusIndicator from 'components/lib/ui/StatusIndicator';
import TextButton from 'components/lib/ui/TextButton';

import {
  getInstitutionPlaidStatus,
  getStatusForDataProvider,
} from 'common/lib/accounts/Institution';
import theme from 'common/lib/theme';

import { INSTITUTION } from 'common/constants/copy';
import { COLOR_FOR_STATUS, TITLE_FOR_STATUS } from 'common/constants/institutions';

import type {
  AccountListItemFieldsFragment,
  InstitutionStatusTooltipFieldsFragment,
} from 'common/generated/graphql';
import { DataProviderLegacy } from 'common/generated/graphql';
import type { InstitutionStatus } from 'common/types/institutions';

const WIDTH_PX = 272;

type Props = {
  institution: InstitutionStatusTooltipFieldsFragment;
  credential: AccountListItemFieldsFragment['credential'];
  syncDisabled: boolean;
  openReportIssueModal: () => void;
};

const Root = styled(EventPropagationBoundary).attrs({ onClick: true, preventDefault: true })`
  width: ${WIDTH_PX}px;
`;

const Section = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};

  :not(:last-child) {
    border-bottom: 1px solid ${rgba('#000', 0.2)};
  }
`;

const Logo = styled(InstitutionLogo)`
  width: 16px;
  height: 16px;
`;

const TitleSection = styled(Section).attrs({ alignCenter: true })`
  white-space: nowrap;
  overflow: hidden;
`;

const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.color.textWhite};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Header = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => rgba(theme.color.textWhite, 0.7)};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const WarningMessage = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-align: left;
  padding: 10px ${({ theme }) => theme.spacing.large};
  border-radius: ${({ theme }) => theme.radius.small};
  background: ${({ theme }) => rgba(theme.color.white, 0.05)};
  overflow: hidden;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 8px;
    background: ${({ theme }) => theme.color.yellow};
  }
`;

const StyledReconnectCredentialButton = styled(ReconnectCredentialButton)`
  font-size: ${({ theme }) => theme.fontSize.small};
  transition: inherit;
`;

const StyledTextButton = styled(TextButton)`
  transition: inherit;
`;

const StatusRow = styled(
  ({
    title,
    status,
    isDisconnected,
    ...props
  }: {
    title: string;
    status: InstitutionStatus;
    isDisconnected: boolean;
  }) => (
    <FlexContainer alignCenter justifyBetween {...props}>
      <div>{title}</div>
      <StatusIndicator color={isDisconnected ? theme.color.red : COLOR_FOR_STATUS[status]}>
        {isDisconnected ? 'Disconnected' : TITLE_FOR_STATUS[status]}
      </StatusIndicator>
    </FlexContainer>
  ),
)`
  align-self: stretch;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

const InstitutionStatusTooltip = ({
  institution,
  openReportIssueModal,
  credential,
  syncDisabled,
}: Props) => {
  const { name, logo, hasIssuesReported, hasIssuesReportedMessage, id } = institution;
  const { updateRequired, disconnectedFromDataProviderAt, dataProvider } = credential ?? {};
  const isDisconnected = updateRequired || !!disconnectedFromDataProviderAt;
  const balanceStatus = getStatusForDataProvider(
    getInstitutionPlaidStatus(institution, 'balanceStatus'),
    dataProvider,
    syncDisabled,
  );
  const transactionsStatus = getStatusForDataProvider(
    getInstitutionPlaidStatus(institution, 'transactionsStatus'),
    dataProvider,
    syncDisabled,
  );

  return (
    <Root>
      <TitleSection>
        {!!logo && <Logo logo={logo} />}
        <Title>{name}</Title>
      </TitleSection>
      <Section column alignStart>
        <Header>Connection Status</Header>
        <StatusRow title="Balances" status={balanceStatus} isDisconnected={isDisconnected} />
        <StatusRow
          title="Transactions"
          status={transactionsStatus}
          isDisconnected={isDisconnected}
        />
        {hasIssuesReported && dataProvider === DataProviderLegacy.PLAID && (
          <WarningMessage>
            {hasIssuesReportedMessage || INSTITUTION.FALLBACK_ISSUES_REPORTED_MESSAGE}
          </WarningMessage>
        )}
      </Section>
      <Switch>
        <Case when={RA.isTruthy(updateRequired) && !disconnectedFromDataProviderAt}>
          {RA.isNotNil(credential) && (
            <Section column alignCenter>
              <StyledReconnectCredentialButton institution={{ name, id }} credential={credential} />
            </Section>
          )}
        </Case>
        <Case when={!disconnectedFromDataProviderAt}>
          <FeatureFlagGate name="report-sync-issue">
            <Section column alignCenter>
              <StyledTextButton onClick={openReportIssueModal}>Report an issue</StyledTextButton>
            </Section>
          </FeatureFlagGate>
        </Case>
        <Case default>{null}</Case>
      </Switch>
    </Root>
  );
};

InstitutionStatusTooltip.WIDTH_PX = WIDTH_PX;

InstitutionStatusTooltip.fragments = {
  InstitutionStatusTooltipFields: gql`
    fragment InstitutionStatusTooltipFields on Institution {
      id
      logo
      name
      status
      plaidStatus
      hasIssuesReported
      url
      hasIssuesReportedMessage
      transactionsStatus
      balanceStatus
    }
  `,
};

export default InstitutionStatusTooltip;
