import React from 'react';
import styled from 'styled-components';

import FeatureOnboardingPage from 'components/lib/layouts/FeatureOnboardingPage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import FooterButton from 'components/plan/onboarding/FooterButton';
import OnboardingCategoryVariabilityCard from 'components/plan/onboarding/OnboardingCategoryVariabilityCard';

import { spacing } from 'common/lib/theme/dynamic';
import usePlanQuery from 'lib/hooks/plan/usePlanQuery';
import usePlanState from 'lib/hooks/plan/usePlanState';

import * as COPY from 'common/constants/copy';

import { CategoryGroupType } from 'common/generated/graphql';

const Column = styled(FlexContainer).attrs({ column: true, full: true })`
  max-width: 600px;
  gap: ${spacing.small};

  padding-bottom: 200px;
`;

type Props = {
  onBack: () => void;
  onCancel: () => void;
  onNext: () => void;
  progress: number;
};

const OnboardingSelectVariabilities = ({ onBack, onCancel, onNext, progress }: Props) => {
  const [state] = usePlanState();
  const { data } = usePlanQuery(state);

  const groups =
    data?.categoryGroups.filter((group) => group.type === CategoryGroupType.EXPENSE) || [];

  return (
    <FeatureOnboardingPage
      pageName="How do you want to budget?"
      title={COPY.BUDGET.ONBOARDING.SELECT_VARIABILITIES.TITLE}
      description={COPY.BUDGET.ONBOARDING.SELECT_VARIABILITIES.DESCRIPTION}
      descriptionMaxWidth={690}
      hideNextButton
      progress={progress}
      onClickBack={onBack}
      onClickCancel={onCancel}
    >
      <Column>
        {groups.map((group) => (
          <OnboardingCategoryVariabilityCard key={group.id} group={group} />
        ))}
      </Column>
      <FooterButton onClickNext={onNext} />
    </FeatureOnboardingPage>
  );
};

export default OnboardingSelectVariabilities;
