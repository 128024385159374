import * as React from 'react';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import PlanHeaderControls from 'components/plan/PlanHeaderControls';
import Header, { HEADER_HEIGHT } from 'components/routes/Header';

import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import type { UpdateQueryParamOptions } from 'lib/hooks/useQueryParams';

const Container = styled(FlexContainer)`
  flex: 1;
`;

const Tab = styled.div<{ $active: boolean }>`
  align-items: center;
  display: flex;
  height: ${HEADER_HEIGHT};
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-top: ${({ theme }) => `-${theme.spacing.xsmall}`};
  margin-bottom: ${({ theme }) => `-${theme.spacing.xsmall}`};
  margin-right: ${({ theme }) => theme.spacing.xlarge};

  :first-child {
    margin-left: ${({ theme }) => theme.spacing.xlarge};
  }
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.color.text};
  }

  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.color.orange};
      transition: unset;
      box-shadow: inset 0 -2px 0 0 ${({ theme }) => theme.color.orange};

      &:hover {
        color: ${({ theme }) => theme.color.orange};
      }
    `}
`;

type Props = {
  title: string;
  timeframe: PlanTimeframe;
  onClickToday: () => void;
  onPanLeft: () => void;
  onPanRight: () => void;
  onChangeTimeframe: (timeframe: PlanTimeframe, options?: UpdateQueryParamOptions) => void;
};

const PlanHeader = ({
  title,
  timeframe,
  onClickToday,
  onPanLeft,
  onPanRight,
  onChangeTimeframe,
}: Props) => (
  <Header>
    <Container alignCenter justifyBetween>
      <Container alignCenter>
        {title}
        <Tab
          $active={timeframe === PlanTimeframe.SingleMonth}
          onClick={() => onChangeTimeframe(PlanTimeframe.SingleMonth, { pushHistory: true })}
        >
          Budget
        </Tab>
        <Tab
          $active={timeframe !== PlanTimeframe.SingleMonth}
          onClick={() => onChangeTimeframe(PlanTimeframe.Monthly, { pushHistory: true })}
        >
          Forecast
        </Tab>
      </Container>
      <Container justifyEnd>
        <PlanHeaderControls
          onClickToday={onClickToday}
          onLeft={onPanLeft}
          onRight={onPanRight}
          timeframe={timeframe}
          onChangeTimeframe={onChangeTimeframe}
        />
      </Container>
    </Container>
  </Header>
);

export default PlanHeader;
