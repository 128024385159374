import type theme from 'common/lib/theme';

import type StyleMixin from 'types/StyleMixin';

/* Mixin to apply the correct color to the amount, if it should be green. */
export const amountMixin: StyleMixin<{
  isGreen: boolean | undefined;
  defaultColor: keyof (typeof theme)['color'];
}> = ({ theme: { uiTheme, color }, isGreen, defaultColor }) =>
  `color: ${(() => {
    if (!isGreen) {
      return color[defaultColor];
    }

    return uiTheme === 'dark' ? color.green : color.greenText;
  })()}`;
