import React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';

const Root = styled.div<{ $size?: number }>`
  border-radius: 100%;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  flex-shrink: 0;
  display: flex;
  background-color: ${({ theme }) => theme.color.grayFocus};
  box-shadow: inset 0 0 0 1px rgba(8, 40, 100, 0.08);
`;

const StyledIcon = styled(Icon)`
  margin: auto;
  height: 50%;
  width: 50%;
  color: ${({ theme }) => theme.color.text};
`;

type Props = {
  icon: string;
  className?: string;
  size?: number;
};

const ManualAccountLogo = ({ icon, className, size = 48 }: Props) => (
  <Root className={className} $size={size}>
    <StyledIcon name={icon} />
  </Root>
);

export default ManualAccountLogo;
