import { createSelector } from 'reselect';

import { DEFAULT_UI_THEME_PREFERENCE } from 'state/persistentUi/reducer';
import type { RootState } from 'state/types';
import { getUserIsLoggedIn } from 'state/user/selectors';

const getPersistentUiState = ({ persistentUi }: RootState) => persistentUi;

export const getDismissedMobileBrowserWarning = createSelector(
  getPersistentUiState,
  ({ dismissedMobileBrowserWarning }) => dismissedMobileBrowserWarning,
);

export const getPlaidLinkInfo = createSelector(
  getPersistentUiState,
  ({ plaidLinkInfo }) => plaidLinkInfo,
);

export const getUiThemePreference = createSelector(
  getPersistentUiState,
  getUserIsLoggedIn,
  ({ themePreference }, isLoggedIn) =>
    // Only allow dark mode if user is logged in
    !isLoggedIn ? 'light' : themePreference ?? DEFAULT_UI_THEME_PREFERENCE,
);

export const getIsBulkSelectActive = createSelector(
  getPersistentUiState,
  ({ isBulkSelectActive }) => isBulkSelectActive,
);

export const getIsTransactionsSummaryColumnVisible = createSelector(
  getPersistentUiState,
  ({ isTransactionsSummaryColumnVisible }) => isTransactionsSummaryColumnVisible,
);

export const getPlanDashboardWidgetViewIndex = createSelector(
  getPersistentUiState,
  ({ planDashboardWidgetViewIndex }) => planDashboardWidgetViewIndex,
);
