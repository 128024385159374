import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import CancelSubscriptionContactSupport from 'components/settings/billing/cancel/CancelSubscriptionContactSupport';
import CancelSubscriptionDiscountOfferModal from 'components/settings/billing/cancel/CancelSubscriptionDiscountOfferModal';
import PerformCancelSubscriptionModal from 'components/settings/billing/cancel/PerformCancelSubscriptionModal';

import { CANCELLATION_REASON_COPY } from 'common/lib/billing/Billing';
import { useStackContext } from 'common/lib/contexts/StackContext';
import { track } from 'lib/analytics/segment';
import useTrack from 'lib/hooks/useTrack';

import { CancelFlowEventNames } from 'common/constants/analytics';
import {
  CANCEL_SUBSCRIPTION_MODAL_SUBTITLE,
  CANCEL_SUBSCRIPTION_MODAL_TITLE,
} from 'common/constants/copy';

import { CancellationReason } from 'common/generated/graphql';

const REASON_OPTIONS = R.toPairs(CANCELLATION_REASON_COPY).map(([value, label]) => ({
  value,
  label,
}));

const CardBody = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const MainText = styled(Text)`
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

type Props = {
  isOnFreeTrial?: boolean;
  goBack?: () => void;
};

/**
 * This is part of the new cancellation flow which is behind the feature flag: web-cancel-discount-flow
 */
const CancelSubscriptionReasonModal = ({ isOnFreeTrial, goBack }: Props) => {
  const { push } = useStackContext();

  useTrack(CancelFlowEventNames.CancelFlowStarted);

  return (
    <ModalCard title={CANCEL_SUBSCRIPTION_MODAL_TITLE} onClickBackButton={goBack}>
      <Form<{ reason: CancellationReason }>
        onSubmit={({ reason }) => {
          track(CancelFlowEventNames.CancelReasonSelected, { reason });

          if (isOnFreeTrial) {
            // If user is on a trial, skip all the discount offers and just go straight to cancel.
            // In the future we may have a special flow for these users.
            push(PerformCancelSubscriptionModal, { reason });
          } else if (reason === CancellationReason.CONNECTION_ISSUES) {
            // Offer contact support before discount
            push(CancelSubscriptionContactSupport, { reason });
          } else {
            // Go to the discount flow - this will either show a discount offer or cancel the subscription
            push(CancelSubscriptionDiscountOfferModal, { reason });
          }
        }}
      >
        <CardBody>
          <MainText>{CANCEL_SUBSCRIPTION_MODAL_SUBTITLE}</MainText>
          <SelectField
            name="reason"
            options={REASON_OPTIONS}
            placeholder="Select a reason for cancelling your subscription..."
            required
          />
        </CardBody>
        <CardFooter justifyEnd>
          <FormSubmitButton size="small" disableWhenValuesUnchanged={false}>
            Continue
          </FormSubmitButton>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};

export default CancelSubscriptionReasonModal;
