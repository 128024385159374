import styled from 'styled-components';

import Tab from 'components/lib/ui/tabs/Tab';

import type StyleMixin from 'types/StyleMixin';

const pillTabStyleMixin: StyleMixin = ({ theme }) => `
  --color: ${theme.color.textLight};
  --activeColor: ${theme.color.text};
  --activeBackgroundColor: ${theme.color.grayBackground};

  border-radius: ${theme.radius.pill};
  padding: ${theme.spacing.xsmall} ${theme.spacing.default};
  font-size: ${theme.fontSize.small};
  line-height: 150%;
  text-align: center;

  transition: ${theme.transition.default};
  :hover {
    background-color: ${theme.color.grayBackground};
  }
  :active {
    background-color: ${theme.color.grayBackground};
    box-shadow: ${theme.shadow.inset};
  }
`;

const PillTab = styled(Tab).attrs({ underline: false })`
  ${pillTabStyleMixin};
`;

export default PillTab;
