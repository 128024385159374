import React from 'react';
import styled from 'styled-components';

import { ACTIVE_CLASS_NAME } from 'components/lib/ui/link/NavLink';
import NavBarLink from 'components/sidebar/NavBarLink';

import { track } from 'lib/analytics/segment';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';

import { ReferralsEventNames } from 'common/constants/analytics';
import routes from 'constants/routes';

const YellowLink = styled(NavBarLink)`
  color: ${({ theme }) => theme.color.yellow};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.small};

  &.${ACTIVE_CLASS_NAME}, &:hover {
    color: ${({ theme }) => theme.color.yellow};
  }
`;

/**
 * This is separated out into its own component to ensure we only log an
 * impression for the feature flag when the user actually sees the button.
 */
const ReferralLinkButton = () => {
  const isReferralPromoCopyChangesActive = useIsFeatureFlagOn('referral-promo-test-copy-changes');

  return (
    <YellowLink
      to={routes.settings.referrals()}
      onClick={() => track(ReferralsEventNames.ButtonClicked)}
      iconName="gift"
    >
      {isReferralPromoCopyChangesActive ? 'Summer Referral Bonus' : 'Get 1 Month Free'}
    </YellowLink>
  );
};

export default ReferralLinkButton;
