import React from 'react';

import theme from 'common/lib/theme';

type Props = {
  color?: string;
  size?: number;
};

const Mark = ({ color = theme.color.orange, size = 28 }: Props) => (
  <svg width={size} height={size} viewBox="0 0 28 28">
    <path
      d="M25.8125 7.4375C25.8138 9.47695 25.1419 11.2173 23.1875 11.8125L18.8125 13.5625C18.6012 13.6265 18.3753 13.7796 18.375 14C18.3741 14.2215 18.6002 14.3732 18.8125 14.4375L23.1875 16.1875C25.1361 16.784 25.8121 18.5282 25.8125 20.5625V27.125C19.5383 25.3729 15.3125 20.5028 15.3125 14C15.3125 7.49725 19.5383 2.62711 25.8125 0.875V7.4375Z"
      fill={color}
    />
    <path
      d="M2.1875 7.4375C2.18621 9.47695 2.85812 11.2173 4.81251 11.8125L9.18751 13.5625C9.39884 13.6265 9.62466 13.7796 9.62501 14C9.6259 14.2215 9.39983 14.3732 9.18751 14.4375L4.81251 16.1875C2.86387 16.784 2.18787 18.5282 2.1875 20.5625V27.125C8.46172 25.3729 12.6875 20.5028 12.6875 14C12.6875 7.49725 8.46172 2.62711 2.1875 0.875V7.4375Z"
      fill={color}
    />
  </svg>
);

export default Mark;
