import * as React from 'react';
import styled from 'styled-components';

import CardBody from 'components/lib/ui/CardBody';
import Link from 'components/lib/ui/Link';
import ModalCard from 'components/lib/ui/ModalCard';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import {
  MONARCH_PRIVACY_POLICY,
  MONARCH_TERMS_OF_USE_URL,
  SPINWHEEL_PRIVACY_POLICY,
  SPINWHEEL_TERMS_OF_USE_URL,
} from 'common/constants/externalUrls';

const Root = styled.div`
  padding: 0 70px 50px;
`;

const CTAButton = styled(PrimaryButton).attrs({
  size: 'large',
})`
  width: 100%;
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  line-height: 150%;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const CenteredCardBody = styled(CardBody)`
  display: block;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.large} 0 ${({ theme }) => theme.spacing.xlarge};
`;

const Footer = styled.div``;

const FooterText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  line-height: 150%;
  color: ${({ theme }) => theme.color.textLight};
  text-align: center;
  margin: ${({ theme }) => theme.spacing.xlarge} 0 0;
`;

type Props = {
  onStartSyncCreditReport: () => void;
};

const FirstTimeAddBillsModalCard = ({ onStartSyncCreditReport }: Props) => (
  <ModalCard hideBottomBorder>
    <Root>
      <Title>Connect to Spinwheel to track statement balances and due dates</Title>
      <CenteredCardBody>
        You will be able to view debt accounts in your name, and choose which ones you&apos;d like
        to view and track within Monarch.
      </CenteredCardBody>
      <Footer>
        <CTAButton onClick={onStartSyncCreditReport}>Sync with Spinwheel</CTAButton>
        <FooterText>
          By syncing your Spinwheel account, you agree to Spinwheel&apos;s{' '}
          <Link href={SPINWHEEL_TERMS_OF_USE_URL}>Terms of Use</Link> and{' '}
          <Link href={SPINWHEEL_PRIVACY_POLICY}>Privacy Policy</Link>. Any information synced from
          your Spinwheel account to your Monarch account will be processed in accordance with
          Monarch&apos;s updated <Link href={MONARCH_TERMS_OF_USE_URL}>Terms of Use</Link> and{' '}
          <Link href={MONARCH_PRIVACY_POLICY}>Privacy Policy</Link>.
        </FooterText>
      </Footer>
    </Root>
  </ModalCard>
);

export default FirstTimeAddBillsModalCard;
