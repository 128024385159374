import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ManualAccountLogo from 'components/accounts/ManualAccountLogo';

const DEFAULT_SIZE_PX = 48;

const Root = styled.div<{ $logoUrl?: string; $size: number }>`
  border-radius: 100%;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  flex-shrink: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('${({ $logoUrl }) => $logoUrl}');
  box-shadow: inset 0 0 0 1px rgba(8, 40, 100, 0.08);
`;

const InvisibleImg = styled.img`
  display: none;
`;

export type AccountLogoProps = {
  logoUrl?: string | null;
  icon?: string | null;
};

type Props = {
  size?: number;
  className?: string;
} & AccountLogoProps;

const AccountLogo = ({ logoUrl, className, icon, size = DEFAULT_SIZE_PX }: Props) => {
  const [hasImageError, setHasImageError] = useState(false);

  // Reset the error state when the logoUrl changes
  useEffect(() => {
    setHasImageError(false);
  }, [logoUrl]);

  if (logoUrl && !hasImageError) {
    return (
      <>
        <Root $logoUrl={logoUrl} className={className} $size={size} />
        {/*
          Add an invisible img tag just to use the onError listener.
          This will help us fall back to the ManualAccountLogo component if the image fails to load (e.g. 404 error).
        */}
        <InvisibleImg
          src={logoUrl}
          onError={() => setHasImageError(true)}
          aria-hidden="true"
          alt=""
        />
      </>
    );
  }

  return <ManualAccountLogo size={size} icon={icon || 'dollar-sign'} className={className} />;
};

export default AccountLogo;
