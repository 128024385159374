import React from 'react';
import styled from 'styled-components';

import ButterflyLogo from 'components/lib/ui/ButterflyLogo';
import CardTitle from 'components/lib/ui/CardTitle';
import FlexContainer from 'components/lib/ui/FlexContainer';

export const MONARCH_WATERMARK_NODE_ID = 'monarchWatermark';

const Root = styled(FlexContainer).attrs({
  alignCenter: true,
  gap: 'small',
})`
  display: none;
  gap: 8px;
  margin: 16px 24px 0px;
  position: relative;
  left: -100%;
  user-select: none;
  pointer-events: none;

  ${ButterflyLogo} {
    height: 17px; /* to align with the text */
  }
`;

const MonarchWatermark = () => (
  <Root id={MONARCH_WATERMARK_NODE_ID}>
    <ButterflyLogo />
    <CardTitle>Shared via monarchmoney.com</CardTitle>
  </Root>
);

export default MonarchWatermark;
