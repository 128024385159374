import { useTreatments } from '@splitsoftware/splitio-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_SPLIT_TREATMENT_VALUE, parseFlagsFromSplit } from 'common/lib/external/splitio';
import type { FeatureFlags } from 'common/lib/external/splitio';
import { track } from 'lib/analytics/segment';
import isEnvDevelopment from 'lib/isEnvDevelopment';
import { getSplitAttributes } from 'selectors';
import type { FeatureFlagName } from 'state/features/types';

import { AnalyticsEventNames } from 'common/constants/analytics';

export const useFeatureFlags = <TConfig>(names: FeatureFlagName[]): FeatureFlags<TConfig> => {
  const attrs = useSelector(getSplitAttributes);
  const flags = useTreatments(names, attrs);
  return parseFlagsFromSplit(flags);
};

export type Options = {
  trackImpression?: boolean;
  /** Whether to add the feature flag impression to Microsoft Clarity as a custom tag. */
  sendToMsClarity?: boolean;
};

const useFeatureFlag = <TConfig>(
  flagName: FeatureFlagName,
  { trackImpression, sendToMsClarity }: Options = {},
) => {
  const result = useFeatureFlags<TConfig>([flagName])[flagName];
  const { treatment } = result;

  useEffect(() => {
    if (trackImpression && treatment) {
      track(AnalyticsEventNames.FeatureFlagImpression, { split: flagName, treatment });
    }

    if (sendToMsClarity && window.clarity && treatment !== DEFAULT_SPLIT_TREATMENT_VALUE) {
      sendTagToClarity(flagName, treatment);
    }
  }, [treatment, flagName, trackImpression]);

  return result;
};

const sendTagToClarity = (flagName: string, treatment: string) => {
  const clarityTag = `flag-${flagName}`;
  window.clarity('set', clarityTag, treatment);
  if (isEnvDevelopment()) {
    // eslint-disable-next-line no-console
    console.log('[Clarity] Flag sent as custom tag', { [clarityTag]: treatment });
  }
};

export default useFeatureFlag;
