import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { formatCurrency } from 'common/utils/Currency';

import { MONTHS_PER_YEAR } from 'common/constants/time';

import { PaymentPeriod } from 'common/generated/graphQlTypes/globalTypes';
import { StripeCouponDuration } from 'common/generated/graphql';

const Root = styled(FlexContainer).attrs({ column: true })<{
  $isSelected?: boolean;
  $isDisabled?: boolean;
}>`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  box-shadow: ${({ theme }) => theme.shadow.small};
  transition: ${({ theme }) => theme.transition.default};
  padding: ${({ theme }) => theme.spacing.default};
  cursor: pointer;
  position: relative;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border-color: ${({ theme }) => theme.color.orange};
      background: ${({ theme }) => theme.color.orangeBackground};
    `};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      background: ${({ theme }) => theme.color.grayBackground};
      border: solid 1px ${({ theme }) => theme.color.grayLight};
      cursor: not-allowed;
    `};
`;

const Subtitle = styled(Text).attrs({ size: 'small' })`
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
`;

const SubsequentBilling = styled(Text).attrs({ size: 'xxsmall' })`
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
`;

const PromoBadge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.textWhite};
  background: ${({ theme, color }) =>
    color === 'orange' ? theme.color.orange : theme.color.black};
  padding: ${({ theme }) => theme.spacing.xxsmall} ${({ theme }) => theme.spacing.xsmall};
  font-size: 11px;
  line-height: 15px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  border-radius: 0px 7px 0px 4px;
`;

const StrikeThroughText = styled(Text)`
  text-decoration: line-through;
  color: ${({ theme }) => theme.color.textLight};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  price: number;
  discountedPrice: number | undefined | null;
  period: string;
  duration: string | undefined | null;
  durationInMonths: number | undefined | null;
  isSelected: boolean;
  onClick?: () => void;
  promoBadgeText?: string;
  className?: string;
  disabled?: boolean;
};

const UpgradeFormPlanOption = ({
  price: basePrice,
  discountedPrice,
  period,
  duration,
  durationInMonths,
  isSelected,
  onClick,
  promoBadgeText,
  className,
  disabled = false,
}: Props) => {
  const price = discountedPrice ?? basePrice;

  const { subtitle, priceInUsd } = useMemo(() => {
    switch (period) {
      case PaymentPeriod.MONTHLY:
        return {
          subtitle: `${formatCurrency(price * MONTHS_PER_YEAR)} per year, billed monthly`,
          priceInUsd: `${formatCurrency(price)} / month`,
        };
      case PaymentPeriod.YEARLY:
        return {
          subtitle: `${formatCurrency(price)} per year, billed yearly`,
          priceInUsd: `${formatCurrency(price / MONTHS_PER_YEAR)} / month`,
        };
      case PaymentPeriod.LIFETIME:
        return {
          subtitle: 'Lifetime, billed once',
          priceInUsd: formatCurrency(price),
        };
      default:
        return {
          subtitle: '',
          priceInUsd: '',
        };
    }
  }, [price, period]);

  const planOptionNotes = useMemo(() => {
    switch (duration) {
      case StripeCouponDuration.FOREVER:
        return undefined;
      case StripeCouponDuration.ONCE:
        return discountedPrice
          ? 'Subsequent periods will be billed at the regular rate.'
          : undefined;
      case StripeCouponDuration.REPEATING:
        return discountedPrice
          ? `Valid for ${durationInMonths} months. Subsequent periods will be billed at the regular rate.`
          : undefined;
      default:
        return undefined;
    }
  }, [price, duration, durationInMonths, period]);

  return (
    <Root
      $isDisabled={disabled}
      $isSelected={isSelected}
      onClick={() => !disabled && onClick?.()}
      className={className}
    >
      <FlexContainer>
        <Text weight="medium">{priceInUsd}</Text>
      </FlexContainer>
      <Subtitle>
        {RA.isNotNil(discountedPrice) && (
          <StrikeThroughText>{formatCurrency(basePrice)}</StrikeThroughText>
        )}
        {subtitle}
      </Subtitle>
      {planOptionNotes && <SubsequentBilling>{planOptionNotes}</SubsequentBilling>}
      {!!promoBadgeText && <PromoBadge color="orange">{promoBadgeText}</PromoBadge>}
      {period === PaymentPeriod.LIFETIME && <PromoBadge color="black">{'BEST VALUE'}</PromoBadge>}
    </Root>
  );
};

export default UpgradeFormPlanOption;
