import React from 'react';
import styled from 'styled-components';

const DEFAULT_SIZE_PX = 48;

type Props = {
  logo: string;
  className?: string;
  size?: number;
};

const Root = styled.div<{ url?: string; $size: number }>`
  border-radius: 100%;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  flex-shrink: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('${({ url }) => url}');
  box-shadow: inset 0 0 0 1px rgba(8, 40, 100, 0.08);
`;

const InstitutionLogo = ({ logo, className, size = DEFAULT_SIZE_PX }: Props) => (
  <Root url={`data:image/png;base64,${logo}`} className={className} $size={size} />
);

export default InstitutionLogo;
