import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import FeatureFlagGate from 'components/lib/higherOrder/FeatureFlagGate';
import FlexItem from 'components/lib/ui/FlexItem';
import Icon from 'components/lib/ui/Icon';
import FreeTrialDurationPanel from 'components/sidebar/FreeTrialDurationPanel';
import LinkIcon from 'components/sidebar/LinkIcon';
import NavBarLink from 'components/sidebar/NavBarLink';
import NavBarLinkSmall from 'components/sidebar/NavBarLinkSmall';
import ReferralLinkButton from 'components/sidebar/ReferralLinkButton';
import SidebarYellowLinkButton from 'components/sidebar/SidebarYellowLinkButton';

import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';
import useTrialStatusQuery from 'common/lib/hooks/premium/useTrialStatusQuery';
import { getIsSponsor } from 'common/state/user/selectors';
import useDemoHousehold from 'lib/hooks/useDemoHousehold';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useTheme from 'lib/hooks/useTheme';

import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

import type { Web_GetSidebarDataQuery } from 'common/generated/graphql';
import { PaymentPeriod, SubscriptionDetailsPaymentSource } from 'common/generated/graphql';

const NavBarLinkSmallYellow = styled(NavBarLinkSmall)`
  color: ${({ theme }) => theme.color.yellow};

  :hover {
    color: ${({ theme }) => theme.color.yellow};
  }
`;

type Props = {
  subscription?: Web_GetSidebarDataQuery['subscription'] | null;
  subscriptionOffering?: Web_GetSidebarDataQuery['subscriptionOffering'] | null;
  onSupportButtonClick?: () => Promise<void> | void;
  onUpgradeButtonClick?: () => Promise<void> | void;
  pendingStreamsCount: number;
};

const SideBarDefaultContent = ({
  subscription,
  subscriptionOffering,
  onSupportButtonClick,
  onUpgradeButtonClick,
  pendingStreamsCount,
}: Props) => {
  const history = useHistory();
  const theme = useTheme();
  const { isDemoHousehold } = useDemoHousehold();
  const isBillTrackingEnabled = useIsFeatureFlagOn('load-liabilities');

  const {
    paymentSource,
    billingPeriod,
    hasPremiumEntitlement: isSubscriptionActive,
    isOnFreeTrial: isTrialing,
    nextPaymentAmount,
    activeSponsorship,
  } = subscription ?? {};

  const isSponsored = !!activeSponsorship;

  const { plans } = subscriptionOffering ?? {};

  const { hasPremiumTrialAvailable } = useTrialStatusQuery();
  const isSponsor = useSelector(getIsSponsor);

  const { householdPreferences } = useHouseholdPreferences();
  const { aiAssistantEnabled } = householdPreferences ?? {};

  // we're experimenting the annual upsell only with users with monthly active subscription via Stripe.
  const isAnnualUpsellAvailable =
    !!subscription &&
    !!isSubscriptionActive &&
    billingPeriod === PaymentPeriod.MONTHLY &&
    paymentSource === SubscriptionDetailsPaymentSource.STRIPE &&
    !!nextPaymentAmount &&
    nextPaymentAmount === 14.99;

  return (
    <>
      <FlexItem>
        {isTrialing && subscription && !isDemoHousehold && (
          <FreeTrialDurationPanel data={subscription} />
        )}
        <NavBarLink to={routes.dashboard()} exact iconName="home">
          Dashboard
        </NavBarLink>
        <NavBarLink to={routes.accounts()} iconName="layers">
          Accounts
        </NavBarLink>
        <NavBarLink to={routes.transactions()} iconName="credit-card">
          Transactions
        </NavBarLink>
        <NavBarLink to={routes.cashFlow()} iconName="bar-chart">
          Cash Flow
        </NavBarLink>
        <FeatureFlagGate name="reports">
          <NavBarLink to={routes.reports()} iconName="pie-chart" badge="Beta">
            Reports
          </NavBarLink>
        </FeatureFlagGate>
        <NavBarLink
          to={routes.plan()}
          isActive={(match, location) => !!match || location.pathname === routes.planning()}
          iconName="map"
        >
          Budget
        </NavBarLink>
        <NavBarLink
          to={routes.recurring()}
          iconName="calendar"
          badge={
            isBillTrackingEnabled && pendingStreamsCount > 0
              ? pendingStreamsCount.toString()
              : undefined
          }
          badgeColor={theme.color.navyLight}
        >
          Recurring
        </NavBarLink>
        <NavBarLink to={routes.goalsV2()} iconName="target">
          Goals
        </NavBarLink>
        <NavBarLink to={routes.investments()} iconName="trending-up">
          Investments
        </NavBarLink>
        {!isSponsored && !isSponsor && (
          <NavBarLink to={routes.advice({})} iconName="thumbs-up">
            Advice
          </NavBarLink>
        )}
        {aiAssistantEnabled && (
          <NavBarLink to={routes.assistant({})} iconName="stars">
            Assistant
          </NavBarLink>
        )}
      </FlexItem>
      <FlexItem grow={1} />
      <FlexItem>
        <Switch>
          <Case when={isSponsor}>
            <NavBarLinkSmall onClick={() => history.push(routes.advisorPortal.clients())}>
              <LinkIcon>
                <Icon name="users" size={16} />
              </LinkIcon>
              Advisor Portal
            </NavBarLinkSmall>
          </Case>
          {/* Show the experiment with the annual upsell */}
          <Case when={isAnnualUpsellAvailable}>
            <SidebarYellowLinkButton plans={plans} />
          </Case>
          {/* Show the Referral button without going through the split - we don't want annual/lifetime users on this experiment*/}
          <Case when={!isAnnualUpsellAvailable}>
            <ReferralLinkButton />
          </Case>
          <Case when={!isTrialing}>
            <NavBarLinkSmallYellow onClick={onUpgradeButtonClick}>
              <LinkIcon>
                <Icon name="diamond" size={16} />
              </LinkIcon>
              {hasPremiumTrialAvailable
                ? COPY.PREMIUM.UPGRADE_CTA.TRIAL_AVAILABLE
                : COPY.PREMIUM.UPGRADE_CTA.TRIAL_UNAVAILABLE}
            </NavBarLinkSmallYellow>
          </Case>
          <Case default>{null}</Case>
        </Switch>
        <NavBarLinkSmall onClick={onSupportButtonClick}>
          <LinkIcon>
            <Icon name="message-circle" size={16} />
          </LinkIcon>
          Help & Support
        </NavBarLinkSmall>
      </FlexItem>
    </>
  );
};

export default SideBarDefaultContent;
