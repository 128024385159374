import type { MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import { useCallback } from 'react';

import { GET_ME, UPDATE_USER_PROFILE_MUTATION } from 'common/lib/graphQl/user';
import useQuery from 'common/lib/hooks/useQuery';

import type {
  Common_UpdateUserProfileMutation,
  Common_UpdateUserProfileMutationVariables,
  Common_GetMeQuery,
  Common_GetMeQueryVariables,
  UpdateMeInput,
  UpdateUserProfileInput,
} from 'common/generated/graphql';

/**
 * Manages a specific user profile attribute ("flag") by providing its current value and a
 * function to update it.
 *
 * Returns a tuple with the attribute value (or `undefined` during initial load) and the update
 * function.
 *
 * Note: The update function triggers server side update and hence can be asynchronous. The new value
 * may not immediately reflect in the attribute value returned by the hook.
 */
const useProfileFlag = (
  flag: keyof UpdateUserProfileInput,
  options?: MutationHookOptions<
    Common_UpdateUserProfileMutation,
    Common_UpdateUserProfileMutationVariables
  >,
  queryOptions?: QueryHookOptions<Common_GetMeQuery, Common_GetMeQueryVariables>,
) => {
  const { data, isLoadingInitialData } = useQuery(GET_ME, queryOptions);
  const flagValue = isLoadingInitialData ? undefined : R.path(['me', 'profile', flag], data);

  const [updateFlag] = useMutation(UPDATE_USER_PROFILE_MUTATION, options);

  const changeFlagValue = useCallback(
    (value: typeof flagValue = true) =>
      updateFlag({
        variables: {
          updateProfileInput: { [flag]: value } as UpdateUserProfileInput,
          updateMeInput: {} as UpdateMeInput,
        },
      }),
    [updateFlag, flag],
  );

  return [flagValue, changeFlagValue] as const;
};

export default useProfileFlag;
