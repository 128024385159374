import React from 'react';
import styled from 'styled-components';

import FeatureOnboardingPage from 'components/lib/layouts/FeatureOnboardingPage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import OnboardingBudgetList from 'components/plan/onboarding/OnboardingBudgetList';

import { spacing } from 'common/lib/theme/dynamic';
import {
  getGroupDataWithBudgetedAmounts,
  getTotalBudgetedAmountFromSummaryForType,
} from 'lib/budget/onboardingAdapters';
import usePlanAdapter from 'lib/hooks/plan/usePlanAdapter';
import usePlanQuery from 'lib/hooks/plan/usePlanQuery';
import usePlanState from 'lib/hooks/plan/usePlanState';

import * as COPY from 'common/constants/copy';

import { CategoryGroupType } from 'common/generated/graphql';

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: ${spacing.xlarge};
`;

type Props = {
  onBack: () => void;
  onNext: () => void;
  onCancel: () => void;
  progress: number;
};

const OnboardingIncome = ({ onBack, onNext, onCancel, progress }: Props) => {
  const [state] = usePlanState();
  const { data, fetchedDateRange } = usePlanQuery(state);
  const { isLoadingInitialData, gridDisplayData, gridAmounts, budgetSummaryData } = usePlanAdapter(
    data,
    state,
    fetchedDateRange,
  );

  // gridDisplayData has the presentational info that we need, but gridAmounts has the budgeted amounts.
  const incomeDataWithAmounts = getGroupDataWithBudgetedAmounts(
    gridDisplayData,
    gridAmounts,
    CategoryGroupType.INCOME,
    state.thisMonth,
  );

  // extract the total budgeted income from the budgetSummaryData
  const totalBudgetedIncome = getTotalBudgetedAmountFromSummaryForType(
    budgetSummaryData,
    CategoryGroupType.INCOME,
  );

  return (
    <FeatureOnboardingPage
      pageName="Income"
      title={COPY.BUDGET.ONBOARDING.INCOME.TITLE}
      description={COPY.BUDGET.ONBOARDING.INCOME.DESCRIPTION}
      progress={progress}
      onClickBack={onBack}
      onClickNext={onNext}
      onClickCancel={onCancel}
    >
      <FlexContainer center column>
        {isLoadingInitialData ? (
          <StyledLoadingSpinner />
        ) : (
          <OnboardingBudgetList
            data={incomeDataWithAmounts}
            footerTitle="Total Budgeted Income"
            footerValue={totalBudgetedIncome}
          />
        )}
      </FlexContainer>
    </FeatureOnboardingPage>
  );
};

export default OnboardingIncome;
