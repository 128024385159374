import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import Tabs from 'common/components/tabs/Tabs';
import BreakdownCard from 'components/cashFlows/BreakdownCard';
import BreakdownSection from 'components/cashFlows/BreakdownSection';
import CashFlowBarChart from 'components/cashFlows/CashFlowBarChart';
import CashFlowControls from 'components/cashFlows/CashFlowControls';
import CashFlowSankeyCard from 'components/cashFlows/CashFlowSankeyCard';
import CashFlowViewSelect from 'components/cashFlows/CashFlowViewSelect';
import FeatureFlagGate from 'components/lib/higherOrder/FeatureFlagGate';
import Column from 'components/lib/ui/Column';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Grid, { GridItem } from 'components/lib/ui/Grid';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import PageWithNoAccountsEmptyState from 'components/lib/ui/PageWithNoAccountsEmptyState';
import Row from 'components/lib/ui/Row';
import StatisticCard from 'components/lib/ui/StatisticCard';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';

import {
  aggregatesDataAdapter,
  getDateRangeForTimeframe,
  getDefaultDateForTimeframe,
} from 'common/lib/cashFlow/adapters';
import useQuery from 'common/lib/hooks/useQuery';
import useToggle from 'common/lib/hooks/useToggle';
import { formatPercent } from 'common/utils/Number';
import { chartDataPointFormatter } from 'lib/cashFlow/Adapters';
import {
  byCategoryAsEntries,
  byCategoryGroupAsEntries,
  byMerchantAsEntries,
} from 'lib/cashFlow/BreakdownAdapters';
import {
  convertCashFlowFilterToInput,
  getMostRecentDateIfCurrentDateNonAvailable,
} from 'lib/cashFlow/filter';
import { SANKEY_GROUP_MODE_TO_LABEL } from 'lib/cashFlow/sankey';
import type { CashFlowContextType } from 'lib/contexts/CashFlowContext';
import CashFlowContext from 'lib/contexts/CashFlowContext';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import usePersistentFilter from 'lib/hooks/usePersistentFilter';
import { DEFAULT_FILTERS } from 'lib/transactions/Filters';
import { ChartType } from 'lib/ui/charts';
import { formatFullDateRange, getDateShorthand } from 'utils/dateRange';

import { BREAKDOWN_GROUP_BY_OPTIONS } from 'common/constants/cashFlow';
import { ONBOARDING_CASH_FLOW_OVERLAY_TITLE } from 'common/constants/copy';
import { TIMEFRAME_OPTIONS } from 'common/constants/timeframes';

import { gql as newGql } from 'common/generated/gql';
import { CategoryGroupType } from 'common/generated/graphql';

const LoadingContainer = styled(Flex)`
  flex: 1;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.default};
`;

const DateLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const StatisticCardGrow = styled(StatisticCard)`
  flex: 1;
`;

const SummaryCardsContainer = styled(FlexContainer).attrs({
  justifyBetween: true,
  alignCenter: true,
  gap: 'gutter',
})`
  @media (max-width: ${({ theme }) => theme.breakPoints.md}px) {
    flex-flow: row wrap;
    gap: ${({ theme }) => theme.spacing.small};
    margin: ${({ theme }) => theme.spacing.gutter} 0 ${({ theme }) => theme.spacing.small};

    > ${StatisticCardGrow} {
      flex-basis: 33%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    > ${StatisticCardGrow} {
      flex-basis: 50%;
    }
  }
`;

const DateViewContainer = styled(FlexContainer).attrs({
  justifyBetween: true,
  alignCenter: true,
})`
  @media (max-width: ${({ theme }) => theme.breakPoints.md}px) {
    margin-top: ${({ theme }) => theme.spacing.small};
    margin-bottom: -${({ theme }) => theme.spacing.small};
  }
`;

const CashFlow = () => {
  const isDefaultToSankeyEnabled = useIsFeatureFlagOn('ab-test-cash-flow-sankey-default', {
    trackImpression: true,
  });

  const filtersKey = isDefaultToSankeyEnabled ? 'cashFlowSankey' : 'cashFlow';

  const {
    activeFilters: {
      timeframe: activeTimeframe,
      date,
      view,
      sankey: sankeyView,
      breakdown: breakdownGroupBy,
      ...aggregateFilters
    },
    updateFilter,
  } = usePersistentFilter(filtersKey);
  const [hideAmounts, { toggle: toggleHideAmounts, setOff: unhideAmounts }] = useToggle(false);

  const initialTimeframeIndex = useMemo(
    () => TIMEFRAME_OPTIONS.findIndex(({ value }) => value === activeTimeframe),
    [],
  );

  const initialSankeyGroupModeIndex = useMemo(
    () => Object.keys(SANKEY_GROUP_MODE_TO_LABEL).findIndex((value) => value === sankeyView),
    [sankeyView],
  );

  const activeDate = date || getDefaultDateForTimeframe(activeTimeframe);

  const onBarClick = (barDate: string) => updateFilter({ date: barDate });

  const cleanedFilterInput = convertCashFlowFilterToInput(aggregateFilters);

  const { data: aggregatesData } = useQuery(GET_CASH_FLOW_BY_TIMEFRAME_QUERY, {
    variables: { filters: R.mergeRight(DEFAULT_FILTERS, cleanedFilterInput) },
  });

  const timeframeData = useMemo(
    () => aggregatesDataAdapter(aggregatesData, activeTimeframe, chartDataPointFormatter),
    [aggregatesData, activeTimeframe],
  );

  const dateRange = useMemo(
    () => getDateRangeForTimeframe(activeTimeframe, timeframeData, activeDate),
    [activeDate, activeTimeframe, timeframeData],
  );

  const { data, refetch, isDataAvailable, isNetworkRequestInFlight } = useQuery(
    GET_CASH_FLOW_BY_ENTITY_QUERY,
    {
      variables: {
        filters: R.mergeRight(DEFAULT_FILTERS, { ...cleanedFilterInput, ...dateRange }),
      },
    },
  );

  const isLoadingSummary = !isDataAvailable && isNetworkRequestInFlight;

  const breakdownItems = useMemo(() => {
    const { byCategory = [], byCategoryGroup = [], byMerchant = [] } = data ?? {};

    const incomesByCategory = byCategoryAsEntries(
      byCategory,
      dateRange,
      CategoryGroupType.INCOME,
      activeTimeframe,
    );
    const expensesByCategory = byCategoryAsEntries(
      byCategory,
      dateRange,
      CategoryGroupType.EXPENSE,
      activeTimeframe,
    );
    const expensesByMerchant = byMerchantAsEntries(
      byMerchant,
      dateRange,
      CategoryGroupType.EXPENSE,
      activeTimeframe,
    );
    const incomesByMerchant = byMerchantAsEntries(
      byMerchant,
      dateRange,
      CategoryGroupType.INCOME,
      activeTimeframe,
    );
    const incomesByCategoryGroup = byCategoryGroupAsEntries(
      byCategoryGroup,
      CategoryGroupType.INCOME,
      dateRange,
      activeTimeframe,
    );
    const expensesByCategoryGroup = byCategoryGroupAsEntries(
      byCategoryGroup,
      CategoryGroupType.EXPENSE,
      dateRange,
      activeTimeframe,
    );

    return {
      incomesByCategory,
      expensesByCategory,
      expensesByMerchant,
      incomesByMerchant,
      incomesByCategoryGroup,
      expensesByCategoryGroup,
    };
  }, [data, dateRange, activeTimeframe]);

  const dateShorthand = useMemo(() => getDateShorthand(dateRange), [dateRange]);

  const context: CashFlowContextType = useMemo(
    () => ({
      hideAmounts,
      shareDisabled: !data,
      toggleHideAmounts,
      unhideAmounts,
    }),
    [hideAmounts, data, toggleHideAmounts, unhideAmounts],
  );

  useEffect(() => {
    const newDate = getMostRecentDateIfCurrentDateNonAvailable(timeframeData, activeDate);
    if (newDate !== activeDate) {
      updateFilter({ date: newDate });
    }
  }, [timeframeData, activeDate]);

  return (
    <Tabs initialIndex={initialTimeframeIndex}>
      <PageWithNoAccountsEmptyState
        name="Cash Flow"
        emptyIcon="bar-chart2"
        emptyTitle={ONBOARDING_CASH_FLOW_OVERLAY_TITLE}
        controls={<CashFlowControls filtersKey={filtersKey} />}
        onAccountAdded={refetch}
      >
        {(() => {
          if (data && aggregatesData !== undefined) {
            const {
              summary: [
                {
                  summary: { sumIncome, sumExpense, savings, savingsRate },
                },
              ],
            } = data;

            return (
              <Grid template={`"chart" "date" "summary" "income" "expense"`}>
                <GridItem area="chart">
                  <CashFlowBarChart
                    onBarClick={onBarClick}
                    data={timeframeData}
                    timeframe={activeTimeframe}
                    activeDate={activeDate}
                  />
                </GridItem>

                <GridItem area="date">
                  <DateViewContainer>
                    <DateLabel>{dateShorthand || formatFullDateRange(dateRange)}</DateLabel>
                    <FeatureFlagGate name="sankey-diagram">
                      <CashFlowViewSelect
                        selectedView={view}
                        onChangeView={(changedView) => updateFilter({ view: changedView })}
                      />
                    </FeatureFlagGate>
                  </DateViewContainer>
                </GridItem>

                <GridItem area="summary">
                  <SummaryCardsContainer>
                    <StatisticCardGrow
                      value={<CashFlowCurrency type="income" value={sumIncome} round emphasis />}
                      label="Income"
                      isLoading={isLoadingSummary}
                    />
                    <StatisticCardGrow
                      value={<CashFlowCurrency type="expense" value={sumExpense} round emphasis />}
                      label="Expenses"
                      isLoading={isLoadingSummary}
                    />
                    <StatisticCardGrow
                      value={<CashFlowCurrency type="savings" value={savings} round />}
                      label="Total Savings"
                      isLoading={isLoadingSummary}
                    />
                    <StatisticCardGrow
                      value={formatPercent(Math.max(0, savingsRate))}
                      label="Savings Rate"
                      isLoading={isLoadingSummary}
                    />
                  </SummaryCardsContainer>
                </GridItem>

                <CashFlowContext.Provider value={context}>
                  {
                    // This is fine for now since we only have two views
                    view === ChartType.CashFlowBreakdown ? (
                      <Tabs
                        initialIndex={
                          breakdownGroupBy
                            ? BREAKDOWN_GROUP_BY_OPTIONS.indexOf(breakdownGroupBy)
                            : undefined
                        }
                      >
                        <BreakdownSection
                          onChangeIndex={(index) =>
                            updateFilter({ breakdown: BREAKDOWN_GROUP_BY_OPTIONS[index] })
                          }
                        >
                          <GridItem area="income">
                            <BreakdownCard
                              title="Income"
                              byCategory={breakdownItems.incomesByCategory}
                              byMerchant={breakdownItems.incomesByMerchant}
                              byCategoryGroup={breakdownItems.incomesByCategoryGroup}
                              total={sumIncome}
                            />
                          </GridItem>
                          <GridItem area="expense">
                            <BreakdownCard
                              title="Expenses"
                              byCategory={breakdownItems.expensesByCategory}
                              byMerchant={breakdownItems.expensesByMerchant}
                              byCategoryGroup={breakdownItems.expensesByCategoryGroup}
                              total={sumExpense}
                            />
                          </GridItem>
                        </BreakdownSection>
                      </Tabs>
                    ) : (
                      <Row padding={0}>
                        <Column md={12}>
                          <Tabs initialIndex={initialSankeyGroupModeIndex}>
                            <CashFlowSankeyCard data={data} isLoading={isLoadingSummary} />
                          </Tabs>
                        </Column>
                      </Row>
                    )
                  }
                </CashFlowContext.Provider>
              </Grid>
            );
          } else {
            return (
              <LoadingContainer center column>
                <LoadingSpinner />
                Loading cash flow...
              </LoadingContainer>
            );
          }
        })()}
      </PageWithNoAccountsEmptyState>
    </Tabs>
  );
};

/*
  TODO: Using this in the new A/B test of showing the Sankey chart in the onboarding flow. This is a temp solution.
  If we decide by moving forward with the experiment, ideally we should move it to commons or think in a different solution.
*/

export const GET_CASH_FLOW_BY_TIMEFRAME_QUERY = newGql(/* GraphQL */ `
  query Web_CashFlowAggregates($filters: TransactionFilterInput) {
    byYear: aggregates(groupBy: ["year"], fillEmptyValues: true, filters: $filters) {
      groupBy {
        year
      }

      summary {
        savings
        savingsRate
        sumIncome
        sumExpense
      }
    }

    byMonth: aggregates(groupBy: ["month"], fillEmptyValues: true, filters: $filters) {
      groupBy {
        month
      }

      summary {
        savings
        savingsRate
        sumIncome
        sumExpense
      }
    }

    byQuarter: aggregates(groupBy: ["quarter"], fillEmptyValues: true, filters: $filters) {
      groupBy {
        quarter
      }

      summary {
        savings
        savingsRate
        sumIncome
        sumExpense
      }
    }
  }
`);

export const GET_CASH_FLOW_BY_ENTITY_QUERY = newGql(/* GraphQL */ `
  query Web_GetCashFlowPage($filters: TransactionFilterInput) {
    byCategory: aggregates(filters: $filters, groupBy: ["category"]) {
      groupBy {
        category {
          id
          name
          icon

          group {
            id
            type
          }
        }
      }

      summary {
        sum
      }
    }

    byCategoryGroup: aggregates(filters: $filters, groupBy: ["categoryGroup"]) {
      groupBy {
        categoryGroup {
          id
          name
          type
        }
      }

      summary {
        sum
      }
    }

    byMerchant: aggregates(filters: $filters, groupBy: ["merchant"]) {
      groupBy {
        merchant {
          id
          name
          logoUrl
        }
      }

      summary {
        sumIncome
        sumExpense
      }
    }

    summary: aggregates(filters: $filters, fillEmptyValues: true) {
      summary {
        sumIncome
        sumExpense
        savings
        savingsRate
      }
    }
  }
`);

export default CashFlow;
