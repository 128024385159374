import React, { useState } from 'react';

import { ButtonGroup } from 'components/lib/ui/ButtonGroup';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PlanRowTitle from 'components/plan/PlanRowTitle';
import { CardRow, Left, Right } from 'components/plan/onboarding/OnboardingCard';

import { BudgetVariability } from 'common/generated/graphql';

type Props = {
  icon: string;
  name: string;
  variability?: BudgetVariability | null;
};

const OnboardingCategoryVariabilityCardRow = ({ icon, name, variability }: Props) => {
  const [selectedVariability, setSelectedVariability] = useState<BudgetVariability>(
    variability || BudgetVariability.FIXED,
  );

  return (
    <CardRow $withTopBorder>
      <Left>
        <PlanRowTitle icon={icon}>
          <span>{name}</span>
        </PlanRowTitle>
      </Left>
      <Right>
        <ButtonGroup>
          <VariabilityButton
            active={selectedVariability === BudgetVariability.FIXED}
            onClick={() => setSelectedVariability(BudgetVariability.FIXED)}
            text="Fixed"
          />
          <VariabilityButton
            active={selectedVariability === BudgetVariability.FLEXIBLE}
            onClick={() => setSelectedVariability(BudgetVariability.FLEXIBLE)}
            text="Flexible"
          />
          <VariabilityButton
            active={selectedVariability === BudgetVariability.NON_MONTHLY}
            onClick={() => setSelectedVariability(BudgetVariability.NON_MONTHLY)}
            text="Non-monthly"
          />
        </ButtonGroup>
      </Right>
    </CardRow>
  );
};

const VariabilityButton = ({
  active,
  onClick,
  text,
}: {
  active: boolean;
  onClick: () => void;
  text: string;
}) => (
  <DefaultButton size="xsmall" active={active} onClick={onClick}>
    <Text>{text}</Text>
  </DefaultButton>
);

export default OnboardingCategoryVariabilityCardRow;
