import React from 'react';
import styled from 'styled-components';

import FeatureOnboardingPage from 'components/lib/layouts/FeatureOnboardingPage';
import Badge from 'components/lib/ui/Badge';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import useBudgetSystem from 'common/lib/hooks/budget/useBudgetSystem';

import * as COPY from 'common/constants/copy';

import { BudgetSystem } from 'common/generated/graphql';

const Column = styled(FlexContainer).attrs({ full: true, column: true })`
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  border-radius: ${({ theme }) => theme.radius.medium};
  background: ${({ theme }) => theme.color.white};

  max-width: 704px;
`;

const ChoiceContainer = styled(FlexContainer).attrs({
  alignCenter: true,
  justifyBetween: true,
  full: true,
})`
  padding: ${({ theme }) => theme.spacing.default};

  &:first-child {
    border-bottom: ${({ theme }) => theme.color.grayBackground} 1px solid;
  }
`;

const ChoiceTitle = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xxxsmall};
`;

const ChoiceDescription = styled.div`
  max-width: 528px;
`;

const StyledBadge = styled(Badge)`
  text-transform: none;
  letter-spacing: unset;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-left: ${({ theme }) => theme.spacing.small};
`;

const ContinueButton = styled(PrimaryButton)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  onSelectFixFlexBudget: () => void;
  onSelectCategoryBudget: () => void;
  onBack: () => void;
  onCancel: () => void;
  progress: number;
};

const OnboardingMethodChoice = ({
  onSelectFixFlexBudget,
  onSelectCategoryBudget,
  onBack,
  onCancel,
  progress,
}: Props) => {
  const { updateBudgetSystem } = useBudgetSystem();

  const handleSelectFixFlexBudget = async () => {
    await updateBudgetSystem(BudgetSystem.FIXED_AND_FLEX, {
      onDone: onSelectFixFlexBudget,
    });
  };

  const handleSelectCategoryBudget = async () => {
    await updateBudgetSystem(BudgetSystem.GROUPS_AND_CATEGORIES, {
      onDone: onSelectCategoryBudget,
    });
  };

  return (
    <FeatureOnboardingPage
      pageName="How do you want to budget?"
      title={COPY.BUDGET.ONBOARDING.METHOD.TITLE}
      description={COPY.BUDGET.ONBOARDING.METHOD.DESCRIPTION}
      descriptionMaxWidth={624}
      hideNextButton
      progress={progress}
      onClickBack={onBack}
      onClickCancel={onCancel}
    >
      <Column>
        <ChoiceContainer>
          <div>
            <ChoiceTitle>
              <Text weight="medium">Flex Budget™</Text>
              <StyledBadge color="orange">Recommended</StyledBadge>
            </ChoiceTitle>
            <ChoiceDescription>
              <Text size="small">
                Organize your spending categories into <Text weight="medium">Fixed</Text>,
                <Text weight="medium"> Flexible</Text>, and
                <Text weight="medium"> Non-Monthly</Text> to make creating and sticking to a budget
                a breeze. This is great if you don&apos;t want to maintain an itemized budget.
              </Text>
            </ChoiceDescription>
          </div>
          <div>
            <ContinueButton onClick={handleSelectFixFlexBudget}>Continue</ContinueButton>
          </div>
        </ChoiceContainer>

        <ChoiceContainer>
          <div>
            <ChoiceTitle>
              <Text weight="medium">Category Budget</Text>
            </ChoiceTitle>
            <ChoiceDescription>
              <Text size="small">
                Organize your income and spending categories by type, and budget each category. This
                is great if you like to set a budget amount for every category and track each
                individually.
              </Text>
            </ChoiceDescription>
          </div>
          <div>
            <ContinueButton onClick={handleSelectCategoryBudget}>Continue</ContinueButton>
          </div>
        </ChoiceContainer>
      </Column>
    </FeatureOnboardingPage>
  );
};

export default OnboardingMethodChoice;
