import { prop } from 'ramda';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import { color, spacing } from 'common/lib/theme/dynamic';

const ICON_SIZE_PX = 42;
const CONTAINER_WIDTH_PX = 240;

const Root = styled(FlexContainer).attrs({ center: true, column: true, gap: 'xxsmall' })<{
  width: number;
}>`
  width: ${prop('width')}px;
  margin: ${spacing.xxxxlarge} auto;
  text-align: center;
`;

const CardIcon = styled(Icon).attrs({ size: ICON_SIZE_PX })`
  color: ${color.gray};
  margin-bottom: ${spacing.xxsmall};
`;

type Props = {
  title?: string;
  iconName?: string;
  width?: number;
};

const EmptyStateCard: React.FC<React.PropsWithChildren<Props>> = ({
  title = 'No data found',
  iconName,
  width = CONTAINER_WIDTH_PX,
  children,
}) => (
  <Root width={width}>
    {iconName && <CardIcon name={iconName} />}
    <Text weight="medium">{title}</Text>
    <Text size="small">
      <FlexContainer gap="small" column center>
        {/* Adding a div so that the children are not wrapped in the flex container */}
        <div>{children}</div>
      </FlexContainer>
    </Text>
  </Root>
);

export default EmptyStateCard;
