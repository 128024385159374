import styled from 'styled-components';

import { color, shadow } from 'common/lib/theme/dynamic';

const Popover = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};
  box-shadow: ${shadow.large};
  border: 1px solid ${color.grayLight};
`;

export default Popover;
