import type { DateTime } from 'luxon';

import type { GetBudgetStatus_budgetStatus } from 'common/generated/graphQlTypes/GetBudgetStatus';

/**
 * Only handles month timeframe right now. If we add more timeframes in the future we will have to modify this.
 * Returns exclusive range, i.e. 03-01-2020 -> 03-31-2020
 * Whereas a budget has an inclusive end date, i.e. 03-01-2020 -> 04-01-2020
 * */
export const getDateRangeForBudgetStartDate = (
  startDate: DateTime,
): { startDate: string; endDate: string } => ({
  startDate: startDate.toISODate(),
  endDate: startDate.plus({ months: 1 }).minus({ days: 1 }).toISODate(),
});

export const getEmptyStateCopy = (budgetStatus: GetBudgetStatus_budgetStatus) => {
  const FROM_SPENDING_HISTORY =
    'We’ll create one for you based on your spending history, and you can fully customize it from there.';

  const FROM_EMPTY_DEFAULT_CATEGORIES =
    'We’ll create a sample plan to get you started, and you can fully customize it from there.';

  const copy = budgetStatus.willCreateBudgetFromEmptyDefaultCategories
    ? FROM_EMPTY_DEFAULT_CATEGORIES
    : FROM_SPENDING_HISTORY;

  return copy;
};
