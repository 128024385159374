import THEME from 'common/lib/theme';

const { color } = THEME;

export const ACCEPTED_COLORS = [
  color.red,
  color.orange,
  color.yellow,
  color.green,
  color.teal,
  color.blue,
  color.navy,
  color.purple,
];
