import { gql } from '@apollo/client';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

export const GET_GOAL_TYPES_QUERY = gql`
  query GetGoalTypes {
    goalTypes {
      display
      icon
      name
    }
  }
`;

export const GOAL_PLANNED_CONTRIBUTION_FIELDS_FRAGMENT = gql`
  fragment GoalPlannedContributionFields on GoalPlannedContribution {
    id
    amount
    startDate
    goal {
      id
    }
  }
`;

export const CREATE_GOAL_MUTATION = gql`
  mutation Common_CreateGoalMutation($input: CreateGoalMutationInput!) {
    createGoal(input: $input) {
      errors {
        ...PayloadErrorFields
      }
      goal {
        id
        name
        icon
        targetAmount
        targetDate
        plannedMonthlyContribution
        goalType
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const UPDATE_GOAL_MUTATION = gql`
  mutation Common_UpdateGoalMutation($input: UpdateGoalMutationInput!) {
    updateGoal(input: $input) {
      errors {
        ...PayloadErrorFields
      }
      goal {
        id
        name
        icon
        targetAmount
        targetDate
        plannedMonthlyContribution
        goalType
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const DELETE_GOAL_MUTATION = gql`
  mutation Common_DeleteGoalMutation($id: UUID!) {
    deleteGoal(id: $id) {
      errors {
        ...PayloadErrorFields
      }
      deleted
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const GET_GOAL_BALANCES_QUERY = gql`
  query GetGoalBalances {
    goalBalances {
      availableBalance
      totalSavingsBalance
      totalContributedBalance
    }
  }
`;

export const UPDATE_OR_CREATE_GOAL_PLANNED_CONTRIBUTION_MUTATION = gql`
  mutation Common_UpdateOrCreateGoalPlannedContributionMutation(
    $input: UpdateOrCreateGoalPlannedContributionMutationInput!
  ) {
    updateOrCreateGoalPlannedContribution(input: $input) {
      goalPlannedContribution {
        ...GoalPlannedContributionFields
      }
    }
  }
  ${GOAL_PLANNED_CONTRIBUTION_FIELDS_FRAGMENT}
`;

export const UPDATE_GOAL_CONTRIBUTION_MUTATION = gql`
  mutation Common_UpdateGoalContributionMutation($input: UpdateGoalContributionMutationInput!) {
    updateGoalContribution(input: $input) {
      errors {
        ...PayloadErrorFields
      }
      goalContribution {
        id
        date
        amount
        type
        label
        notes
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const DELETE_GOAL_CONTRIBUTION_MUTATION = gql`
  mutation Common_DeleteGoalContributionMutation($id: UUID!) {
    deleteGoalContribution(id: $id) {
      errors {
        ...PayloadErrorFields
      }
      deleted
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const GET_DASHBOARD_GOALS_QUERY = gql`
  query GetDashboardGoals {
    goals {
      id
      name
      icon
      targetAmount
      targetDate
      contributedBalance
      completedAt
    }
    goalBalances {
      availableBalance
      totalSavingsBalance
      totalContributedBalance
    }
  }
`;

export const CREATE_GOAL_CONTRIBUTIONS_MUTATION = gql`
  mutation Common_CreateGoalContributionsMutation($input: [CreateGoalContributionsMutationInput]!) {
    createGoalContributions(input: $input) {
      goalContributions {
        id
        amount
        goal {
          id
          contributedBalance
          name
          icon
          targetDate
          targetAmount
          completedAt
          projectedCompletionDateGivenContribution(proposedContributionAmount: 0)
        }
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const UPDATE_HAS_SEEN_TOUR_MUTATION = gql`
  mutation Common_UpdateHasSeenGoalsTour($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      userProfile {
        id
        hasSeenGoalsFeatureTour
      }
    }
  }
`;

export const GET_PROJECTED_COMPLETION_DATE_QUERY = gql`
  query GetGoalProjectedCompletion($id: UUID!, $proposedContributionAmount: Float!) {
    goal(id: $id) {
      id
      projectedCompletionDateGivenContribution(
        proposedContributionAmount: $proposedContributionAmount
      )
    }
  }
`;
