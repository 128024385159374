import React from 'react';
import styled from 'styled-components';

import FeatureOnboardingPage from 'components/lib/layouts/FeatureOnboardingPage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import { CardWithHeavyShadow } from 'components/plan/onboarding/OnboardingCard';

import { spacing } from 'common/lib/theme/dynamic';

import * as COPY from 'common/constants/copy';

const StyledCard = styled(CardWithHeavyShadow)`
  max-width: 556px;
`;

const VariabilityContainer = styled(FlexContainer).attrs({
  alignCenter: true,
  justifyBetween: true,
  full: true,
})`
  padding: ${spacing.default};

  &:not(:last-child) {
    border-bottom: ${({ theme }) => theme.color.grayBackground} 1px solid;
  }
`;

const VariabilityTitle = styled.div`
  margin-bottom: ${spacing.xxxsmall};
`;

const VariabilityDescription = styled.div`
  margin-bottom: ${spacing.xsmall};
  max-width: 445px;
`;

const GreatFor = styled.div`
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.textLight};
  margin-right: ${spacing.small};
`;

const CategoryBadge = styled.div`
  display: inline-flex;
  padding: ${spacing.xxsmall} ${spacing.small};
  justify-content: center;
  align-items: center;
  gap: ${spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  background: ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.pill};

  margin-right: ${spacing.xxsmall};
`;

type Props = {
  onBack: () => void;
  onCancel: () => void;
  onNext: () => void;
  progress: number;
};

const OnboardingFlexIntroduction = ({ onBack, onCancel, onNext, progress }: Props) => (
  <FeatureOnboardingPage
    pageName="How the Flex Budget works"
    title={COPY.BUDGET.ONBOARDING.FLEX_INTRODUCTION.TITLE}
    description={
      <Text>
        On the next step, you&apos;ll mark each of your categories as either{' '}
        <Text weight="medium">Fixed</Text>, <Text weight="medium">Flexible</Text>, or{' '}
        <Text weight="medium">Non-Monthly</Text>. Here&apos;s what these words mean:
      </Text>
    }
    descriptionMaxWidth={496}
    progress={progress}
    onClickBack={onBack}
    onClickNext={onNext}
    onClickCancel={onCancel}
  >
    <StyledCard>
      <FlexContainer full column>
        {COPY.FIXED_FLEXIBLE_NON_MONTHLY_INTRODUCTION.map((item) => (
          <VariabilityContainer key={item.title}>
            <div>
              <VariabilityTitle>
                <Text weight="medium">{item.title}</Text>
              </VariabilityTitle>
              <VariabilityDescription>
                <Text size="small">{item.description}</Text>
              </VariabilityDescription>
              <FlexContainer alignCenter>
                <GreatFor>Great for</GreatFor>
                {item.greatFor.map((item) => (
                  <CategoryBadge key={item}>{item}</CategoryBadge>
                ))}
              </FlexContainer>
            </div>
          </VariabilityContainer>
        ))}
      </FlexContainer>
    </StyledCard>
  </FeatureOnboardingPage>
);

export default OnboardingFlexIntroduction;
