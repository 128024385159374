import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import * as RA from 'ramda-adjunct';
import React, { useMemo, useContext, useEffect, useState, useCallback } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import AddHoldingModal from 'components/holdings/AddHoldingModal';
import InvestmentsHoldingsBenchmarksHeader from 'components/investments/InvestmentsHoldingsBenchmarksHeader';
import InvestmentsHoldingsPerformanceGraph from 'components/investments/InvestmentsHoldingsPerformanceGraph';
import InvestmentsHoldingsTable from 'components/investments/InvestmentsHoldingsTable';
import {
  holdingsGroupByOptions,
  HoldingGroupByValues,
} from 'components/investments/holdingsGroupByOptions';
import Form from 'components/lib/form/Form';
import SelectField from 'components/lib/form/SelectField';
import RouteModal from 'components/lib/routing/RouteModal';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import HelpIconTooltip from 'components/lib/ui/HelpIconTooltip';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import useQuery from 'common/lib/hooks/useQuery';
import { findDefaultBenchmark } from 'common/lib/investments/benchmarks';
import { sortHoldingsBySecurityType } from 'common/lib/investments/table';
import { flattenEdgeNodes } from 'common/lib/list';
import typewriter from 'lib/analytics/typewriter';
import PageWithNoAccountsEmptyStateContext from 'lib/contexts/PageWithNoAccountsEmptyStateContext';
import usePerformanceChartColors from 'lib/hooks/investments/usePerformanceChartColors';
import useMockDataWhenNoAccountsQuery from 'lib/hooks/useMockDataWhenNoAccountsQuery';
import {
  historicalPerformanceQueryToLineChartDataAdapter,
  getBenchmarkHeaderBenchmarkData,
  getBenchmarkHeaderPortfolioData,
} from 'lib/investments/adapters';
import { getDoesNotHaveAccounts } from 'state/emptyState/selectors';

import { INVESTMENTS_TABLE_EMPTY_STATE_NEW } from 'common/constants/copy';
import routes from 'constants/routes';

import type {
  Web_GetPortfolio,
  Web_GetPortfolioVariables,
} from 'common/generated/graphQlTypes/Web_GetPortfolio';
import type {
  Web_GetSecuritiesHistoricalPerformance,
  Web_GetSecuritiesHistoricalPerformanceVariables,
} from 'common/generated/graphQlTypes/Web_GetSecuritiesHistoricalPerformance';
import type DateRange from 'common/types/DateRange';
import type { SecurityInterface } from 'common/types/investments';

const Root = styled(FlexContainer).attrs({ column: true })`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.gutter};
`;

const HelpTooltip = styled(HelpIconTooltip)`
  height: ${({ theme }) => theme.spacing.default};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const TooltipContent = styled.div`
  margin: ${({ theme }) => theme.spacing.xsmall};
`;

const TooltipFooterText = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xsmall};

  a {
    color: ${({ theme }) => theme.color.blue};

    &:hover {
      color: ${({ theme }) => theme.color.blue};
      text-decoration: underline;
    }
  }
`;

const HoldingsActionContainer = styled(Flex)`
  gap: ${({ theme }) => theme.spacing.default};
`;

const StyledSelectField = styled(SelectField)`
  width: 200px;
`;

const TableContainer = styled.div`
  transform: scale(1); /* create new stacking context */
`;

const TableHeaderContainer = styled(FlexContainer)`
  position: relative;
  z-index: 1; /* stylelint-disable-line plugin/no-z-index */

  align-items: center;
  background: ${(props) => props.theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};
  padding: ${(props) => props.theme.spacing.large} ${(props) => props.theme.spacing.large}
    ${(props) => props.theme.spacing.default} ${(props) => props.theme.spacing.large};
  transform: translateY(8px);
  justify-content: space-between;
  margin-top: -8px;
`;

const TableContentsContainer = styled.div`
  position: relative;
  z-index: 0; /* stylelint-disable-line plugin/no-z-index */
`;

const PlusIcon = styled(Icon).attrs({ name: 'plus', size: 14 })`
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  activeAccountIds: string[];
  dateRange: DateRange;
  onClickLinkAccount: () => void;
  useMockData?: boolean;
};

const HoldingsTableHelpTooltip = () => (
  <HelpTooltip
    tooltip={
      <TooltipContent>
        <TooltipFooterText>
          Market prices for investments are provided by{' '}
          <a
            href="https://site.financialmodelingprep.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Financial Modeling Prep
          </a>
          .
        </TooltipFooterText>
      </TooltipContent>
    }
    clickable
  />
);

const getTimeFrameText = (dateRange: DateRange) => {
  const { startDate, endDate } = dateRange;
  if (startDate) {
    switch (startDate) {
      case DateTime.local().minus({ years: 1 }).toISODate():
        return 'Past 1 Year';
      case DateTime.local().minus({ years: 3 }).toISODate():
        return 'Past 3 Years';
    }
  }
  if (startDate && endDate) {
    const startDateTime = DateTime.fromISO(startDate);
    const endDateTime = DateTime.fromISO(endDate);
    const { days } = endDateTime.diff(startDateTime, 'days');
    return `Past ${days} ${pluralize('day', days)}`;
  } else {
    return 'Custom Range';
  }
};

const InvestmentsHoldings = ({ activeAccountIds, dateRange, useMockData }: Props) => {
  const [holdingsGroupBy, setHoldingsGroupBy] = useState(HoldingGroupByValues.Type);
  const [holdingsSortBy, setHoldingsSortBy] = useState<{ id: string; desc?: boolean } | null>(null);

  const history = useHistory();
  useEffect(() => {
    typewriter.viewedInvestmentPerformancePage();
  }, []);
  const doesNotHaveAccounts = useSelector(getDoesNotHaveAccounts);

  const {
    selectedAggregateHoldingInfos,
    selectedBenchmarks,
    selectHolding,
    selectBenchmarkHolding,
  } = usePerformanceChartColors();
  const [activeBenchmarkIndex, setActiveBenchmarkIndex] = useState(-1);
  const [selectedSecurityId, setSelectedSecurityId] = useState<GraphQlUUID | undefined>(undefined);

  const {
    data,
    isLoadingInitialData: isLoadingHoldings,
    refetch,
  } = useMockDataWhenNoAccountsQuery<
    Web_GetPortfolio,
    // @ts-ignore
    Web_GetPortfolioVariables
  >(GET_HOLDINGS_QUERY, {
    forceMockData: useMockData,
    variables: {
      portfolioInput: {
        accountIds: activeAccountIds.length > 0 ? activeAccountIds : undefined,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      },
    },
  });

  const isEmptyDataStillEnabled = selectedBenchmarks
    .concat(selectedAggregateHoldingInfos)
    .some(({ id }) => !parseInt(id));

  const {
    data: historicalPerformanceData,
    isNetworkRequestInFlight,
    isLoadingInitialData,
  } = useQuery<
    Web_GetSecuritiesHistoricalPerformance,
    Web_GetSecuritiesHistoricalPerformanceVariables
  >(GET_HISTORICAL_PERFORMANCE_QUERY, {
    variables: {
      input: {
        securityIds: selectedBenchmarks
          .concat(selectedAggregateHoldingInfos)
          .map(({ id }) => id)
          // This filtering is a guard rail against an issue caused by
          // mock data with invalid security IDs being used in the query after
          // immediately after the first account is created. We had a fix in place
          // (see comment below) but that was not completely effective, so the way
          // isEmptyDataStillEnabled was updated, but we decided to leave this filter
          // just in case. We should probably improve how we are injecting mock data
          //  here so that we never get invalid security IDs in the query.
          .filter((id) => parseInt(id)),
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      },
    },
    // We need isEmptyDataStillEnabled because when disabling empty state (ie after linking
    // accounts, via demo menu), this query is executed before necessary, resulting in errors.
    // As we don't generate IDs for the securities (we just assign the ticker as the ID), it's
    // easy to know when empty state data is still being used and skip this query.
    // See: https://linear.app/monarch/issue/ENG-2839/skip-securityhistoricalperformance-query-if-empty-state-is-on
    skip: doesNotHaveAccounts || isEmptyDataStillEnabled || useMockData,
  });

  const portfolioHistoricalChart = data?.portfolio.performance.historicalChart ?? [];
  const portfolioPerformanceData = data?.portfolio.performance;
  const holdingsMeta = data?.portfolio.performance;
  const comparisonData = historicalPerformanceData?.securityHistoricalPerformance ?? [];
  const benchmarkData = useMemo(
    () => data?.portfolio.performance.benchmarks ?? [],
    [data?.portfolio.performance.benchmarks],
  );

  const chartData = historicalPerformanceQueryToLineChartDataAdapter(
    portfolioHistoricalChart,
    comparisonData,
    benchmarkData,
  );
  const benchmarkHeaderBenchmarkData = getBenchmarkHeaderBenchmarkData(portfolioPerformanceData);
  const benchmarkHeaderPortfolioData = getBenchmarkHeaderPortfolioData(portfolioPerformanceData);

  const setActiveBenchmark = useCallback(
    (security: SecurityInterface | null, i: number) => {
      setActiveBenchmarkIndex(i);
      selectBenchmarkHolding(security);
    },
    [setActiveBenchmarkIndex, selectBenchmarkHolding],
  );

  const { addAccountAddedListener } = useContext(PageWithNoAccountsEmptyStateContext);

  useEffect(() => {
    addAccountAddedListener(refetch);
  }, []);

  useEffect(() => {
    if (benchmarkData && benchmarkData.length > 0 && activeBenchmarkIndex < 0) {
      const defaultBenchmark = findDefaultBenchmark(benchmarkData);
      if (RA.isNotNilOrEmpty(defaultBenchmark)) {
        const { id, ticker, name } = defaultBenchmark?.security ?? {};
        // @ts-ignore
        setActiveBenchmark({ id, ticker, name }, 0);
      }
    }
  }, [benchmarkData, activeBenchmarkIndex, setActiveBenchmark]);

  const holdings = useMemo(
    () =>
      sortHoldingsBySecurityType(flattenEdgeNodes(data?.portfolio.aggregateHoldings.edges ?? [])),
    [data],
  );

  return (
    <>
      <Helmet>
        <title>Monarch | Investment Holdings</title>
      </Helmet>
      <Root>
        <InvestmentsHoldingsBenchmarksHeader
          benchmarkData={benchmarkHeaderBenchmarkData}
          portfolioData={benchmarkHeaderPortfolioData}
          activeBenchmarkIndex={activeBenchmarkIndex}
          setActiveBenchmark={setActiveBenchmark}
          timeFrameText={getTimeFrameText(dateRange)}
          isLoading={isLoadingHoldings}
        />
        <InvestmentsHoldingsPerformanceGraph
          selectedAggregateHoldingInfos={selectedAggregateHoldingInfos}
          selectedBenchmarks={selectedBenchmarks}
          data={chartData}
          isLoading={isNetworkRequestInFlight || isLoadingInitialData}
        />
        <TableContainer>
          {(holdings.length > 0 || isLoadingHoldings) && (
            <TableHeaderContainer>
              <FlexContainer alignCenter>
                <Text size="xlarge" weight="medium">
                  Holdings
                </Text>
                <HoldingsTableHelpTooltip />
              </FlexContainer>
              <HoldingsActionContainer>
                <Form
                  initialValues={{ groupBy: holdingsGroupBy }}
                  onSubmit={({ groupBy }) => setHoldingsGroupBy(groupBy)}
                  submitOnChange
                >
                  <StyledSelectField
                    hideLabel
                    name="groupBy"
                    placeholder="Group by type"
                    options={holdingsGroupByOptions}
                    onChange={(value: HoldingGroupByValues) => setHoldingsGroupBy(value)}
                  />
                </Form>

                <PrimaryButton
                  className="fs-add-manual-holding"
                  linkTo={routes.investments.holdings.addHolding()}
                >
                  <PlusIcon />
                  Add holding
                </PrimaryButton>
              </HoldingsActionContainer>
            </TableHeaderContainer>
          )}
          <TableContentsContainer>
            <InvestmentsHoldingsTable
              holdings={holdings}
              groupBy={holdingsGroupBy}
              meta={holdingsMeta}
              isLoading={isLoadingHoldings}
              onHoldingDrawerClose={() => setSelectedSecurityId(undefined)}
              onClickHoldingChevron={setSelectedSecurityId}
              selectedSecurityId={selectedSecurityId}
              onClickHolding={selectHolding}
              selectedAggregateHoldingInfos={selectedAggregateHoldingInfos}
              onClickAddHolding={() => history.push(routes.investments.holdings.addHolding.path)}
              timeFrameText={getTimeFrameText(dateRange)}
              noHoldingsEmptyStateTitle={INVESTMENTS_TABLE_EMPTY_STATE_NEW.TITLE}
              noHoldingsEmptyStateSubtitle={INVESTMENTS_TABLE_EMPTY_STATE_NEW.SUBTITLE}
              noHoldingsEmptyStateButtonText={INVESTMENTS_TABLE_EMPTY_STATE_NEW.BUTTON_TITLE}
              sortBy={holdingsSortBy}
              setSortBy={setHoldingsSortBy}
            />
          </TableContentsContainer>
        </TableContainer>
      </Root>
      <RouteModal path={routes.investments.holdings.addHolding.path}>
        {({ close }) => (
          <AddHoldingModal
            onDone={() => {
              close();
              refetch();
            }}
            onAddMore={() => {
              history.push(routes.investments.holdings.addHolding.path);
            }}
            onCancel={close}
          />
        )}
      </RouteModal>
    </>
  );
};

const GET_HOLDINGS_QUERY = gql`
  query Web_GetPortfolio($portfolioInput: PortfolioInput) {
    portfolio(input: $portfolioInput) {
      performance {
        totalValue
        totalBasis
        totalChangePercent
        totalChangeDollars
        oneDayChangePercent
        historicalChart {
          date
          returnPercent
        }
        benchmarks {
          security {
            id
            ticker
            name
            oneDayChangePercent
          }
          historicalChart {
            date
            returnPercent
          }
        }
      }
      aggregateHoldings {
        edges {
          node {
            id
            quantity
            basis
            totalValue
            securityPriceChangeDollars
            securityPriceChangePercent
            lastSyncedAt
            holdings {
              id
              type
              typeDisplay
              name
              ticker
              closingPrice
              closingPriceUpdatedAt
              quantity
              value
              account {
                id
                mask
                icon
                logoUrl
                institution {
                  id
                  name
                }
                type {
                  name
                  display
                }
                subtype {
                  name
                  display
                }
                displayName
                currentBalance
              }
            }
            security {
              id
              name
              ticker
              currentPrice
              currentPriceUpdatedAt
              closingPrice
              closingPriceUpdatedAt
              type
              typeDisplay
            }
          }
        }
      }
    }
  }
`;

const GET_HISTORICAL_PERFORMANCE_QUERY = gql`
  query Web_GetSecuritiesHistoricalPerformance($input: SecurityHistoricalPerformanceInput!) {
    securityHistoricalPerformance(input: $input) {
      security {
        id
      }
      historicalChart {
        date
        returnPercent
      }
    }
  }
`;
export default InvestmentsHoldings;
