import type { PickerProps } from 'emoji-mart';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  .emoji-mart {
    font-family: 'DM Sans', sans-serif;
    color: ${({ theme }) => theme.color.text};
    border: none;
    background: none;

    &-anchor {
      color: ${({ theme }) => theme.color.textLight};
      :hover {
        color: ${({ theme }) => theme.color.blue};
      }
    }

    > .emoji-mart-bar {
      border-color: ${({ theme }) => theme.color.grayLight};
      :last-child {
        display: none;
      }
    }

    &-anchor-selected {
      color: ${({ theme }) => theme.color.blue} !important;

      .emoji-mart-anchor-bar {
        background-color: ${({ theme }) => theme.color.blue} !important;
      }
    }

    &-search {
      > input {
        border-color: ${({ theme }) => theme.color.grayFocus};

        ::placeholder {
          color: ${({ theme }) => theme.color.textLight};
        }
      }
      &-icon {
        color: ${({ theme }) => theme.color.textLight};
        > svg {
          opacity: 1;
          > path {
            fill: currentColor;
          }
        }
      }
    }

    &-scroll {
      margin-top: 6px;
    }

    &-category-label {
      background: ${({ theme }) => theme.color.grayBackground};
      color: ${({ theme }) => theme.color.textLight};
      font-size: ${({ theme }) => theme.fontSize.small};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      padding: 6px ${({ theme }) => theme.spacing.small};
      margin: 0 -6px;

      > span {
        background-color: ${({ theme }) => theme.color.grayBackground};
        padding: 0;
      }
    }
  }
`;

const EmojiPicker = (props: Partial<PickerProps>) => (
  <Root>
    <Picker set="apple" showPreview={false} autoFocus native custom={[]} {...props} />
  </Root>
);

export default EmojiPicker;
