import React, { useMemo } from 'react';
import { BarChart as RechartsBarChart, XAxis, YAxis, Bar, Cell, Tooltip } from 'recharts';
import type {
  BarChartProps,
  TickFormatterFunction,
  DataKey,
  YAxisProps,
  XAxisProps,
  CartesianGridProps,
} from 'recharts';
import styled from 'styled-components';

import CartesianGrid from 'components/lib/charts/CartesianGrid';
import type { TooltipComponentProps } from 'components/lib/charts/ChartTooltip';
import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import FlexContainer from 'components/lib/ui/FlexContainer';

import THEME from 'common/lib/theme';
import useTheme from 'lib/hooks/useTheme';

const DEFAULT_MARGIN_PX = parseInt(THEME.spacing.xxlarge, 10);
const DEFAULT_TICK_MARGIN_PX = parseInt(THEME.spacing.xsmall, 10);
const DEFAULT_ANIMATION_MS = 400;

const Root = styled(FlexContainer)`
  /* Hide last horizontal line of cartesian grid (so the XAxis border isn't overlapped by it) */
  g.recharts-cartesian-grid-horizontal > line:first-child {
    stroke-opacity: 0;
  }

  .recharts-tooltip-wrapper {
    /** We can't adjust the index by just editing our tooltip */
    z-index: 1; /* stylelint-disable-line plugin/no-z-index */
  }
`;

type BarDataKey = { name: string; color: string };
// eslint-disable-next-line
type Props<TDatum extends object> = Omit<BarChartProps, 'data' | 'width' | 'height' | 'barSize'> & {
  data: TDatum[];
  width: number;
  height: number;
  xDataKey: keyof TDatum;
  yDataKey: keyof TDatum;
  barWidth?: number;
  barDataKeys: BarDataKey[];
  xAxisFormatter?: (value: TDatum) => string | number;
  yAxisFormatter?: TickFormatterFunction;
  onClickBar?: (value: TDatum) => void;
  tooltipComponent?: TooltipComponentProps<string, string, TDatum>;
  dashed?: boolean;
  roundBarCorners?: boolean;
  yAxisProps?: YAxisProps;
  xAxisProps?: XAxisProps;
  cartesianGridProps?: CartesianGridProps;
  customTickStyle?: Record<string, unknown>;
  disableAnimation?: boolean;
  tickMargin?: number;
};

const BarChart = <TDatum extends Record<string, string | number | symbol>>({
  data,
  width,
  height,
  xDataKey,
  yDataKey,
  barWidth,
  barDataKeys = [],
  xAxisFormatter,
  yAxisFormatter,
  onClickBar,
  tooltipComponent,
  dashed = true,
  roundBarCorners,
  yAxisProps,
  xAxisProps,
  cartesianGridProps,
  customTickStyle,
  disableAnimation,
  tickMargin,
  ...chartProps
}: Props<TDatum>) => {
  const dataWithIndex = useMemo(() => data.map((datum, index) => ({ ...datum, index })), [data]);
  const theme = useTheme();

  const tickStyle = useMemo(
    () => ({
      fontSize: theme.fontSize.xsmall,
      fill: theme.color.textLight,
      fontWeight: parseInt(theme.fontWeight.medium, 10),
      ...customTickStyle,
    }),
    [theme, customTickStyle],
  );

  return (
    <Root alignCenter {...maskClassProps}>
      <RechartsBarChart
        data={dataWithIndex}
        width={width}
        height={height}
        margin={{ top: DEFAULT_MARGIN_PX, left: 5 }}
        {...chartProps}
      >
        <CartesianGrid dashed={dashed} {...cartesianGridProps} />
        {tooltipComponent && <Tooltip cursor={false} content={tooltipComponent} />}
        <XAxis
          dataKey="index"
          tickFormatter={(index) => xAxisFormatter?.(data[index]) ?? data[index][xDataKey]}
          tick={{
            ...tickStyle,
            transform: `translate(0, ${tickMargin ?? DEFAULT_TICK_MARGIN_PX})`,
          }}
          stroke={theme.color.gray}
          {...xAxisProps}
        />
        <YAxis
          dataKey={yDataKey as DataKey}
          tickFormatter={yAxisFormatter}
          tick={{
            ...tickStyle,
            transform: `translate(${tickMargin ?? DEFAULT_TICK_MARGIN_PX * -1}, 0)`,
          }}
          stroke={theme.color.gray}
          {...yAxisProps}
        />
        <Bar
          dataKey={yDataKey as DataKey}
          barSize={barWidth}
          animationBegin={DEFAULT_ANIMATION_MS}
          animationDuration={DEFAULT_ANIMATION_MS}
          cursor={onClickBar ? 'pointer' : undefined}
          radius={roundBarCorners ? [6, 6, 0, 0] : [0, 0, 0, 0]}
          onClick={onClickBar}
          fill={theme.color.gray}
          isAnimationActive={!disableAnimation}
        >
          {barDataKeys.map(({ name, color }) => (
            <Cell key={name} fill={color} />
          ))}
        </Bar>
      </RechartsBarChart>
    </Root>
  );
};

export default BarChart;
