import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import AccountSummaryCardGroup from 'components/accounts/AccountSummaryCardGroup';
import Card from 'components/lib/ui/Card';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import TextButton from 'components/lib/ui/TextButton';

import { getAccountSummaryComposition } from 'lib/accounts/AccountGraphs';

import type { Web_GetAccountsPageQuery } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

type AccountSummary = ElementOf<Web_GetAccountsPageQuery, 'accountTypeSummaries'>;

const CardSection = styled.div`
  :not(:last-child) {
    padding: ${({ theme }) => theme.spacing.xlarge};
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }

  :last-child {
    padding: 0;
  }
`;

const Spinner = styled(LoadingSpinner)`
  width: 21px;
  height: 21px;
`;

const FooterButton = styled(TextButton)`
  padding: ${({ theme }) => theme.spacing.default};
  margin: 0 auto;
`;

type Props = {
  onClickAccountType: (accountType: string) => void;
  onClickDownloadCsv?: () => Promise<void>;
  isDownloadingCsv?: boolean;
  accountSummaries: AccountSummary[];
};

const AccountsSummaryCard = ({
  onClickAccountType,
  onClickDownloadCsv,
  isDownloadingCsv,
  accountSummaries,
}: Props) => {
  const [assets, liabilities] = R.partition<AccountSummary>(R.pathEq(['type', 'group'], 'asset'))(
    accountSummaries,
  );

  const assetComposition = getAccountSummaryComposition(assets);
  const liabilityComposition = getAccountSummaryComposition(liabilities);
  const assetsTotal = R.sum(R.map(R.prop('amount'), assetComposition));
  const liabilitiesTotal = R.sum(R.map(R.prop('amount'), liabilityComposition));

  return (
    <Card title="Summary">
      <AccountSummaryCardGroup
        title="Assets"
        items={assetComposition}
        total={assetsTotal}
        onClickType={onClickAccountType}
      />
      <AccountSummaryCardGroup
        title="Liabilities"
        items={liabilityComposition}
        total={liabilitiesTotal}
        onClickType={onClickAccountType}
      />
      <CardSection>
        <FooterButton onClick={onClickDownloadCsv}>
          {isDownloadingCsv ? <Spinner /> : 'Download CSV'}
        </FooterButton>
      </CardSection>
    </Card>
  );
};

export default AccountsSummaryCard;
