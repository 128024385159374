import { ellipsis } from 'polished';
import type { DefaultTheme } from 'styled-components';
import styled from 'styled-components';

const Text = styled.span<{
  weight?: keyof DefaultTheme['fontWeight'];
  italic?: boolean;
  size?: keyof DefaultTheme['fontSize'];
  color?: keyof DefaultTheme['color'];
  align?: 'left' | 'right' | 'center' | 'justify';
  transform?: 'uppercase' | 'lowercase';
  clampLines?: number;
}>`
  font-weight: ${({ weight, theme }) => (weight ? theme.fontWeight[weight] : 'inherit')};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  font-size: ${({ theme, size }) => (size ? theme.fontSize[size] : 'inherit')};
  color: ${({ theme, color }) => (color ? theme.color[color] : 'inherit')};
  text-align: ${({ align }) => align ?? 'inherit'};
  line-height: 150%;
  text-transform: ${({ theme, transform }) => transform ?? 'none'};

  ${({ clampLines }) => clampLines && ellipsis(undefined, clampLines)};
`;

export default Text;
