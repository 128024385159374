import { useQuery } from '@apollo/client';
import type { PaymentMethod } from '@stripe/stripe-js';
import * as RA from 'ramda-adjunct';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Card from 'components/lib/ui/ModalCard';
import type { Props as CancelSubscriptionModalProps } from 'components/settings/billing/CancelSubscriptionModal';
import SubscriptionModalBody from 'components/settings/billing/SubscriptionModalBody';

import { getDaysLeftOfTrial, getSubscriptionModalTitle } from 'common/lib/billing/Billing';
import useToggle from 'common/lib/hooks/useToggle';
import { useQueryParam } from 'lib/hooks';
import { getTemporaryPromoCode } from 'state/onboarding/selectors';

import { gql } from 'common/generated/gql';
import type { PaymentPeriod } from 'common/generated/graphQlTypes/globalTypes';
import type { Web_GetSubscriptionModalQuery } from 'common/generated/graphql';

export type Props = {
  createSubscription: (
    paymentMethod: PaymentMethod | null,
    stripePriceId: string,
    stripePromoCode: string | null,
  ) => Promise<void>;
  reactivateSubscription: (
    paymentMethod: PaymentMethod | null,
    stripePriceId: string,
    stripePromoCode: string | null,
  ) => Promise<void>;
  changeSubscription: (stripePriceId: string, stripePromoCode: string | null) => Promise<void>;
  onDone: () => void;
  next: (params: Omit<CancelSubscriptionModalProps, 'goBack'>) => void;
  goBack?: () => void;
} & Omit<CancelSubscriptionModalProps, 'goBack'>;

const getCurrentPlanIndex = (
  plans: Web_GetSubscriptionModalQuery['subscriptionOffering']['plans'][0][],
  currentBillingPeriod: PaymentPeriod | null | undefined,
): number => {
  // Returns -1 if not found
  const currentPlanIndex = plans.findIndex(({ period }) => period === currentBillingPeriod);
  return Math.max(currentPlanIndex, 0);
};

const SubscriptionModal = ({
  onDone,
  createSubscription,
  reactivateSubscription,
  changeSubscription,
  next,
  cancelMutation,
  onCancelSuccess,
  onCancelClick,
}: Props) => {
  const temporaryPromoCode = useSelector(getTemporaryPromoCode);
  const [appliedPromoCode, setAppliedPromoCode] = useState<string | null>(null);
  const initialPromoCode = useQueryParam('promo-code');
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);
  const [isLoading, { setOn: setLoading, setOff: setNotLoading }] = useToggle(false);
  const [fetchedPlan, { setOn: setFetchedPlanOn }] = useToggle(false);

  const { data } = useQuery(SUBSCRIPTION_MODAL_QUERY, {
    variables: { promoCode: appliedPromoCode },
  });

  const {
    billingPeriod: currentBillingPeriod,
    paymentMethod,
    trialEndsAt,
    hasPremiumEntitlement,
    willCancelAtPeriodEnd,
  } = data?.subscription ?? {};

  const hasPaymentMethod = RA.isNotNil(paymentMethod);
  const daysLeftOfTrial = getDaysLeftOfTrial(trialEndsAt);

  useEffect(() => {
    if (data && !fetchedPlan) {
      setSelectedPlanIndex(
        getCurrentPlanIndex(data.subscriptionOffering.plans, currentBillingPeriod),
      );
      setFetchedPlanOn();
    }
    if (selectedPlanIndex >= (data?.subscriptionOffering?.plans?.length || 0)) {
      setSelectedPlanIndex(0);
    }
  }, [data, currentBillingPeriod, fetchedPlan, setFetchedPlanOn, selectedPlanIndex]);

  useEffect(() => {
    if (initialPromoCode || temporaryPromoCode) {
      setAppliedPromoCode(initialPromoCode || (temporaryPromoCode ?? null));
    }
  }, [initialPromoCode]);

  return (
    <Card
      title={getSubscriptionModalTitle(
        Boolean(hasPremiumEntitlement),
        Boolean(willCancelAtPeriodEnd),
      )}
    >
      {data
        ? (() => {
            const {
              subscription: {
                billingPeriod: currentBillingPeriod,
                paymentSource,
                hasStripeSubscriptionId,
                hasPremiumEntitlement,
                willCancelAtPeriodEnd,
                isOnFreeTrial,
                referralRedemption,
              },
              subscriptionOffering: { plans, promoCodeDescription, promoCodeError },
            } = data;

            return (
              <SubscriptionModalBody
                referralRedemption={referralRedemption}
                hasPremiumEntitlement={hasPremiumEntitlement}
                willCancelAtPeriodEnd={willCancelAtPeriodEnd}
                plans={plans}
                selectedPlanIndex={selectedPlanIndex}
                setSelectedPlanIndex={setSelectedPlanIndex}
                promoCodeDescription={promoCodeDescription}
                promoCodeError={promoCodeError}
                daysLeftOfTrial={daysLeftOfTrial}
                hasPaymentMethod={hasPaymentMethod}
                paymentSource={paymentSource}
                currentBillingPeriod={currentBillingPeriod}
                isLoading={isLoading}
                setLoading={setLoading}
                setNotLoading={setNotLoading}
                appliedPromoCode={appliedPromoCode}
                createSubscription={createSubscription}
                reactivateSubscription={reactivateSubscription}
                changeSubscription={changeSubscription}
                hasStripeSubscriptionId={hasStripeSubscriptionId}
                onClickCancelSubscription={() =>
                  next({ cancelMutation, onCancelSuccess, onCancelClick, isOnFreeTrial })
                }
                setAppliedPromoCode={setAppliedPromoCode}
                onDone={onDone}
              />
            );
          })()
        : null}
    </Card>
  );
};

// The SubscriptionModalAnnualUpsell also uses this query while running the A/B test
export const SUBSCRIPTION_MODAL_QUERY = gql(`
  query Web_GetSubscriptionModal($promoCode: String) {
    subscription {
      id
      billingPeriod
      trialEndsAt
      hasPremiumEntitlement
      willCancelAtPeriodEnd
      isOnFreeTrial
      paymentSource
      paymentMethod {
        brand
        lastFour
      }
      hasStripeSubscriptionId
      hasChargedForLifetime
      referralRedemption {
        campaign
      }
    }
    subscriptionOffering(stripePromoCode: $promoCode) {
      promoCodeError
      promoCodeDescription
      promoCodeDuration
      promoCodeDurationInMonths
      plans {
        name
        period
        pricePerPeriodDollars
        discountedPricePerPeriodDollars
        stripeId
        newTrialEndsAt
        requirePaymentMethod
        sponsoredBy {
          name
          email
          profilePictureUrl
        }
      }
    }
  }
`);

export default SubscriptionModal;
