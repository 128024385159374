import { gql } from '@apollo/client';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import AccountLogosGroup from 'components/accounts/AccountLogosGroup';
import GoalImage from 'components/goalsV2/GoalImage';
import GoalNameProgressBar from 'components/goalsV2/GoalNameProgressBar';

import type { WebGoalCardFieldsFragment } from 'common/generated/graphql';

const Root = styled.div`
  background-color: white;
  border-radius: ${({ theme }) => theme.radius.medium};
  box-shadow: ${({ theme }) => theme.shadow.medium};
  flex: 1;
  overflow: hidden;
  position: relative;
  padding: ${({ theme }) => theme.spacing.xlarge};

  display: flex;
  align-items: flex-end;
`;

const StyledGoalImage = styled(GoalImage)``;

const StyledAccountLogosGroup = styled(AccountLogosGroup)`
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

type Props = {
  goal: WebGoalCardFieldsFragment;
  imageSize?: 'small' | 'medium' | 'large';
  right?: React.ReactNode;
  className?: string;
};

const GoalCard = ({ goal, imageSize = 'medium', right, className }: Props) => {
  const { imageStorageProvider, imageStorageProviderId, archivedAt, accountAllocations } = goal;

  const accounts = useMemo(
    () => accountAllocations.map(({ account }) => account),
    [accountAllocations],
  );

  return (
    <Root className={className}>
      <StyledGoalImage
        size={imageSize}
        showGradient
        imageStorageProvider={imageStorageProvider}
        imageStorageProviderId={imageStorageProviderId}
        archived={!!archivedAt}
      />
      <GoalNameProgressBar
        goal={goal}
        right={right ?? <StyledAccountLogosGroup accounts={accounts} />}
      />
    </Root>
  );
};

GoalCard.fragments = {
  WebGoalCardFields: gql`
    fragment WebGoalCardFields on GoalV2 {
      id
      imageStorageProvider
      imageStorageProviderId
      priority
      archivedAt
      accountAllocations {
        id
        account {
          id
          logoUrl
          icon
        }
      }
      ...WebGoalNameProgressBarFields
    }
    ${GoalNameProgressBar.fragments.WebGoalNameProgressBarFields}
  `,
};

export default GoalCard;
