import * as R from 'ramda';
import React, { useState, useRef, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import TextInput from 'components/lib/form/TextInput';
import Emoji from 'components/lib/ui/Emoji';
import Icon from 'components/lib/ui/Icon';
import * as Select from 'components/lib/ui/RadixSelect';
import { useSelectContext as radixUseContext } from 'components/lib/ui/RadixSelect';
import Text from 'components/lib/ui/Text';

import type { OptionType } from 'types/select';

const SelectIconWrapper = styled(Select.Icon)`
  opacity: 0;
  color: ${({ theme }) => theme.color.grayDark};
  margin-left: ${({ theme }) => theme.spacing.xxsmall};
`;

const SelectValue = styled.div<{ hasValue: boolean }>`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${({ hasValue }) =>
    !hasValue &&
    css`
      color: ${({ theme }) => theme.color.grayDark};
    `}
`;

// Content spills out 26px to the right when the trigger is full width
const ICON_OFFSET_PX = 26;

const SelectTrigger = styled(Select.Trigger)<{
  $alwaysShowBorder?: boolean;
  $fullWidthTrigger?: boolean;
}>`
  all: unset;
  cursor: default;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  height: 20px;
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.small};
  border: 1px solid transparent;
  width: auto;

  &:hover,
  &:focus {
    border: 1px solid ${({ theme }) => theme.color.gray};
    ${SelectIconWrapper} {
      opacity: 1;
    }
  }

  ${({ $alwaysShowBorder }) =>
    $alwaysShowBorder &&
    css`
      border: 1px solid ${({ theme }) => theme.color.grayLight};
      ${SelectIconWrapper} {
        opacity: 1;
      }
    `}

  ${({ $fullWidthTrigger }) =>
    $fullWidthTrigger &&
    css`
      width: calc(100% - ${ICON_OFFSET_PX}px);
    `}

  &[data-state='open'] {
    border: 1px solid ${({ theme }) => theme.color.gray};
    ${SelectIconWrapper} {
      opacity: 1;
    }
  }

  font-size: ${({ theme }) => theme.fontSize.base};

  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;

  &[data-disabled] {
    cursor: not-allowed;

    &:hover {
      background: ${({ theme }) => theme.color.grayBackground};
      border-color: ${({ theme }) => theme.color.grayFocus};
    }
  }
`;

const SelectContent = styled(Select.Content).attrs({
  position: 'popper',
  side: 'right',
  align: 'center',
  sticky: 'always',
})`
  border-radius: ${({ theme }) => theme.radius.medium};
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.shadow.medium};

  transition: ${({ theme }) => theme.transition.default};
  width: 320px;

  @keyframes selectContentIn {
    from {
      opacity: 0;
      transform: scale(0.9, 0.9);
    }
    to {
      opacity: 1;
      transform: scale(1, 1);
    }
  }

  @keyframes selectContentOut {
    from {
      opacity: 1;
      transform: scale(1, 1);
    }
    to {
      opacity: 0;
      transform: scale(0.9, 0.9);
    }
  }

  &[data-state='open'] {
    animation: selectContentIn 100ms ease-out;
  }

  &[data-state='closed'] {
    animation: selectContentOut 100ms ease-in;
  }

  max-height: var(--radix-select-content-available-height);
`;

const SelectPortal = styled(Select.Portal)``;

const SelectViewPort = styled(Select.Viewport)`
  &::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.gray} !important;
    border-radius: 999px !important;
  }
  scrollbar-width: 4px;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
`;

const SelectGroup = styled(Select.Group)`
  line-height: 18px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.xsmall};
`;

const SelectLabel = styled(Select.Label)`
  padding: ${({ theme }) => theme.spacing.xsmall};
  padding-top: 0;
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const SelectItem = styled(Select.Item)<{ selected: boolean }>`
  user-select: none;
  border-radius: ${({ theme }) => theme.radius.small};
  height: 32px;
  font-size: ${({ theme }) => theme.fontSize.small};
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.default};
  padding-right: ${({ theme }) => theme.spacing.small};
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 2px;
  &[data-state='checked'] {
    background: ${({ theme }) => theme.color.blueBackground};
    color: ${({ theme }) => theme.color.blueText};

    [data-select-hover] {
      color: ${({ theme }) => theme.color.blueText};
    }
  }
  &:hover,
  &:focus {
    background-color: ${({ disabled, theme }) => (disabled ? 'none' : theme.color.grayBackground)};
    color: ${({ theme }) => theme.color.text};
    [data-select-hover] {
      color: ${({ theme }) => theme.color.grayDark};
    }
  }
  &:focus-visible {
    outline-color: transparent;
  }
  ${({ disabled, selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => (disabled ? 'none' : theme.color.grayBackground)};
    `}
`;

const SelectItemText = styled(Select.ItemText)`
  text-align: left;
`;

const SelectSeparator = styled(Select.Separator)`
  margin: 0;
  padding: 0;
`;
const SelectRoot = Select.Root;
const SelectChevronIcon = styled(Icon).attrs({
  name: 'chevron-down',
  size: 16,
})``;

const SelectIcon = ({ children }: { children?: React.ReactNode }) => (
  <SelectIconWrapper>{children ?? <SelectChevronIcon />}</SelectIconWrapper>
);

const useSelectContext = () => {
  const context = radixUseContext('Select', undefined);
  return context;
};

const SearchTextInput = styled(TextInput)`
  font-size: ${({ theme }) => theme.fontSize.small};
  border-radius: ${({ theme }) => theme.radius.medium} ${({ theme }) => theme.radius.medium} 0px 0px;
  border-width: 0;
  border-bottom-width: 1px;

  &:focus:not(:disabled) {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.color.blue};
  }
`;

export type SelectGroupType = {
  id: string;
  label: string;
  options: OptionType[];
  loading?: boolean;
  loadingComponent?: React.ReactNode;
  fixed?: boolean;
};

export type Props = {
  options: SelectGroupType[];
  value: OptionType | undefined;
  footerSection: React.ReactNode;
  searchPlaceholder: string;
  fixedGroupsAlwaysVisible?: boolean;
  alwaysShowBorder?: boolean;
  fullWidthTrigger?: boolean;
  disabled?: boolean;
  italicizeValueLabel?: boolean;
  onOpen?: () => void;
  onBlur?: () => void;
  onHover?: () => void;
  onLoadMore?: () => void;
  onChange: (value: OptionType['value']) => void;
  onSearch?: (value: string) => void;
  /** Use this function if you want to customize how the selected option is displayed. */
  renderSelectedOption?: (option: OptionType) => React.ReactNode;
};

const FullScreenSelect = React.memo(
  ({
    options,
    value,
    footerSection,
    searchPlaceholder,
    fixedGroupsAlwaysVisible,
    alwaysShowBorder,
    italicizeValueLabel,
    fullWidthTrigger,
    disabled,
    onSearch,
    onLoadMore,
    onChange,
    onOpen,
    onHover,
    renderSelectedOption,
  }: Props) => {
    const [lastTypedInput, setLastTypedInput] = useState<string>('');
    const [focusedOnSearch, setFocusedOnSearch] = useState<boolean>(false);
    const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
    const [hovered, setHovered] = useState<boolean>(false);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const isSearching = !!lastTypedInput;
    const searchDefaultSelectedOption =
      lastTypedInput && options.length ? options[0]?.options[0]?.value : undefined;

    const onOpenChange = useCallback((open: boolean) => {
      setIsSelectOpen(open);

      if (!open) {
        setLastTypedInput('');
        onSearch?.('');
      }
      if (open) {
        onOpen?.();
        // Every time the menu is opened, we want to focus the search input
        searchInputRef.current?.focus();
        setFocusedOnSearch(true);
      }
    }, []);

    const [fixedOptions, nonFixedOptions] = useMemo(
      () => R.partition(({ fixed }) => fixed ?? false, options),
      [options],
    );

    const handleHover = () => {
      setHovered(true);
      onHover?.();
    };

    const renderedValue = useMemo(() => {
      if (renderSelectedOption && value) {
        return renderSelectedOption(value);
      }

      return (
        <>
          {value?.icon && <Emoji>{value?.icon}</Emoji>}
          <Text italic={italicizeValueLabel}>{value?.label ?? searchPlaceholder}</Text>
        </>
      );
    }, [value, searchPlaceholder, renderSelectedOption, italicizeValueLabel]);

    if (!hovered) {
      return (
        <SelectRoot value={value?.value} onValueChange={onChange} onOpenChange={onOpenChange}>
          <SelectTrigger
            onFocus={handleHover}
            onMouseOver={handleHover}
            $alwaysShowBorder={alwaysShowBorder}
            $fullWidthTrigger={fullWidthTrigger}
          >
            <SelectValue hasValue={!!value}>{renderedValue}</SelectValue>
            <SelectIcon />
          </SelectTrigger>
        </SelectRoot>
      );
    }

    return (
      <SelectRoot
        value={value?.value}
        onValueChange={onChange}
        onOpenChange={onOpenChange}
        disabled={disabled}
      >
        <SelectTrigger
          onFocus={onHover}
          onMouseOver={onHover}
          $alwaysShowBorder={alwaysShowBorder}
          $fullWidthTrigger={fullWidthTrigger}
        >
          <SelectValue hasValue={!!value}>{renderedValue}</SelectValue>
          <SelectIcon />
        </SelectTrigger>

        <SelectPortal>
          <SelectContent
            // Only submits on enter when there is a search
            submitOnEnter={isSearching}
            onKeyDown={(event) => {
              // If any alphanumeric/backspace key pressed while menu is open, focus on search
              if (/^[A-Za-z0-9_\- ]$|^Backspace$/gi.test(event.key)) {
                searchInputRef.current?.focus();
                setFocusedOnSearch(true);
                return;
              }

              // if focus is not on search then don't show search default selected option
              const currentFocusElement = document.activeElement;
              if (currentFocusElement !== searchInputRef.current) {
                setFocusedOnSearch(false);
              }
            }}
          >
            <SearchTextInput
              autoFocus
              name="search"
              ref={searchInputRef}
              value={lastTypedInput}
              placeholder={searchPlaceholder}
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                setLastTypedInput(target.value);
                onSearch?.(target.value);
              }}
            />
            {(fixedGroupsAlwaysVisible || !lastTypedInput) &&
              isSelectOpen &&
              fixedOptions.map(({ id, label, options, loading, loadingComponent }) => (
                <SelectGroup key={id}>
                  <SelectLabel>{label}</SelectLabel>
                  {loading
                    ? loadingComponent ?? undefined
                    : options.map(({ value, label, disabled }) => (
                        <SelectItem
                          key={value}
                          value={value || '--'}
                          disabled={disabled ?? !value}
                          selected={focusedOnSearch && searchDefaultSelectedOption === value}
                        >
                          <SelectItemText>{label}</SelectItemText>
                        </SelectItem>
                      ))}
                </SelectGroup>
              ))}
            <SelectViewPort
              onScroll={(event) => {
                const target = event.target as HTMLInputElement;
                if (target.scrollHeight - target.scrollTop === target.clientHeight) {
                  onLoadMore?.();
                }
              }}
            >
              {isSelectOpen &&
                nonFixedOptions.map(({ id, label, options, loading, loadingComponent }) => (
                  <SelectGroup key={id}>
                    <SelectLabel>{label}</SelectLabel>
                    {loading
                      ? loadingComponent ?? undefined
                      : options.map(({ value, label }) => (
                          <SelectItem
                            key={value}
                            value={value}
                            selected={focusedOnSearch && searchDefaultSelectedOption === value}
                          >
                            <SelectItemText>{label}</SelectItemText>
                          </SelectItem>
                        ))}
                  </SelectGroup>
                ))}
              <SelectSeparator />
            </SelectViewPort>

            {footerSection}
          </SelectContent>
        </SelectPortal>
      </SelectRoot>
    );
  },
);

export {
  SelectIcon,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectPortal,
  SelectViewPort,
  SelectGroup,
  SelectLabel,
  SelectItem,
  SelectItemText,
  SelectSeparator,
  SelectRoot,
  useSelectContext,
  FullScreenSelect,
  SelectIconWrapper,
};
