import React from 'react';

import OnboardingAmountCell from 'components/plan/onboarding/OnboardingAmountCell';
import OnboardingBudgetCardRow from 'components/plan/onboarding/OnboardingBudgetCardRow';
import type { BudgetedRowData } from 'components/plan/onboarding/OnboardingBudgetCardRow';
import OnboardingCard from 'components/plan/onboarding/OnboardingCard';

import type { PlanGroupData } from 'lib/plan/Adapters';

export type GroupDataWithBudgetedValue = PlanGroupData & { budgeted?: number | null } & {
  rows: BudgetedRowData[];
};

type Props = {
  group: GroupDataWithBudgetedValue;
};

const OnboardingBudgetCard = ({ group: { name, rows, id, budgeted, canBeBudget } }: Props) => (
  <OnboardingCard
    title={name}
    rightNode={
      <OnboardingAmountCell canEdit={canBeBudget} isGroup itemId={id} value={budgeted ?? 0} />
    }
  >
    {rows.map((rowData) => (
      <OnboardingBudgetCardRow key={rowData.id} rowData={rowData} />
    ))}
  </OnboardingCard>
);

export default React.memo(OnboardingBudgetCard);
