import { isNotNil } from 'ramda-adjunct';
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';

import InvestmentsAllocationChartTooltip from 'components/investments/InvestmentsAllocationChartTooltip';
import BarChart from 'components/lib/charts/BarChart';
import Card from 'components/lib/ui/Card';
import ExportElementButton from 'components/lib/ui/ExportElementButton';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';

import { getSecurityTypeColor } from 'common/lib/investments/table';
import type { CleanedAllocationEntry } from 'common/lib/investments/types';
import THEME from 'common/lib/theme';
import { formatISODate } from 'common/utils/date';
import useMeasure from 'lib/hooks/useMeasure';

const CHART_DEFAULT_PADDING_PX = parseInt(THEME.spacing.xxlarge, 10);
const CHART_BAR_WIDTH_PX = 56;
const CHART_HEIGHT_PX = 295;

const Root = styled(Card).attrs({ hideHeader: true })`
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
`;

const Header = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  padding: ${({ theme }) => `${theme.spacing.xsmall} ${theme.spacing.xlarge}`};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const ChartRoot = styled.div`
  width: 100%;
  padding-bottom: ${CHART_DEFAULT_PADDING_PX}px;
`;

const PlaceholderContainer = styled(FlexContainer).attrs({
  alignCenter: true,
  justifyCenter: true,
})`
  height: ${CHART_HEIGHT_PX}px;
`;

const ChartExportButton = styled(ExportElementButton)`
  padding: 6px 0;
`;

type Props = {
  data: CleanedAllocationEntry[] | undefined;
  isLoading?: boolean;
};

const InvestmentsAllocationChart = ({ data = [], isLoading = false }: Props) => {
  const graphRef = useRef<HTMLDivElement>(null);

  const [ref, { width = 0 }] = useMeasure<HTMLDivElement>();
  const chartToDataKeys = useMemo(
    () =>
      data.map(({ type }) => ({
        name: type,
        color: getSecurityTypeColor(type),
      })),
    [data],
  );

  return (
    <Root ref={graphRef}>
      <Header>
        <Text weight="medium">Your Portfolio</Text>{' '}
        <ChartExportButton
          fileName={`allocation-${formatISODate(new Date())}`}
          element={graphRef.current}
          type="Investment Types"
        />
      </Header>
      <ChartRoot ref={ref}>
        <LoadingContainerWrapper isLoading={isLoading}>
          {isNotNil(data) ? (
            <BarChart
              width={width - CHART_DEFAULT_PADDING_PX}
              height={CHART_HEIGHT_PX}
              data={data}
              xDataKey="type"
              yDataKey="allocationPercent"
              barDataKeys={chartToDataKeys}
              barWidth={CHART_BAR_WIDTH_PX}
              yAxisFormatter={(percent) => `${percent}%`}
              xAxisFormatter={({ typeDisplay }) => typeDisplay}
              tooltipComponent={({ payload, active }) => {
                if (!payload || payload.length === 0) {
                  return null;
                }
                const payloadData = payload[0].payload;

                return (
                  <InvestmentsAllocationChartTooltip
                    active={active}
                    allocationPercent={payloadData.allocationPercent}
                    label={payloadData.typeDisplay}
                    title="Your Portfolio Allocation"
                    color={getSecurityTypeColor(payloadData.type)}
                  />
                );
              }}
            />
          ) : (
            <PlaceholderContainer>
              <Text>Sorry, there was an error loading this chart.</Text>
            </PlaceholderContainer>
          )}
        </LoadingContainerWrapper>
      </ChartRoot>
    </Root>
  );
};

const LoadingContainerWrapper = ({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children: React.ReactNode;
}) => (
  <>
    {isLoading ? (
      <PlaceholderContainer>
        <LoadingSpinner />
      </PlaceholderContainer>
    ) : (
      children
    )}
  </>
);

export default InvestmentsAllocationChart;
