import React from 'react';

import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import CancelSubscriptionDiscountOfferModal from 'components/settings/billing/cancel/CancelSubscriptionDiscountOfferModal';
import ContactSupportFlow from 'components/support/ContactSupportFlow';

import { useStackContext } from 'common/lib/contexts/StackContext';
import { track } from 'lib/analytics/segment';
import { useModalContext } from 'lib/contexts/ModalContext';
import useTrack from 'lib/hooks/useTrack';

import { CancelFlowEventNames } from 'common/constants/analytics';

import type { CancellationReason } from 'common/generated/graphql';

type Props = {
  reason: CancellationReason;
};

/**
 * This is part of the new cancellation flow which is behind the feature flag: web-cancel-discount-flow
 */
const CancelSubscriptionContactSupport = ({ reason }: Props) => {
  const { pop, push } = useStackContext();
  const { close } = useModalContext();

  useTrack(CancelFlowEventNames.CancelContactSupportOffered);

  return (
    <ModalCard
      title="Can we help?"
      description="Our support team can often fix problems with account connection issues."
      onClickBackButton={pop}
    >
      <CardFooter justifyEnd>
        <DefaultButton onClick={() => push(CancelSubscriptionDiscountOfferModal, { reason })}>
          I still want to cancel
        </DefaultButton>
        <PrimaryButton
          onClick={() => {
            track(CancelFlowEventNames.CancelContactSupportAccepted);
            push(ContactSupportFlow, {
              onComplete: close,
              initialValues: { tags: ['cancelflow'] },
            });
          }}
        >
          Contact support
        </PrimaryButton>
      </CardFooter>
    </ModalCard>
  );
};

export default CancelSubscriptionContactSupport;
