import { rgba } from 'polished';
import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import type { CurrencyType } from 'components/lib/ui/currency/CashFlowCurrency';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';
import Currency from 'components/lib/ui/currency/Currency';

export const WIDTH_PX = 300;
const BORDER_COLOR = rgba('#000000', 0.5);

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.xxxsmall} 0;
  width: ${WIDTH_PX}px;
`;

const Row = styled(FlexContainer).attrs({ justifyBetween: true, alignCenter: true })<{
  $medium?: boolean;
}>`
  font-weight: ${({ theme, $medium }) =>
    $medium ? theme.fontWeight.medium : theme.fontWeight.book};
  font-size: ${({ theme }) => theme.fontSize.small};

  margin-top: ${({ theme }) => theme.spacing.xsmall};
  padding: 0 ${({ theme }) => theme.spacing.default};
`;

const MediumWeight = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const HeaderFooter = styled(Row)`
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.default};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  :first-child {
    border-bottom: 1px solid ${BORDER_COLOR};
    margin-top: 0;
  }
  :last-child {
    border-top: 1px solid ${BORDER_COLOR};
  }
`;

export type AmountsTooltipRowProps = {
  title: string;
  value: number | undefined;
  type?: CurrencyType;
  emphasis?: boolean;
};

const AmountsRow = ({ title, value, type, emphasis }: AmountsTooltipRowProps) => (
  <>
    <Text>{title}</Text>
    <MediumWeight>
      {type ? (
        <CashFlowCurrency round type={type} emphasis={emphasis} value={value} />
      ) : (
        <Currency round value={value} />
      )}
    </MediumWeight>
  </>
);

type Props = {
  rows: AmountsTooltipRowProps[];
  title?: string;
};

const AmountsTooltip = ({ title, rows }: Props) => {
  const footer = R.last(rows);

  return (
    <Root>
      {!!title && <HeaderFooter>{title}</HeaderFooter>}
      {R.dropLast(1, rows).map((row) => (
        <Row key={row.title}>
          <AmountsRow {...row} />
        </Row>
      ))}
      {footer && (
        <HeaderFooter>
          <AmountsRow {...footer} />
        </HeaderFooter>
      )}
    </Root>
  );
};

export default AmountsTooltip;
