import React from 'react';
import Countdown from 'react-countdown';
import { useSelector } from 'react-redux';

import NestedDropdownMenu from 'components/lib/ui/NestedDropdownMenu';
import NestedDropdownSubMenu from 'components/lib/ui/NestedDropdownSubMenu';

import { getUserId, getUser, getTokenExpirationDate } from 'common/state/user/selectors';
import toast from 'lib/ui/toast';
import { copyToClipboard } from 'utils/clipboard';

const EmployeeMenuDebugInfo = () => {
  const userId = useSelector(getUserId);
  const user = useSelector(getUser);
  const tokenExpiration = useSelector(getTokenExpirationDate);

  const copyText = (text: string) => {
    copyToClipboard(text);
    toast({
      title: 'Copied to clipboard',
    });
  };

  return (
    <NestedDropdownSubMenu title="Debug Info">
      <NestedDropdownMenu
        items={[
          {
            title: 'User ID',
            right: userId,
            onClick: () => userId && copyText(userId),
          },
          {
            title: 'External User ID',
            right: user?.external_id,
            onClick: () => user?.external_id && copyText(user.external_id),
          },
          {
            title: 'Session Expiration',
            right: tokenExpiration ? (
              <Countdown
                date={tokenExpiration}
                renderer={({ minutes, seconds }) => `${minutes}:${seconds}`}
              />
            ) : (
              'None'
            ),
          },
        ]}
      />
    </NestedDropdownSubMenu>
  );
};

export default EmployeeMenuDebugInfo;
