import React, { useMemo } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import usePremiumStripeOfferings from 'common/lib/hooks/premium/usePremiumStripeOfferings';
import { getMonthlyPriceDisplayStringForAnnualPrice } from 'common/lib/premium/adapters';

import { CHECK, PLAN_COMPARISON_TABLE, PRICE_PLACEHOLDER } from 'common/constants/premium';

import { PaymentPeriod } from 'common/generated/graphQlTypes/globalTypes';

const BADGE_HEIGHT_PX = 24;
const COLUMN_WIDTH = '110px';
const RIGHT_PADDING = '12px';

const Root = styled.div`
  position: relative;
  padding-top: ${BADGE_HEIGHT_PX / 2}px;
  text-align: left;
  padding-right: ${RIGHT_PADDING};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr ${COLUMN_WIDTH};
  row-gap: ${({ theme }) => theme.spacing.default};
  position: relative;
  padding-bottom: ${({ theme }) => theme.spacing.default};
  padding-top: 30px;
`;

const HeaderText = styled(Text).attrs({ align: 'center', weight: 'medium' })`
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
`;

const ColumnBackground = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  position: absolute;
  top: ${BADGE_HEIGHT_PX / 2}px;
  right: ${RIGHT_PADDING};
  bottom: 0;
  width: ${COLUMN_WIDTH};
  background: ${({ theme }) => theme.color.orangeBackground};
  border: 1px solid ${({ theme }) => theme.color.orangeFocus};
  border-radius: ${({ theme }) => theme.radius.small};
  box-shadow: 0px 1px 2px rgba(8, 40, 100, 0.1);
`;

const Badge = styled.div`
  background: ${({ theme }) => theme.color.orange};
  color: ${({ theme }) => theme.color.textWhite};
  border-radius: ${({ theme }) => theme.radius.small};
  padding: 0 ${({ theme }) => theme.spacing.xsmall};
  text-transform: uppercase;
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: 1px;
  box-shadow: 0px 4px 8px rgba(8, 40, 100, 0.08);
  transform: translateY(-50%);
  width: calc(100% + ${({ theme }) => theme.spacing.default});
  text-align: center;
`;

type Props = {
  className?: string;
  promoCode?: string;
};

const PremiumPlanComparisonTable = ({ className, promoCode }: Props) => {
  const { plans } = usePremiumStripeOfferings({ promoCode });

  const priceDisplayString = useMemo(() => {
    const annualPlan = plans.find(({ period }) => period === PaymentPeriod.YEARLY);
    return getMonthlyPriceDisplayStringForAnnualPrice(
      annualPlan?.discountedPricePerPeriodDollars ?? annualPlan?.pricePerPeriodDollars,
    );
  }, [plans]);

  return (
    <Root className={className}>
      <ColumnBackground>
        <Badge>Premium</Badge>
      </ColumnBackground>
      <Grid>
        {PLAN_COMPARISON_TABLE.map(([feature, ...columns], rowIndex) => (
          <React.Fragment key={feature}>
            <Text>{feature}</Text>
            {columns.map((value, index) =>
              rowIndex === 0 ? (
                <HeaderText key={index}>
                  {value === PRICE_PLACEHOLDER ? priceDisplayString : value}
                </HeaderText>
              ) : (
                <Text key={index} align="center" color="orangeText" weight="medium" size="small">
                  {value === CHECK ? <Icon name="check" size={24} /> : value}
                </Text>
              ),
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Root>
  );
};

export default PremiumPlanComparisonTable;
