import { DateTime } from 'luxon';
import type { Moment } from 'moment';
import moment from 'moment';
import { rgba } from 'polished';
import { range } from 'ramda';
import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { SingleDatePicker as ReactDatesSingleDatePicker } from 'react-dates';
import styled, { css } from 'styled-components';

import NativeSelect from 'components/lib/form/NativeSelect';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Tooltip from 'components/lib/ui/Tooltip';
import IconButton from 'components/lib/ui/button/IconButton';

import {
  color,
  fontSize,
  fontWeight,
  radius,
  shadow,
  spacing,
  transition,
} from 'common/lib/theme/dynamic';
import fieldStyleMixin, { fieldStyleFocus, fieldStyleHover } from 'lib/styles/fieldStyleMixin';

const ARROW_SIZE_PX = 16;
const DEFAULT_INITIAL_YEAR = 2000;

const Root = styled.div<{ $small: boolean; $isFocused?: boolean }>`
  --container-width-px: 345px;
  --default-padding-px: 12px;
  --border-radius: ${radius.medium};

  position: relative;

  .SingleDatePicker {
    width: 100%;
    border-radius: var(--border-radius);

    &_picker {
      background: transparent;
      top: 44px !important; /* 36px input + 8px margin */
      border: 1px solid ${color.grayFocus};
      border-radius: var(--border-radius);
    }
  }

  .SingleDatePickerInput {
    width: 100%;
    background: transparent;
  }

  .DateInput {
    flex: 1;
    display: flex;
    align-items: center;
    border: 1px solid ${color.grayFocus};
    border-radius: ${radius.small};
    transition: ${transition.default};
    background: transparent;
    position: relative;
    width: 100%;

    :hover {
      ${({ $isFocused, theme }) => !$isFocused && fieldStyleHover({ theme })};
    }

    &__disabled {
      background: ${color.white};
    }

    ${({ $isFocused, theme }) => $isFocused && fieldStyleFocus({ theme })};
    .DateInput {
      flex: 1;
      border-radius: ${radius.small};
      background: transparent;

      &_fang {
        display: none;
      }

      &_fangStroke {
        stroke: ${color.grayBackground};
        fill: ${color.white};
      }

      &_input {
        ${({ theme, $small }) => fieldStyleMixin({ theme, small: $small, dynamic: false })};

        ${({ $small }) =>
          $small &&
          css`
            padding: 4px 12px;
          `}
        line-height: unset;
        border: 0;
        padding-right: 38px; /* calendar icon */

        :focus {
          color: ${color.blueDark};

          ::placeholder {
            color: ${({ $isFocused, theme }) =>
              $isFocused ? theme.color.gray : theme.color.textLight};
          }
        }

        :disabled {
          color: ${color.textLight};
          background-color: ${color.white};
        }
      }
    }
  }

  .DateInput_arrow {
    position: relative;
    top: -2px;
  }

  .CalendarDay {
    &__default {
      color: ${color.text};
      background: ${color.white};
      border-color: ${color.grayFocus};
    }

    &:hover:not(&__selected):not(&__blocked_out_of_range) {
      background: ${({ theme }) => rgba(theme.color.blue, 0.1)};
      box-shadow: inset 0 0 0 1px ${color.blue};
      color: ${color.blue};
    }

    &__selected {
      background: ${color.blue};
      border-color: ${color.blue};
      color: ${color.textWhite};
      font-weight: ${fontWeight.medium};

      &:hover {
        background: ${color.blueDark};
        border-color: ${color.blueDark};
      }
    }

    &__today:not(&__selected) {
      color: ${color.blue};
    }

    &__blocked_out_of_range {
      color: ${color.textLight};
      cursor: not-allowed;
    }
  }

  .CalendarMonth_caption {
    color: ${color.text};
    padding-bottom: 50px;

    > strong {
      font-weight: ${fontWeight.medium};
    }
  }

  .DayPicker {
    background: ${color.white};
    min-width: var(--container-width-px);
    border-radius: var(--border-radius);

    &__withBorder {
      box-shadow: ${shadow.large};
    }

    &_transitionContainer {
      min-width: var(--container-width-px);
      border-radius: var(--border-radius);
    }
  }

  .DayPicker_weekHeader {
    color: ${color.textLight};
    width: calc(100% - var(--default-padding-px) / 2);

    > ul {
      display: flex;
      justify-content: space-around;
    }
  }

  .DayPicker > div {
    display: flex;

    > div {
      flex: 1;
    }
  }

  .DayPicker_calendarInfo_horizontal {
    display: flex;
    background: ${color.white};
  }

  .CalendarMonthGrid {
    background: ${color.white};
  }

  .CalendarMonth {
    background: ${color.white};
    min-width: var(--container-width-px);

    &_table {
      width: calc(100% - var(--default-padding-px));
    }
  }

  .DayPickerNavigation_button {
    border: none;
  }

  .DayPickerNavigation_button__disabled {
    opacity: 0.5;
    cursor: disabled;
    pointer-events: none;
  }
`;

const SelectContainer = styled(FlexContainer).attrs({ justifyCenter: true })`
  margin-top: -9px;
  gap: ${spacing.xsmall};
  transform: translateX(-5px);
`;

const SmallNativeSelect = styled(NativeSelect).attrs({ small: true })`
  font-size: ${fontSize.xsmall};
  font-weight: ${fontWeight.medium};
`;

const CustomIcon = styled(Icon).attrs({ size: ARROW_SIZE_PX })`
  color: ${color.textLight};
  vertical-align: middle;
  margin: 0 ${spacing.default};
`;

const LeftArrowButton = styled(IconButton).attrs({ icon: 'arrow-left' })`
  position: absolute;
  left: ${spacing.small};
  top: 13px;
`;

const RightArrowButton = styled(IconButton).attrs({ icon: 'arrow-right' })`
  position: absolute;
  right: ${spacing.small};
  top: 13px;
`;

const TooltipContent = styled.div`
  padding: ${spacing.xsmall} ${spacing.small};
`;

const CalendarIconButton = styled(IconButton).attrs({ icon: 'calendar', size: 'xsmall' })``;

const InputIconContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  line-height: 0;
  pointer-events: none;
  user-select: none;
`;

export type SingleDatePickerProps = Omit<
  React.ComponentProps<typeof ReactDatesSingleDatePicker>,
  'date' | 'onDateChange' | 'onFocusChange' | 'focused' | 'id'
> & {
  /* Override these props from the original SingleDatePickerShape so consumer doesn't have to use moment.js */
  date: string | null;
  onDateChange: (date: SingleDatePickerProps['date']) => void;

  id?: string;
  className?: string;
  small?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  focused?: boolean;
  onFocusChange?: (arg: { focused: boolean | null }) => void;
};

const SingleDatePicker: FC<SingleDatePickerProps> = ({
  id = 'monarch--date-picker',
  className,
  date: dateProp,
  small = false,
  minDate,
  maxDate,
  onDateChange,
  onFocusChange,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const date = useMemo(
    () => (dateProp ? moment(DateTime.fromISO(dateProp).toJSDate()) : null),
    [dateProp],
  );

  const minYear = minDate ? DateTime.fromJSDate(minDate).year : DEFAULT_INITIAL_YEAR;

  const onDateChangeWrapper = useCallback(
    (d: Moment | null) => {
      if (!d) {
        onDateChange(null);
        return;
      }

      onDateChange(DateTime.fromJSDate(d.toDate()).toISODate());
    },
    [onDateChange],
  );

  const calculateIsOutsideRange = useCallback(
    (day: Moment) => {
      if (minDate && day.isBefore(minDate)) {
        return true;
      }

      if (maxDate && day.isAfter(maxDate)) {
        return true;
      }

      // Fall back to default behavior
      return day.year() < minYear;
    },
    [minDate, maxDate],
  );

  return (
    <Root $isFocused={focused} $small={small} className={className}>
      <ReactDatesSingleDatePicker
        id={id}
        {...props}
        date={date}
        onDateChange={onDateChangeWrapper}
        customCloseIcon={<CustomIcon name="x-circle" />}
        navPrev={<LeftArrowButton size="xsmall" />}
        navNext={<RightArrowButton size="xsmall" />}
        focused={focused}
        onFocusChange={({ focused }) => {
          setFocused(!!focused);
          onFocusChange?.({ focused });
        }}
        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
          <SelectContainer>
            <SmallNativeSelect
              name="month"
              value={month.month()}
              onChange={(e) => onMonthSelect(month, e.currentTarget.value)}
            >
              {moment.months().map((label, value) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </SmallNativeSelect>
            <SmallNativeSelect
              name="year"
              value={month.year()}
              onChange={(e) => onYearSelect(month, e.currentTarget.value)}
            >
              {range(
                Math.min(minYear, month.year()),
                Math.max(month.year(), DateTime.local().year) + 1, // add 1 because range is exclusive
              ).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </SmallNativeSelect>
            <Tooltip
              content={
                !month.isSame(moment(), 'month') && (
                  <TooltipContent>Go to current month</TooltipContent>
                )
              }
              portal
            >
              <CalendarIconButton
                disabled={month.isSame(moment(), 'month')}
                onClick={() => {
                  const today = moment();

                  onMonthSelect(month, String(today.month()));
                  onYearSelect(month.set({ month: today.month() }), String(today.year()));
                }}
              />
            </Tooltip>
          </SelectContainer>
        )}
        numberOfMonths={1} // show a single month
        isOutsideRange={calculateIsOutsideRange}
        enableOutsideDays={false}
        noBorder
        hideKeyboardShortcutsPanel
      />
      <InputIconContainer>
        <CustomIcon name="calendar" size={ARROW_SIZE_PX} />
      </InputIconContainer>
    </Root>
  );
};

export default SingleDatePicker;
