import type { DateTime } from 'luxon';

import type { SummaryDisplayBudgetType } from 'common/lib/budget/Adapters';
import type { GridAmounts, PlanSectionData, PlanSectionType } from 'lib/plan/Adapters';

export const getGroupDataWithBudgetedAmounts = (
  gridDisplayData: PlanSectionData[],
  gridAmounts: GridAmounts,
  type: PlanSectionType,
  month: DateTime,
) => {
  const dataForType = gridDisplayData?.find((group) => group.type === type);

  return (
    dataForType?.groups.map((group) => {
      const groupData = gridAmounts?.[type][group.id];

      const groupBudgetedAmount = groupData.aggregate[month.toISODate()]?.budgeted;

      return {
        ...group,
        budgeted: groupBudgetedAmount,
        rows: group.rows.map((row) => {
          const rowBudgetedAmount = groupData[row.id][month.toISODate()]?.budgeted;

          return {
            ...row,
            budgeted: rowBudgetedAmount,
          };
        }),
      };
    }) ?? []
  );
};

export const getTotalBudgetedAmountFromSummaryForType = (
  budgetSummaryData: SummaryDisplayBudgetType[],
  type: PlanSectionType,
) => budgetSummaryData.filter((item) => item.type === type)[0].budgeted ?? 0;
