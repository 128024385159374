import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import Banner from 'components/lib/ui/Banner';
import Icon from 'components/lib/ui/Icon';
import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import { PlanCellError } from 'components/plan/PlanCell';
import PlanColumns from 'components/plan/PlanColumns';
import { PlanGridColumn, PlanGridRow } from 'components/plan/PlanGrid';
import PlanRowTitle from 'components/plan/PlanRowTitle';

import { color, fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';
import { formatCurrencyNoCents } from 'common/utils/Currency';
import type { GetDateForColumn } from 'lib/contexts/PlanContext';

import { BUDGET } from 'common/constants/copy';

const TOOLTIP_SHOW_DELAY_MS = 200;
const TOOLTIP_MAX_WIDTH = 306;
const TOOLTIP_AVAILABLE_FLEXIBLE_BUDGET_MAX_WIDTH = 290;

const GridIcon = styled(Icon).attrs({ name: 'grid', size: 16 })`
  color: ${color.textLight};
`;

const UnallocatedBudgetText = styled.div<{ $isNegative?: boolean }>`
  color: ${({ theme, $isNegative }) => ($isNegative ? theme.color.redText : theme.color.textLight)};
  font-style: italic;

  /* Add padding to the right to account for the tooltip arrow without actually moving the text */
  padding-right: ${spacing.xsmall};
  transform: translateX(${spacing.xsmall});
`;

const HorizontalPlanRowTitle = styled(PlanRowTitle)`
  color: ${color.textLight};
  display: flex;
  align-items: center;
  cursor: default;
`;

const InfoIcon = styled(Icon).attrs({ name: 'info', size: 12 })`
  margin-left: ${spacing.xsmall};
`;

const TooltipContent = styled.div`
  margin: ${spacing.xsmall} ${spacing.small};
`;

export const PlanUnallocatedBudgetRowTitle = () => (
  <PlanGridRow>
    <HorizontalPlanRowTitle icon={<GridIcon />}>
      <Text italic>Available Flexible Budget</Text>
      <Tooltip
        content={BUDGET.FLEXIBLE_BUDGET.TOOLTIP_TEXT}
        delayShow={TOOLTIP_SHOW_DELAY_MS}
        maxWidth={TOOLTIP_AVAILABLE_FLEXIBLE_BUDGET_MAX_WIDTH}
        hyphens={false}
        portal
      >
        <InfoIcon />
      </Tooltip>
    </HorizontalPlanRowTitle>
  </PlanGridRow>
);

type PlanUnallocatedBudgetRowAmountProps = {
  amount: number;
  numColumns: number;
  error?: PlanCellError;
  categoryBudgetAmount: number;
  flexibleBudgetAmount: number;
  getDateForColumn: GetDateForColumn;
  onClickUpdateFlexBudget: () => void;
};

export const PlanUnallocatedBudgetRowAmount = React.memo(
  ({
    amount,
    numColumns,
    error,
    categoryBudgetAmount,
    flexibleBudgetAmount,
    getDateForColumn,
    onClickUpdateFlexBudget,
  }: PlanUnallocatedBudgetRowAmountProps) => {
    const [hide, setHide] = useState(false);

    // This is used to hide the tooltip after a click, and then show it again after a short delay
    const handleClick = useCallback(() => {
      setHide(true);
      onClickUpdateFlexBudget();
      setTimeout(() => setHide(false), TOOLTIP_SHOW_DELAY_MS);
    }, [onClickUpdateFlexBudget]);

    return (
      <PlanGridRow>
        <PlanColumns numColumns={numColumns} getDateForColumn={getDateForColumn}>
          {(column, date) =>
            column === 0 ? (
              <Tooltip
                content={
                  !hide && error === PlanCellError.FlexibleBudgetExceeded ? (
                    <TooltipContent>
                      Your category budgets ({formatCurrencyNoCents(categoryBudgetAmount)}) are
                      greater than your flex budget ({formatCurrencyNoCents(flexibleBudgetAmount)}).
                      Click <Link onClick={handleClick}>here</Link> to set your flex budget to the
                      sum of your category budgets.
                    </TooltipContent>
                  ) : undefined
                }
                maxWidth={TOOLTIP_MAX_WIDTH}
                delayShow={TOOLTIP_SHOW_DELAY_MS}
                place="right"
                clickable
                portal
              >
                <UnallocatedBudgetText $isNegative={amount < 0}>
                  {formatCurrencyNoCents(amount)}
                </UnallocatedBudgetText>
              </Tooltip>
            ) : (
              <PlanGridColumn />
            )
          }
        </PlanColumns>
      </PlanGridRow>
    );
  },
);

const UnallocatedBudgetErrorBanner = styled(Banner).attrs({ type: 'error' })`
  font-size: ${fontSize.xsmall};
  padding: ${spacing.xsmall} ${spacing.small};
  line-height: 150%;

  ${Link} {
    text-decoration: underline;
    font-weight: ${fontWeight.medium};
    color: ${color.redText};

    &:hover {
      color: ${color.redDark};
    }
  }
`;

export const PlanUnallocatedErrorBanner = ({
  onClickUpdateFlexBudget,
}: Pick<PlanUnallocatedBudgetRowAmountProps, 'onClickUpdateFlexBudget'>) => (
  <UnallocatedBudgetErrorBanner>
    Your category budgets are greater than your flex budget. Click{' '}
    <Link onClick={onClickUpdateFlexBudget}>here</Link> to set your flex budget to the sum of your
    category budgets.
  </UnallocatedBudgetErrorBanner>
);
